export enum ADDRESS_TYPE {
    Current = 1,
	Prior = 2,
	Work = 3,
	Mailing = 4,
	Other = 5,
	BeneficiaryAddress = 6,
	Minor = 7,
	Employer = 8
}

export enum ADDRESS_TYPE_NAME {
    Current = 'current',
	Prior = 'prior',
	Work = 'work',
	Mailing = 'mailing',
	Other = 'other',
	BeneficiaryAddress = 'beneficiaryaddress',
	Minor = 'minor',
	Employer = 'employer',
	HomeAddress = 'HomeAddress'
}