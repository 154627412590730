declare var environment: any;

import { Injectable } from '@angular/core';
import { BaseService } from 'services/abstracts';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProxyService extends BaseService {

  checkProxyAccess(): Observable<boolean> {
    const organization = environment.organizationName || 'axos';
    return this._get(`auth/access/${organization}`).pipe(
      map(({ success }) => success)
    );
  }

  handoffApplication(applicationId: string) {
    return this._post(`api/proxy/application/${applicationId}/handoff`).pipe(
      map(({ success }) => success)
    );
  }

  repCodes(){
    return this._get<any>(`user/repcodes`);
  }

  verifyUserAccessToApplication(applicationId: string): Observable<boolean> {
    return this._get(`application/${applicationId}/verifyUserAccess`).pipe(
      map(({ success }) => success)
    );
  }

  setProxyDeclinedTrustedSelection(applicantId: string, declinedTrusted: boolean){
    return this._post<any>(
      `contact/disclosure/${applicantId}?trustedDeclined=${declinedTrusted}`).pipe(
        map(response => response.success)
    )
  }
}
