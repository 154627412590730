// RxJs
import { take, combineLatest, map } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getBusinessApplicant, getApplication } from 'store/selectors';
// Navigation
import { FlowNavigationService } from 'app/core/services/flow-navigation.service';
import { FlowBaseGuard } from 'app/core/guards';


export abstract class BusinessBaseGuard extends FlowBaseGuard {

    constructor(store$: Store<EnrollmentState>, navService: FlowNavigationService) {
        super(store$, navService);
    }

    getData() {
        const business$ = this.store$.select(getBusinessApplicant).pipe(take(1));
        const application$ = this.store$.select(getApplication).pipe(take(1));
        return business$.pipe(
            combineLatest(application$),
            map(result => {
                return {
                    business: result[0],
                    application: result[1]
                }
            })
        )
    }
}
