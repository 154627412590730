import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { State } from 'models';

import { zipLeadingZeros } from 'app/core/utils';
import { EnrollmentState } from 'store';
import { Store } from '@ngrx/store';
import { getCurrentApplicantType } from 'store/selectors';

@Component({
  selector: 'app-address-form',
  styleUrls: ['./address-form.component.scss'],
  templateUrl: './address-form.component.html'
})
export class AddressFormComponent implements OnInit {
  currentApplicantType$ = this.store$.select(getCurrentApplicantType);

  @Input() form: FormGroup;
  @Input() addressName: string;
  @Input() states: State[];
  @Input() showMailingCheckbox: boolean = false;
  @Input() isAddressUniversal: boolean = false;
  @Output() onChangeSameAddress: EventEmitter<null> = new EventEmitter();

  constructor(private store$: Store<EnrollmentState>) { }

  ngOnInit(): void {
    if (this.form.get("zip").value) {
      this.form.get("zip").patchValue(zipLeadingZeros(this.form.get("zip").value))
    }
  }

  onClickSameAddress() {
    this.onChangeSameAddress.emit();
  }
  onEndfocus(e){
    console.log(e)
    //sets cursor position at end of MaskedTextBox
    e.selectionStart=e.selectionEnd = e.maskedValue.length;
    }

    omitSpecialChar(event){
      var k;  
      k = event.charCode;  //         k = event.keyCode;  (Both can be used)
      return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
    }
}
