import { createAction, props } from "@ngrx/store";
import { ContactInformation } from "models/proxy";
import { OptionLevel } from "models/wl";

export const getOptionLevels = createAction(
    '[WL] Get Option Levels'
);

export const getOptionLevelsSuccess = createAction(
    '[WL] Get Option Levels Success',
    props<{ optionLevels: OptionLevel[] }>()
);

export const getOptionLevelsFail = createAction(
    '[WL] Get Option Levels Fail',
    props<{error: string}>()
);

export const setOptionLevel = createAction(
    '[WL] Set Option Level',
    props<{ optionLevel: OptionLevel }>()
);

export const setOptionLevelSuccess = createAction(
    '[WL] Set Option Level Success',
    props<{ optionLevel: OptionLevel }>()
);

export const setOptionLevelFail = createAction(
    '[WL] Set Option Level Fail',
    props<{ error: string }>()
);

export const getSelectedOptionLevel = createAction(
    '[WL] Get Selected Option Level'
);

export const getSelectedOptionLevelSucces = createAction(
    '[WL] Get Selected Option Level Succes',
    props<{ optionLevel: OptionLevel }>()
);

export const getSelectedOptionLevelFail = createAction(
    '[WL] Get Selected Option Level Fail',
    props<{ error: string }>()
);

export const setTrustedContact = createAction(
    '[WL] Set Trusted Contact',
    props<{ applicantId: string, trustedContact: ContactInformation, skipHistory?: boolean }>()
);

export const setTrustedContactSuccess = createAction(
    '[WL] Set Trusted Contact Success',
    props<{ trustedContact: ContactInformation }>()
);

export const setTrustedContactFail = createAction(
    '[WL] Set Trusted Contact Fail',
    props<{ error: string }>()
);

export const setDeclinedTrustedSelection = createAction(
    '[WL] Set declined trusted selection',
    props<{ applicantId: string, declinedTrusted: boolean, isProxy?: boolean, trustedContact:any}>()
)

export const getTrustedContact = createAction(
    '[WL] Get Trusted Contact',
    props<{ applicantId: string }>()
);

export const getTrustedContactSucces = createAction(
    '[WL] Get Trusted Contact Success',
    props<{ trustedContact: ContactInformation }>()
);

export const getTrustedContactFail = createAction(
    '[WL] Get Trusted Contact Fail',
    props<{ error: string }>()
);

export const clearWhiteLabelState = createAction(
    '[WL] Clear State'
);