import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'axos-error',
  host: {
    class: 'uk-error',
  }
})
export class ErrorDirective {

  @HostBinding('attr.id') @Input() id: string;
  uniqueId = 0;

  constructor() {
    this.id = `axos-error-${this.uniqueId++}`;
  }
}
