declare var environment: any;

import { Component, ChangeDetectionStrategy, ViewChild, OnInit, ElementRef, Renderer2 } from '@angular/core';
// NgRx Store
import { Store, select } from '@ngrx/store';
import { EnrollmentState, GetBrandLogoAction } from 'store';
// Selectors
import { getBrandLogo, getBrokerEmail, getBrokerPhone, getOrganizationEmail, getOrganizationPhone } from 'store/selectors';
import { switchMap, filter, first, map } from 'rxjs/operators';

@Component({
    selector: 'axos-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  showPhone = false;
  showEmail = false;
  @ViewChild('logoImg', { static: true }) logoImg: ElementRef;

  public isAxosUniversal = environment.isAxosUniversal;

  organizationEmail = environment.organizationEmail;
  organizationPhone = environment.organizationPhone;
  brokerPhone$ = this.store.pipe(select(getBrokerPhone));
  brokerEmail$ = this.store.pipe(select(getBrokerEmail));

  brandLogo$ = this.store.select(getBrandLogo).pipe(
    switchMap(logo => {
      if(!logo) {
        this.store.dispatch(new GetBrandLogoAction(environment.brandName));
      }
      return this.store.select(getBrandLogo).pipe(filter(brandLogo => !!brandLogo));
    }),
    first()
  );

  constructor(
    private store: Store<EnrollmentState>,
    private render: Renderer2
  ) { }

  ngOnInit() {
    const nImg = this.logoImg.nativeElement;
    this.render.listen(nImg, 'error', () => this.render.setStyle(nImg, 'display', 'none'));
  }

  togglePhone() { this.showPhone = !this.showPhone; }
  toggleEmail() { this.showEmail = !this.showEmail; }
}
