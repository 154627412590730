import { Injectable } from '@angular/core';
import { BaseService } from './abstracts/base.service';
import { AuditLogRequest } from 'models';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService extends BaseService {

  logAction(message: string, applicationApplicantType: string, eventContextTypeId: number) {
    const request: AuditLogRequest = {
      eventContextTypeId,
      message,
      applicationApplicantType,
      level: 1
    };
    return this._post('auditLog/action', request);
  }
}
