import { ModuleRouteConfig } from 'models/navigation';

export const LANDING_PARENT_PATH = '';
export const LANDING_PARENT_PATH_OLD = 'landing';
export const LANDING_MODULE_PREFIX = 'LANDING';

export const LANDING_ROUTES: { [key: string]: ModuleRouteConfig } = {
  HOME: {
    name: `${LANDING_MODULE_PREFIX}_HOME`,
    path: ''
  },
  GETTING_STARTED: {
    name: `${LANDING_MODULE_PREFIX}_GETTING-STARTED`,
    path: 'register'
  },
  E_AGREEMENT: {
    name: `${LANDING_MODULE_PREFIX}_E_CONSENT`,
    path: 'e-consent'
  },
  E_AGREEMENT_PARAM: {
    name: `${LANDING_MODULE_PREFIX}_E_CONSENT`,
    path: 'e-consent/:guid'
  },
  REGISTRATION: {
    name: `${LANDING_MODULE_PREFIX}_REGISTRATION`,
    path: 'registration'
  },
  PRODUCT_ACCOUNT: {
    name: `${LANDING_MODULE_PREFIX}_PRODUCT_ACCOUNT`,
    path: 'productAccount'
  },
  CONFIRM_IDENTITY: {
    name: `${LANDING_MODULE_PREFIX}_CONFIRM_IDENTITY`,
    path: 'confirm-identity'
  },
  SSO_ERROR: {
    name: `${LANDING_MODULE_PREFIX}_SSO-ERROR`,
    path: 'error'
  },
  ERROR: {
    name: `${LANDING_MODULE_PREFIX}_ERROR`,
    path: 'systemError'
  },
  CREATE_USER: {
    name: `${LANDING_MODULE_PREFIX}_CREATE-USER`,
    path: 'createUser'
  },
  UNIVERSAL_HOME: {
    name: `${LANDING_MODULE_PREFIX}_UNIVERSAL_HOME`,
    path: 'universal_home'
  },
  CONFIGURATION_UNDERWAY: {
    name: `${LANDING_MODULE_PREFIX}_CONFIGURATION_UNDERWAY`,
    path: 'configurationUnderway'
  },
  // PRESERVING OLD PATH AND REDIRECT TO AVOID BREAKING CHANGES TO SITECORE
  GETTING_STARTED_OLD: {
    name: `${LANDING_MODULE_PREFIX}_GETTING-STARTED`,
    path: 'gettingStarted'
  },
  HOME_OLD: {
    name: `${LANDING_MODULE_PREFIX}_HOME`,
    path: 'home'
  }
};
