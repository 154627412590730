import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {

    transform(phone: string, useAltFormat: boolean = false): string {
        var mask = new RegExp('^(\\d{3})(\\d{3})(\\d{4}).*');
        var value = phone.toString().trim().replace(/^\+|-|\(|\)| /g, '')
        const replace = useAltFormat ? '($1) $2-$3' : '$1-$2-$3';

        return value.replace(mask, replace)
    }

}