export enum INVESTMENT_GOALS_ENUM {
    RainyDay = 'rainy_day',
    Retirement = 'retirement',
    Custom = 'custom',
    BuildWealth = 'build_wealth',
}

export enum RETIREMENT_ACCOUNT_TYPE_ENUM {
    traditional_ira = 'traditional_ira',
    personal_investment_account = 'personal_investment_account',
    roth_ira = 'roth_ira'
}

export enum RETIREMENT_ACCOUNT_TYPE_NAME {
    TraditionalIRA = 'Traditional IRA',
    PersonalInvestmentAccount = 'Personal Investment Account',
    RothIRA = 'Roth IRA'
}

export const RETIREMENT_ACCOUNT_TYPE_TO_NAME = {
    [RETIREMENT_ACCOUNT_TYPE_ENUM.traditional_ira]: RETIREMENT_ACCOUNT_TYPE_NAME.TraditionalIRA,
    [RETIREMENT_ACCOUNT_TYPE_ENUM.personal_investment_account]: RETIREMENT_ACCOUNT_TYPE_NAME.PersonalInvestmentAccount,
    [RETIREMENT_ACCOUNT_TYPE_ENUM.roth_ira]: RETIREMENT_ACCOUNT_TYPE_NAME.RothIRA
}