import { Action, createReducer, on } from "@ngrx/store";
import { WhiteLabelState } from "store/states/white-label.state";
import * as fromActions from '../actions/white-label.actions';

export const initialState: WhiteLabelState = {
    optionLevels: [],
    optionLevel: null,
    trustedContact: null,
    declinedTrustedContact: false
};

export const whiteLabelReducer = createReducer(
    initialState,
    on(
        fromActions.getOptionLevelsSuccess, (state, { optionLevels }) => ({...state, optionLevels: optionLevels })
    ),
    on(
        fromActions.getOptionLevelsFail, (state) => ({...state, optionLevels: [] })
    ),
    on(
        fromActions.setOptionLevelSuccess,
        fromActions.getSelectedOptionLevelSucces, 
        (state, { optionLevel }) => ({...state, optionLevel: optionLevel })
    ),
    on(
        fromActions.setOptionLevelFail, (state) => ({...state, optionLevel: null })
    ),
    on(
        fromActions.setTrustedContactSuccess, (state, { trustedContact }) => ({...state, trustedContact: trustedContact})
    ),
    on(
        fromActions.setTrustedContactFail, (state) => ({...state, trustedContact: null})
    ),
    on(
        fromActions.setDeclinedTrustedSelection, 
        (state, { declinedTrusted }) => ({...state, declinedTrustedContact: declinedTrusted})
    ),
    on(
        fromActions.clearWhiteLabelState, () => ({...initialState})
    )
);

export function reducer(state: WhiteLabelState | undefined, action: Action){
    return whiteLabelReducer(state, action);
}