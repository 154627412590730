import { Injectable } from '@angular/core';
import { ContactInformation } from 'models/proxy';
import { OptionLevel } from 'models/wl';
import { map } from 'rxjs/operators';
import { BaseService } from 'services';

declare var environment: any;

@Injectable()
export class WhiteLabelService extends BaseService {
  organization = environment.organizationName;

  getOptionLevels(){
    return this._get(`generic/organization/${this.organization}/optionslevels`).pipe(
      map(({ data }) => data)
    );
  }

  setOptionLevel(option: OptionLevel, appId: string){
    return this._post<any>(
      `application/${appId}/features/requestedlevel`,
      {feature: 'options', requestedLevel: option? option.id: null }
    ).pipe(
      map(response => response.success)
    );
  }

  getSelectedOptionLevel(appId: string){
    return this._get(`application/${appId}/features/options/requestedlevel`).pipe(
      map(({ data }) => data)
    );
  }

  setTrustedContact(applicantId: string, contact: ContactInformation, skipHistory: boolean = false){
    const requestBody = contact ? [contact] : [];
    return this._post<any>(
      `contact/${applicantId}?skipHistory=${skipHistory}`, requestBody).pipe(
      map(response => response)
    );
  }

  setDeclinedTrustedSelection(applicantId: string, declinedTrusted: boolean){
    return this._post<any>(
      `contact/disclosure/${applicantId}?trustedDeclined=${declinedTrusted}`).pipe(
        map(response => response.success)
    )
  }

  getTrustedContact(applicantId: string, contactTypeName: string = "relative"){
    return this._get(`generic/contact/${applicantId}?contactTypeName=${contactTypeName}`).pipe(
      map(({ data }) => data)
    );
  }
}