import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { tap, filter, first } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetIDAQuestionsAction } from 'store/actions';
import { getIDAQuestions } from 'store/selectors';
import { ApplicantIdaQuestionSessionValues } from 'models';

@Injectable()
export class IDAQuestionsResolver implements Resolve<Observable<ApplicantIdaQuestionSessionValues[]>> {

    constructor(private store$: Store<EnrollmentState>) { }

    resolve(route: ActivatedRouteSnapshot) {
      return this.store$.select(getIDAQuestions).pipe(
        tap(questions => {
          if(!questions) {
            let isRetry = Boolean[route.params['isRetry']];
            this.store$.dispatch(new GetIDAQuestionsAction(!!isRetry));
          }
        }),
        filter(questions => !!questions),
        first()
      );
   }
}
