import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseDestroyComponent } from 'app/core/components';
/* RxJs */
import { filter, takeUntil } from 'rxjs/operators';
/* NgRx */
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getChatBotSettings, getUser } from 'store/selectors';
/* Services */
import { brandColorConfigurations, ChatBotSettings } from 'models/brand/chatbot-settings.model';
import { getToken } from 'store/selectors/auth.selectors';

@Component({
  selector: 'axos-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent extends BaseDestroyComponent {
  @ViewChild('abe', { static: true }) abe: ElementRef<HTMLDivElement>;

  userId: number;
  token: string;
  controlName = 'embeddedWebSdk';
  controlReadyEventName = 'embeddedWebSdkReady';
  brand: string;
  isChatBotOpen = false;
  settings: ChatBotSettings;
  chatBotSettings$ = this.store$.select(getChatBotSettings)
    .pipe(filter(settings => !!settings));
  user$ = this.store$.select(getUser);
  token$ = this.store$.select(getToken);

  constructor(
    private store$: Store<EnrollmentState>
  ) {
    super();
   }

  ngOnInit() {
    this.chatBotSettings$
      .pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(data => {
        this.brand = data.BrandName.toLocaleLowerCase();
        this.addAbeTags(data);
        this.addListeners();
        this.loadColorConfiguration(this.brand);
      });
    
    this.user$
      .pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(data => {
        if (data != null) {
          this.userId = +data.ApplicationId;
        }
      });

    this.token$
      .pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(data => {
        if (data != null) {
          this.token = data.access_token;
        }
      });
  }

  private addAbeTags(settings: ChatBotSettings) {
    this.abe.nativeElement.insertAdjacentHTML(
      'beforeend',
      settings.Component
    );
    // Script to load widget
    this.scriptLoaderService(settings.WidgetChatBotUrl);
  }

  private scriptLoaderService(scriptSrc: string): Promise<boolean> {
    if (!scriptSrc) {
      return;
    }

    return new Promise<boolean>((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = scriptSrc;
      script.async = true;
      script.onload = () => resolve(true);
      script.onerror = (error: Event) => {
        reject(error);
      };
      document.querySelector('body').appendChild(script);
    });
  }

  private addListeners() {
    window.addEventListener(this.controlReadyEventName, this.onEmbeddedWebSdkReady.bind(this));
  }

  private onEmbeddedWebSdkReady() {
    if (this.userId) {
      window[this.controlName].authenticateUser(this.userId, { token: this.token });
    } else {
      window[this.controlName].unauthenticateUser();
    }
  }

  private loadColorConfiguration(brand: string) {
    const brandColorConfiguration = brandColorConfigurations[brand];

    Object.keys(brandColorConfiguration).forEach(key => {
      document.documentElement.style.setProperty(`--${key}`, brandColorConfiguration[key]);
    });
  }

}
