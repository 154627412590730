import { TOKEN_STORAGE_NAME, USER_STORAGE_NAME } from "app/auth/constants";
import { GET_CACHE } from "app/core/utils";
import * as fromActions from "../actions/auth.action";
import { AuthState } from "../states";
import { IOtpChallengeInfo } from "models/auth/auth.models";
import { IUserLead, PhoneLead, PhoneLeadResponse } from "models";


export const initialState: AuthState = {
  user: null, //GET_CACHE(USER_STORAGE_NAME)
  //userProspect: null,
  userLead: null,
  userLeadResponse: null,
  token: null, //GET_CACHE(TOKEN_STORAGE_NAME)
  deviceInfo: null,
  otpChallengeInfo: null,
  phoneNumber: null,
  NoOtp: null,
  challengeInfo: null,
  existingUser: null,
  loggedIn: false,
  loading: false,
  error: "",
  securityQuestions: null,
  isExisting: false,
  showRecoverySent: false,
  userIdToken: null,
  userNameToken: null,
  displayQuestion: false,
  displayLoginPage: false,
  question: {},
  stepTitle: "",
  guid: ""
};

export function reducer(
  state = initialState,
  action: fromActions.AuthAction
): AuthState {
  switch (action.type) {
    case fromActions.USER_ACTION_TYPES.REGISTER_EXISTING_USER_SUCCESS:
    case fromActions.USER_ACTION_TYPES.GET_USER_SUCCESS: {
      const user = state.user ? { ...state.user, ...action.user } : action.user;
      return { ...state, user };
    }
    case fromActions.USER_ACTION_TYPES.GET_USER_FAIL: {
      return { ...state };
    }
    case fromActions.USER_ACTION_TYPES.LOGIN: {
      return { ...state, loading: true };
    }
    case fromActions.USER_ACTION_TYPES.LOGIN_SUCCESS: {
      const UserName = action.username;
      const user = { ...state.user, UserName };
      return {
        ...state,
        token: action.token,
        loggedIn: true,
        error: "",
        isExisting: action.isExisting,
        loading: false,
        user
      };
    }
    case fromActions.USER_ACTION_TYPES.REGISTER_EXISTING_USER_FAIL:
    case fromActions.USER_ACTION_TYPES.LOGIN_FAIL: {
      return {
        ...state,
        token: null,
        loading: false,
        error: action.error
      };
    }
    case fromActions.USER_ACTION_TYPES.TOKEN_LOGIN_SUCCESS:
    case fromActions.USER_ACTION_TYPES.COOKIE_LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.token,
        error: "",
        loggedIn: true
      };
    }
    case fromActions.USER_ACTION_TYPES.TOKEN_LOGIN_FAIL:
    case fromActions.USER_ACTION_TYPES.COOKIE_LOGIN_FAIL: {
      return {
        ...state,
        token: null,
        loading: false
      };
    }
    case fromActions.USER_ACTION_TYPES.CREATE_USER_SUCCESS: {
      return { ...state };
    }
    case fromActions.USER_ACTION_TYPES.CREATE_USER_FAIL: {
      return { ...state };
    }
    case fromActions.USER_ACTION_TYPES.REFRESH_TOKEN: {
      return { ...state, loading: true };
    }
    case fromActions.USER_ACTION_TYPES.REFRESH_TOKEN_SUCCESS: {
      return { ...state, token: action.token, loading: false };
    }
    case fromActions.USER_ACTION_TYPES.REFRESH_TOKEN_FAIL: {
      return { ...state, token: null, loading: false };
    }
    case fromActions.USER_ACTION_TYPES.REDIRECT_OTP: {
      const otpChallengeInfo: IOtpChallengeInfo = {
        authenticationToken: action.challengeInfo.challengeToken,
        otp: null
      }
      return {
        ...state,
        loading: true,
        challengeInfo: action.challengeInfo,
        otpChallengeInfo
      };
    }
    case fromActions.USER_ACTION_TYPES.REQUEST_MULTIFACTOR_NoUser_OTP_SUCCESS: {
      return {
        ...state, loading: false,
         NoOtp:action.response.data.data,
         phoneNumber:action.response.PhoneNumber
         };
    }
    case fromActions.USER_ACTION_TYPES.REQUEST_MULTIFACTOR_OTP_SUCCESS: {
      return {
        ...state,
        loading: false,
        otpChallengeInfo: action.response,
        error: ""
      };
    }
    case fromActions.USER_ACTION_TYPES.REQUEST_MULTIFACTOR_OTP_FAIL: {
      return {
        ...state,
        error: action.error
      };
    }
    case fromActions.USER_ACTION_TYPES.VALIDATE_OTP_CODE_SUCCESS: {
      return {
        ...state,
        token: action.token,
        error: ""
      };
    }
    case fromActions.USER_ACTION_TYPES.VALIDATE_OTP_CODE_FAIL: {
      return {
        ...state,
        error: action.error
      };
    }
    case fromActions.USER_ACTION_TYPES.VALIDATE_EXISTING_CUSTOMER_SUCCESS: {
      const user = state.existingUser
        ? { ...state.existingUser, ...action.user }
        : action.user;
      return { ...state, existingUser: user, loading: false };
    }
    case fromActions.USER_ACTION_TYPES.GET_ENROLLMENT_URL_SUCCESS: {
      const user = state.existingUser
        ? { ...state.existingUser, ...action.user }
        : action.user;
      return { ...state, existingUser: user, loading: false };
    }
    case fromActions.USER_ACTION_TYPES.GET_SECURITY_QUESTIONS_SUCCESS: {
      return { ...state, securityQuestions: action.questions };
    }
    case fromActions.USER_ACTION_TYPES.SAVE_SECURITY_QUESTIONS_FAIL: {
      return { ...state, error: action.error };
    }
    case fromActions.USER_ACTION_TYPES.VALIDATE_EXISTING_CUSTOMER_FAIL: {
      const user = state.existingUser
        ? { ...state.existingUser, ...action.user }
        : action.user;
      return { ...state, existingUser: user, loading: false };
    }
    case fromActions.USER_ACTION_TYPES.CLEAR_ERROR: {
      return { ...state, error: "" };
    }
    case fromActions.USER_ACTION_TYPES.FORGOT_PASSWORD_SUCCESS: {
      return { ...state, showRecoverySent: true };
    }
    case fromActions.USER_ACTION_TYPES.FORGOT_PASSWORD_FAIL: {
      return { ...state, showRecoverySent: false };
    }
    case fromActions.USER_ACTION_TYPES.FORGOT_USERNAME_SUCCESS: {
      return { ...state, showRecoverySent: true };
    }
    case fromActions.USER_ACTION_TYPES.FORGOT_USERNAME_FAIL: {
      return { ...state, showRecoverySent: false };
    }
    case fromActions.USER_ACTION_TYPES.SHOW_LOGIN: {
      return { ...state, showRecoverySent: !action.show };
    }
    case fromActions.USER_ACTION_TYPES
      .VERIFY_SECURITY_QUESTION_ANSWER_SUCCESS: {
        const stepTitle = action.response;
        return { ...state, displayQuestion: false, stepTitle, error: "" };
      }
    case fromActions.USER_ACTION_TYPES.GET_RANDOM_SECURITY_QUESTION_SUCCESS: {
      const question = action.response[0];
      const stepTitle = "For your protection before resetting your password, please answer one of your security questions.";
      return {
        ...state,
        error: "",
        displayQuestion: true,
        question,
        stepTitle,
        displayLoginPage: false
      };
    }
    case fromActions.USER_ACTION_TYPES.GET_RANDOM_SECURITY_QUESTION_FAIL: {
      const error = action.error;
      return {
        ...state,
        error,
        displayQuestion: false,
        displayLoginPage: true
      };
    }
    case fromActions.USER_ACTION_TYPES.VERIFY_SECURITY_QUESTION_ANSWER_FAIL: {
      const error = action.error;
      return {
        ...state,
        displayQuestion: true,
        error
      };
    }
    case fromActions.USER_ACTION_TYPES.CHANGE_PASSWORD_FAIL:
    case fromActions.USER_ACTION_TYPES.VALIDATE_RESET_PASSWORD_FAIL: {
      const error = action.error;
      return { ...state, error };
    }
    case fromActions.USER_ACTION_TYPES.VALIDATE_RESET_PASSWORD_SUCCESS: {
      const displayQuestion = action.response.userName != null;
      const userIdToken = displayQuestion ? action.response.userIdToken : null;
      const userNameToken = displayQuestion ? action.response.userNameToken : null;
      const UserName = displayQuestion ? action.response.userName : action.response;
      const user = { ...state.user, UserName };

      return { ...state, userIdToken, user, userNameToken, displayQuestion };
    }
    case fromActions.USER_ACTION_TYPES.CREATE_LEAD_SUCCESS: {
      let phones: PhoneLead[];
      if(action.userLeadResponse.phones && action.userLeadResponse.phones.length >  0){
        phones = [
          {
            Number: action.userLeadResponse.phones[0].number,
            PhoneType: action.userLeadResponse.phones[0].phoneType
          }
        ];
      }

      const userLead: IUserLead = {
        leadId: action.userLeadResponse.leadId,
        FirstName: action.userLeadResponse.firstName,
        LastName: action.userLeadResponse.lastName,
        MiddleInitial: action.userLeadResponse.middleInitial,
        Suffix: action.userLeadResponse.suffix,
        DateTime: action.userLeadResponse.dateTime,
        SSN: action.userLeadResponse.ssn,
        Email: action.userLeadResponse.email,
        Phones: phones,
        ReceiveTextMessageAlerts: action.userLeadResponse.receiveTextMessageAlerts,
        RepCode: action.userLeadResponse.repCode
      };

      return { ...state, userLead: userLead, userLeadResponse: action.userLeadResponse };
    }
    case fromActions.USER_ACTION_TYPES.UPDATE_LEAD_SUCCESS: {
      let phones: PhoneLead[];
      if(action.userLeadResponse.phones && action.userLeadResponse.phones.length >  0){
        phones = [
          {
            Number: action.userLeadResponse.phones[0].number,
            PhoneType: action.userLeadResponse.phones[0].phoneType
          }
        ];
      }

      const userLead: IUserLead = {
        leadId: action.userLeadResponse.leadId,
        FirstName: action.userLeadResponse.firstName,
        LastName: action.userLeadResponse.lastName,
        MiddleInitial: action.userLeadResponse.middleInitial,
        Suffix: action.userLeadResponse.suffix,
        DateTime: action.userLeadResponse.dateTime,
        SSN: action.userLeadResponse.ssn,
        Email: action.userLeadResponse.email,
        Phones: phones,
        ReceiveTextMessageAlerts: action.userLeadResponse.receiveTextMessageAlerts,
        RepCode: action.userLeadResponse.repCode
      };

      return { ...state, userLead: userLead, userLeadResponse: action.userLeadResponse };
    }
    case fromActions.USER_ACTION_TYPES.CREATE_LEAD_FAIL: {
      return { ...state, userLead: null, error: action.error }
    }
    case fromActions.USER_ACTION_TYPES.SET_GUID: {
      return { ...state, guid: action.guid}
    }
    case fromActions.USER_ACTION_TYPES.CHECK_GUID_VALID_SUCCESS: {
      return { ...state,  isExisting: action.response,}
    }
    case fromActions.USER_ACTION_TYPES.CHECK_APP_VALID_SUCCESS: {
      return { ...state,  isExisting: action.response,}
    }
    case fromActions.USER_ACTION_TYPES.VERIFY_EXISTING_CUSTOMER_FAIL: {
      return { ...state, error: action.error }
    }
    case fromActions.USER_ACTION_TYPES.CLEAR_ERROR: {
      return { ...state, error: "" }
    }
  }
  return state;
}

