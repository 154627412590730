import { createAction, props } from "@ngrx/store";
import { ISimpleProduct } from "models";
import { Customer, ContactInformation, CustomerRequest, RepCode } from "models/proxy";

export const setCustomer = createAction(
    '[Proxy] Set customer',
    props<{ customer: Customer }>()
);

export const setCustomerSuccess = createAction(
    '[Proxy] Set customer success',
    props<{ customer: Customer }>()
);

export const clearCustomer = createAction(
    '[Proxy] Clear customer'
);

export const getCustomers = createAction(
    '[Proxy] Get customer'
);

export const getCustomersSuccess = createAction(
    '[Proxy] Get customer success',
    props<{ customers: Customer[] }>()
);

export const getCustomersFail = createAction(
    '[Proxy] Get customer fail'
);

export const searchCustomers = createAction(
    '[Proxy] Search customer',
    props<{search: string}>()
);

export const searchCustomersSuccess = createAction(
    '[Proxy] Search customer success',
    props<{ customers: Customer[] }>()
);

export const searchCustomersFail = createAction(
    '[Proxy] Search customer fail'
);

export const clearCustomers = createAction(
    '[Proxy] Clear customers'
);

export const setProduct = createAction(
    '[Proxy] Set product',
    props<{ products: ISimpleProduct[] }>()
);

export const saveContact = createAction(
    '[Proxy] Save contact',
    props<{
        applicantId: string,
        contact: ContactInformation,
        skipHistory?: boolean
    }>()
);

export const saveContactSucces = createAction(
    '[Proxy] Save contact success',
    props<{ contact: ContactInformation }>()
);

export const saveContactFail = createAction(
    '[Proxy] Save contact fail'
);

export const registerExisitingCustomer = createAction(
    '[Proxy] Register existing customer',
    props<{ customer: Customer }>()
);

export const handOff = createAction(
    '[Proxy] Hand Off',
    props<{ applicationId: string }>()
);

export const handOffSuccess = createAction(
    '[Proxy] Hand Off success'
);

export const getRepCodes = createAction(
    '[Proxy] Get Rep Codes'
)

export const getRepCodesSuccess = createAction(
    '[Proxy] Get Rep Codes success',
    props<{ repCodes: RepCode[] }>()
)

export const getRepCodesFail = createAction(
    '[Proxy] Get Rep Codes fail'
)

export const setRepCode = createAction(
    '[Proxy] Set Rep Code',
    props<{ repCode: string }>()
);

export const clearProxyState = createAction(
    '[Proxy] Clear State'
);

export const setProxyDeclinedTrustedSelection = createAction(
    '[Proxy] Set declined trusted selection',
    props<{ applicantId: string, declinedTrustedFlag: boolean, trustedDeclined?: any}>()
);
