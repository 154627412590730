import { EventEmitter, Output } from "@angular/core";

export interface NavigationChangedEvent {
    goBack: boolean;
    optionName?: string;
    value?: any;
}

export abstract class EnrollmentNavigationComponent {
    @Output() onNavigationChanged: EventEmitter<NavigationChangedEvent> = new EventEmitter();
    goBack() { this.onNavigationChanged.emit({ goBack: true }); }
}