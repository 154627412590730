export enum APPLICANT_STATE {
  NotStarted = 'notStarted',
  RegistrationPage = 'registrationPage',
  UserNamePassword = 'usernamePassword',
  AccountFeature = 'accountFeature',
  AccountType = 'accountType',
  AdditionalOwnerInformation = 'additionalOwnerInformation',
  PersonalInformation = 'personalInformation',
  AddressInformation = 'addressInformation',
  FinancialInformation = 'financialInformation',
  InvestmentProfile = 'investmentProfile',
  ConfirmIdentity = 'confirmIdentity',
  NumberOfTrustees = 'numberOfTrustees',
  ReviewAndSubmit = 'reviewAndSubmit',
  EditInformation = 'editInformation',
  PendingDocumentReview = 'pendingDocumentReview',
  DocumentsRequested = 'documentsRequested',
  DocumentsReceived = 'documentsReceived',
  Complete = 'complete',
}
