export * from './auth.effects';
export * from './applicants.effects';
export * from './application.effects';
export * from './brand.effects';
export * from './products.effects';
export * from './catalogs.effects';
export * from './rules.effects';
export * from './disclosures.effects';
export * from './organization.effects';

import { AuthEffects } from './auth.effects';
import { ApplicantsEffects } from './applicants.effects';
import { BrandEffects } from './brand.effects';
import { ProductsEffects } from './products.effects';
import { GenericCatalogsEffects } from './catalogs.effects';
import { ApplicationEffects } from './application.effects';
import { DisclosuresEffects } from './disclosures.effects';
import { LayoutEffects } from './layout.effects';
import { RulesEffects } from './rules.effects';
import { RouteEffects } from './route.effects';
import { TradingEffects } from './trading.effects';
import { OrganizationEffects } from './organization.effects';
import { EnrollmentWorkflowEffects } from '../workflows'
import { ProxyEffects } from './proxy.effects';
import { WhiteLabelEffects } from './white-label.effects';
import { PdfEffects } from './pdf.effects';
import { FeatureFlagsEffects } from './feature-flags.effects';

export const effects = [
    ApplicantsEffects,
    ApplicationEffects,
    AuthEffects,
    BrandEffects,
    ProductsEffects,
    GenericCatalogsEffects,
    DisclosuresEffects,
    LayoutEffects,
    RulesEffects,
    RouteEffects,
    TradingEffects,
    OrganizationEffects,
    EnrollmentWorkflowEffects,
    ProxyEffects,
    WhiteLabelEffects,
    PdfEffects,
    FeatureFlagsEffects
]
