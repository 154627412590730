import { createSelector, createFeatureSelector } from "@ngrx/store";
import { TradingState } from "store/states/trading.state";
import { FEATURE_NAMES } from "store/constants";
import { Feature, AccountFeature } from "models";

export const selectTradingState = createFeatureSelector(FEATURE_NAMES.Trading);

export const selectAccountFeature = createSelector(
    selectTradingState, (state: TradingState) => state ? state.accountFeature : null
);

export const selectAccountSelectedFeatures = createSelector(
    selectTradingState,
    (state: TradingState) => state.accountFeature ? state.accountFeature.TradingFeatures : []
);

export const selectFinancialInformation = createSelector(
    selectTradingState,
    (state: TradingState) => state.financialInformation
);

export const selectFeatures = createSelector(
    selectTradingState, (state: TradingState) => state ? state.features: null
)

export const selectFeatureList = createSelector(
    selectTradingState,
    selectAccountFeature, (features: Feature[]) => features
);

export const selectInvestmentObjetives = createSelector(
    selectTradingState, (state: TradingState) => state.investmentObjetives ? state.investmentObjetives : null
);

export const selectInvestmentProfile = createSelector(
    selectTradingState, (state: TradingState) => state.investmentProfile
);

export const selectSkipVerifiction = createSelector(
    selectTradingState, (state: TradingState) => state.skipVerification
);
