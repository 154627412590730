import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UKValidators } from '@uikit/components/uk-validators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState, RecommendedApplicantAddressAction, SaveApplicantAddressAction } from 'store';
import { getIsManagedPortfolioOrSelfDirectedTrading, getStates, selectAddressInfoes } from 'store/selectors';

import { ZipService } from 'services';
// Models
import { Address } from 'models';
// Forms
import {
  ADDRESS_DEFAULT_VALIDATORS,
  setZipCodeValidator,
  ADDRESS_FORM_CREATOR,
  INVEST_CUSTOM_ADDRESS_FORM_CREATOR,
  INVEST_ADDRESS_DEFAULT_VALIDATORS,
} from 'app/shared-forms'
// Components
import { BaseFormComponent } from 'app/core/components';
import { ADDRESS_TYPE_NAME } from 'models/enums';
import { takeUntil } from 'rxjs/operators';
import { AddressPopupComponent } from 'app/modals/components';
import { MatDialog } from '@angular/material/dialog';

declare var environment;

@Component({
  selector: 'app-address-universal',
  templateUrl: './address-universal.component.html',
  styleUrls: ['./address-universal.component.scss'],
  providers: [ZipService],
})
export class AddressUniversalComponent extends BaseFormComponent<Address> {

  @Input() allowHomePOBox: boolean = false;
  @Input() allowMailingPOBox: boolean = false;
  @Input() addressName: string;
  @Input() mailingAddressNam: string;
  @Output() addressSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();

  states$ = this.store$.select(getStates);
  addressInfoes$ = this.store$.select(selectAddressInfoes);
  isProxy = !!environment.isProxy;
  ifAddressSame: boolean;
  recHomeAddres: any;
  isInvest: boolean = false;
  
  constructor(private fb: FormBuilder,
    private zipCodeService: ZipService,
    private store$: Store<EnrollmentState>,
    public dialog: MatDialog) {
    super();
    this.store$.select(getIsManagedPortfolioOrSelfDirectedTrading).subscribe(isInvest => this.isInvest = isInvest);
  }

  setFormValues(addressType: ADDRESS_TYPE_NAME) {

  }

  ngOnInit() {
    super.ngOnInit();
    this.form = this.fb.group({
      HomeAddressInfo: this.fb.group(this.isInvest ? INVEST_CUSTOM_ADDRESS_FORM_CREATOR() : ADDRESS_FORM_CREATOR()),
      MailingAddressInfo: this.fb.group(this.isInvest ? INVEST_CUSTOM_ADDRESS_FORM_CREATOR() : ADDRESS_FORM_CREATOR())
    });

    const homeAddressForm = <FormGroup>this.form.get('HomeAddressInfo'),
      mailingAddressForm = <FormGroup>this.form.get('MailingAddressInfo');

    this.form.get('HomeAddressInfo').get('isSameMailingAddress').valueChanges
      .subscribe(isSelected => {
        if (isSelected) {
          this.form.get('MailingAddressInfo').disable();
        }
        else {
          this.form.get('MailingAddressInfo').enable();
        }
      });

    this.form.get('HomeAddressInfo').get('isSameMailingAddress').setValue(true);

    this.addressInfoes$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(addressInfoes => {
      if (addressInfoes.length > 0) {
        if (addressInfoes.length > 1) {
          this.isSameMailingAddress(addressInfoes) ?
            this.form.get('HomeAddressInfo').get('isSameMailingAddress').setValue(true) :
            this.form.get('HomeAddressInfo').get('isSameMailingAddress').setValue(false);
        }

        addressInfoes.forEach(address => {
          if (address.addressType == ADDRESS_TYPE_NAME.Current) {
            homeAddressForm.patchValue(address);
          }
          if (address.addressType == ADDRESS_TYPE_NAME.Mailing) {
            mailingAddressForm.patchValue(address);
          }
        })
      }
    });

    const addressValidators = this.isInvest ? INVEST_ADDRESS_DEFAULT_VALIDATORS : ADDRESS_DEFAULT_VALIDATORS
    setZipCodeValidator(homeAddressForm, this.zipCodeService);
    homeAddressForm.get('street').setValidators(
      this.allowHomePOBox ?
        addressValidators.Street :
        [...addressValidators.Street, UKValidators.POBoxNotAllow]
    );

    homeAddressForm.get('street2').setValidators(
      addressValidators.Street2
    );

    setZipCodeValidator(mailingAddressForm, this.zipCodeService);
    mailingAddressForm.get('street').setValidators(
      this.allowMailingPOBox ?
        addressValidators.Street :
        [...addressValidators.Street, UKValidators.POBoxNotAllow]
    );

    mailingAddressForm.get('street2').setValidators(
      addressValidators.Street2
    );

    // this.form.get('HomeAddressInfo').get('IsSameMailingAddress').valueChanges
    // .subscribe(isSelected => {
    //   if(isSelected){
    //     this.form.get('MailingAddressInfo').disable();
    //   }
    //   else{
    //     this.form.get('MailingAddressInfo').enable();
    //   }
    // });

    //this.form.get('HomeAddressInfo').get('IsSameMailingAddress').setValue(true);

  }

  isSameMailingAddress(addressInfoes: Address[]): boolean {
    const current = addressInfoes.find(x => x.addressType == ADDRESS_TYPE_NAME.Current);
    const mailing = addressInfoes.find(x => x.addressType == ADDRESS_TYPE_NAME.Mailing);
    if (current && mailing) {
      return current.street.toLowerCase() == mailing.street.toLowerCase() &&
        (current.street2 && current.street2.toLowerCase()) == (mailing.street2 && mailing.street2.toLowerCase()) &&
        current.city.toLowerCase() == mailing.city.toLowerCase() &&
        current.state.toLowerCase() == mailing.state.toLowerCase() &&
        current.zip == mailing.zip &&
        (current.country && current.country.toLowerCase()) == (mailing.country && mailing.country.toLowerCase());
    }
    return true;
  }

  goBack() { this.back.emit(); }

  onSave() {
    const homeAddress = {
      street: this.form.controls['HomeAddressInfo'].value.street,
      street2: this.form.controls['HomeAddressInfo'].value.street2,
      zip: this.form.controls['HomeAddressInfo'].value.zip,
      city: this.form.controls['HomeAddressInfo'].value.city,
      state: this.form.controls['HomeAddressInfo'].value.state,
    },
      mailingAddress = {
        street: this.form.controls['MailingAddressInfo'].value.street,
        street2: this.form.controls['MailingAddressInfo'].value.street2,
        zip: this.form.controls['MailingAddressInfo'].value.zip,
        city: this.form.controls['MailingAddressInfo'].value.city,
        state: this.form.controls['MailingAddressInfo'].value.state,
      }

    const isSameAddress = this.form.controls['HomeAddressInfo'].value.isSameMailingAddress
    this.ifAddressSame = isSameAddress;

    /**usps adddres code */
    if (isSameAddress) {
      let address = this.setFormate(homeAddress, isSameAddress, null);
      const enteredData = { ...address };
      delete enteredData.label;
      delete enteredData.isSameAddress;
      this.store$.dispatch(new RecommendedApplicantAddressAction(enteredData, false, false));
      this.homeModalDialog(address);
    } else {
      let address = this.setFormate(homeAddress, isSameAddress, mailingAddress)
      const enteredData = { ...address };
      delete enteredData.label;
      delete enteredData.isSameAddress;
      this.store$.dispatch(new RecommendedApplicantAddressAction(enteredData, false, false));
      this.homeModalDialog(address);
    }

  }
  setFormate(homeaddress, issameAddress, mailingaddress?) {

    let homeAddress, mailAddress,zip4,zip5;
    
    if (homeaddress) {
      if(homeaddress.zip.includes('-')){
        zip4 = homeaddress.zip.split('-')[1];
        zip5 = homeaddress.zip.split('-')[0];
      }
      homeAddress = {
        id: "0",
        streetAddress1: homeaddress.street,
        streetAddress2: homeaddress.street2,
        city: homeaddress.city,
        state: homeaddress.state,
        zip5: zip5 ? zip5 : homeaddress.zip,
        zip4: zip4 ? zip4 : "",
        label: 'Home Address',
        isSameAddress: issameAddress
      }
      return homeAddress;
    }
    if (mailingaddress) {
      if(mailingaddress.zip.includes('-')){
        zip4 = mailingaddress.zip.split('-')[1];
        zip5 = mailingaddress.zip.split('-')[0];
      }
      mailAddress = {
        id: "0",
        streetAddress1: mailingaddress.street,
        streetAddress2: mailingaddress.street2,
        city: mailingaddress.city,
        state: mailingaddress.state,
        zip5: zip5 ? zip5 : mailingaddress.zip,
        zip4: zip4 ? zip4 : "",
        label: 'Mailing Address',
        isSameAddress: issameAddress
      }
      return mailAddress;
    }

  }
  homeModalDialog(address: any): void {
    const dialogRef = this.dialog.open(AddressPopupComponent, {
      width: '350px',
      data: address

    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.ifAddressSame && result) {
        this.store$.dispatch(new SaveApplicantAddressAction(result, ADDRESS_TYPE_NAME.Current, false, false));
        this.store$.dispatch(new SaveApplicantAddressAction(result, ADDRESS_TYPE_NAME.Mailing, true, false));
      }
      if (!this.ifAddressSame && result) {
        this.recHomeAddres = result;
        this.form.controls['HomeAddressInfo'].patchValue(result)

        let mailadd = {
          street: this.form.controls['MailingAddressInfo'].value.street,
          street2: this.form.controls['MailingAddressInfo'].value.street2,
          zip: this.form.controls['MailingAddressInfo'].value.zip,
          city: this.form.controls['MailingAddressInfo'].value.city,
          state: this.form.controls['MailingAddressInfo'].value.state,
        }
        let address = this.setFormate(null, this.ifAddressSame, mailadd)
        const enteredData = { ...address };
        delete enteredData.label;
        delete enteredData.isSameAddress;
        this.store$.dispatch(new RecommendedApplicantAddressAction(enteredData, false, false));
        this.mailModalDialog(address);

      }
    
    });
  }
  mailModalDialog(address) {
    const dialogRef = this.dialog.open(AddressPopupComponent, {
      width: '350px',
      data: address

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store$.dispatch(new SaveApplicantAddressAction(this.recHomeAddres, ADDRESS_TYPE_NAME.Current, false, false));
        this.store$.dispatch(new SaveApplicantAddressAction(result, ADDRESS_TYPE_NAME.Mailing, true, false));
      }
    });
  }
}
