declare var environment: any;

import { Injectable } from '@angular/core';
import { BaseService } from './abstracts';
import { IUserProspect } from 'models';

@Injectable()
export class UserProspectService extends BaseService {

    create(user: IUserProspect) {
        return this._post<IUserProspect>('consumer/userprospect', this.prepareData(user), false);
    }

    update(user: IUserProspect) {
        return this._put<IUserProspect>('consumer/userprospect', this.prepareData(user), false);
    }

    private prepareData(data: IUserProspect): IUserProspect {
        return {
            ...data,
            BrandId: environment.brandId,
            Phone: data.Phone ? data.Phone.replace(/-|\)|\(|\s/g, '') : ''
        }
    }
}
