declare var environment: any;

// Ng
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
// NgRx Store
import { Store } from "@ngrx/store";
import { EnrollmentInitilizerService } from "app/core/services";
// Core
import { GET_BRAND_TITLE } from "app/core/utils";
// RxJs
import { EnrollmentState, RefreshTokenAction } from "store";
import {
  getMobileLayout,
  getOrganizationDisplayName,
  getPageTitle,
} from "store/selectors";
// IU Kit
import { UkHandlerService, UkModalService } from "@uikit/components";
// Modals
import { SessionTimeOutComponent } from "./modals/components";
import { AppSettingsService } from "services/app-settings.service";
import { map, withLatestFrom } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  isMobile$ = this.store$.select(getMobileLayout);
  isConfigurationUnderway = false;
  private themeWrapper = document.querySelector("body");
  isWhiteLabel:boolean = environment.isWhiteLabel;

  constructor(
    private store$: Store<EnrollmentState>,
    private initilizer: EnrollmentInitilizerService,
    private ukHandlerService: UkHandlerService,
    private modalService: UkModalService,
    private settingsService: AppSettingsService,
    private router: Router,
    ngTitleService: Title
  ) {
    this.isConfigurationUnderway = environment.isConfigurationUnderway;
    
    initilizer.load();
    // hide index.html/main.ts preloader
    document.getElementById("preloader").style.display = "none";

    this.store$
      .select(getPageTitle)
      .pipe(withLatestFrom(this.store$.select(getOrganizationDisplayName)))
      .subscribe(([title, organizationdisplayName]) =>
        ngTitleService.setTitle(title ? title : organizationdisplayName)
      );

    this.ukHandlerService.sessionExpirationWarning().subscribe((isWarned) => {
      this.modalService.createModal(
        { Icon: "information" },
        false,
        SessionTimeOutComponent
      );
    });

    this.ukHandlerService.refreshToken().subscribe((needRefresh) => {
      console.log("Refresh token " + new Date().toISOString());
      this.store$.dispatch(new RefreshTokenAction(""));
    });
  }

  ngOnInit() {
    this.initilizer.setupGlance();
    this.changeTheme();
    this.initilizer.setupWebBacon();
    if(environment.organizationName == 'axos'){
      const bodyElement = document.querySelector('body');
        if (bodyElement) {
          // Add your dynamic class
          bodyElement.classList.add('axos-theme');
        }
     }
  }

  private changeTheme() {
    this.settingsService
      .settings()
      .pipe(
        map((settings) => {
          this.themeWrapper.style.setProperty(
            `--organizationName`,
            environment.organizationName
          );
          settings
            .filter((x) => x.name !== "footer")
            .map((x) => {
              let value = (x.name == 'bannerColor') ? `linear-gradient(71.95deg, ${x.value}, 67.77%,#A1A7BF 137.36%)` : x.value;
              this.themeWrapper.style.setProperty(`--${x.name}`, value);
            });
        })
      )
      .subscribe();
      
  }
}
