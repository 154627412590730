declare var environment: any;

import { Injectable, Inject } from '@angular/core';
import { of, Observable } from 'rxjs';
import { Customer, ContactInformation, ExistingCustomerRequest } from 'models/proxy';
import { BaseService } from 'services';
import { HttpClient } from '@angular/common/http';
import { BrowserTypeService, LoadingService } from 'app/core/services';
import { API_URL } from 'app/core/constants';
import { map } from 'rxjs/operators';

declare var environment: any;
declare var client: any;

@Injectable()
export class CustomerService extends BaseService {

  constructor(
    http: HttpClient,
    loadingService: LoadingService,
    private browserService: BrowserTypeService,
    @Inject(API_URL) baseUrl: string
  ) {
    super(http, loadingService, baseUrl);
  }

  search(search: string): Observable<Customer[]>{
    return this._post<any>(`existing/search`, {
      value: search,
      organizationName: environment.organizationName || 'axos',
      organizationId: environment.organizationId || 1,
      max: 100
    })
      .pipe(
        map(response => {
          if(response.success && response.data){
            return response.data;
          }
          return [];
        })
      )
  }

  getAll(): Observable<Customer[]>{
    return this._get<any>(`existing/all/${environment.organizationName}`)
      .pipe(
        map(response => {
          if(response.success && response.data){
            return response.data;
          }
          return [];
        })
      )
  }

  getDetails(identityId:string): Observable<Customer>{
    return this._get<any>(`existing/${environment.organizationName}/${identityId}`)
      .pipe(
        map(response => response.data)
      );
  }

  saveContact(applicantId: string, contact: ContactInformation, skipHistory: boolean = false){
    const requestBody = contact? [contact] : [];
    return this._post<any>(`contact/${applicantId}?skipHistory=${skipHistory}`, requestBody).pipe(
      map(response => response)
    );
  }

  registerExistingCustomer(existingCustomerRequest: ExistingCustomerRequest) {
    const browser = this.browserService.getBrowserAndDeviceInfo();
    const request =
    {
      ipAddress: client.getProperty('externalip'),
      userAgent: browser.userAgent,
      identityId: existingCustomerRequest.identityId,
      applicationRequest: {
        productCodes: existingCustomerRequest.productCodes,
        organizationName: environment.organizationName,
        organizationId: environment.organizationId,
        branchId: environment.brandId,
        applicationTypeId: existingCustomerRequest.applicationTypeId,
        applicationTypeName: existingCustomerRequest.applicationTypeName,
        channelId: environment.channelId,
        repCode: existingCustomerRequest.repCode
      }

    };

    return this._post<any>('existing', request);
  }

  handOff(applicationId: string){
    return this._get<any>(`application/${applicationId}/handoff`).pipe(
      map(response => response)
    );
  }

}
