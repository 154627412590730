import { ElementRef, HostBinding, HostListener, Input, Optional, Self } from "@angular/core";
import { NgControl } from "@angular/forms";
import { UkBoolean } from "@uikit/components";
import { UkBaseFormControl } from '@uikit/components/common';

const INVALID_INPUT = 'input type is not supported';
const VALID_INPUTS = [
  'text',
  'number',
  'password',
  'color',
  'date',
  'month',
  'week',
  'time',
  'email',
  'search',
  'tel',
  'url'
];

export abstract class BaseInputComponent extends UkBaseFormControl<string> {
  protected _type: string;
  inputValue: HTMLInputElement;

  constructor(
      protected elementRef: ElementRef, 
      @Optional() @Self() public ngControl: NgControl, 
      componentName: string = 'input') { 
    super(ngControl, componentName);
    this.inputValue = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event'])
  onInputChange($event) {
      const input = <HTMLInputElement>$event.target;
      this.value = input.value;
      this.setValue(input.value);
  }

  /** ===> Manage Focus <=== */
  @HostListener('focus')
  onInputFocus() { 
    this.focused = true; 
  }

  @HostListener('blur')
  onInputBlur() { 
    this.focused = false; 
  }

  @HostBinding('readonly') @Input() @UkBoolean() readonly;

  @HostBinding('attr.aria-describedby') ariaDescribedby: string;

  @HostBinding('attr.placeholder') @Input() placeholder: string;  

  // type property
  @HostBinding('attr.type') 
  @Input() 
  get type(): string { 
    return this._type; 
  }
  set type(value: string) {
    this._type = value || 'text';
    this.validateType();
  }
  
  @HostBinding('attr.disabled') 
  get inputDisabled() { 
    return this.disabled ? '' : null; 
  }

  onClick() { 
    this.elementRef.nativeElement.focus(); 
  }

  setValue(value) {
    if (this.inputValue.value !== value) {
        this.inputValue.value = value;
    }
    super.setValue(value);
  }

  validateType() {
      const isInvalidInput = VALID_INPUTS.indexOf(this._type) < 0;
      if (isInvalidInput) {
          throw Error(`${this._type} ${INVALID_INPUT}`);
      }
  }
}
