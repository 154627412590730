import {
  createSelector,
  createFeatureSelector
} from '@ngrx/store';
// Models
import { APPLICANT_TYPE, VESTING_TYPE_ID, NAVIGATION_DIRECTION, BRAND_ID, SOURCE_TYPES, APPLICANT_TYPE_TO_NAME, APPLICANT_TYPE_NAME } from 'models/enums';
// Utils
import { Applicant, IApplicantMetadata, IApplication } from 'models';
import {  GET_APPLICANT_TITLE, GET_APPLICANT_POSSESSIVE } from 'app/core/utils';

import { ApplicantsState } from '../states';
import { FEATURE_NAMES } from '../constants';

import { getApplicantsMetadata, getApplication, getApplicationVestingTypeId } from './application.selector';
import { getDmdEnabled } from './brand.selectors';
import { Person } from 'models/trading/person.model';

export const getApplicantsState = createFeatureSelector(FEATURE_NAMES.Applicants);

export const getApplicantsLoading = createSelector(
  getApplicantsState, (state: ApplicantsState) => state.loading
);

export const getApplicants = createSelector(
  getApplicantsState, (state: ApplicantsState) => state.applicants
);

// #region Applicant Types
export function getApplicantByType(type: APPLICANT_TYPE_NAME) {
  return createSelector(
      getApplicants, applicants => applicants.find(applicant =>  applicant.type == type)
  );
}

export const getPrimaryApplicant = getApplicantByType(APPLICANT_TYPE_NAME.Primary);

export const getJointApplicant = getApplicantByType(APPLICANT_TYPE_NAME.Joint);

export const getTeenApplicant = getApplicantByType(APPLICANT_TYPE_NAME.Teen);

export const getMinorApplicant = getApplicantByType(APPLICANT_TYPE_NAME.Minor);

export const getTrustApplicant = getApplicantByType(APPLICANT_TYPE_NAME.Trust);

export const getBusinessApplicant = getApplicantByType(APPLICANT_TYPE_NAME.Business);
// #endregion

export const getApplicantsLoaded = createSelector(
  getApplicantsState,
  (state: ApplicantsState) => state.loaded
);

// #region Current Applicant
export const getCurrentApplicantType = createSelector(
  getApplicantsState,
  (state: ApplicantsState) => state.currentApplicantType
);

// export const getApplicantId = createSelector(
//   getApplication,
//   (state: ApplicantsState) => state.
// );

export const getNumberOfApplicants = createSelector(
getApplicantsState,
(state: ApplicantsState) => state.applicants.length
);

export const getCurrentApplicantOrder = createSelector(
getApplicantsState,
getNumberOfApplicants,
(state: ApplicantsState, nApplicants: number) => {
  // TODO: Add Busines logic
  switch(state.currentApplicantType) {
    case APPLICANT_TYPE_NAME.Primary:
      return 0;
    case APPLICANT_TYPE_NAME.Joint:
    case APPLICANT_TYPE_NAME.Minor:
    case APPLICANT_TYPE_NAME.Teen:
      return 1;
    case APPLICANT_TYPE_NAME.Trust:
        return nApplicants - 1;
  }
}
);

export const getCurrentApplicant = createSelector(
getApplicants,
getCurrentApplicantType,
(applicants: Applicant[], applicantType: APPLICANT_TYPE_NAME) => {
  return applicants.find(applicant => applicant.type.toLowerCase() == applicantType)
}
);

export const getCurrentApplicantFullName = createSelector(
getCurrentApplicant,
(applicant: Applicant) => applicant ? applicant.FirstName + ' ' + applicant.LastName : null
);

export const getCurrentApplicantId = createSelector(
  getCurrentApplicant, (applicant: Applicant) => applicant ? applicant.applicantId : null
)

export const getCurrentApplicantExisting = createSelector(
  getCurrentApplicant, (applicant: Applicant) => applicant ? applicant.isExisting : null
)

export const selectPersonalInformation = createSelector(
getCurrentApplicant,
(applicant: Applicant) => applicant ? applicant.person : null
);

export const selectCurrentApplicantDOB = createSelector(
selectPersonalInformation,
(person: Person) => person ? person.birthDate : null
);

// TODO: Check why Is not taking latest value
/*
export const getCurrentApplicantIdentificationInfo = createSelector(
  getCurrentApplicant, (applicant: Applicant) => applicant ? applicant.IdentificationInfo : null
)
*/

export function getNextPrevApplicantByType(direction: NAVIGATION_DIRECTION) {
  return createSelector(
      getCurrentApplicant,
      getApplicantsMetadata,
      (currentApplicant: Applicant, applicants: IApplicantMetadata[]) => {
          const idx = applicants.findIndex(applicant => applicant.ApplicantId === currentApplicant.ApplicantId);
          const newIdx = idx + (direction === NAVIGATION_DIRECTION.Foward ? 1 : -1);
          return newIdx < applicants.length && newIdx >= 0 ? applicants[newIdx].ApplicantType.ApplicantTypeId : null;
      });
}
// #endregion

// #region Current Applicant Title
export const getShowApplicantTitle = createSelector(
  getApplicants,
  (applicants: Applicant[]) => applicants.length > 1
);

export function getCurrentApplicantTitle(
  possessive = false,
  prefix = false,
  yourself = false,
  ignoreTeenOrMinor = false) {
  return createSelector(
      getCurrentApplicant,
      getApplicationVestingTypeId,
      (applicant: Applicant, vestingType: VESTING_TYPE_ID) => {
          return applicant ?
              GET_APPLICANT_TITLE(vestingType, applicant, ignoreTeenOrMinor, possessive, prefix, yourself) :
              '';
      }
  );
}

export const getCurrentApplicanPossesiveTitle = createSelector(
  getCurrentApplicant,
  getApplicationVestingTypeId,
  (applicant: Applicant, vestingType: VESTING_TYPE_ID) => GET_APPLICANT_POSSESSIVE(vestingType, applicant)
);
// #endregion

// #region DMD Decisions
export const currentApplicantCanDoDMD = createSelector(
getCurrentApplicant, getApplication,
(currentApplicant: Applicant, application: IApplication) => {
  return currentApplicant && ((
      currentApplicant.type === APPLICANT_TYPE_NAME.Primary &&
      application.SourceType.SourceTypeId !== SOURCE_TYPES.existingEnroller
    ) || currentApplicant.type === APPLICANT_TYPE_NAME.Joint);
}
);

export const shouldNavigateToDMD = createSelector(getCurrentApplicant, (currentApplicant: Applicant) => false);

// export const shouldNavigateToDMD = createSelector(
//   currentApplicantCanDoDMD, getApplication, getDmdEnabled,
//   (currentApplicantDMD: boolean, application: IApplication, dmdEnabled) => {
//     return dmdEnabled && application.Brand.Id !== BRAND_ID.AxosAdvisor && currentApplicantDMD;
//   }
// );
// #endregion


export const getApplicantRequestedDocuments = createSelector(
getCurrentApplicant,
(applicant: Applicant) => applicant ? applicant.requestedDocuments : null
);

export const selectCurrentApplicantSSN = createSelector(
  getCurrentApplicant,
  (applicant: Applicant) => applicant ? applicant.taxPayerId : null
);

export const selectCurrentApplicantEmail = createSelector(
  selectPersonalInformation,
  (person: Person) => person ? person.email : null 
);

export const selectIdDetails = createSelector(
  getCurrentApplicant,
  (applicant: Applicant) => applicant ? applicant.applicantIdentification : null
);