// Ng
import { Injectable, OnDestroy } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
} from '@angular/router';
// RxJs
import { Subject, Observable, of } from 'rxjs';
import { filter, map, takeUntil, switchMap, first } from 'rxjs/operators';
// Services
import { ApplicationFlowService } from 'services/http/application-flow.service';
// Models
import {
  NAVIGATION_DIRECTION,
  FLOW_STEPS,
  APPLICANT_TYPE_NAME,
  APPLICATION_STATE,
  APPLICATION_DECISION_STATUS
} from 'models/enums';
import { GET_FIRST_CHILD_ROUTE } from '../utils';
// Routing
import { APPLICANT_INFORMATION_ROUTES, APPLICANT_INFORMATION_PARENT_PATH, APPLICANT_INFORMATION_FLOWS } from 'app/applicant-information/routing';
import { PRODUCTS_PARENT_PATH } from 'app/products/routing';
import { REVIEWSUBMIT_PARENT_ROUTE } from 'app/review-submit/route.constants';
import { DOCUMENTS_PARENT_ROUTE, DOCUMENTS_ROUTES } from 'app/documents/constants';
import { FEATURES_PARENT_PATH, FEATURES_ROUTES } from 'app/trading-features/routing/constants';
import { OUTCOME_PARENT_ROUTE, OUTCOME_ROUTES } from 'app/outcome/constants';
import { PROXY_PARENT_PATH, PROXY_ROUTES } from 'app/proxy/routing/constants/routes';
import { MANAGE_PORTFOLIO_PARENT_PATH, MANAGE_PORTFOLIO_ROUTES } from 'app/manage-portfolio/routing';
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store/states';
import { setWorkflowStep } from 'store/workflows/workflow.store';

declare var environment;

interface AxosFlowStep {
  name: FLOW_STEPS;
  subWorkflowName: string;
  steps?: AxosFlowStep[];
  lastStep?: FLOW_STEPS;
  innerSteps?: any[];
}

// TODO: Not able to manage multiple levels
@Injectable()
export class FlowNavigationService implements OnDestroy {

  private level = 0;
  private _index: number;
  private lastKnownState?: APPLICATION_STATE;
  private lastKnownDecisionStatus?: APPLICATION_DECISION_STATUS;
  private firstCheck = true;
  private unsubscribe$ = new Subject();
  flow: AxosFlowStep[] = [];
  currentFlow: AxosFlowStep[] = [];
  currentFlowName: string;
  innerIndex: number;
  isMP: boolean;
  get currentIndex() { return this._index; }
  get currentStep() { return this.currentFlow[this.currentIndex]; }

  direction: NAVIGATION_DIRECTION = NAVIGATION_DIRECTION.Foward;
  onStepsFinished: Subject<NAVIGATION_DIRECTION> = new Subject();
  onStepsMoved: Subject<NAVIGATION_DIRECTION> = new Subject();

  constructor(
    private router: Router,
    private appFlow: ApplicationFlowService,
    route: ActivatedRoute,
    private store: Store<EnrollmentState>
  ) {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private isOutsideBounds(index: number, flow: AxosFlowStep[]) {
    return index >= flow.length || index < 0;
  }

  getStep(flow: AxosFlowStep[], stepName: FLOW_STEPS, flowName?: string): AxosFlowStep {
    const _idx = flow.findIndex(s => s.name === stepName);
    if(_idx < 0) {
      console.warn(`Step: ${stepName} is not part of ${flowName}`);
      return;
    }
    this._index = _idx;
    return flow[_idx];
  }

  hasSubFlow(flow: AxosFlowStep[], stepName: FLOW_STEPS): boolean {
    const step = this.getStep(flow, stepName);
    return !!step.subWorkflowName;
  }

  initialize(
    flow: AxosFlowStep[],
    applicationId: string,
    applicantType: APPLICANT_TYPE_NAME,
    initialStep?: FLOW_STEPS,
    state?: APPLICATION_STATE,
    decisionStatus?: APPLICATION_DECISION_STATUS) {
    if(!flow || !flow.length) {
      console.warn('Workflow is not valid');
      return;
    }
    this.lastKnownState = state;
    this.lastKnownDecisionStatus = decisionStatus;
    this.flow = this.currentFlow = flow;
    this.currentFlowName = '';
    this.setState(initialStep, applicationId, applicantType);
  }

  setState(stepName: FLOW_STEPS, applicationId: string, applicantType: APPLICANT_TYPE_NAME, navigate = true, flow = this.currentFlow) {
    if(navigate) {
      this.getLastStep(stepName, flow, applicationId, applicantType)
        .subscribe(step => this.navigateToStep(step));
    }
  }

  fetchSubFlow(step: AxosFlowStep, applicationId: string, applicantType = APPLICANT_TYPE_NAME.Primary): Observable<AxosFlowStep> {
    this.level += 1;
    return this.appFlow.getSubflow(step.subWorkflowName, applicationId, applicantType)
      .pipe(
        map(({ nextStep, lastStep, steps }) => {
          this.store.dispatch(setWorkflowStep({ step: nextStep, lastStep: lastStep}));
          return {
            ...step,
            lastStep: nextStep || lastStep,
            steps,
          }
        })
      );
  }

  navigateToStep(stepName: FLOW_STEPS) {
    if(stepName) {
      if(stepName === FLOW_STEPS.Complete && this.lastKnownState) {
        if (this.lastKnownState === 27) {
          // this.getStep(this.step)
          const prevStep = this.currentFlow[this.currentFlow.length - 2];
          this.navigateToStep(prevStep.name);
          return;
        }
        this.navigateToState(this.lastKnownState, this.lastKnownDecisionStatus);
        return;
      }
      this.navigate(this.getApplicationFlowRoute(stepName), `No Route Map to Step: ${stepName}`);
    } else {
      console.warn(`No Current Step`);
    }
  }

  navigateToState(state: APPLICATION_STATE, decisionStatus: APPLICATION_DECISION_STATUS) {
    this.lastKnownState = state;
    this.lastKnownDecisionStatus = decisionStatus;
    this.navigate(
        this.getApplicationStateRoute(state, decisionStatus),
        `No Route Map to State: ${state} && Decision: ${decisionStatus}`);
  }

  navigate(url: string, noUrlError = 'No Route to navigate to.') {
    if (this.firstCheck) {
      this.firstCheck = false;
    }
    if(!url) {
      console.warn(noUrlError);
      return;
    }
    console.log(`Navigating to: ${url}`);
    this.router.navigate([`./${url}`]);
  }

  getLastStep(
    stepName: FLOW_STEPS,
    flow: AxosFlowStep[],
    applicationId: string,
    applicantType: APPLICANT_TYPE_NAME,
    flowName?: string): Observable<FLOW_STEPS> {
    let currentStep = stepName || flow[0].name;
    if(currentStep === FLOW_STEPS.NotStarted) {
      currentStep = flow[1].name;
    }
    if(currentStep === FLOW_STEPS.Complete) {
      return of(currentStep);
    }
    const step = this.getStep(flow, stepName);
    if(step.subWorkflowName) {
      return (step.steps ? of(step) : this.fetchSubFlow(step, applicationId, applicantType)).pipe(
        switchMap((step => {
          this.currentFlow = step.steps;
          this.currentFlowName = step.subWorkflowName;
          return this.getLastStep(step.lastStep, step.steps, applicationId, applicantType);
        }))
      );
    }
    return of(currentStep);
  }

  move(direction: NAVIGATION_DIRECTION = this.direction) {
    this.direction = direction;
    let newIndex =  this._index + direction;

    if (this.isOutsideBounds(newIndex, this.currentFlow)) {
      this.onStepsFinished.next(this.direction);
      // this.resetNavigation();
    } else {
      let innerSteps = this.currentStep.innerSteps;
      if(innerSteps) {
        const newInnerIndex = this.innerIndex + direction;
        const isOutsideBounds = newInnerIndex >= -1 && newInnerIndex < innerSteps.length;
        if (isOutsideBounds) {
          this.innerIndex = newInnerIndex;
          const stepName = this.innerIndex == -1 ? this.currentStep.name : innerSteps[this.innerIndex].name;
          this.navigateToStep(stepName);
          return;
        }
        if(!isOutsideBounds) {
          if(newInnerIndex < -1){
            this.navigateToStep(this.currentFlow[newIndex].name);
          }
          else{
            this.onStepsFinished.next(NAVIGATION_DIRECTION.Foward);
            return;
          }
        }
        if (this.currentFlow[newIndex].innerSteps) {
          if (direction === NAVIGATION_DIRECTION.Foward) {
            this.innerIndex = -1;
          } else if (direction === NAVIGATION_DIRECTION.Back) {
            innerSteps = this.currentFlow[newIndex].innerSteps;
            this.innerIndex = innerSteps.length - 1;
            this._index = newIndex;
            this.navigateToStep(innerSteps[this.innerIndex].name);
            return;
          }
        }
      }
      this._index = newIndex;
      if(this.currentStep.name === FLOW_STEPS.NotStarted &&
        this.direction === NAVIGATION_DIRECTION.Back
        && this.level > 0) {
        // TODO: Manage levels
        newIndex = this.flow.findIndex(s => s.subWorkflowName === this.currentFlowName);
        this._index = newIndex;
        this.level -= 1;
        if(this.level === 0) {
          this.currentFlow = this.flow;
        }
        this.move();
        return;
      }
      this.navigateToStep(this.currentStep.name);
    }
  }

  moveNext() { this.move(NAVIGATION_DIRECTION.Foward); }
  moveCurrent() { this.move(NAVIGATION_DIRECTION.Current); }
  moveBack() { this.move(NAVIGATION_DIRECTION.Back); }

  private getPIPRoute(route: string): string {
    // TODO: Change Applicant Type
    return `${APPLICANT_INFORMATION_PARENT_PATH}/primary/${route}`;
  }

  private getManagePortfolioRoute() {
    // TODO:
  }

  getApplicationFlowRoute(state: FLOW_STEPS) {
    const stateRouteMap = {
      [FLOW_STEPS.AccountType]: PRODUCTS_PARENT_PATH,
      [FLOW_STEPS.AccountFeature]: FEATURES_PARENT_PATH,
      [FLOW_STEPS.OptionsLevel]: `${FEATURES_PARENT_PATH}/${FEATURES_ROUTES.OPTION_LEVELS.path}`,
      [FLOW_STEPS.PersonalInformation]: this.getPIPRoute(APPLICANT_INFORMATION_ROUTES.TRADING_PRIMARY),
      [FLOW_STEPS.AddressInformation]: this.getPIPRoute(APPLICANT_INFORMATION_ROUTES.HOME_MAILING_ADDRESS),
      [FLOW_STEPS.AdditionalOwnerInformation]: this.getPIPRoute(APPLICANT_INFORMATION_ROUTES.TRADING_ADDITIONAL_OWNER),
      [FLOW_STEPS.FinancialInformation]: this.getPIPRoute(APPLICANT_INFORMATION_ROUTES.FINANCIAL_INFORMATION),
      [FLOW_STEPS.InvestmentProfile]: this.getPIPRoute(APPLICANT_INFORMATION_ROUTES.INVESTMENT_PROFILE),
      [FLOW_STEPS.IdDetails1]: this.getPIPRoute(APPLICANT_INFORMATION_ROUTES.ID_DETAILS1),
      [FLOW_STEPS.IdDetails2]: this.getPIPRoute(APPLICANT_INFORMATION_ROUTES.ID_DETAILS2),
      // [FLOW_STEPS.TrustedContactProxy]: `${PROXY_PARENT_PATH}/${PROXY_ROUTES.CONTACT_INFO.path}`,
      [FLOW_STEPS.TrustedContactProxy]: environment.isProxy ? `${PROXY_PARENT_PATH}/${PROXY_ROUTES.CONTACT_INFO.path}` : this.getPIPRoute(PROXY_ROUTES.CONTACT_INFO.path),
      [FLOW_STEPS.Beneficiaries]: this.getPIPRoute(APPLICANT_INFORMATION_ROUTES.BENEFICIARIES),
      [FLOW_STEPS.ContingentBeneficiaries]: this.getPIPRoute(APPLICANT_INFORMATION_ROUTES.CONTINGENT_BENEFICIARIES),
      [FLOW_STEPS.TrustCertificate]: this.getPIPRoute(APPLICANT_INFORMATION_ROUTES.TRUST_CERTIFICATION),
      [FLOW_STEPS.ReviewAndSubmit]: REVIEWSUBMIT_PARENT_ROUTE,
      [FLOW_STEPS.DocumentsRequested]: `${DOCUMENTS_PARENT_ROUTE}/${DOCUMENTS_ROUTES.NEEDED}`,
      [FLOW_STEPS.WaitingForSecondary]: `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.PENDING_ADDITIONAL_OWNER}`,
      //Investment Goal sub flows
      [FLOW_STEPS.investmentGoal]: `${MANAGE_PORTFOLIO_PARENT_PATH}/${MANAGE_PORTFOLIO_ROUTES.INVESTMENT_GOALS}`,
      [FLOW_STEPS.accountSelection]: `${MANAGE_PORTFOLIO_PARENT_PATH}/${MANAGE_PORTFOLIO_ROUTES.RETIREMENT_ACCOUNT_TYPE}`,
      [FLOW_STEPS.timeHorizon]: `${MANAGE_PORTFOLIO_PARENT_PATH}/${MANAGE_PORTFOLIO_ROUTES.TIME_HORIZON}`,
      [FLOW_STEPS.investingHistory]: `${MANAGE_PORTFOLIO_PARENT_PATH}/${MANAGE_PORTFOLIO_ROUTES.INVESTING_HISTORY}`,
      [FLOW_STEPS.goalSelection]: `${MANAGE_PORTFOLIO_PARENT_PATH}/${MANAGE_PORTFOLIO_ROUTES.LARGE_PURCHASE}`,
      [FLOW_STEPS.riskQuestions]: `${MANAGE_PORTFOLIO_PARENT_PATH}/${MANAGE_PORTFOLIO_ROUTES.RISK_QUESTIONS}`,
      [FLOW_STEPS.recommendedPortfolio]: `${MANAGE_PORTFOLIO_PARENT_PATH}/${MANAGE_PORTFOLIO_ROUTES.RECOMMENDED}`,
      [FLOW_STEPS.portfolioReviewAndSubmit]: REVIEWSUBMIT_PARENT_ROUTE,
    };
    return stateRouteMap[state];
  }

  getDecisionRoute(decisionStatus: APPLICATION_DECISION_STATUS, firstCheck = true) {
    switch (decisionStatus) {
      case APPLICATION_DECISION_STATUS.AutoApproved:
      case APPLICATION_DECISION_STATUS.ManuallyApproved:
      case APPLICATION_DECISION_STATUS.ApprovedAutoPendingPrincipalReview:
        // return firstCheck ?
        //   `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.BOARDING_IN_PROGRESS}` :
        //   `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.APPROVED_FAILURE}`;
        return `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.BOARDING_IN_PROGRESS}`;
      case APPLICATION_DECISION_STATUS.AutoDeclined:
      case APPLICATION_DECISION_STATUS.ManuallyDeclined:
        return `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.DECLINED}`;
      case APPLICATION_DECISION_STATUS.InReview:
        return `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.INREVIEW}`;
    }
    return '';
  }

  getMP_DecisionRoute(decisionStatus: APPLICATION_DECISION_STATUS) {
    switch (decisionStatus) {
      case APPLICATION_DECISION_STATUS.AutoApproved:
      case APPLICATION_DECISION_STATUS.ManuallyApproved:
      case APPLICATION_DECISION_STATUS.ApprovedAutoPendingPrincipalReview:
        //return `${OUTCOME_PARENT_ROUTE}/t/approved`;
        return `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.BOARDING_IN_PROGRESS}`;
      case APPLICATION_DECISION_STATUS.AutoDeclined:
      case APPLICATION_DECISION_STATUS.ManuallyDeclined:
        return `${OUTCOME_PARENT_ROUTE}/t/declined`;
      case APPLICATION_DECISION_STATUS.InReview:
        return `${OUTCOME_PARENT_ROUTE}/t/inreview`;
    }
    return '';
  }

  getApplicationStateRoute(state: APPLICATION_STATE, decisionStatus: APPLICATION_DECISION_STATUS) {
    const decisionRoute = this.isMP ?
      this.getMP_DecisionRoute(decisionStatus) :
      this.getDecisionRoute(decisionStatus, this.firstCheck);
    switch(state) {
      case APPLICATION_STATE.Complete:
        return decisionRoute;
      case APPLICATION_STATE.DocumentsRequested:
        return `${DOCUMENTS_PARENT_ROUTE}/${DOCUMENTS_ROUTES.NEEDED}`;
      case APPLICATION_STATE.Submitted:
      case APPLICATION_STATE.RMNewApplications:
      case APPLICATION_STATE.PendingDocumentReview:
      case APPLICATION_STATE.PendingContactInfoReview:
      case APPLICATION_STATE.PendingApplicationReview:
      case APPLICATION_STATE.InReview:
        return this.isMP ? `${OUTCOME_PARENT_ROUTE}/t/inreview` : `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.INREVIEW}`;
      case APPLICATION_STATE.SystemError:
      case APPLICATION_STATE.AccountNumberError:
        return decisionRoute || 'systemError';
    }
    // Default Route
    /* if (this.firstCheck) {
      return 'systemError';
    } */
  }

  setSubWorkflow(workFlowName: string, steps: any[]) {
    const flow = this.currentFlow.find(x => x.name == workFlowName);
    flow.innerSteps = steps;
    this.innerIndex = -1;
  }
}
