declare var environment: any;

import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { CustomerService } from "services/proxy";
import * as proxyActions from '../actions/proxy.actions';
import { switchMap, map, catchError, tap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { PROXY_PARENT_PATH, PROXY_ROUTES } from "app/proxy/routing/constants/routes";
import { Store } from "@ngrx/store";
import { EnrollmentState } from "store/states";
import * as proxySelectors from  '../selectors/proxy.selectors';
import * as productsActions from '../actions/products.action';
import * as applicationAction from "../actions/application.action";
import { SetProductsSelectionSuccessAction } from "store/actions";
import { getIRAFlag, getMarketingSelectedProducts, selectedRepCode } from "store/selectors";
import { APPLICATION_TYPE, APPLICATION_TYPE_FLOW, APPLICATION_TYPE_FLOW_ID } from "models/enums/application-type.enum";
import { ProxyService } from "services";


@Injectable()
export class ProxyEffects {

    proxyRoutes = PROXY_ROUTES;

    constructor(
        private action$: Actions,
        private customerService: CustomerService,
        private router: Router,
        private store: Store<EnrollmentState>,
        private proxyService: ProxyService
    ) { }

    searchCustomer$ = createEffect(() =>
        this.action$.pipe(
            ofType(proxyActions.searchCustomers),
            switchMap(data => {
                return this.customerService.search(data.search).pipe(
                    map(response => response.length > 0 ? proxyActions.searchCustomersSuccess({customers: response}) : proxyActions.clearCustomer()),
                    catchError(() => of(proxyActions.searchCustomersFail()))
                )
            })
        ),
    );

    getCustomer$ = createEffect(() =>
        this.action$.pipe(
            ofType(proxyActions.getCustomers),
            switchMap(() => {
                return this.customerService.getAll().pipe(
                    map(response => response.length > 0 ? proxyActions.searchCustomersSuccess({customers: response}) : proxyActions.clearCustomer()),
                    catchError(() => of(proxyActions.getCustomersFail()))
                )
            })
        ),
    );
    
    // setCustomer$ = createEffect(() =>
    // this.action$.pipe(
    //     ofType(proxyActions.setCustomer),
    //     tap(data => {
    //         this.store.dispatch(proxyActions.setCustomerSuccess({ customer: data.customer }));
    //         this.store.dispatch(proxyActions.clearCustomers());
    //         this.router.navigateByUrl(`${PROXY_PARENT_PATH}/${this.proxyRoutes.PRODUCT_SELECTION.path}`);
    //     })
    // ),
    // { dispatch: false }
    // );

    setCustomer$ = createEffect(() =>
        this.action$.pipe(
            ofType(proxyActions.setCustomer),
            switchMap(data => {
                console.log(data);
                return this.customerService.getDetails(data.customer.identityId).pipe(
                    tap(customer => {
                        console.log(customer);
                        if(customer) {
                            this.store.dispatch(proxyActions.setCustomerSuccess({ customer: customer }));
                            this.router.navigateByUrl(`${PROXY_PARENT_PATH}/${this.proxyRoutes.PRODUCT_SELECTION.path}`);
                        }
                    }
                ))
            })
    ),
    { dispatch: false }
    );

    setProduct$ = createEffect(() =>
        this.action$.pipe(
            ofType(proxyActions.setProduct),
            withLatestFrom(this.store.select(proxySelectors.getSelectedCustomer)),
            tap(data => {
                const products = data[0].products;
                const customer = data[1];

                const IRAProduct = products.find(p => p.productCode == 'RIRA' || p.productCode == 'TIRA');

                this.store.dispatch( new SetProductsSelectionSuccessAction(products));
                this.store.dispatch(new productsActions.SetIRAFlag(!!IRAProduct));

                if(customer){
                    this.store.dispatch(proxyActions.registerExisitingCustomer({customer: customer}));
                }
                else{
                    this.router.navigateByUrl('/registration');
                }
            })
        ),
        { dispatch: false }
    );

    saveContact$ = createEffect(() =>
        this.action$.pipe(
            ofType(proxyActions.saveContact),
            switchMap(data => {
                return this.customerService.saveContact(data.applicantId, data.contact, data.skipHistory).pipe(

                    map(response => {
                        //service should return trustedDeclined value
                        const contact = response.data.data[0] ? response.data.data[0] : null;
                        if(response.success){
                            return proxyActions.saveContactSucces({ contact: contact });
                        }
                        else{
                            return proxyActions.saveContactFail();
                        }
                    }),
                    catchError(() => of(proxyActions.saveContactFail()))
                )
            })
        ),
    );

    saveContactSuccess$ = createEffect(() =>
        this.action$.pipe(
            ofType(proxyActions.saveContactSucces),
            tap(() =>{
                //TBD
            })
        ),
        { dispatch: false }
    );

    registerExistingCustomer$ = createEffect(() =>
        this.action$.pipe(
            ofType(proxyActions.registerExisitingCustomer),
            withLatestFrom(
                this.store.select(getMarketingSelectedProducts), 
                this.store.select(getIRAFlag),
                this.store.select(selectedRepCode)
            ),
            switchMap(data =>{
                const productCodes = data[1].map(x => x.productCode);
                const isIRA: boolean = !!data[2];
                const isIRAProduct = productCodes.find(x => x.toLowerCase() == 'tira' || x.toLowerCase() == 'rira');

                const existingCustomerRequest = {
                    identityId: data[0].customer.identityId, 
                    productCodes: productCodes, 
                    applicationTypeId: isIRA || isIRAProduct ? APPLICATION_TYPE_FLOW_ID.ProxyIRA : environment.applicationTypeId, 
                    applicationTypeName: isIRA || isIRAProduct ? APPLICATION_TYPE_FLOW.ProxyIRA : environment.applicationTypeName,
                    repCode: data[3]
                };
                
                return this.customerService.registerExistingCustomer(existingCustomerRequest).pipe(
                    map(response =>{
                        return new applicationAction.LoadApplicationAction(response.data);
                    })
                );
            })
        )
    );

    handOff$ = createEffect(() =>
        this.action$.pipe(
            ofType(proxyActions.handOff),
            switchMap(data => {
                return this.customerService.handOff(data.applicationId).pipe(
                    map(response => {
                        if(response.code === 200 && response.success){
                            return proxyActions.handOffSuccess();
                        }
                    })
                );
            })
        )
    );

    handOffSuccess$ = createEffect(() =>
        this.action$.pipe(
            ofType(proxyActions.handOff),
            tap(( )=> {
                this.router.navigateByUrl('/handoffSuccess');
            })
        ),
        { dispatch: false }
    );

    getRepCodes$ = createEffect(() =>
        this.action$.pipe(
            ofType(proxyActions.getRepCodes),
            switchMap(() => {
                return this.proxyService.repCodes().pipe(
                    map(response => {
                        if(response.success){
                            return proxyActions.getRepCodesSuccess({ repCodes: response.data });
                        }
                        else{
                            return proxyActions.getRepCodesFail();
                        }
                    }),
                    catchError(() => of(proxyActions.getRepCodesFail()))
                )
            })
        ),
    );

    setProxyDeclinedTrustedSelection$ = createEffect(() => 
        this.action$.pipe(
            ofType(proxyActions.setProxyDeclinedTrustedSelection),
            switchMap(data => {
                return this.proxyService.setProxyDeclinedTrustedSelection(data.applicantId, data.declinedTrustedFlag).pipe(
                    map(response => {
                        let applicantId = data.applicantId;
                        return proxyActions.saveContact({applicantId: applicantId, contact: data.trustedDeclined, skipHistory: false});
                    }),
                    //catchError(error => of(whiteLabelActions))
                )
            })
        )
    );
}
