import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, withLatestFrom, map, switchMap, take, combineLatest } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { LoadAllApplicantsAction } from 'store/actions';
import { getApplicants, getApplicantsLoading } from 'store/selectors';
// Resolvers
import { ApplicationResolver } from './application.resolver';
import { CountryResolver } from './country.resolver';

@Injectable()
export class ApplicantsResolver implements Resolve<Observable<boolean>> {

  constructor(
    private store$: Store<EnrollmentState>,
    private applicationResolver: ApplicationResolver, private countryResolver: CountryResolver) { }

  resolve() {
    return this.applicationResolver.resolve().pipe(
      combineLatest(this.countryResolver.resolve()),
      map(data => data[0].Applicants),
      withLatestFrom(this.store$.select(getApplicantsLoading)),
      filter(data => {
        const applicants = data[0], loading = data[1];
        return applicants && applicants.length > 0 && !loading;
      }),
      map(data => data[0]),
      switchMap(metadata => {
        return this.store$.select(getApplicants).pipe(
          map(applicants => {
            const allLoaded = metadata.length === applicants.length;
            if(!allLoaded) {
              this.store$.dispatch(new LoadAllApplicantsAction())
            }
            return allLoaded;
          }),
          filter(allLoaded => allLoaded)
        )
      }),
      take(1)
    );
  }
}
