import { Injectable } from '@angular/core';
import { IdentificationDetailsInfo, WLIdentificationDetailsInfo } from 'models';
import { IdDetails } from 'models/wl/id-details.model';
import { map } from 'rxjs/operators';
// Services
import { BaseService } from 'services';

@Injectable()
export class IdentificationUploadService extends BaseService {

    mapModel(data) {
        return {
            ...data,
            DocumentTypeId: parseInt(data.DocumentTypeId)
        };
    }

    uploadDocuments(data, extractData = false) {
        const model = this.mapModel(data);
        return extractData ? this.extractData(model) : this.uploadMitekId(model);
    }

    private uploadMitekId(model) {
        return super._post('NME/Mitek/UploadMitekId', model);
    }

    private extractData(model) {
        return super._post('ExtractData/', model);
    }

    saveIdentificationDetailsInfo(applicantId: string, data: WLIdentificationDetailsInfo, applicationId: string, skipHistory: boolean = false){
        return super._post<any>(`identification/${applicantId}/upload?skipHistory=${skipHistory}`, data).pipe(
            map(response => response.success)
        );
    }

    saveIdDetailsPage2(applicantId: string, idDetails: IdDetails, skipHistory: boolean = false){
        return this._post<any>(`identification/${applicantId}/details?skipHistory=${skipHistory}`, idDetails).pipe(
          map(response => response)
        );
      }
}
