import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, filter, first } from 'rxjs/operators';

import { IInvestmentObjetive } from 'models';

import { Store } from '@ngrx/store';
import { EnrollmentState, getInvestmentObjetives } from 'store';
import { selectInvestmentObjetives } from 'store/selectors';

@Injectable()
export class InvestmentObjetiveResolver implements Resolve<Observable<any>> {
    constructor(private store$: Store<EnrollmentState>) { }

    resolve() {
        return this.store$.select(selectInvestmentObjetives).pipe(
            tap(objetives => {
                if(!objetives) {
                    this.store$.dispatch(getInvestmentObjetives());
                }
            }),
            filter(objetives => !!objetives),
            first()
        );
    }
}