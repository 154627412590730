import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";

import { Observable } from "rxjs";

import { IInvestmentProfile } from "models";

import { Store } from "@ngrx/store";
import { EnrollmentState, getInvestmentProfile } from "store";
import { selectInvestmentProfile, getApplicationLoaded } from "store/selectors";
import { filter, tap, first, switchMap } from "rxjs/operators";

@Injectable()
export class InvestmentProfileResolver implements Resolve<Observable<IInvestmentProfile>> {

    constructor(private store$: Store<EnrollmentState>) { }

    resolve() {
        return this.store$.select(getApplicationLoaded).pipe(
          filter(loaded => loaded),
          switchMap(() => this.store$.select(selectInvestmentProfile)),
          tap(profile => {
              if (!profile) {
                  this.store$.dispatch(getInvestmentProfile());
              }
          }),
          filter(profile => !!profile),
          first()
        );
    }
}
