// Environment
declare var environment: any;
// Ng
import { Injectable } from '@angular/core';
// RxJs
import { of, Observable } from 'rxjs';
import { switchMap, map, withLatestFrom, catchError, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState, LoadApplicantAction, SetApplicationApplicants } from 'store';
import { getMarketingSelectedProducts, getApplication, shouldNavigateToDMD, getUser, getOrganizationDisplayNameOnly, getBrand, getBrandName } from 'store/selectors';
// NgRx Effects
import { Effect, Actions, ofType } from '@ngrx/effects';
// Services
import { ProductsService, ProductSelectionService, VestingTypeService } from 'services';
import { CookieService } from 'ngx-cookie-service';
// Models
// Actions
import * as productsActions from '../actions/products.action';
import * as bannerActions from '../actions/banner.action';
import * as applicationActions from '../actions/application.action';
import * as applicantActions from '../actions/applicants.action';
import { APPLICATION_STATE } from 'models/enums';
import { ISimpleProduct, SelectedVestingType, Applicant, IApplicantMetadata } from 'models';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class ProductsEffects {
    constructor(
        private actions$: Actions,
        private store$: Store<EnrollmentState>,
        private cookieService: CookieService,
        private productsService: ProductsService,
        private vestingTypeService: VestingTypeService,
        private router: Router,
        private productSelectionService: ProductSelectionService) { }

    @Effect()
    getSelectedProducts$ = this.actions$.pipe(
        ofType(productsActions.PRODUCTS_ACTION_TYPES.GET),
        switchMap(() => {
          return this.productSelectionService.getProductsFromApplication()
            .pipe(
              switchMap(products => {
                const IRAProduct = products.find(p => p.productCode == 'RIRA' || p.productCode == 'TIRA');
                return [
                  new productsActions.SetIRAFlag(!!IRAProduct),
                  new productsActions.GetProductsSuccessAction(products)
                ]
            }));
        })
    );

    @Effect()
    getVestingTypes$ = this.actions$.pipe(
      ofType(productsActions.PRODUCTS_ACTION_TYPES.GET_VESTINGS),
      withLatestFrom(
        this.store$.select(getBrand)
      ),
      switchMap(([action, brand]) => {
        return this.vestingTypeService.getVestingTypes(environment.organizationId, brand.name, environment.applicationTypeName)
          .pipe(
              map(response => new productsActions.GetVestingsSuccessAction(response))
          );
      })
    );

    @Effect()
    setVestingType$ = this.actions$.pipe(
      ofType(productsActions.PRODUCTS_ACTION_TYPES.SET_VESTING_TYPE),
      withLatestFrom(
        this.store$.select(getApplication),
        this.store$.select(shouldNavigateToDMD),
        this.store$.select(getMarketingSelectedProducts)
      ),
      switchMap(data => {
        const action = <productsActions.SetVestingTypeAction> data[0],
          application = data[1],
          dmdFlow = data[2],
          products = data[3];
          var isAxosUniversal = !!environment.isAxosUniversal;
          //TODO get is axos trading flow set variable

        const vestingType: SelectedVestingType = {
          id: application.applicationId,
          numberOfApplicants: action.numberOfApplicants,
          vestingTypeName: action.vestingTypeName
        };
        return this.vestingTypeService.saveVestingType(vestingType)
        .pipe(
          map((applicants: Applicant[]) => {
            let Applicants: IApplicantMetadata[] = applicants.map((app) => {
              return {
                applicantId: app.applicantId, 
                type: app.type
              }
            });
            //Set applicants in store (applicants>applicants, application>applicants, application>Applicants)
            this.store$.dispatch(new SetApplicationApplicants(applicants));
            this.store$.dispatch(new applicantActions.LoadAllApplicantsAction());
            this.store$.dispatch(new applicationActions.SetVestingTypeSuccessAction(vestingType.vestingTypeName, Applicants));
            return new applicationActions.SetApplicationStateAction(APPLICATION_STATE.AccountFeature);
          }),
          catchError((error: HttpErrorResponse) => {
            const errorMessage = error.error ? error.error.Message : "Set Vesting Type Error";
            return of(new productsActions.SetVestingTypeFailAction(errorMessage))
          })
        );
      })
    );

    @Effect({dispatch: false})
    setVestingTypeFail$ = this.actions$.pipe(
      ofType(productsActions.PRODUCTS_ACTION_TYPES.SET_VESTING_TYPE_FAIL),
      tap(() => {
        return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
      })
    );

    @Effect()
    setProductsSelection$ = this.actions$.pipe(
      ofType(productsActions.PRODUCTS_ACTION_TYPES.SET_PRODUCT_SELECTION),
      withLatestFrom(
        this.store$.select(getMarketingSelectedProducts)
      ),
      switchMap(data => {
        const action = <productsActions.SetProductsSelectionAction>data[0],
          storeProductCode = (data[1] || []).map(product => product.productCode);
        
        let productCodeObservable: Observable<string[]>;
        let userIdentityId;
        if (window.location.pathname.indexOf('/auth/ResetPassword/') > -1) {//reset password url
          userIdentityId = window.location.pathname.replace('/auth/ResetPassword/', '');
        }
        if (action.productCodes) {
          productCodeObservable = of(action.productCodes);
        } else if (userIdentityId !== undefined) {
          productCodeObservable = this.productSelectionService.getProductsByUserIdentityGuId(userIdentityId).pipe(
            map((response: any) => response as string[])
          );
        } else {
          productCodeObservable = of(storeProductCode);
        }
        return productCodeObservable.pipe(
          switchMap(productCode => {
        const  request: Observable<ISimpleProduct[]> = !productCode || productCode.length <= 0 ?
            this.productSelectionService.getProductsFromApplication() :
            this.productSelectionService.getProductsByCode(productCode);
        // Not needed right now, but might be for a refactor
        if(!productCode || productCode.length == 0){
          return of(new productsActions.SetProductsSelectionFailAction([]))
        }
        const hasCookie = !!this.cookieService.get('XSRF-TOKEN');
        return request.pipe(
          switchMap(products => {
            // TODO: This rutine should be an utility
            let productsName = '';
            const productsLabel = `Product${products.length <= 1 ? '' : 's'}`;
            products.forEach((prod, index, array) => {
              productsName = `${productsName}${prod.displayName}${!Object.is(array.length - 1, index) ? ', ' : ''}`;
            });
            const IRAProduct = products.find(p => p.productCode == 'RIRA' || p.productCode == 'TIRA');
            return [
              new productsActions.SetIRAFlag(!!IRAProduct),
              new bannerActions.UpdateSubBannerAction(true, productsLabel, productsName),
              new productsActions.SetProductsSelectionSuccessAction(products),
            ]
          }),
          catchError(err => of(new productsActions.SetProductsSelectionFailAction([])))
        );
      }));
      })
    );

    // @Effect({dispatch: false})
    // setProductSelectionFail$ = this.actions$.pipe(
    //   ofType(productsActions.PRODUCTS_ACTION_TYPES.SET_PRODUCT_SELECTION_FAIL),
    //   tap((data) => {
    //     this.store$.dispatch(new productsActions.RedirecProductSelectionAction());
    //   })
    // );

    @Effect({dispatch: false})
    updateProductSelection$ = this.actions$.pipe(
      ofType(productsActions.PRODUCTS_ACTION_TYPES.UPDATE_PRODUCT_SELECTION),
      tap((data) => {
        const action = <productsActions.UpdateProductSelectionAction>data;
        const IRAProduct: boolean = action.product.productCode == 'RIRA' || action.product.productCode == 'TIRA';
        let products: ISimpleProduct[] = [];
        products.push(action.product);
        this.store$.dispatch(new productsActions.SetIRAFlag(IRAProduct));
        this.store$.dispatch(new bannerActions.UpdateSubBannerAction(true, 'Prooduct', action.product.displayName));
        this.store$.dispatch(new productsActions.UpdateProductSelectionSuccessAction(products));
        this.router.navigateByUrl('/registration');
      })
    );

    @Effect({dispatch: false})
    redirectProductSelection$ = this.actions$.pipe(
      ofType(productsActions.PRODUCTS_ACTION_TYPES.REDIRECTO_PRODUCT_SELECTION),
      tap((data) => {
        console.log('Redirecto to product page');
        //this.router.navigateByUrl('/productAccount');
      })
    );

    // @Effect()
    // getProductsByOrganization$ = this.actions$.pipe(
    //   ofType(productsActions.PRODUCTS_ACTION_TYPES.GET_PROXY_PRODUCTS),
    //   switchMap(data => {
    //     const action = <productsActions.GetProxyProductsAction>data;
    //     return this.productsService.getByOrganization(action.applicationTypeName)
    //       .pipe(
    //           map(response => new productsActions.GetProxyProductsSuccesAction(response.data))
    //       );
    //   })
    // );

    @Effect()
    getProductsByOrganization$ = this.actions$.pipe(
      ofType(productsActions.PRODUCTS_ACTION_TYPES.GET_PRODUCTS_BY_ORGANIZATION),
      switchMap(data => {
        const action = <productsActions.GetProductsByOrganizationAction>data;
        return this.productsService.getByOrganization(action.applicationTypeName)
          .pipe(
              map((response) => {
                if(environment.isProxy){
                  return new productsActions.GetProxyProductsSuccesAction(response.data)
                }
                return new productsActions.GetProductsByOrganizationSuccesAction(response.data)
              })
          );
      })
    );
}
