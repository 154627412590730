// Ng
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// RxJs
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, catchError, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { LoadAccountsAction } from 'store/actions';
import { getToken, getApplicationId, getConnectionId, getApplicationVestingTypeId, getApplicationVestingTypeName, getIsManagedPortfolio } from 'store/selectors';
// NgRx Effects
import { Effect, Actions, ofType } from '@ngrx/effects';
// Services
import { ApplicationService, RulesService, QuestionnaireService, BoardingSignalService, ManagedPortfolioService, AuthService } from 'services';
// Actions
import * as rulesActions from '../actions/rules.action';
import * as applicantsActions from '../actions/applicants.action';
import * as applicationActions from '../actions/application.action';
// Models
import { ApplicationStatus, RulesApplicationStatus } from 'models/rules';
import { APPLICATION_DECISION_STATUS, APPLICATION_STATE, APPLICATION_STATUS, APPLICANT_TYPE_TO_NAME, VESTING_TYPE_NAME } from 'models/enums';
// Routes Paths
import { OUTCOME_PARENT_ROUTE, OUTCOME_ROUTES } from 'app/outcome';
import { LANDING_ROUTES } from 'app/landing/routing';
import { BOARDING_RESULT } from 'store/constants';
import { CookieService } from 'ngx-cookie-service';
import { AuthHandlerService, TOKEN_STORAGE_NAME } from 'app/auth';
import { HttpErrorResponse } from '@angular/common/http';
import { IAuthToken } from 'models';
import { GET_CACHE } from 'app/core/utils';
import { FlowNavigationService } from 'app/core/services';



@Injectable()
export class RulesEffects {

  private errorPage = 'systemError';

  constructor(
    private actions$: Actions,
    private store$: Store<EnrollmentState>,
    private router: Router,
    private rulesService: RulesService,
    public boardingSignalService: BoardingSignalService,
    private appliationService: ApplicationService,
    private questionnaireSerice: QuestionnaireService,
    private portfolioService: ManagedPortfolioService,
    private cookieService: CookieService,
    private authService: AuthService,
    private authHandlerService: AuthHandlerService,
    private flowService: FlowNavigationService) { }

  private applicationId$ = this.store$.select(getApplicationId)
    .pipe(filter(applicationId => !!applicationId));
  private vestingType$ = this.store$.select(getApplicationVestingTypeName);

  private getIsManagedPortfolios$ = this.store$.select(getIsManagedPortfolio);

  @Effect()
  validateApplication$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.VALIDATE_APPLICATION),
    withLatestFrom(this.applicationId$),
    switchMap(data => {
      const applicationId = data[1];
      return this.rulesService.validateApplication(applicationId).pipe(
        //tap(() => this.store$.dispatch(new rulesActions.ParserApplicationAction())),
        map(response => {
          if (response.status === APPLICATION_STATUS.SystemFailure) {
            this.router.navigateByUrl('systemError');
          }
          return new rulesActions.ValidateApplicationSuccessAction(response['data']);
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error ? error.error.Message : "Validate Application Error";
          return of(new rulesActions.ValidateApplicationFailAction(errorMessage))
        })
      );
    })
  );

  @Effect()
  validateApplicationFail$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.VALIDATE_APPLICATION_FAIL),
    tap(() => {
      return of(new applicationActions.RedirectToGeneralErrorPage())
    })
  );

  @Effect()
  resumeRules$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.RESUME_RULES),
    withLatestFrom(this.applicationId$),
    switchMap(data => {
      const action = <rulesActions.ResumeRulesAction>data[0],
        applicationId = data[1];
      return this.rulesService.resumeRules(applicationId, action.questionnaire).pipe(
        map(response => new rulesActions.ResumeRulesSuccessAction(
          this.ApplicationStatusMapper(response.rulesResult.ApplicationStatusValues)
        )),
        catchError(err => of(new rulesActions.ResumeRulesFailAction()))
      );
    })
  );

  @Effect({ dispatch: false })
  boardApplication$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.BOARD_APPLICATION),
    switchMap((action: rulesActions.BoardApplicationAction) => {
      return this.boardingSignalService.board().pipe(
        tap(res => {
          switch (res) {
            case BOARDING_RESULT.COMPLETED:
            this.store$.dispatch(new rulesActions.BoardInProgressComplete(res));
            break;
            case BOARDING_RESULT.IN_PROGRESS:
              this.router.navigateByUrl(`${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.BOARDING_IN_PROGRESS}`);
              break;
            case BOARDING_RESULT.STARTED:
            case BOARDING_RESULT.BOARDING_ERROR:
              this.router.navigateByUrl(`${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.APPROVED_FAILURE}`);
              break;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error ? error.error.Message : "Board Application Error";
          return of(new rulesActions.BoardApplicationFailAction(errorMessage))
        })
      )
    })
  );

  @Effect()
  boardInProgressComplete$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.BOARD_INPROGRESS_COMPLETE),
    switchMap((action: rulesActions.BoardInProgressComplete) => {
      return of(new rulesActions.BoardApplicationSuccessAction(action.status))
    })
  )

  @Effect({ dispatch: false })
  boardApplicationSuccess$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.BOARD_APPLICATION_SUCCESS),
    withLatestFrom(this.getIsManagedPortfolios$),
    tap(([action, isManagedPortfolio]) => {
      if (action['status'] === BOARDING_RESULT.COMPLETED) {
        let url = `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.APPROVED}`;
        if (isManagedPortfolio) {
          url = `${OUTCOME_PARENT_ROUTE}/t/approved`;
        }
        this.router.navigateByUrl(url)
      }
    })
  );

  @Effect()
  boardAplicationFail$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.BOARD_APPLICATION_FAIL),
    tap(() => {
      return of(new applicationActions.RedirectToGeneralErrorPage())
    })
  );

  @Effect()
  boardInMitek$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.BOARD_IN_MITEK),
    withLatestFrom(this.applicationId$),
    switchMap(data => {
      const applicationId = data[1];
      return this.rulesService.runMitekRules(applicationId).pipe(
        tap(() => this.store$.dispatch(new rulesActions.ParserApplicationAction())),
        map(response => new rulesActions.BoardInMitekSuccessAction(
          this.ApplicationStatusMapper(response.rulesResult.ApplicationStatusValues)
        )),
        catchError(err => of(new rulesActions.BoardInMitekFailAction()))
      );
    })
  );

  // @Effect({ dispatch: false })
  // boardApplicationSuccess$ = this.actions$.pipe(
  //   ofType(rulesActions.RULES_ACTION_TYPES.BOARD_APPLICATION_SUCCESS),
  //   tap((action: rulesActions.BoardApplicationSuccessAction) => {
  //     if (action.wasBorded) {
  //       this.store$.dispatch(new LoadAccountsAction())
  //       this.router.navigateByUrl(`${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.APPROVED}`)
  //     }
  //   })
  // )

  @Effect({ dispatch: false })
  validateFail$ = this.actions$.pipe(
    ofType(
      rulesActions.RULES_ACTION_TYPES.VALIDATE_APPLICATION_FAIL,
    ),
    withLatestFrom(this.vestingType$),
    tap(([_, vestingType]) => {
      this.router.navigateByUrl(vestingType.toLowerCase() === VESTING_TYPE_NAME.Individual ?
        `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.APPROVED_FAILURE}` :
        `systemError`);
    })
  );

  @Effect({ dispatch: false })
  approveFail$ = this.actions$.pipe(
    ofType(
      rulesActions.RULES_ACTION_TYPES.BOARD_APPLICATION_FAIL,
      rulesActions.RULES_ACTION_TYPES.RESUME_RULES_FAIL,
      applicationActions.APPLICATION_ACTION_TYPES.LOAD_ACCOUNTS_FAIL,
    ),
    tap(() => {
      this.router.navigateByUrl(`${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.APPROVED_FAILURE}`);
    })
  );

  @Effect({ dispatch: false })
  evaluateDecision$ = this.actions$.pipe(
    ofType(
      rulesActions.RULES_ACTION_TYPES.VALIDATE_APPLICATION_SUCCESS,
      rulesActions.RULES_ACTION_TYPES.RESUME_RULES_SUCCESS
    ),
    tap(action => {
      const state = <ApplicationStatus>action['state'];
      this.store$.dispatch(new applicationActions.GetConfirmationNumberAction());
      if (state && (state.decisionStatus === APPLICATION_DECISION_STATUS.AutoApproved ||
        state.decisionStatus === APPLICATION_DECISION_STATUS.ManuallyApproved ||
        state.decisionStatus === APPLICATION_DECISION_STATUS.ApprovedAutoPendingPrincipalReview)) {
        this.store$.dispatch(new rulesActions.BoardApplicationAction());
      } else {
        this.flowService.navigateToState(state.stateId, state.decisionStatus)
      }
    })
  );

  @Effect({ dispatch: false })
  pendingKBA$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.UPDATE_PENDING_KBA),
    withLatestFrom(this.applicationId$, this.store$.select(getToken)),
    tap(data => {
      const applicationId = data[1], token = data[2];
      this.rulesService.updateToPendingKBAXMLHttp(applicationId, token);
    })
  );

  @Effect({ dispatch: false })
  pendingBankReview$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.UPDATE_PENDING_BANK_REVIEW),
    withLatestFrom(this.applicationId$, this.store$.select(getToken)),
    tap(data => {
      const applicationId = data[1], token = data[2];
      this.rulesService.updateToPendingBankReviewXMLHttp(applicationId, token);
    })
  );

  @Effect()
  getIDAQuestions$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.GET_IDA_QUESTIONS),
    withLatestFrom(this.applicationId$),
    switchMap(data => {
      const action = <rulesActions.GetIDAQuestionsAction>data[0],
        applicationId = data[1];

      return this.questionnaireSerice.getIdaQuestions$(applicationId, action.isRetry).pipe(
        map(response => new rulesActions.GetIDAQuestionsSuccessAction(
          this.ApplicationStatusMapper(response.initiateIdaResponse.ApplicationStatusValues),
          response.initiateIdaResponse.IdaQuestionValues
        )),
        catchError(() => of(new rulesActions.GetIDAQuestionsFailAction()))
      );
    })
  );

  @Effect()
  getIDAQuestionsFail$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.GET_IDA_QUESTIONS_FAIL),
    map(() => new applicationActions.SetApplicationStateAction(APPLICATION_STATE.PendingCompletionKBA))
  );

  @Effect()
  getPendingApplicantsIDVerification$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.GET_PENDING_ID_VERIFICATION_APPLICANTS),
    withLatestFrom(this.applicationId$),
    switchMap(data => {
      const applicationId = data[1];
      return this.rulesService.getApplicantsMitekDocuments(applicationId);
    }),
    switchMap(response => [
      response.applicants.length > 0 ?
        new applicantsActions.SetCurrentApplicantTypeAction(APPLICANT_TYPE_TO_NAME[response.applicants[0].applicantTypeId]) :
        new rulesActions.BoardInMitekAction(),
      new rulesActions.GetPendingIDVerificationApplicantsSuccessAction(
        response.applicants.map(applicant => applicant.applicantTypeId)
      ),
    ])
  );

  @Effect({ dispatch: false })
  parserApplication$ = this.actions$.pipe(
    ofType(rulesActions.RULES_ACTION_TYPES.PARSE_APPLICATION),
    withLatestFrom(this.applicationId$),
    switchMap(data => {
      const applicationId = data[1];
      return this.rulesService.parser(applicationId);
    })
  );

  ApplicationStatusMapper(status: RulesApplicationStatus): ApplicationStatus {
    return {
      stateId: status.ApplicationState,
      status: status.ApplicationStatus,
      decisionReason: status.DecisionReason,
      decisionStatus: status.DecisionStatus
    }
  }
}
