import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
// UI Kit
import { NgxMaskModule } from 'ngx-mask';
import { UIKitCommonModule } from '@uikit/components/common';
import { UkFormControlsModule } from '@uikit/components/uk-form-controls';
import { UkDropdownModule } from '@uikit/components/uk-dropdown';
// Components
import { AddressFormComponent } from './components';
import { setRangeDirective } from 'app/enrollment-common/directives';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // UI Kit
    UIKitCommonModule,
    UkFormControlsModule,
    UkDropdownModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    AddressFormComponent,
    setRangeDirective
  ],
  exports: [
    AddressFormComponent,
    setRangeDirective
  ]
})
export class SharedFormsModule { }
