import { Resolve } from '@angular/router';

import { Store } from '@ngrx/store';
import { EnrollmentState, GetProductsAction, SetProductsSelectionAction, UpdateBannerAction, UpdateSubBannerAction } from 'store';

import { Observable } from 'rxjs';
import { filter, first, map, tap, withLatestFrom } from 'rxjs/operators';
import { GET_URL_ALL_PARAMETERS } from '../utils';
import { getMarketingSelectedProducts } from 'store/selectors';

export class MarketingProductResolver implements Resolve<Observable<any>> {
    constructor(private store$: Store<EnrollmentState>) {  }

    resolve(){
        return this.store$.select(getMarketingSelectedProducts).pipe(
            map(products => {
                const stateParams = GET_URL_ALL_PARAMETERS();
                const productCodes = stateParams['products[]'];
                let vestingType = stateParams['vestingtype'] || 1;
                let advisorId = stateParams['advisorid'];
                let productsName = '';
                if(productCodes && productCodes != ""){
                    this.store$.dispatch(new SetProductsSelectionAction(productCodes, vestingType, advisorId));
                }else{
                    if(products.length > 0 ){
                        products.forEach((prod, index, array) => {
                            productsName = `${productsName}${prod.displayName}${!Object.is(array.length - 1, index) ? ', ' : ''}`;
                        });
                        this.store$.dispatch(new UpdateSubBannerAction(true, 'Product', productsName));
                        return products;
                    }
                }
                return products
            }),
            filter(products => !!products),
            first()
        );
    }
}