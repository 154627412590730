import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FEATURE_NAMES } from "store/constants";
import { WhiteLabelState } from "store/states/white-label.state";

export const selectWLState = createFeatureSelector(FEATURE_NAMES.WhiteLabel);

export const selectOptionLevels = createSelector(
    selectWLState, 
    (state: WhiteLabelState) => state.optionLevels? state.optionLevels: []
);

export const selectOptionLevel = createSelector(
    selectWLState, 
    (state: WhiteLabelState) => state.optionLevel? state.optionLevel: null
);

export const selectTrustedContactWl = createSelector(
    selectWLState,
    (state: WhiteLabelState) => state.trustedContact ? state.trustedContact : null
)