import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
// Models
import { Applicant } from 'models';
import { APPLICANT_TYPE_NAME } from 'models/enums';
import { ApplicantBaseResolver } from './abstracts';
// Resolver
import { ApplicationResolver } from './application.resolver';

@Injectable()
export class ApplicantPrimaryResolver extends ApplicantBaseResolver implements Resolve<Observable<Applicant>> {

  constructor(store$: Store<EnrollmentState>, appResolver: ApplicationResolver) {
    super(store$, appResolver);
  }

  resolve() { return this.getApplicant(APPLICANT_TYPE_NAME.Primary); }
}
