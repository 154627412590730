import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';


@Injectable()
export class DownloadService {

  constructor(private http: HttpClient) { }

  downloadPDF(url): any {
    return this.http.get(url, { responseType: 'blob' as 'json' }).pipe(
      map(res => {
        return new Blob([res as any], { type: 'application/pdf' });
      })
    );
  }
}
