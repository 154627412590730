import {
    createSelector,
    createFeatureSelector
} from '@ngrx/store';

import { ProductsState } from '../states';
import { FEATURE_NAMES, PRODUCTS } from '../constants';
import { ISimpleProduct } from 'models';

export const getProductsState = createFeatureSelector(FEATURE_NAMES.Products);

export const getVestingTypes = createSelector(
    getProductsState, (state: ProductsState) => state.vestingTypes
);

export const getMarketingProductsValidated= createSelector(
    getProductsState, (state: ProductsState) => state.marketingProductsValidated
);

export const getMarketingSelectedProducts = createSelector(
    getProductsState, (state: ProductsState) => state.marketingSelectedProducts
);

export const getSelectedProducts = createSelector(
    getProductsState, (state: ProductsState) => state.selectedProducts
);

export const getSelectedVestingTypeId = createSelector(
    getProductsState, (state: ProductsState) => state.marketingSelectedVestingType
);

export const getAdvisorId = createSelector(
    getProductsState, (state: ProductsState) => state.marketingAdvisorId
);

export const getIRAFlag = createSelector(
    getProductsState, (state: ProductsState) => state.IRAFlag
);

export const getProxyProducts = createSelector(
    getProductsState, (state: ProductsState) => state.proxyProducts
);

export const getProxySelectedProducts = createSelector(
    getProductsState, (state: ProductsState) => state.proxySelectedProducts
);

export const getOrganizationProducts = createSelector(
    getProductsState, (state: ProductsState) => state.organizationProducts
);

export const getIsManagedPortfolio = createSelector(
    getProductsState, (state: ProductsState) => state.marketingSelectedProducts.some(x => x.productCode == 'MPO')
);

export const getIsManagedPortfolioOrSelfDirectedTrading = createSelector(
    getProductsState, (state: ProductsState) => state.marketingSelectedProducts.some(x => [PRODUCTS.MPO, PRODUCTS.TRAD].includes(x.productCode))
);
