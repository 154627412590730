declare var environment: any;

import { Injectable } from '@angular/core';

import { CONSUMER_DISCLOSURE_LIST, BUSINESS_DISCLOSURE_LIST } from 'app/disclosures/mocks';
// Services
import { BaseService } from './abstracts';
// Models
import { DisclosuresRequest, DisclosureList, DisclosuresSaveRequest } from 'models';
import { BRAND_CONTEXT } from 'models/enums';

@Injectable()
export class DisclosuresService extends BaseService {
  postTerms(request: DisclosuresRequest) {
    return this._post('v2/pip/Terms', request);
  }

  confirmDisclosures(applicationId: number) {
    return this._post(`v2/Disclosures/Accept/${applicationId}`);
  }

  save(request: DisclosuresSaveRequest) {
    return this._post('v2/Disclosures', request);
  }

  get(): DisclosureList {
    return environment.brandContext === BRAND_CONTEXT.Business ?
      BUSINESS_DISCLOSURE_LIST : CONSUMER_DISCLOSURE_LIST;
  }
}
