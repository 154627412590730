import { Action } from "@ngrx/store";

export enum LAYOUT_ACTION_TYPES{
     SET_MOBILE_LAYOUT = '[Layout] Set Mobile Layout',
     SET_MOBILE_LAYOUT_SUCCESS = '[Layout] Set Mobile Layout Success',
     SET_MOBILE_LAYOUT_FAIL = '[Layout] Set Mobile Layout Fail'
}

export class SetMobileLayout implements Action{
     readonly type = LAYOUT_ACTION_TYPES.SET_MOBILE_LAYOUT;
     constructor(){ }
}

export class SetMobileLayoutSuccess implements Action{
     readonly type = LAYOUT_ACTION_TYPES.SET_MOBILE_LAYOUT_SUCCESS;
     constructor(public isMobile: boolean){}
}

export class SetMobileLayoutFail implements Action{
     readonly type = LAYOUT_ACTION_TYPES.SET_MOBILE_LAYOUT_FAIL;
}

export type LayoutAction = SetMobileLayout |
SetMobileLayoutSuccess | SetMobileLayoutFail;