import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { filter, first, map, withLatestFrom, switchMap } from 'rxjs/operators';

import { Resolve } from "@angular/router";
import { Beneficiary } from "models";
import { Store } from "@ngrx/store";
import { EnrollmentState, GetBeneficiariesInformationAction } from "store";
import { selectBeneficiariesInformation, getApplicationStateId, selectBeneficiariesContingentInformation, getIRAFlag } from "store/selectors";
import { APPLICATION_STATE, BENEFICIARY_TYPE } from "models/enums";

@Injectable()
export class BeneficiariesContingentInformationResolver implements Resolve<Observable<Beneficiary[]>>{

    constructor(private store$: Store<EnrollmentState>){}

    resolve(){
        return this.store$.select(selectBeneficiariesContingentInformation).pipe(
            map(beneficiaries => {
                if (!beneficiaries){
                    this.store$.dispatch(new GetBeneficiariesInformationAction(BENEFICIARY_TYPE.Contingent));
                }
                
                return beneficiaries
            }),
            filter(beneficiaries => !!beneficiaries),
            first()
        )
    }
}