import { Injectable } from '@angular/core';
// RxJs
import { switchMap, map, filter, first, withLatestFrom, concatMap, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getUserApplicationId, getApplicationId, getOrganization } from 'store/selectors';
// NgRx Effects
import { Effect, Actions, ofType } from '@ngrx/effects';
// Services
import { DisclosuresService, DisclosuresUniversalService } from 'services';
// Actions
import * as disclosuresActions from '../actions/disclosures.action';
import * as rulesActions from '../actions/rules.action';
import { getECMDisclosureDocuments } from 'store/selectors/disclosure.selectors';

import { saveAs } from 'file-saver';

@Injectable()
export class DisclosuresEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<EnrollmentState>,
    private disclosuresService: DisclosuresService,
    private disclosuresUniversalService: DisclosuresUniversalService) { }

  private applicationId$ = this.store$.select(getUserApplicationId)
    .pipe(filter(applicationId => !!applicationId));

  @Effect()
  getDetailDisclosures$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_DETAIL_DISCLOSURES),
    withLatestFrom(this.store$.select(getApplicationId)),
    concatMap(data => {
      return this.disclosuresUniversalService.getAxosClearingDisclosures().pipe(map(() => data));
    }),
    concatMap(data => {
      const applicationId = data[1];
      return this.disclosuresUniversalService.getDetailDisclosures(applicationId).pipe(
        map(disclosures => new disclosuresActions.GetDetailDisclosuresSuccessAction(disclosures)));
    })
  );

  @Effect()
  getAxosInvestDisclosures$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_AXOS_INVEST_DISCLOSURE),
    withLatestFrom(this.store$.select(getApplicationId)),
    concatMap(data => {
      const applicationId = data[1];
      return this.disclosuresUniversalService.getApplicationApplicantDisclosure("AxosInvestElectronicAgreement").pipe(
        map(disclosure => new disclosuresActions.GetAxosInvestDisclosureSuccessAction(disclosure)));
    })
  );

  @Effect()
  getTaxPayerDisclosures$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_TAXPAYER_DISCLOSURE),
    withLatestFrom(this.store$.select(getApplicationId)),
    concatMap(data => {
      const applicationId = data[1];
      return this.disclosuresUniversalService.getApplicationApplicantDisclosure("TaxpayerIdentificationNumberCertification").pipe(
        map(disclosure => new disclosuresActions.GetTaxPayerDisclosureSuccessAction(disclosure)));
    })
  );

  @Effect()
  getAxosClearingDisclosures$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_AXOS_CLEARING_DISCLOSURES),
    switchMap(() => {
      return this.disclosuresUniversalService.getAxosClearingDisclosures().pipe(
        map(disclosures => new disclosuresActions.GetAxosClearingDisclosuresSuccessAction(disclosures)))
    })
  );

  @Effect()
  getElectronicDisclosure$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_ELECTRONIC_DISCLOSURE),
    switchMap(() => {
      return this.disclosuresUniversalService.getElectronicDisclosure().pipe(
        map(disclosure => new disclosuresActions.GetElectronicDisclosureSuccessAction(disclosure))
      );
    })
  );

  @Effect()
  getinitialDisclosures$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_INITIAL_DISCLOSURES),
    switchMap(() => {
      return this.disclosuresUniversalService.getInitialDisclosures().pipe(
        map(disclosures => new disclosuresActions.GetInitialDisclosuresSuccessAction(disclosures))
      );
    })
  );

  @Effect()
  save$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.SAVE),
    withLatestFrom(this.applicationId$),
    switchMap(data => {
      const action = <disclosuresActions.SaveDisclosuresAction>data[0],
        applicationId = data[1];
      action.request.applicationId = applicationId;
      return this.disclosuresService.save(action.request);
    }),
    switchMap(() => [
      new disclosuresActions.SaveDisclosuresSuccessAction(),
      new rulesActions.ValidateApplicationAction()
    ])
  );

  @Effect()
  confirm$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.CONFIRM),
    switchMap(() => this.applicationId$.pipe(first())),
    switchMap(applicationId => {
      return this.disclosuresService.confirmDisclosures(+applicationId).pipe(
        map(() => new disclosuresActions.ConfirmDisclosuresSuccessAction()));
    })
  );

  @Effect()
  postTerms$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.POST_TERMS),
    switchMap((action: disclosuresActions.PostTermsAction) => {
      return this.disclosuresService.postTerms(action.request).pipe(
        map(() => new disclosuresActions.PostTermsSuccessAction()));
    })
  );

  @Effect()
  getDisclosuresCatalog$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_DISCLOSURES_CATALOG),
    switchMap(() => {
      return this.disclosuresUniversalService.getDisclosuresCatalog().pipe(
        map(disclosures => new disclosuresActions.GetDisclosuresCatalogSuccessAction(disclosures)));
    })
  );

  @Effect()
  updateDisclosure$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.UPDATE_DISCLOSURE),
    withLatestFrom(this.store$.select(getApplicationId)),
    concatMap(data => {
      const action = <disclosuresActions.UpdateDisclosuresAction>data[0],
        applicationId = data[1];
      return this.disclosuresUniversalService.updateDisclosure(applicationId, action.disclosure).pipe(
        map(() => new disclosuresActions.UpdateDisclosuresSuccessAction()));
    })
  );

  @Effect()
  saveApplicantDisclosures$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.SAVE_APPLICANT_DISCLOSURES),
    withLatestFrom(this.store$.select(getApplicationId)),
    concatMap(data => {
      const action = <disclosuresActions.SaveApplicantDisclosuresAction>data[0],
        applicationId = data[1];
      return this.disclosuresUniversalService.saveApplicantDisclosures(applicationId, action.disclosures).pipe(
        map(() => new disclosuresActions.UpdateDisclosuresSuccessAction()));
    })
  );

  @Effect()
  getECMDisclosures$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURES),
    withLatestFrom(this.store$.select(getApplicationId)),
    switchMap(data => {
      const action = <disclosuresActions.GetECMDisclosuresAction>data[0],
        applicationId = data[1];
      return this.disclosuresUniversalService.getECMDisclosures(applicationId).pipe(
        map(disclosures => new disclosuresActions.GetECMDisclosuresSuccessAction(disclosures.data)));
    })
  );

  @Effect()
  getAndOpenECMDisclosureDocument$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_AND_OPEN_ECM_DISCLOSURE_DOCUMENT),
    switchMap((action: disclosuresActions.GetECMDisclosureDocumentAction) => {
      return this.disclosuresUniversalService.getECMDisclosureDocument(action.documentId);
    }),
    switchMap(result => [
      new disclosuresActions.GetECMDisclosureDocumentSuccessAction(result.data),
      new disclosuresActions.OpenECMDisclosureAction(result.data.disclosureName)
    ])
  );

  @Effect({ dispatch: false })
  openECMDisclosureDocument$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.OPEN_ECM_DISCLOSURE_DOCUMENT),
    withLatestFrom(this.store$.select(getECMDisclosureDocuments)),
    tap(data => {
      const action = <disclosuresActions.OpenECMDisclosureAction>data[0],
        documents = data[1];
      const document = documents.find(x => x.disclosureName == action.disclosureName);
      const byteCharacters = atob(document.base64File);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: 'application/pdf;base64' });
      saveAs(file, document.documentName);
    })
  );

  @Effect()
  getECMDisclosureDocument$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURE_DOCUMENT),
    switchMap((action: disclosuresActions.GetECMDisclosureDocumentAction) => {
      return this.disclosuresUniversalService.getECMDisclosureDocument(action.documentId);
    }),
    map(result => 
      new disclosuresActions.GetECMDisclosureDocumentSuccessAction(result.data)
    )
  );

  @Effect()
  getECMDisclosureDocumentByDisclosure$ = this.actions$.pipe(
    ofType(disclosuresActions.DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURE_DOCUMENT_BY_DISCLOSURE),
    switchMap((action: disclosuresActions.GetECMDisclosureDocumentByDisclosureAction) => {
      return this.disclosuresUniversalService.getECMDisclosureDocumentByDisclosureId(action.disclosureId);
    }),
    map(disclosureDocument => 
      new disclosuresActions.GetECMDisclosureDocumentByDisclosureSuccessAction(disclosureDocument.data)
    )
  );
}
