import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationError, NavigationCancel } from '@angular/router';
// RxJs
import { Subject, BehaviorSubject } from 'rxjs';
import { filter, delay } from 'rxjs/operators';

@Injectable()
export class LoadingService {
  private _customTasksCount: number;
  private _requestStack: string[];
  private _inTransition: boolean;
  private delayRequest$ = new Subject<string>();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  get requestsInProgress(): boolean { return this._requestStack.length > 0; }
  get tasksInProgress(): boolean { return this._customTasksCount > 0; }
  get inTransition(): boolean { return this._inTransition; }

  constructor(router: Router) {
    this._requestStack = [];
    this._customTasksCount = 0;
    this._inTransition = false;
    // Listen for transtion end
    router.events.pipe(
      filter((event) => event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel)
    ).subscribe(() => this.transition(false));
    // Listen for transtion start
    router.events.pipe(
      filter((event) => event instanceof NavigationStart)
    ).subscribe(() => this.transition(true));

    this.delayRequest$.pipe(delay(500))
      .subscribe(id => this.updateRequest(id, false));
  }

  private updateRequest(id: string, add: boolean) {
    const requestExist = this._requestStack.includes(id);
    if(add && !requestExist) {
      this._requestStack = [ ...this._requestStack, id ];
    }
    if(!add && requestExist) {
      this._requestStack = [ ...this._requestStack.filter(rId => rId !== id)];
    }
    this.onChange();
  }

  private updateTask(increase: number){
    this._customTasksCount = this._customTasksCount + increase;
    this.onChange();
  }

  private onChange() {
    const _loading = this.requestsInProgress || this.inTransition || this.tasksInProgress;
    if(_loading !== this.loading$.value) {
      this.loading$.next(_loading);
    }
  }

  private transition(start: boolean) {
    this._inTransition = start;
    // Resets any open task after transition
    if(this.inTransition && this.tasksInProgress) {
      this._customTasksCount = 0;
    }
    this.onChange();
  }

  requestStart(id) { this.updateRequest(id, true); }
  requestStop(id) { this.delayRequest$.next(id); }

  startTask() { this.updateTask(1); }
  endTask() { this.updateTask(-1); }
}
