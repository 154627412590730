import { BUSINESS_ROUTES } from './route.constants';
import { APPLICATION_STATE } from 'models/enums';

export const BUSINESS_FLOW: APPLICATION_STATE[] = [
  /*
  BUSINESS_ROUTES.TIN.name,
  BUSINESS_ROUTES.BASIC_INFO.name,
  BUSINESS_ROUTES.CONTACT_INFO.name,
  BUSINESS_ROUTES.DIFF_NAME_SELECTION.name,
  BUSINESS_ROUTES.DIFF_NAME_FORM.name,
  BUSINESS_ROUTES.ADDRESS.name,
  BUSINESS_ROUTES.MAILING_SAME_ADDRESS.name,
  BUSINESS_ROUTES.MAILING_ADDRESS.name,
  BUSINESS_ROUTES.ANNUAL_SALES.name,
  BUSINESS_ROUTES.DEPOSIT_AMOUNT.name
  */
];
