// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first, tap, map, withLatestFrom, switchMap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getAccountFeature, getFeatures } from 'store/actions';
import { selectAccountFeature, selectFeatures, getApplicationLoaded } from 'store/selectors';

@Injectable()
export class AccountFeaturesResolver implements Resolve<Observable<any>> {

     constructor(private store$: Store<EnrollmentState>) {  }

     resolve() {
      return this.store$.select(getApplicationLoaded).pipe(
        filter(loaded => loaded),
        switchMap(() => this.store$.select(selectAccountFeature)),
        withLatestFrom(
          this.store$.select(selectFeatures)
        ),
        map(([accountFeatures, featuresCatalog]) => {
          if(!accountFeatures){
            this.store$.dispatch(getAccountFeature());
          }
          if(!featuresCatalog || featuresCatalog.length < 1){
            this.store$.dispatch(getFeatures());
          }
          return accountFeatures
        }),
        filter(accountFeatures => !!accountFeatures),
        first()
      );
    }
}
