// export enum APPLICATION_STATUS_IDS {
// 	Approved = 1,
// 	Cancelled = 2,
// 	Complete = 3,
// 	Declined = 4,
// 	Incomplete = 5,
// 	Invalid = 6,
// 	NotSubmitted = 7,
// 	Review = 8,
// 	SystemFailure = 9,
// 	WaitingForCustomer = 10
// }

export enum APPLICATION_STATUS {
	Approved = 'approved',
	Cancelled = 'cancelled',
	Complete = 'complete',
	Declined = 'declined',
	Incomplete = 'incomplete',
	Invalid = 'invalid',
	NotSubmitted = 'notsubmitted',
	InReview = 'inreview',
	SystemFailure = 'systemfailure',
	WaitingForCustomer = 'waitingforcustomer'
}

// export enum APPLICATION_STATUS {
//   Incomplete = 1,
//   Pending = 2,
//   Approved = 3,
//   Declined = 4,
//   Review = 5,
//   SubmittedToHost = 6,
// 	DeclinedCredit = 7,
// 	DeclinedVelocity = 8,
// 	DeclinedDigitalResolve = 9,
// 	DeclinedIntelligentSearchTech = 10,
// 	DeclinedInternalWatchLists = 11,
// 	NotSubmitted = 12,
// 	PendingBankReview = 13,
//   WaitingForCustomer = 14,
// 	SystemFailure = 15,
// 	Cancelled = 16,
// 	Complete = 17,
// 	Referred = 18,
// 	WaitingForCustomerToSelectFunding = 19,
// 	WaitingForCustomerToCompleteTaskList = 20,
//   ReferredWaitingonCustomer = 21
// }

// export enum APPLICATION_DECISION_STATUS_IDS {
// 	Approved = 1 || 13,
// 	Declined = 2 || 14,
// 	Cancelled = 3 || 4 || 5 || 6 || 7,
// 	InReview = 12
// }

export enum APPLICATION_DECISION_STATUS{
	AutoApproved = 'autoapproved',
	AutoDeclined = 'autodeclined',
	Cancelled = 'cancelled',
	CancelledByBankAfterApproval = 'cancelledbybankafterapproval',
	CancelledByBankBeforeApproval = 'cancelledbybankbeforeapproval',
	CancelledByCustomerAfterApproval = 'cancelledbycustomerafterapproval',
	CancelledByCustomerBeforeApproval = 'cancelledbycustomerbeforeapproval',
	CautionApproved = 'cautionapproved',
	ExpiredNoResponseAfterApproval = 'expirednoresponseafterapproval',
	ExpiredNoResponseBeforeApproval = 'expirednoresponsebeforeapproval',
	ExpiredNoResponseBeforeSubmission = 'expirednoresponsebeforesubmission',
	InReview = 'inreview',
	ManuallyApproved = 'manuallyapproved',
	ManuallyDeclined = 'manuallydeclined',
	NotSubmitted = 'notsubmitted',
	ApprovedAutoPendingPrincipalReview = 'approvedautopendingprincipalreview',
	ApprovedAutoPrincipalApproved = 'approvedautoprincipalapproved',
	ApprovedReviewPendingPrincipalReview = 'approvedreviewpendingprincipalreview',
	ApprovedReviewPrincipalApproved = 'approvedreviewprincipalapproved'
}

// export enum APPLICATION_DECISION_STATUS {
//   NoDecision = 1,
// 	Approved = 2,
// 	Declined = 3,
// 	Cancelled = 4
// }

// export enum APPLICATION_DECISION_REASON_IDS {
// 	InReview = 1, 
// 	NotSubmitted = 2, 
// 	ApprovedAuto = 3, 
// 	ApprovedReview = 4, 
// 	CustomerReview = 5, 
// 	ApprovedManually = 6,
// 	DcoumentsRequested = 7,
// 	DeclinedAuto = 8, 
// 	DeclinedReview = 9,
// 	DeclinedManually = 10,
// 	Cancelled = 11, 
// 	BoardingError = 12,
// 	SystemError = 13,
// 	CancelledByCustomerBeforeApproval = 14, 
// 	CancelledByCustomerAfterApproval = 15, 
// 	CancelledByBankBeforeApproval = 16,
// 	CancelledByBankAfterApproval = 17,
// 	ExpiredNoResponseBeforeApproval = 18,
// 	ExpiredNoResponseAfterApproval = 19,
// 	ExpiredNoResponseBeforeSubmission = 20,
// 	ExpiredNoResponseAfterReferral = 21,
// }

export enum APPLICATION_DECISION_REASON {
	InReview = 'review', 
	NotSubmitted = 'notsubmitted', 
	ApprovedAuto = 'approvedauto', 
	ApprovedReview = 'approvedreview', 
	CustomerReview = 'customerreview', 
	ApprovedManually = 'approvedmanually',
	DocumentsRequested = 'documentsrequested',
	DeclinedAuto = 'declinedauto', 
	DeclinedReview = 'declinedreview',
	DeclinedManually = 'declinedmanually',
	Cancelled = 'cancelled', 
	BoardingError = 'boardingerror',
	SystemError = 'systemerror',
	CancelledByCustomerBeforeApproval = 'cancelledbycustomerbeforeapproval', 
	CancelledByCustomerAfterApproval = 'cancelledbycustomerafterapproval', 
	CancelledByBankBeforeApproval = 'cancelledbybankbeforeapproval',
	CancelledByBankAfterApproval = 'cancelledbybankafterapproval',
	ExpiredNoResponseBeforeApproval = 'expirednoresponsebeforeapproval',
	ExpiredNoResponseAfterApproval = 'expirednoresponseafterapproval',
	ExpiredNoResponseBeforeSubmission = 'expirednoresponsebeforesubmission',
	ExpiredNoResponseAfterReferral = 'expirednoresponseafterreferral',
}

// export enum APPLICATION_DECISION_REASON {
// 	InReview = 1,
// 	ApprovedAuto = 2,
// 	ManuallyApproved = 3,
// 	DeclinedAuto = 4,
// 	DeclinedAutoCredit = 5,
// 	DeclinedReview = 6,
// 	ManuallyDeclinedCredit = 7,
// 	ReferredAuto = 8,
// 	ReferredReview = 9,
// 	Cancelled = 10,
// 	ReferredAutoAccepted = 11,
// 	ReferredReviewAccepted = 12,
// 	NotSubmitted = 13,
// 	ReferredAutoNotAccepted = 14,
// 	ReferredReviewNotAccepted = 15,
// 	CancelledByCustomerBeforeApproval = 16,
// 	CancelledByCustomerAfterApproval = 17,
// 	CancelledByBankBeforeApproval = 18,
// 	CancelledByBankAfterApproval = 19,
// 	ExpiredNoResponseBeforeApproval = 20,
// 	ExpiredNoResponseAfterApproval = 21,
// 	DeclinedAutoCreditFreeze = 22,
// 	DeclinedReviewCreditFreeze = 23,
// 	ApprovedCaution = 24,
// 	InCautionReview = 25,
// 	DeclinedAutoNotReferred = 26,
// 	ExpiredByProxy = 27,
// 	ExpiredNoResponseBeforeSubmission = 28,
//   ExpiredNoResponseAfterReferral = 29
// }
