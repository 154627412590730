import { Injectable } from "@angular/core";

@Injectable()
export class FileProcessingService {

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let base64 = <string>reader.result;
                base64 = base64.split('base64,')[1];
                resolve(base64)
            };
            reader.onerror = error => reject(error);
        });
    }

    base64ToBufferteArray(base64: string) {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = [];
        for (let i = 0; i < len; i++) {
            bytes.push( binary_string.charCodeAt(i));
        }
        return bytes;        
    }

    getArrayBuffer(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = () => {
                const array = new Uint8Array(<ArrayBuffer>reader.result);
                resolve(array);
            }
            reader.onerror = error => reject(error);
        });
    }
}