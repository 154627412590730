// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetAxosClearingDisclosuresAction } from 'store/actions';
// Model
import { DetailDisclosure } from 'models';
import { getAxosClearingDisclosures } from 'store/selectors/disclosure.selectors';

@Injectable()
export class AxosClearingDisclosuresResolver implements Resolve<Observable<DetailDisclosure[]>> {

    constructor(private store: Store<EnrollmentState>) { }

    resolve() {
        return this.store.select(getAxosClearingDisclosures).pipe(
            tap(disclosures => {
                if (!disclosures) {
                    this.store.dispatch(new GetAxosClearingDisclosuresAction());
                }
                return disclosures;
            }),
            filter(disclosures => !!disclosures),
            first()
        );
    }
}
