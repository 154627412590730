import { Component } from '@angular/core';

@Component({
    selector: 'axos-header-mobile',
    templateUrl: './header-mobile.component.html',
    styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent {    
    constructor() { }
}
