import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { EnrollmentState, GetBeneficiariesCatalogAction } from "store";
import { tap, filter, first, switchMap } from "rxjs/operators";
import { BeneficiariesCatalog } from "models";
import { getRelationShips, getRelationShipsLoaded } from "store/selectors";
import { CatalogItem } from "models/trading/catalog-item.model";

@Injectable()
export class RelationShipsResolver implements Resolve<Observable<CatalogItem[]>>{
    constructor(private store$: Store<EnrollmentState>){ }
    resolve(){
        return this.store$.select(getRelationShipsLoaded).pipe(
            switchMap(loaded => {
                if(!loaded){
                    this.store$.dispatch(new GetBeneficiariesCatalogAction());
                }
                return this.store$.select(getRelationShips).pipe(
                    filter(relationships => relationships.length > 0)
                )
            }),
            first()
        )
    }
}