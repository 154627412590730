import {
  IDENTIFICATION_DOCUMENT_TYPE, IMAGE_TYPE,
} from '../enums'
import { IdVerificationIdentifierDto } from './id-verification-identifier-dto.model';

export class IdentityDocumentDto {
  FileData: string;
  DocumentTypeId: IDENTIFICATION_DOCUMENT_TYPE;
  DocumentSubTypeId: IMAGE_TYPE;
  IdVerificationIdentifier: IdVerificationIdentifierDto;

  constructor(image: string, documentType: IDENTIFICATION_DOCUMENT_TYPE, imageType: IMAGE_TYPE, idvIdentifier: IdVerificationIdentifierDto) {
    this.FileData = image;
    this.DocumentTypeId = documentType;
    this.DocumentSubTypeId = imageType;
    this.IdVerificationIdentifier = idvIdentifier;
  }
}
