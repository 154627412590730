import { Injectable } from "@angular/core";
import { BaseService, AuthService, ApplicationService } from "services";





@Injectable()
export class ExistingCustomerService extends BaseService {



     create(brandId, productVesting) {
        return this._post(`v2/Customer/Existing/Add/${brandId}`, productVesting);        
    }

}
