import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
// RxJs
import { map } from 'rxjs/operators';
// Enrollment Common
import { VESTING_TYPE_ID } from 'models/enums';
import { BusinessBaseGuard } from './base.guard';


@Injectable()
export class BusinessTINGuard extends BusinessBaseGuard implements CanActivate {
    canActivate() {
        return this.getData().pipe(
            map(value => {
                const vestingId = value.application.vestingType.Id;
                const _activate = vestingId === VESTING_TYPE_ID.LLC || vestingId === VESTING_TYPE_ID.SoleProprietorship;
                this.shouldNavigate(_activate);
                return _activate;
            })
        )
    }
}
