// Ng
import { Injectable } from '@angular/core';
// RxJs
import { switchMap, map, withLatestFrom, concatMap, take, catchError, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import {
  getApplicationId,
  getCurrentApplicantId,
  getCurrentApplicant,
  getApplicantsMetadata,
  getCurrentApplicantType,
  //getUserProspect,
  getApplicants,
  getUserLead,
  getCountries,
  getApplicantByType
} from 'store/selectors';
// NgRx Effects
import { Effect, Actions, ofType } from '@ngrx/effects';
// Services
import {
  ApplicantInformationService,
  AddressService
} from 'services';
// Models
import { Applicant, Address, PhoneNumber, IApplicantMetadata, TrustCertificate, RecommendedAddress } from 'models';
// Actions
import * as applicationActions from '../actions/application.action';
import * as applicantsActions from '../actions/applicants.action';
import { MARITAL_STATUS_ID, MARITAL_STATUS_NAME, MARITAL_TO_NAME, MARITAL_TO_ID } from 'models/enums/trading/marital-status.enum';
import { EMPLOYMENT_STATUS_ID, EMPLOYMENT_TO_ID, EMPLOYMENT_TO_NAME } from 'models/enums/trading/employment-status.enum';
import { Affiliation, AffiliationName } from 'models/trading/affiliation.model';
import { TITLES_NAME, AFFILIATION_ID } from 'models/enums/trading/affiliations.enum';
import { OCCUPATION_NAME } from 'models/enums/trading/occupation.enum';
import { ADDRESS_TYPE_NAME, APPLICANT_TYPE_NAME } from 'models/enums';
import { setWorkflowStep, setWorkflow } from 'store/workflows';
import { APPLICANT_TYPE_TO_NAME } from 'models/enums/applicant/applicant-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { DocumentRequest, DocumentRequestService } from 'app/documents';
import { IdentificationUploadService } from 'app/enrollment-common';

@Injectable()
export class ApplicantsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<EnrollmentState>,
    private addressService: AddressService,
    private applicantInformationService: ApplicantInformationService,
    private identificationUploadService: IdentificationUploadService,
    public titleCasePipe: TitleCasePipe,
    private documentService: DocumentRequestService) { }

  private applicationId$ = this.store$.select(getApplicationId);

  @Effect()
  afterApplicationLoaded$ = this.actions$.pipe(
    ofType(
      applicationActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_SUCCESS,
      applicationActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_BY_TOKEN_SUCCESS),
    map(({ application }) => {
      return new applicantsActions.SetCurrentApplicantTypeAction(
        APPLICANT_TYPE_TO_NAME[application['applicantTypeId']] || APPLICANT_TYPE_NAME.Primary
      );
    }));

  LoadApplicationSuccessAction

  @Effect()
  loadAllApplicants$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.LOAD_ALL_APPLICANTS),
    withLatestFrom(this.applicationId$, this.store$.select(getCountries)),
    switchMap(data => {
      const applicationId = data[1];
      const states = data[2];
      return this.applicantInformationService.getAllApplicants(applicationId)
        .pipe(
          map((applicants: Applicant[]) => {
            applicants = applicants.map(applicant => {
              applicant.type = applicant.type.toLowerCase();
              applicant.affiliations = applicant.affiliations.map(affiliation => {
                affiliation.affiliationTypeName = (affiliation.affiliationTypeName as AffiliationName).name;
                affiliation.companyTitleName = (affiliation.companyTitleName as AffiliationName).name;
                return affiliation;
              });
              applicant.person.affiliations = applicant.affiliations || [];
              applicant.person.maritalStatus = MARITAL_TO_ID[applicant.person.maritalStatus];
              applicant.person.employment.employmentType = EMPLOYMENT_TO_ID[applicant.person.employment.employmentType];
              const Applicants: IApplicantMetadata[] = applicants.map((app) => {
                return {
                  applicantId: app.applicantId,
                  type: app.type
                }
              });
              applicant.addresses.forEach((element)=>{
                if(element.zipPlus4 && element.zipPlus4 !=''){
                    if(!element.zip.includes('-')){
                        element.zip = `${element.zip}-${element.zipPlus4}`;
                    }
                }
            });
              applicant.addresses = applicant.addresses.map((address) => {
                return {
                  ...address,
                  street: this.titleCasePipe.transform(address.street),
                  street2: this.titleCasePipe.transform(address.street2),
                  city: this.titleCasePipe.transform(address.city)
                }
              });
              this.store$.dispatch(new applicationActions.SetApplicantsMeta(Applicants));
              return applicant;
            });
            return new applicantsActions.LoadAllApplicantsSuccessAction(applicants);
          })
        );
    })
  );

  @Effect()
  loadApplicant$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.LOAD_APPLICANT),
    withLatestFrom(this.store$.select(getCountries)),
    concatMap(data => {
      const action = <applicantsActions.LoadApplicantAction>data[0];
      const states = data[1];
      return this.applicantInformationService.getApplicant(action.applicantId)
        .pipe(
          map(response => {
            response.type = response.type.toLowerCase();
            response.affiliations = response.affiliations.map(affiliation => {
              affiliation.affiliationTypeName = (affiliation.affiliationTypeName as AffiliationName).name;
              affiliation.companyTitleName = (affiliation.companyTitleName as AffiliationName).name;
              return affiliation;
            });
            response.person.affiliations = response.affiliations;
            response.person.maritalStatus = MARITAL_TO_ID[response.person.maritalStatus];
            response.person.employment ? response.person.employment.employmentType = EMPLOYMENT_TO_ID[response.person.employment.employmentType] : '';
            return new applicantsActions.LoadApplicantSuccessAction(response, action.setAsCurrent);
          })
        );
    })
  );

  /*
  @Effect()
  loadApplicantSuccess$ = this.actions$.pipe(
      ofType(applicantsActions.APPLICANTS_ACTION_TYPES.LOAD_APPLICANT_SUCCESS),
      map(({ applicant }) => setWorkflow({
        workflow: (<Applicant>applicant).workflowSteps,
        state: (<Applicant>applicant).applicantStateName,
      })));
  */

  // @Effect()
  // updateApplicant$ = this.actions$.pipe(
  //     ofType(applicantsActions.APPLICANTS_ACTION_TYPES.UPDATE_APPLICANT),
  //     switchMap((action: applicantsActions.UpdateApplicantAction) => {
  //         const applicant = action.applicant;
  //         return this.applicantInformationService.updateApplicant({
  //             ApplicantId: applicant.ApplicantId,
  //             FirstName: applicant.FirstName,
  //             MiddleName: applicant.MiddleName,
  //             LastName: applicant.LastName,
  //             SuffixId: applicant.SuffixId,
  //             Occupation: applicant.Occupation,
  //             DOB: applicant.DOB,
  //             CitizenshipTypeId: applicant.CitizenshipTypeId,
  //             EmploymentTypeId: applicant.EmploymentTypeId,
  //             SSN: applicant.SSN,
  //             EmailPrimary: applicant.EmailPrimary,
  //             TrustName: applicant.TrustName
  //         }).pipe(map(response => new applicantsActions.UpdateApplicantSuccessAction(response, applicant.applicantId, action.shouldNavigate)))
  //     })
  // );

  @Effect()
  saveApplicantAddress$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_ADDRESS),
    withLatestFrom(this.store$.select(getCurrentApplicantId)),
    concatMap(data => {
      const action = <applicantsActions.SaveApplicantAddressAction>data[0];
      const currentApplicantId = data[1];

      const address: Address = {
        ...action.address,
        applicantId: currentApplicantId,
        addressType: action.addressType
      };
      return this.addressService.updateApplicantAddress(currentApplicantId, address, action.skipHistory)
        .pipe(
          map(() => new applicantsActions.SaveApplicantAddressSuccessAction(address, currentApplicantId, action.shouldNavigate, action.skipHistory)),
          catchError((error: HttpErrorResponse) => {
            const errorMessage = error.error ? error.error.Message : "Save Address Error";
            return of(new applicantsActions.SaveApplicantAddressFailAction(errorMessage))
          })
        );
    })
  );

  @Effect({ dispatch: false })
  saveApplicantAddressFail$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_ADDRESS_FAIL),
    tap(() => {
      return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
    })
  );

  /***** Address Side Effect *****/

  @Effect()
  recommendedApplicantAddress$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.RECOMMENDED_APPLICANT_ADDRESS),
    withLatestFrom(this.store$.select(getCurrentApplicantId)),
    concatMap(data => {
      const action = <applicantsActions.RecommendedApplicantAddressAction>data[0];
      const currentApplicantId = data[1];

      const address: RecommendedAddress = {
        ...action.recaddress,
      };
      return this.addressService.getRecommendedAddress(address)
        .pipe(
          map(response => new applicantsActions.RecommendedApplicantAddressSuccessAction(response, currentApplicantId, action.shouldNavigate, action.skipHistory)),
          catchError((error: HttpErrorResponse) => {
            const errorMessage = error.error ? error.error.Message : "Recommended Address Error";
            return of(new applicantsActions.RecommendedApplicantAddressFailAction(errorMessage))
          })
        );
    })
  );

  @Effect({ dispatch: false })
  recommendApplicantAddressFail$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.RECOMMENDED_APPLICANT_ADDRESS_FAIL),
    tap(() => {
      return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
    })
  );

  /***** USPS Side Effect *****/

  @Effect()
  saveApplicantPhone$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_PHONE),
    withLatestFrom(this.store$.select(getCurrentApplicantId)),
    concatMap(data => {
      const action = <applicantsActions.SaveApplicantPhoneAction>data[0];
      const currentApplicantId = data[1];

      const phone: PhoneNumber = {
        ...action.phone,
        PhoneTypeId: action.phoneType,
        applicantId: currentApplicantId
      };
      return this.applicantInformationService.updateApplicantPhone(currentApplicantId, phone)
        .pipe(
          map(response => new applicantsActions.SaveApplicantPhoneSuccessAction(response, currentApplicantId, action.shouldNavigate))
        );
    })
  );

  @Effect()
  saveApplicantIdentificationInfo$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_IDENTIFICATION),
    withLatestFrom(this.store$.select(getCurrentApplicantId)),
    switchMap(data => {
      const action = <applicantsActions.SaveApplicantIdentificationInfosAction>data[0];
      const currentApplicantId = data[1];

      return this.applicantInformationService.updateApplicantIdentification(currentApplicantId, action.identification)
        .pipe(
          map(response => new applicantsActions.SaveApplicantIdentificationInfoSuccessAction(response, currentApplicantId, action.shouldNavigate))
        );
    })
  );

  // @Effect()
  // saveApplicantPIUniversal$ = this.actions$.pipe(
  //     ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_PI_UNIVERSAL),
  //     withLatestFrom(this.store$.select(getCurrentApplicantId)),
  //     switchMap(data => {
  //         const action = <applicantsActions.SavePIUniversalAction> data[0];
  //         const currentApplicantId = data[1]
  //         return this.applicantInformationService.saveApplicantPIUniversal(currentApplicantId, action.personalInformationUniversal)
  //             .pipe(
  //                 map(response => new applicantsActions.SavePIUniversalSuccessAction(response, currentApplicantId, action.shouldNavigate))
  //             );
  //     })
  // );

  @Effect()
  getAddressInfoes = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.GET_ADDRESS_INFOES),
    switchMap((action: applicantsActions.GetAddressInfoesAction) => {
      const applicantId = action.applicantId;
      return this.addressService.getAddressInfoes(applicantId).pipe(
        map(res => {
          const addresses: Address[] = res.addresses;
          return new applicantsActions.GetAddressInfoesSuccessAction(addresses, applicantId)
        })
      )
    })
  )

  @Effect()
  saveApplicantPIUniversal = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_PI_UNIVERSAL),
    withLatestFrom(
      this.store$.select(getApplicationId),
      this.store$.select(getCurrentApplicantId)
    ),
    switchMap(data => {
      const action = <applicantsActions.SavePIUniversalAction>data[0];
      const applicationId = data[1];
      const applicantId = data[2];
      const person = action.Person;
      // Need to review this because it is different in the API
      person.citizenship = person.citizenship || 'usresident';
      person.country = person.country;
      person.maritalStatus = person.maritalStatus || 'None';
      person.occupation = person.occupation || OCCUPATION_NAME.None;
      person.dependents = person.dependents || 0;
      person.employment.yearsEmployed = person.employment.yearsEmployed || 0;
      if (person.employment.employmentType === EMPLOYMENT_STATUS_ID.Employed) {
        person.employment.address.addressType = ADDRESS_TYPE_NAME.Work;
      }
      person.affiliations = person.affiliations || [];
      const affiliations = person.affiliations;
      if (affiliations.length > 0) {
        for (const affiliation of affiliations) {
          affiliation.companyTitleName = affiliation.companyTitleName || TITLES_NAME.NONE;
          affiliation.affiliationTypeName = affiliation.affiliationTypeName || AFFILIATION_ID.NONE;
        }
      }
      return this.applicantInformationService.saveApplicantPIUniversal(action.Person, applicationId, applicantId, action.skipHistory).pipe(
        map(() => {
          return new applicantsActions.SavePIUniversalSuccessAction(person, applicantId, action.shouldNavigate, action.skipHistory)
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error ? error.error.Message : "Save Applicant Information Error";
          return of(new applicantsActions.SavePIUniversalFailAction(errorMessage))
        })
      )
    })
  );

  @Effect({ dispatch: false })
  saveApplicantPIUniversalFail$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_PI_UNIVERSAL_FAIL),
    tap(() => {
      return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
    })
  )

  @Effect({ dispatch: false })
  setCurrentapplicant$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SET_CURRENT_APPLICANT_TYPE),
    withLatestFrom(
      this.store$.select(getApplicantsMetadata),
      this.store$.select(getCurrentApplicantType)
    ),
    map(data => {
      const action = <applicantsActions.SetCurrentApplicantTypeAction>data[0];
      const applicantType = action.applicantType;
      const metadata = data[1];
      const currentApplicantType = data[2];
      if (currentApplicantType !== applicantType) {
        // TODO: Add option to dispatch Action only if needed
        const mApplicant = metadata.find(applicant => applicant.type === applicantType);
        this.store$.dispatch(new applicantsActions.LoadApplicantAction(mApplicant.applicantId));
      }
    })
  )

  @Effect()
  saveBasicInformation$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_BASIC_INFORMATION),
    switchMap((action: applicantsActions.SaveBasicInformationAction) => {
      action.person.dependents ? action.person.dependents : action.person.dependents = 0;
      return this.applicantInformationService.saveBasicInformation(action.applicantId, action.person).pipe(
        map(() => new applicantsActions.SaveBasicInformationActionSuccess(action.applicantId, action.person))
      )
    })
  );

  @Effect()
  updateTaxpayerId$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.UPDATE_TAX_PAYER_ID),
    switchMap((action: applicantsActions.UpdateApplicantTaxPayerIdAction) => {
      return this.applicantInformationService.updateTaxpayerId(action.applicantId, action.taxPayerId).pipe(
        map(() => new applicantsActions.UpdateApplicantTaxPayerIdActionSuccess(action.applicantId, action.taxPayerId))
      )
    })
  );

  @Effect()
  updateProxyTaxpayerId$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.UPDATE_PROXY_TAX_PAYER_ID),
    switchMap((action: applicantsActions.UpdateProxyApplicantTaxPayerIdAction) => {
      return this.applicantInformationService.updateProxyTaxpayerId(action.applicationId, action.applicantId, action.taxPayerId).pipe(
        map(() => new applicantsActions.UpdateProxyApplicantTaxPayerIdActionSuccess(action.applicationId, action.applicantId, action.taxPayerId))
      )
    })
  );

  @Effect()
  saveAdditionalOwner$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_ADDITIONAL_OWNER_INFO),
    withLatestFrom(
      this.store$.select(getApplicantByType(APPLICANT_TYPE_NAME.Joint)),
      this.applicationId$,
    ),
    switchMap((data) => {
      const action = <applicantsActions.SaveAdditionalOwnerAction>data[0];
      const applicantId = data[1].applicantId;
      const applicationId = data[2];
      return this.applicantInformationService.saveAdditionalOwner(applicationId, applicantId, action.additionalPerson, action.skipHistory).pipe(
        map(() => {
          return action.updateAdditional ?
            new applicantsActions.UpdateAdditionalOwnerSuccessAction(applicantId, action.additionalPerson) :
            new applicantsActions.SaveAdditionalOwnerSuccessAction(applicantId, action.additionalPerson)
        })
      )
    })
  );

  @Effect()
  getRequestedDocuments$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.GET_REQUESTED_DOCUMENTS),
    withLatestFrom(
      this.store$.select(getCurrentApplicantId),
      this.store$.select(getApplicationId)
    ),
    switchMap((data) => {
      const action = <applicantsActions.GetRequestedDocumentsAction>data[0];
      const applicantId = data[1];
      const applicationId = data[2];
      return this.documentService.getRequestedDocuments(applicationId).pipe(
        map((response: DocumentRequest[]) => new applicantsActions.GetRequestedDocumentsSuccessAction(applicantId, response))
      );
    }),
    catchError((error) => {
      return of(new applicantsActions.GetRequestedDocumentsFailAction());
    })
  );

  @Effect()
  saveIdentificationDetailsInfo$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_IDENTIFICATION_DETAILS),
    withLatestFrom(
      this.store$.select(getCurrentApplicantId),
      this.store$.select(getApplicationId)
    ),
    switchMap((data) => {
      const action = <applicantsActions.SaveIdentificationDetailsAction>data[0];
      const applicantId = data[1];
      const applicationId = data[2];

      return this.identificationUploadService.saveIdentificationDetailsInfo(applicantId, action.identificationDetailsInfo, applicationId, action.skipHistory).pipe(
        map(() => {
          return new applicantsActions.SaveIdentificationDetailsSuccessAction(applicantId, action.identificationDetailsInfo)
        })
      )
    }),
    catchError((error) => {
      return of(new applicantsActions.SaveIdentificationDetailsFailAction())
    })
  )

  @Effect()
  saveIdDetailsPage2$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.SAVE_ID_DETAILS_PAGE_2),
    withLatestFrom(
      this.store$.select(getCurrentApplicantId),
    ),
    switchMap((data) => {
      const action = <applicantsActions.SaveIdDetailsPage2>data[0];
      const applicantId = data[1];

      return this.identificationUploadService.saveIdDetailsPage2(applicantId, action.idDetails, action.skipHistory).pipe(
        map(response => {
          if (response.success) {
            return new applicantsActions.SaveIdDetailsPage2Success(applicantId, action.idDetails);
          }
          return new applicantsActions.SaveIdDetailsPage2Success(applicantId, null);
        }),
        catchError(error => of(new applicantsActions.SaveIdDetailsPage2Fail(error)))
      )
    }),
    catchError((error) => {
      return of(new applicantsActions.SaveIdDetailsPage2Fail(error))
    })
  );

  @Effect()
  getApplicantExisting$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.GET_APPLICANT_EXISTING),
    withLatestFrom(
      this.store$.select(getCurrentApplicantId)
    ),
    switchMap(([ac, applicantId]) => {
      let action: applicantsActions.GetApplicantExisting = ac;
      let _applicantId = action.applicantId ? action.applicantId : applicantId;
      return this.applicantInformationService.getApplicantExisting(_applicantId).pipe(
        map((response) => {
          return new applicantsActions.GetApplicantExistingSuccessAction(_applicantId, response.existingCustomer)
        })
      )
    }),
    catchError((error) => {
      return of(new applicantsActions.GetApplicantExistingFailAction());
    })
  );

  //this catch the load of All Applicants to set the isExisting flag per applicant
  @Effect({ dispatch: false })
  loadAllApplicantsSuccess$ = this.actions$.pipe(
    ofType(applicantsActions.APPLICANTS_ACTION_TYPES.LOAD_ALL_APPLICANTS_SUCCESS),
    withLatestFrom(
      this.store$.select(getCurrentApplicantId)
    ),
    map(([ac, applicantId]) => {
      let action: applicantsActions.LoadAllApplicantsSuccessAction = ac;
      this.store$.dispatch(new applicantsActions.GetApplicantExisting(applicantId));
    })
  )
}
