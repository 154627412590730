import { Action } from '@ngrx/store';
import { IBanner } from 'models';

export enum BANNER_ACTION_TYPES {
  UPDATE_BANNER = '[Banner] Update',
  UPDATE_SUB_BANNER = '[Banner] Update SubBanner',
  UPDATE_PRODUCTS = '[Banner] Update Products',
  UPDATE_PROGRESS = '[Banner] Update Progress',
  UPDATE_PAGE_TITLE = '[Banner] Update Page Title',
  SHOW_PRODUCTS = '[Banner] Show Products',
  SHOW_APPLICANT_TITLE = '[Banner] Show Applicant Title'
}

export class UpdateBannerAction implements Action {
  readonly type = BANNER_ACTION_TYPES.UPDATE_BANNER;
  constructor(public banner: IBanner) { }
}

export class UpdateSubBannerAction implements Action {
  readonly type = BANNER_ACTION_TYPES.UPDATE_SUB_BANNER;
  constructor(public show: boolean, public label?: string, public title?: string) { }
}

export class UpdatePageTitleAction implements Action {
  readonly type = BANNER_ACTION_TYPES.UPDATE_PAGE_TITLE;
  constructor(public title: string) { }
}

export class UpdateProgressAction implements Action {
  readonly type = BANNER_ACTION_TYPES.UPDATE_PROGRESS;
  constructor(public progress: number, public show: boolean = true) { }
}

export class ShowApplicantTitleAction implements Action {
  readonly type = BANNER_ACTION_TYPES.SHOW_APPLICANT_TITLE;
  constructor(public show: boolean) { }
}

export type BannerAction = UpdateBannerAction | UpdateSubBannerAction | UpdateProgressAction | UpdatePageTitleAction | ShowApplicantTitleAction;
