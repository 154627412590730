export enum APPLICATION_STATE {
  AccountType = 1,
  AccountFeature = 2,
  PersonalInformation = 3,
  AddressInformation = 4,
  AdditionalOwner = 5,
  FinancialInformation = 6,
  InvestmentProfile = 7,
  //Beneficiaries = 7,
  //ContingentBeneficiaries = 71,
  JointPersonalInformation = 8,
  AffiliationInformation = 9, //JointAboutInformation
  JointAddressQuestion = 10,
  JointAdressInformation = 11,
  JointFinancialInformation = 12,
  JointInvestmentProfile = 13,
  ReviewAndSubmit = 14,
  PendingAdditionalOwnerSubmit = 15,
  EditInformation = 160,
  InReview = 16,
  Declined = 17,
  Complete = 18,
  PendingDocumentReview = 19,
  DocumentsRequested = 20,
  BoardingError = 21,
  SystemError = 22,
  AccountNumberError = 23,
  OrderError = 24,
  BoardingInProgress = 25,
  DocumentsReceived = 26,
  VerifyYourIdentity = 28,
  PendingCompletionKBA = 16000,
  PendingBankReview = 17000,
  ResponseNeededFromCustomer = 18000,
  KBAStillToBeAnswered = 19000,
  PendingMitekUpload = 20000,
  WaitingForCustomerToLiftFreeze = 21000,
  PendingBusinessOlbRegistration = 23000,

  // InvestmentProfile = 70,
  Beneficiaries = 71,
  ContingentBeneficiaries = 72,

  //Old states to be removed
  products = 100,
  reviewAndSubmit = 400,
  CustomerRequestedCancellation = 500,
  Submitted = 700,
  DecisionComplete = 800,
  Funding = 900,
  Boarding = 1000,
  ReferredToSecondChance = 1200,
  Cancelled = 1300,
  PendingIDA = 1400,
  PendingApplicationReview = 1500,
  PendingContactInfoReview = 1700,
  TaskListPreBoarding = 1800,
  PingsSent = 1900,
  NetbankAcceptedProductSelection = 2000,
  KBAToBeRequested = 8400,
  ReferredFromAnotherBrand = 2700,
  TaskListPostBoarding = 2800,
  NetbankAcceptedDisclosures = 2900,
  PendingHRBApplicationReview = 3000,
  PendingHRBForeignAddress = 3100,
  PendingHRBAccountNumber = 3200,
  MitekUploadPage = 3400,
  PendingApplicationReviewRM = 3600,
  PendingApplicationReviewBSA = 3700,
  PendingApplicationReviewManager = 3800,
  UnassignedNewApplication = 3900,
  // #region DMD
  IdVerificationMethod = 4100,
  IdVerificationOtpValidation = 4200,
  IdVerificationIdTypeSelection = 4300,
  IdVerificationFrontId = 4400,
  IdVerificationBackId = 4500,
  IdVerificationSelfie = 4600,
  IdVerificationReviewAndSubmit = 4700,
  // #endregion
  WaitingforManagerApproval = 4800,
  ApprovedbyManager = 4900,
  RMNewApplications = 5000,
  // #region PIP
  // #region Primary
  PersonalInformationPrimary = 300,
  HomeAddressInfoPrimary = 5100,
  MailingAddressQuestionPrimary = 5200,
  MailingAddressInfoPrimary = 5300,
  DobSSNInfoPrimary = 5400,
  IdTypeSelectionManualPrimary = 5500,
  IdInformationPrimary = 5600,
  IdAddressQuestionPrimary = 5700,
  IdAddressInfoPrimary = 5800,
  IdHomeAddressQuestionPrmary = 5900,
  // #endregion
  // #region Joint
  PersonalInformationJoint = 6000,
  ContactInformationJoint = 6100,
  HomeAddressQuestionJoint = 6200,
  HomeAddressInfoJoint = 6300,
  MailingAddressQuestionJoint = 6400,
  MailingAddressInfoJoint = 6500,
  DobSSNInfoJoint = 6600,
  IdTypeSelectionManualJoint = 6700,
  IdInfoJoint = 6800,
  IdAddressQuestionJoint = 6900,
  IdAddressInfoJoint = 7000,
  IdHomeAddressQuestion = 7100,
  // #endregion
  // #region Minor
  PersonalInformationMinor = 7200,
  HomeAddressQuestionMinor = 7300,
  HomeAddressInfoMinor = 7400,
  DobSSNInfoMinor = 7500,
  // #endregion
  // #region Teen
  PersonalInformationTeen = 7600,
  ContactInformationTeen = 7700,
  HomeAddressQuestionTeen = 7800,
  HomeAddressInfoTeen = 7900,
  MailingAddressQuestionTeen = 8000,
  MailingAddressInfoTeen = 8100,
  DobSSNInfoTeen = 8200,
  // #endregion
  // #region Trust
  TrustInfo = 8300,
  // #endregion
  // #region Primary Trading
  //AccountFeatures = 8500,
  //PersonalInformationTradingPrimary = 8600,
  //HomeMailingAddress = 8700
}
// export enum APPLICATION_STATE {
//   AccountType = 1,
//   AccountFeature = 2,
//   PersonalInformation = 3,
//   AddressInformation = 4,
//   FinancialInformation = 5,
//   InvestmentProfile = 6,
//   JointPersonalInformation = 7,
//   AffiliationInformation = 8,
//   JointAddressQuestion = 9,
//   JointAdressInformation = 10,
//   TrustInformation = 11,
//   VerifyYourIdentity = 12,
//   DmdOTPValidation = 31,
//   DmdIDTypeSelection = 14,
//   DmdSelfie = 15,
//   DmdReviewAndSubmitPhotos = 16,
//   ReviewAndSubmit = 17,
//   Complete = 18,
//   BoardingError,
//   SystemError,
//   DocumentsRequested,
//   products = 100,
//   disclosures = 200,
//   reviewAndSubmit = 400,
//   CustomerRequestedCancellation = 500,
//   Submitted = 700,
//   DecisionComplete = 800,
//   Funding = 900,
//   Boarding = 1000,
//   ReferredToSecondChance = 1200,
//   Cancelled = 1300,
//   PendingIDA = 1400,
//   PendingApplicationReview = 1500,
//   PendingDocumentReview = 1600,
//   PendingContactInfoReview = 1700,
//   TaskListPreBoarding = 1800,
//   PingsSent = 1900,
//   NetbankAcceptedProductSelection = 2000,
//   AccountNumberError = 2300,
//   ResponseNeededFromCustomer = 2400,
//   PendingCompletionKBA = 2500,
//   KBAStillToBeAnswered = 2600,
//   KBAToBeRequested = 8400,
//   ReferredFromAnotherBrand = 2700,
//   TaskListPostBoarding = 2800,
//   NetbankAcceptedDisclosures = 2900,
//   PendingHRBApplicationReview = 3000,
//   PendingHRBForeignAddress = 3100,
//   PendingHRBAccountNumber = 3200,
//   PendingMitekUpload = 3300,
//   MitekUploadPage = 3400,
//   WaitingForCustomerToLiftFreeze = 3500,
//   PendingApplicationReviewRM = 3600,
//   PendingApplicationReviewBSA = 3700,
//   PendingApplicationReviewManager = 3800,
//   UnassignedNewApplication = 3900,
//   PendingBusinessOlbRegistration = 4000,
//   // #region DMD
//   IdVerificationMethod = 4100,
//   IdVerificationOtpValidation = 4200,
//   IdVerificationIdTypeSelection = 4300,
//   IdVerificationFrontId = 4400,
//   IdVerificationBackId = 4500,
//   IdVerificationSelfie = 4600,
//   IdVerificationReviewAndSubmit = 4700,
//   // #endregion
//   WaitingforManagerApproval = 4800,
//   ApprovedbyManager = 4900,
//   RMNewApplications = 5000,
//   // #region PIP
//   // #region Primary
//   PersonalInformationPrimary = 300,
//   HomeAddressInfoPrimary = 5100,
//   MailingAddressQuestionPrimary = 5200,
//   MailingAddressInfoPrimary = 5300,
//   DobSSNInfoPrimary = 5400,
//   IdTypeSelectionManualPrimary = 5500,
//   IdInformationPrimary = 5600,
//   IdAddressQuestionPrimary = 5700,
//   IdAddressInfoPrimary = 5800,
//   IdHomeAddressQuestionPrmary = 5900,
//   // #endregion
//   // #region Joint
//   PersonalInformationJoint = 6000,
//   ContactInformationJoint = 6100,
//   HomeAddressQuestionJoint = 6200,
//   HomeAddressInfoJoint = 6300,
//   MailingAddressQuestionJoint = 6400,
//   MailingAddressInfoJoint = 6500,
//   DobSSNInfoJoint = 6600,
//   IdTypeSelectionManualJoint = 6700,
//   IdInfoJoint = 6800,
//   IdAddressQuestionJoint = 6900,
//   IdAddressInfoJoint = 7000,
//   IdHomeAddressQuestion = 7100,
//   // #endregion
//   // #region Minor
//   PersonalInformationMinor = 7200,
//   HomeAddressQuestionMinor = 7300,
//   HomeAddressInfoMinor = 7400,
//   DobSSNInfoMinor = 7500,
//   // #endregion
//   // #region Teen
//   PersonalInformationTeen = 7600,
//   ContactInformationTeen = 7700,
//   HomeAddressQuestionTeen = 7800,
//   HomeAddressInfoTeen = 7900,
//   MailingAddressQuestionTeen = 8000,
//   MailingAddressInfoTeen = 8100,
//   DobSSNInfoTeen = 8200,
//   // #endregion
//   // #region Trust
//   TrustInfo = 8300,
//   // #endregion
//   // #region Primary Trading
//   //AccountFeatures = 8500,
//   //PersonalInformationTradingPrimary = 8600,
//   //HomeMailingAddress = 8700
// }
