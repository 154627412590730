import { Injectable } from '@angular/core';
import { BaseService } from '../abstracts';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdentityVerificationService extends BaseService {
  skipVerification(applicantId: number, desicion: boolean) {
    // return this._post(`skipVerification`, { 'ApplicantId': applicantId, 'desicion': desicion});
    return of(desicion);
  }
}
