export enum APPLICANT_TYPE {
    Primary = 1,
    Joint = 2,
    Trust = 3,
    Minor = 4,
    Teen = 5,
    Business = 6
}

export enum APPLICANT_TYPE_NAME {
    Primary = 'primary',
    Joint = 'secondary',
    Trust = 'trust',
    Minor = 'minor',
    Teen = 'teen',
    Business = 'business'
}

export const APPLICANT_TYPE_TO_NAME = {
    [APPLICANT_TYPE.Primary]: APPLICANT_TYPE_NAME.Primary,
    [APPLICANT_TYPE.Joint]: APPLICANT_TYPE_NAME.Joint,
    [APPLICANT_TYPE.Minor]: APPLICANT_TYPE_NAME.Minor,
    [APPLICANT_TYPE.Trust]: APPLICANT_TYPE_NAME.Trust,
    [APPLICANT_TYPE.Teen]: APPLICANT_TYPE_NAME.Teen,
    [APPLICANT_TYPE.Business]: APPLICANT_TYPE_NAME.Business
}

export const APPLICANT_TYPE_TO_ID = {
    [APPLICANT_TYPE_NAME.Primary]: APPLICANT_TYPE.Primary,
    [APPLICANT_TYPE_NAME.Joint]: APPLICANT_TYPE.Joint,
    [APPLICANT_TYPE_NAME.Minor]: APPLICANT_TYPE.Minor,
    [APPLICANT_TYPE_NAME.Trust]: APPLICANT_TYPE.Trust,
    [APPLICANT_TYPE_NAME.Teen]: APPLICANT_TYPE.Teen,
    [APPLICANT_TYPE_NAME.Business]: APPLICANT_TYPE.Business
}

