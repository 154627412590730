// Ng
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// RxJs
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// UI Kit
import { ModalEvents, UkModalService } from '@uikit/components/uk-modal';
import { AUTH_PARENT_ROUTE, AUTH_ROUTES } from 'app/auth';
import { addMinutes } from 'date-fns';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState, RefreshTokenAction } from 'store';

@Component({
  selector: 'axos-session-time-out',
  templateUrl: './session-time-out.component.html'
})
export class SessionTimeOutComponent implements OnInit {

  expirationTime = 30;
  protected timeoutStart$ = new Subject<boolean>();

  constructor(
    private modalService: UkModalService,
    private router: Router,
    private store$: Store<EnrollmentState>
  ) { }

  ngOnInit() {
    const timer = interval(1000).pipe(
      takeUntil(this.timeoutStart$)
    )
      .subscribe(() => {
        if (this.expirationTime == 0) {
          timer.unsubscribe();
          this.stopTimeOut();
          this.modalService.closeModal(ModalEvents.close);
          this.no();
        }
        else {
          this.expirationTime--;
        }
      });
  }

  yes() {
    this.stopTimeOut();
    this.store$.dispatch(new RefreshTokenAction(''));
  }

  no() {
    this.router.navigateByUrl(`${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.SESSION_TIME_OUT}`)
  }

  private stopTimeOut() {
    this.timeoutStart$.next();
    this.timeoutStart$.complete();
  }
}
