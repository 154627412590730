import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'axos-hint',
  host: {
    class: 'uk-hint',
}
})
export class HintDirective {
  @Input() align: 'left' | 'right';
  @HostBinding('attr.id') @Input() id: string;
  @HostBinding('class.right') isAlignedRight: boolean;
  uniqueId = 0;

  constructor() {
    this.id = `axos-hint-${this.uniqueId++}`;
    this.isAlignedRight = this.align === 'right';
   }
}
