declare var environment: any;
// Ng
import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Core
import { OBJECT_TO_ARRAY } from 'app/core/utils';
// Models
import { IdentificationInfo } from 'models';
import { 
  BRAND_CONTEXT, 
  IDENTIFICATION_TYPE_ID, 
  IDENTIFICATION_ADDRESS_TYPE_NAME, 
  IDENTIFICATION_TYPE 
} from 'models/enums';

@Component({
  selector: 'app-id-selection',
  templateUrl: './id-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdSelectionComponent {
  @Input() information: IdentificationInfo;
  @Input() form: FormGroup;
  @Input() showButtons: boolean = true;

  @Output() typeSelected: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();

  idetificationType = OBJECT_TO_ARRAY(IDENTIFICATION_TYPE);

  get visibleIdentificationTypes(){
    return this.idetificationType.filter(idType=> idType.value.context === environment.brandContext || idType.value.context === BRAND_CONTEXT.All);
  }

  onContinue(IDType: { key: IDENTIFICATION_TYPE_ID, name: IDENTIFICATION_ADDRESS_TYPE_NAME }) {
    this.typeSelected.emit(this.getValue(IDType));
  }

  goBack() { this.back.emit(); }

  private getValue(IDType: { key: IDENTIFICATION_TYPE_ID, name: IDENTIFICATION_ADDRESS_TYPE_NAME }) {
    return {
        ...this.information, 
        IdentificationTypeId: IDType.key, 
        IdentificationTypeName: IDType.name
    };
  }
}
