import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { WhiteLabelService } from "services/wl/white-label.service";
import { EnrollmentState } from "store";
import * as whiteLabelActions from '../actions/white-label.actions'
import * as applicationActions from '../actions/application.action';
import * as proxyActions from '../actions/proxy.actions';
import { getApplicationId } from "store/selectors";

@Injectable()
export class WhiteLabelEffects {

    constructor(
        private actions$: Actions,
        private whiteLabelService: WhiteLabelService,
        private store$: Store<EnrollmentState>,
    ){}

    getOptionLevels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(whiteLabelActions.getOptionLevels),
            switchMap(() => {
                return this.whiteLabelService.getOptionLevels().pipe(
                    map(response => whiteLabelActions.getOptionLevelsSuccess({ optionLevels: response })),
                    catchError(error => of(whiteLabelActions.getOptionLevelsFail({ error: error})))
                )
            })
        )
    )

    getOptionLevelsFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(whiteLabelActions.getOptionLevelsFail),
            tap(() => {
                return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
            }),
        ),
        { dispatch: false }
    );

    setOptionLevel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(whiteLabelActions.setOptionLevel),
            withLatestFrom(this.store$.select(getApplicationId)),
            switchMap(data => {
                return this.whiteLabelService.setOptionLevel(data[0].optionLevel, data[1]).pipe(
                    map(response => {
                        if(response){
                            return whiteLabelActions.setOptionLevelSuccess({ optionLevel: data[0].optionLevel })
                        }
                        else{
                            return whiteLabelActions.setOptionLevelSuccess({ optionLevel: data[0].optionLevel })
                        }
                    }),
                    catchError(error => of(whiteLabelActions.setOptionLevelFail({ error: error})))
                )
            })
        )
    )

    setOptionLevelFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(whiteLabelActions.setOptionLevelFail),
            tap(() => {
                return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
            }),
        ),
        { dispatch: false }
    );

    getSelectedOptionLevel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(whiteLabelActions.getSelectedOptionLevel),
            withLatestFrom(this.store$.select(getApplicationId)),
            switchMap(data => {
                return this.whiteLabelService.getSelectedOptionLevel(data[1]).pipe(
                    map(response => {
                        if (response) {
                            return whiteLabelActions.getSelectedOptionLevelSucces({ optionLevel: response.requestedLevel})
                        }
                        else {
                            return whiteLabelActions.getSelectedOptionLevelFail({ error: 'Error' })
                        }
                    }),
                    catchError(error => of(whiteLabelActions.getSelectedOptionLevelFail({ error: error })))
                )
            })
        )
    );

    setTrustedContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(whiteLabelActions.setTrustedContact),
            switchMap(data => {
                return this.whiteLabelService.setTrustedContact(data.applicantId, data.trustedContact, data.skipHistory).pipe(
                    map(response => {
                        if(response) {
                            const trustedContact = response.data ? response.data[0] : null;
                            return whiteLabelActions.setTrustedContactSuccess({trustedContact: trustedContact});
                        }
                    }),
                    catchError(error => of(whiteLabelActions.setTrustedContactFail({ error: error})))
                )
            })
        )
    )

    setTrustedContactFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(whiteLabelActions.setTrustedContactFail),
            tap(() => {
                return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
            }),
        ),
        { dispatch: false }
    );

    getTrustedContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(whiteLabelActions.getTrustedContact),
            switchMap(data =>{
                return this.whiteLabelService.getTrustedContact(data.applicantId).pipe(
                    map(response => {
                        if(response && response.length){
                            const trustedContact = response? response[0]:null;
                            return whiteLabelActions.setTrustedContactSuccess({trustedContact: trustedContact});
                        }   
                        return whiteLabelActions.setTrustedContactSuccess({trustedContact: null});
                    }),
                    catchError(error => of(whiteLabelActions.setTrustedContactFail({ error: error})))
                )
            })
        )
    );

    setDeclinedTrustedSelection$ = createEffect(() => 
        this.actions$.pipe(
            ofType(whiteLabelActions.setDeclinedTrustedSelection),
            switchMap(data => {
                return this.whiteLabelService.setDeclinedTrustedSelection(data.applicantId, data.declinedTrusted).pipe(
                    map(response => {
                        let applicantId = data.applicantId;
                        if(data.isProxy){
                            return proxyActions.saveContact({applicantId: applicantId, contact: null, skipHistory: false});
                        }
                        return whiteLabelActions.setTrustedContact({applicantId: applicantId, trustedContact: data.trustedContact, skipHistory: false});
                    }),
                    //catchError(error => of(whiteLabelActions))
                )
            })
        )
    );
}