export enum DECISION_NAME {
    YES = 'Yes',
    NO = 'No',
}

export const DECISION = {
    YES: {
        key: DECISION_NAME.YES,
        name: DECISION_NAME.YES,
    },
    NO: {
        key: DECISION_NAME.NO,
        name: DECISION_NAME.NO
    }
}