import { GET_CACHE, SET_CACHE } from 'app/core/utils';
import {
    SELECTED_PRODUCTS_STORAGE_NAME,
    SELECTED_VESTING_TYPE_STORAGE_NAME,
    ADVISOR_ID_STORAGE_NAME
} from 'app/products/constants';

import { ProductsState } from '../states';
import * as fromActions from '../actions/products.action';

export const initialState: ProductsState = {
    marketingProductsValidated: false,
    marketingSelectedProducts: GET_CACHE(SELECTED_PRODUCTS_STORAGE_NAME) || [],
    marketingSelectedVestingType: GET_CACHE(SELECTED_VESTING_TYPE_STORAGE_NAME),
    marketingAdvisorId: GET_CACHE(ADVISOR_ID_STORAGE_NAME),
    selectedProducts: [],
    vestingTypes: [],
    productsSettings: {
        directDeposit: false,
        isVestingEnabled: false
    },
    proxyProducts: [],
    proxySelectedProducts: []
}

export function reducer(
    state = initialState,
    action: fromActions.ProductsAction
): ProductsState {
    switch(action.type) {
        case fromActions.PRODUCTS_ACTION_TYPES.GET_VESTINGS_SUCCESS: {
            return { ...state, vestingTypes: action.vestings };
        }
        case fromActions.PRODUCTS_ACTION_TYPES.GET_SUCCESS: {
            return {
                ...state,
                selectedProducts: action.products
            };
        }
        case fromActions.PRODUCTS_ACTION_TYPES.SET_PRODUCT_SELECTION: {
            SET_CACHE(action.vestingType, SELECTED_VESTING_TYPE_STORAGE_NAME);
            SET_CACHE(action.advisorId, ADVISOR_ID_STORAGE_NAME);
            return {
                ...state,
                marketingSelectedVestingType: action.vestingType,
                marketingAdvisorId: action.advisorId
            };
        }
        case fromActions.PRODUCTS_ACTION_TYPES.SET_PRODUCT_SELECTION_FAIL:
        case fromActions.PRODUCTS_ACTION_TYPES.SET_PRODUCT_SELECTION_SUCCESS: {
            SET_CACHE(action.products, SELECTED_PRODUCTS_STORAGE_NAME);
            return {
                ...state,
                marketingSelectedProducts: action.products,
                marketingProductsValidated: true
            };
        }
        case fromActions.PRODUCTS_ACTION_TYPES.UPDATE_PRODUCT_SELECTION_SUCCESS: {
            SET_CACHE(action.products, SELECTED_PRODUCTS_STORAGE_NAME);
            return{
                ...state,
                marketingSelectedProducts: action.products,
                marketingProductsValidated: true
            }
        }
        case fromActions.PRODUCTS_ACTION_TYPES.SET_IRA_FLAG: {
            return {
                ...state,
                IRAFlag: action.isIRA
            }
        }
        case fromActions.PRODUCTS_ACTION_TYPES.SET_PROXY_PRODUCT_SUCCESS: {
            return {
                ...state,
                proxySelectedProducts: action.products
            }
        }
        case fromActions.PRODUCTS_ACTION_TYPES.GET_PROXY_PRODUCTS_SUCCESS: {
            return {
                ...state,
                proxyProducts: action.products
            }
        }
        case fromActions.PRODUCTS_ACTION_TYPES.GET_PRODUCTS_BY_ORGANIZATION_SUCCESS: {
            return {
                ...state,
                organizationProducts: action.products
            }
        }
        case fromActions.PRODUCTS_ACTION_TYPES.CLEAR_STATE: {
            return {
                ...initialState
            }
        }
        /*
        case fromActions.APPLICATION_ACTION_TYPES.SET_VESTING_TYPE_SUCCESS: {
            const VestingType = { Id: action.vestingType, Name: '' };
            return {
                ...state,
                application: { ...state.application, VestingType: VestingType }
            };
        }
        */
    }
    return state;
}
