// Environment
declare var environment: any;
// Ng
import { Injectable } from "@angular/core";
// Vendor
import { addSeconds } from "date-fns";
import { IUser, IUserLead, IAuthToken, IOtpChallengeInfo, ISignInData, IValidateOtpData, IChallengeOtpData, IOtpNoUser, IValidateOtpNoRequest, IValidateOtpRequest, IUserLeadResponse } from "models";
import { Observable } from "rxjs/internal/Observable";
// RxJs
import { map, tap } from "rxjs/operators";
// Services
import { BaseService } from "services/abstracts";
import { AxosResult } from 'models/enums/trading/axos-result.model';


@Injectable()
export class AuthService extends BaseService {

  //#region Ajax Calls
  getSSOErrorMessage() {
    return this._get<string>("authentication/sso-error");
  }

  validateExistingCustomer() {
    return this._get<AxosResult<string>>(
      "authentication/existingCustomer",
      undefined,
      false
    );
  }

  signIn(data: ISignInData) {
    return this._post<AxosResult<IAuthToken>>(data.IsExisting ? 'Authentication/SignIn?isExisting=true' : "Authentication/SignIn", data)
      .pipe(map(token => {
        return this.setExpiresDate(token.data)
      })
      );
  }

  refreshToken(RefreshToken: string, sso = false) {
    return this._post<AxosResult<IAuthToken>>(
      "authentication/refreshToken?sso=" + sso,
      { RefreshToken },
      false
    ).pipe(map(token => this.setExpiresDate(token.data)));
  }

  refreshTokenByUrl(ssoToken: string) {
    return this._post<AxosResult<IAuthToken>>(
      `authentication/refreshToken?token=${ssoToken}&sso=true`,
      {},
      false
    ).pipe(map(token => this.setExpiresDate(token.data)));
  }

  proxyRefreshToken() {
    return this._post<AxosResult<IAuthToken>>(
      "auth/refreshtoken",
      null,
      false
    ).pipe(map(token => this.setExpiresDate(token.data)));
  }

  getUser() {
    return this._get<AxosResult<IUser>>("User")
      .pipe(map(response => response.data));
  }


  private setExpiresDate(token: IAuthToken) {
    const maximumExpirationInSeconds = 1800;
    token.expires_in =
      token.expires_in < maximumExpirationInSeconds
        ? token.expires_in
        : maximumExpirationInSeconds;
    if (!token.expires_date) {
      token.expires_date = addSeconds(new Date(), token.expires_in || 1799);
    }
    return token;
  }

  requestMultifactorOtp(request: IChallengeOtpData): Observable<IOtpChallengeInfo> {
    return this._post<AxosResult<IOtpChallengeInfo>>(`Authentication/GetOtp?organizationName=${environment.organizationName}`, request)
      .pipe(map(response => response.data));
  }

  evaluateOtpCode(request: IValidateOtpData) {
    return this._post<AxosResult<any>>("Authentication/ValidateOtp", request)
      .pipe(map(response => response.data));
  }

  evaluateNoUserOtp(request: IValidateOtpNoRequest){
    return this._post<AxosResult<any>>(`Authentication/ValidateOtpNoUser`, request)
    .pipe(map(response => response.data))
  }

  getSecurityQuestions(resetPasswordToken) {
    const route = resetPasswordToken
      ? `Authentication/security/questions/${resetPasswordToken}`
      : "Authentication/security/questions";
    return this._get<AxosResult<any>>(route)
      .pipe(map(response => response.data));
  }

  saveSecurityQuestions(questions, resetPasswordToken) {
    const route = resetPasswordToken
      ? `Authentication/security/questions/${resetPasswordToken}`
      : "Authentication/security/questions";
    return this._post<AxosResult<any>>(route, questions)
      .pipe(map(response => response.data));
  }

  resetExistingCustomerTaskStates() {
    return this._post("TaskList/ResetTaskStates");
  }

  createExistingCustomer(brandId, productVesting) {
    return this._post(
      `v2/Customer/Existing/Add/${brandId}`,
      productVesting
    );
  }
  getRandomSecurityQuestion(token) {
    return this._get<AxosResult<any>>(`Authentication/security/questions/random/${token}`)
      .pipe(map(response => response.data));
  }

  verifySecurityQuestionAnswer(username, questions) {
    username = encodeURIComponent(username);
    return this._post<any>(
      `Authentication/security/questions/answer?username=${username}`,
      questions)
      .pipe(map(response => response.data))
  }

  validateResetPasswordLink(idToken, usernameToken?) {
    let route = `User/ValidateResetPasswordLink/${idToken}`;
    if (usernameToken)
      route = `${route}/${usernameToken}`;
    return this._get<AxosResult<any>>(route)
      .pipe(map(resp => resp.data));
  }

  changePassword(userAccount, idToken, usernameToken?) {
    let route = `User/ChangePassword/${idToken}`;
    if (usernameToken)
      route = `${route}/${usernameToken}`;
    route = `${route}?organizationName=${environment.organizationName}`;
    return this._post<AxosResult<any>>(route, userAccount)
      .pipe(map(response => response.data));
  }

  checkGuidvalid(guid) {
    return this._get(`User/Verify/${guid}`);
  }

  // verifyExistingCustomer(taxPayerId: string) {
  //   return this._get<AxosResult<boolean>>(`user/verifyExistingCustomer?taxPayerId=${taxPayerId}`)
  //     .pipe(map(response => response.data));
  // }

  verifyExistingCustomer(lead: IUserLead, guid?: string) {
    let route = `generic/user/verifyExistingCustomer`;
    if (guid)
      route = `${route}?token=${guid}`;
    return this._post<AxosResult<boolean>>(route, lead)
      .pipe(map(response => response.data));
  }

  verifyLoginCreated(lead: IUserLead, guid?: string) {
    let route = `generic/user/verifyLoginCreated`;
    if (guid)
      route = `${route}?token=${guid}`;
    return this._post<AxosResult<boolean>>(route, lead)
      .pipe(map(response => response.data));
  }

  registerLead(leadUser: IUserLead) {
    return this._post('generic/Lead', leadUser).pipe(
      map(({ data }) => data)
    );
  }

  updateLead(leadUser: IUserLead) {
    return this._post('generic/Lead/updateLead', leadUser).pipe(
      map(({ data }) => data)
    );
  }

  otpNoUser(otpNoUser: IOtpNoUser) {
    return this._post(`Authentication/getOtpNoUser`, otpNoUser).pipe(
      map((data) => data)
    )
  }

}
