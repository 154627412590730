import { Component, Input } from '@angular/core';

import { Address } from 'models';
import { EnrollmentNavigationComponent } from '../abstracts';

@Component({
  selector: 'axos-address-selection-universal',
  templateUrl: './address-selection-universal.component.html',
  styleUrls: ['./address-selection-universal.component.scss']
})
export class AddressSelectionUniversalComponent extends EnrollmentNavigationComponent {
  @Input() address: Address;
  @Input() mailingAddress: Address;

  constructor() { super(); }

  onBack(){
    this.onNavigationChanged.emit({ goBack:true });
  }

  onContinue(value: string) { this.onNavigationChanged.emit({ goBack: false, value }); }

  get showMailing(): boolean {
    return !(this.address.street == this.mailingAddress.street &&
    this.address.city == this.mailingAddress.city &&
    this.address.state == this.mailingAddress.state &&
    this.address.zip == this.mailingAddress.zip &&
    this.address.country == this.mailingAddress.country);
  }

}
