import { Injectable } from '@angular/core';
// RxJs
import { switchMap, map, catchError } from 'rxjs/operators';
// NgRx Effects
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
// Services
import { OrganizationService } from 'services';
// Models
import { Broker, Organization } from 'models';
// Actions
import * as organizationActions from '../actions/organization.action';
import * as brokerActions from '../actions/broker.actions';
import { of } from 'rxjs';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService) { }

  @Effect()
  get$ = this.actions$.pipe(
    ofType(organizationActions.ORGANIZATION_ACTION_TYPES.GET_ORGANIZATION),
    switchMap((action: organizationActions.GetOrganizationAction) => {
      return this.organizationService.get(action.organizationName, action.settings)
        .pipe(
          map((response: Organization) => new organizationActions.GetOrganizationSuccessAction(response)),
          catchError(err => of(new organizationActions.GetOrganizationFailAction(err)))
        );
    })
  );

  getBrokerInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(brokerActions.getBrokerInformation),
            switchMap((data) => {
                return this.organizationService.getBrokerInformation(data.guid).pipe(
                    map((response: Broker) => brokerActions.getBrokerInformationSuccess({ broker: response })),
                    catchError(() => of(brokerActions.getBrokerInformationFail()))
                )
            })
        )
    );
}
