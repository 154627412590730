// This method retrive object with key and value
export function OBJECT_TO_ARRAY(object: any) {
    return Object.keys(object).map(key => {
        return { key, value: object[key] }
    })
}

// This method only retrive values
export function OBJECT_TO_SINGLE_ARRAY(object: any) {
    return Object.keys(object).map(key => {
        return object[key]
    })
}

// This method only retrive values
export function OBJECT_TO_ARRAY_TYPE<T>(object: any): T[] {
    return Object.keys(object).map(key => {
        return object[key];
    });
}

export function GET_VALUES_FROM_CATALOG(object: any): any {
    return Object.entries(object).reduce((acc, item: [string, any]) => {
        acc[item[0]] = item[1].name === 'none' ? '' : item[1].name ;
        return acc;
    }, {});
}