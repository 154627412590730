import { FormGroup, FormControl } from '@angular/forms';

import { ADDRESS_DEFAULT_VALIDATORS, INVEST_ADDRESS_DEFAULT_VALIDATORS } from './address.validators';

import { ZipService } from 'services';
import { ZipCodeAsyncValidator } from '../validators';

export function ADDRESS_FORM_CREATOR(): { [fieldName: string]: FormControl } {
  return GetAddressFormControl(ADDRESS_DEFAULT_VALIDATORS)
}

export function INVEST_CUSTOM_ADDRESS_FORM_CREATOR(): { [fieldName: string]: FormControl } {
  return GetAddressFormControl(INVEST_ADDRESS_DEFAULT_VALIDATORS)
}

function GetAddressFormControl(addressDefaultValidators): { [fieldName: string]: FormControl } {
  return {
    street: new FormControl('', addressDefaultValidators.Street),
    street2: new FormControl('', addressDefaultValidators.Street2),
    zip: new FormControl('', {
      validators: addressDefaultValidators.Zip,
      updateOn: 'blur'
    }),
    city: new FormControl('', addressDefaultValidators.City),
    state: new FormControl('', addressDefaultValidators.State),
    isSameMailingAddress: new FormControl(true)
  }   
}

export function setZipCodeValidator(form: FormGroup, zipCodeService: ZipService, names = ['zip', 'city', 'state']) {
  console.log(form.value)
  form.get(names[0]).setAsyncValidators(
    ZipCodeAsyncValidator(
      zipCodeService,
      form.get(names[1]),
      form.get(names[2])
    ));
}
