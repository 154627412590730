// Ng
import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot } from "@angular/router";
// RxJs
import { map } from 'rxjs/operators';
// NgRx Store
import { Store } from "@ngrx/store";
import { EnrollmentState } from "store";
import { CorrectApplicationRouteAction } from 'store/actions';
// Resolvers
import { ApplicationResolver } from "../resolvers";

@Injectable()
export class CorrectStateGuard implements CanActivate {

  constructor(
    private store$: Store<EnrollmentState>,
    private applicationResolver: ApplicationResolver) { }

  canActivate(route: ActivatedRouteSnapshot){
    return this.applicationResolver.resolve().pipe(
      map(application => {
        const currentAppState = application.stateId,
          desiredStates = route.data.ValidApplicationStates.stateId;
        let _activate = false;
        if(Array.isArray(desiredStates)) {
          _activate = desiredStates.map(state => currentAppState === state).reduce((p, c) => p || c);
        } else {
          _activate = currentAppState === desiredStates;
        }
        if(!_activate) { this.store$.dispatch(new CorrectApplicationRouteAction()) }
        return _activate;
      })
    )
  }
}
