import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { PdfService } from "services";
import * as pdfActions from '../actions/pdf.actions';

@Injectable()
export class PdfEffects{
    constructor(
        private action$: Actions,
        private pdfService: PdfService
    ){}

    createPdf$ = createEffect(() =>
        this.action$.pipe(
            ofType(pdfActions.createPDF),
            tap(action => {
                this.pdfService.createPDF(action.data);
            })
        ),
        { dispatch: false }
    );
}