import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { withLatestFrom, switchMap, filter, first, map } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { LoadApplicationAction, LoadApplicationByTokenAction } from 'store/actions';
import { getApplicationLoaded, getApplicationLoading, getApplication, getApplicationId } from 'store/selectors';

import { IApplication } from 'models';
import { UserResolver } from './user.resolver';
declare var environment: any;

@Injectable()
export class ApplicationResolver implements Resolve<Observable<IApplication>> {

  constructor(private store$: Store<EnrollmentState>) { }

  resolve() {
      return this.store$.select(getApplicationLoaded).pipe(
      withLatestFrom(
        this.store$.select(getApplicationLoading),
        this.store$.select(getApplication),
        this.store$.select(getApplicationId)
      ),
      filter(data => {
        const application: IApplication = data[2];
        const loaded = data[0], loading = data[1];
        if (application.applicationId == null && !!environment.isProxy)
          application.applicationId = data[3];
        
        if(!loaded && !loading) {
          application.applicationId ?
          this.store$.dispatch(new LoadApplicationAction(application.applicationId)) :
          this.store$.dispatch(new LoadApplicationByTokenAction());
        }
        return loaded;
      }),
      map(data => data[2]),
      first()
    )
  }
}
