export enum CITIZENSHIP_TYPE_ID {
    USCitizen = 1,
    ResdidentAlien = 2
}

export enum CITIZENSHIP_NAME {
    USCitizen ="U.S. Citizen",
    ResdidentAlien = "Resident Alien"
}

export const CITIZENSHIP_TYPE = {
    USCITIZEN: {
        id: CITIZENSHIP_TYPE_ID.USCitizen,
        name: CITIZENSHIP_NAME.USCitizen,                
    },
    RESIDENTALIEN: {
        id: CITIZENSHIP_TYPE_ID.ResdidentAlien,
        name: CITIZENSHIP_NAME.ResdidentAlien,                
    }    
}