export enum AFFILIATION_ID {
     DEALER = 'DEALER',
     SRO = 'SRO',
     ADVISOR = 'ADVISOR',
     REGULATOR = 'REGULATOR',
     NONE = 'NONE'
}

export enum AFFILIATION_TYPE_NAME {
     FINRA = 'FINRA',
     MEMBER = 'MEMBER',
     POLITICALFIGURE = 'POLITICALFIGURE'
}

export enum AFFILIATION_NAME {
     DEALER = 'FINRA or MSRB Member',
     SRO = 'FINRA or SRO Employee',
     ADVISOR = 'Investment Advisor',
     REGULATOR = 'State or Federal Regulator',
     NONE = 'None'
}

export enum TITLES_ID {
     CEO = 'ceo',
     CFO = 'cfo',
     COO = 'coo',
     SHAREHOLDER = 'shareholder',
     OTHER = 'other',
     NONE = 'none'
}

export enum TITLES_NAME {
     CEO = 'CEO',
     CFO = 'CFO',
     COO = 'COO',
     SHAREHOLDER = 'Shareholder',
     OTHER = 'Other',
     NONE = 'None'
}

export const AFFILIATION_TO_ID = {
     [AFFILIATION_NAME.DEALER]: AFFILIATION_ID.DEALER,
     [AFFILIATION_NAME.SRO]: AFFILIATION_ID.SRO,
     [AFFILIATION_NAME.REGULATOR]: AFFILIATION_ID.REGULATOR,
     [AFFILIATION_NAME.NONE]: AFFILIATION_ID.NONE,
}

export const TITLES_TO_ID = {
     [TITLES_NAME.CEO]: TITLES_ID.CEO,
     [TITLES_NAME.CFO]: TITLES_ID.CFO,
     [TITLES_NAME.COO]: TITLES_ID.COO,
     [TITLES_NAME.SHAREHOLDER]: TITLES_ID.SHAREHOLDER,
     [TITLES_NAME.OTHER]: TITLES_ID.OTHER,
     [TITLES_NAME.NONE]: TITLES_ID.NONE
}

export const AFFILIATIONS = [
     {Code: AFFILIATION_ID.DEALER, Name: AFFILIATION_NAME.DEALER},
     {Code: AFFILIATION_ID.SRO, Name: AFFILIATION_NAME.SRO},
     {Code: AFFILIATION_ID.ADVISOR, Name: AFFILIATION_NAME.ADVISOR},
     {Code: AFFILIATION_ID.REGULATOR, Name: AFFILIATION_NAME.REGULATOR},
     {Code: AFFILIATION_ID.NONE, Name: AFFILIATION_NAME.NONE}
]

export const TITLES = [
     { Code: TITLES_ID.CEO, Name: TITLES_NAME.CEO },
     { Code: TITLES_ID.CFO, Name: TITLES_NAME.CFO },
     { Code: TITLES_ID.COO, Name: TITLES_NAME.COO },
     { Code: TITLES_ID.SHAREHOLDER, Name: TITLES_NAME.SHAREHOLDER },
     { Code: TITLES_ID.OTHER, Name: TITLES_NAME.OTHER },
     { Code: TITLES_ID.NONE, Name: TITLES_NAME.NONE }
]