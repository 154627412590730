import { APPLICATION_STATE } from '../states.enum';
import { APPLICANT_INFORMATION_ROUTES } from 'app/applicant-information/routing';

// export const PRIMARY_PIP_STATES = [
//   APPLICATION_STATE.PersonalInformationPrimary,
//   APPLICATION_STATE.HomeAddressInfoPrimary,
//   APPLICATION_STATE.MailingAddressQuestionPrimary,
//   APPLICATION_STATE.MailingAddressInfoPrimary,
//   APPLICATION_STATE.DobSSNInfoPrimary,
//   APPLICATION_STATE.IdTypeSelectionManualPrimary,
//   APPLICATION_STATE.IdInformationPrimary,
//   APPLICATION_STATE.IdAddressQuestionPrimary,
//   APPLICATION_STATE.IdAddressInfoPrimary
// ]

export const PRIMARY_PIP_TRADING_STATES = [
  APPLICATION_STATE.PersonalInformation,
  APPLICATION_STATE.AddressInformation,
  APPLICATION_STATE.FinancialInformation,
  APPLICATION_STATE.InvestmentProfile,
  //APPLICATION_STATE.Beneficiaries,
  //APPLICATION_STATE.ContingentBeneficiaries
]

export const PRIMARY_PIP_ADDITIONAL_OWNER_STATES = [
    APPLICATION_STATE.PersonalInformation,
    APPLICATION_STATE.AddressInformation,
    APPLICATION_STATE.AdditionalOwner,
    APPLICATION_STATE.FinancialInformation,
    APPLICATION_STATE.InvestmentProfile
]


//Trading new states
// export const PRIMARY_PIP_TRADING_STATES = [
//   APPLICATION_STATE.PersonalInformation,
//   APPLICATION_STATE.AddressInformation,
//   APPLICATION_STATE.FinancialInformation,
//   APPLICATION_STATE.InvestmentProfile
// ]