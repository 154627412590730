import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// Enrollment modules
import { EnrollmentCommonModule } from 'app/enrollment-common/enrollment-common.module';
// Components
import { containers } from './containers';
import { components, LayoutTemplateComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    EnrollmentCommonModule
  ],
  declarations: [
    ...containers,
    ...components,
  ],
  exports: [
    ...containers,
    LayoutTemplateComponent
  ]
})
export class LayoutModule { }
