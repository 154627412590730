import { APPLICATION_STATE } from '../states.enum';

export const TEEN_PIP_STATES = [
  APPLICATION_STATE.PersonalInformationTeen,
  APPLICATION_STATE.ContactInformationTeen,
  APPLICATION_STATE.HomeAddressQuestionTeen,
  APPLICATION_STATE.HomeAddressInfoTeen,
  APPLICATION_STATE.MailingAddressQuestionTeen,
  APPLICATION_STATE.MailingAddressInfoTeen,
  APPLICATION_STATE.DobSSNInfoTeen
];
