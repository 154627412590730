import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'million'
})
export class MillionPipe implements PipeTransform {

    transform(input: any, args?: any): any {

        if (Number.isNaN(input)) {
            return null;
        }

        let nmbr = Number(input.replace(/[^0-9.-]+/g, ""));
        let moneyStr: string = input;;

        if (nmbr >= 1000000) {
            if (nmbr.toString().endsWith('1'))
                nmbr = nmbr - 1;
            moneyStr = '$' + (nmbr / 1000000).toString().substring(0, 4) + ' Million'
        }

        return moneyStr;
    }

}