// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetStatesAction } from 'store/actions';
import { getStates, getStatesLoaded } from 'store/selectors';
// Model
import { State } from 'models';

@Injectable()
export class StatesResolver implements Resolve<Observable<boolean>> {

  constructor(private store$: Store<EnrollmentState>) {  }

  resolve() {
    return this.store$.select(getStatesLoaded).pipe(
      filter(loaded => {
        if(!loaded) { this.store$.dispatch(new GetStatesAction()); }
        return loaded;
      }),
      first()
    );
  }
}
