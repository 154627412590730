import { OrganizationState } from '../states';
import * as fromActions from '../actions/organization.action';

export const initialState: OrganizationState = {
    organization : null
}

export function reducer(
    state = initialState,
    action: fromActions.OrganizationAction
  ): OrganizationState {
    switch(action.type) {
      case fromActions.ORGANIZATION_ACTION_TYPES.GET_ORGANIZATION: {
        return { ...state };
      }
      case fromActions.ORGANIZATION_ACTION_TYPES.GET_ORGANIZATION_SUCCESS: {
        return { ...state,
            organization: action.data
        };
      }
      case fromActions.ORGANIZATION_ACTION_TYPES.GET_ORGANIZATION_FAIL: {
        return { ...state, 
          organization: {
              organizationId: '',
              name: '',
              displayName: '',
              email: '',
              phone: '',
              settings: null,
              url: '',
              isWhiteLabel: false
          }};      
        }      
    }
    return state;
  }
