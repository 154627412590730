export enum SOURCE_TYPES {
    oldNewEnroller = 1,
    oldExistingEnroller = 2,
    newEnroller = 12,
    existingEnroller = 13,
    ProxyINTNew = 6,
    ProxyINTExisting = 9,
    ProxyEXTNew = 10,
    ProxyEXTExisting = 11,
    ProxyTransferNew = 18,
    ProxyTransferExisting = 19
}

export enum TRADING_CHANNEL_ID{
    newEnroller = 1,
    existingCustomer = 2,
    proxyNewEnroller = 3,
    proxyExistingCustomer = 4
}
