import { Injectable } from '@angular/core';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { UpdateProgressAction } from 'store/actions';

@Injectable()
export class ProgressService {

  constructor(private store$: Store<EnrollmentState>) { }

  calculateProgress(completeValue: number, totalSteps: number, currentStep: number, totalSubSteps?: number, currentSubStep?: number) {
    const stepValue = completeValue / totalSteps;
    let progress = stepValue * currentStep;
    if(totalSubSteps) {
      const subStepValue = stepValue / totalSubSteps;
      const subProgress = subStepValue * currentSubStep;
      progress += subProgress;
    }
    return Math.floor(progress);
  }

  setProgress(prevProgress: number, value: number) {
    this.store$.dispatch(new UpdateProgressAction(prevProgress + value));
  }
}
