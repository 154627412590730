import { ApplicationState } from '../states';
import * as fromActions from '../actions/application.action';
import * as fromRulesActions from '../actions/rules.action';
import { IApplication } from 'models';
import { VESTING_TYPE_ID, VESTING_TYPE_NAME, SOURCE_TYPES } from 'models/enums';

export const emptyApplication: IApplication = {
  applicationId: null,
  ConfirmationNumber: null,
  created: null,
  submittedDate: null,
  createNewApplication: false,
  applicationTypeName: null,
  vestingType: { Id: VESTING_TYPE_ID.Individual, Name: <string>VESTING_TYPE_NAME.Individual },
  ApplicationState: {
    PriorApplicationStateId: null,
    stateId: null,
    status: null,
    decisionReason: null,
    decisionStatus: null
  },
  SourceType: {
    SourceTypeId: SOURCE_TYPES.newEnroller,
    SourceName: ''
  },
  Brand: { Id: null, Name: null },
  Accounts: [],
  Applicants: [],
  PromoCode: null,
  beneficiariesIinformation: [],
  beneficiariesContingentIinformation: []
};

export const initialState: ApplicationState = {
  application: { ...emptyApplication },
  accounts: null,
  loaded: false,
  loading: false,
  trustTypes: []
}

export function reducer(
  state = initialState,
  action: fromActions.ApplicationAction | fromRulesActions.RulesAction
): ApplicationState {
  switch (action.type) {
    case fromActions.APPLICATION_ACTION_TYPES.CREATE_APPLICATION:
    case fromActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION:
    case fromActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_BY_TOKEN:
    case fromActions.APPLICATION_ACTION_TYPES.LOAD_ACCOUNTS: {
      return { ...state, loading: true };
    }
    case fromActions.APPLICATION_ACTION_TYPES.CREATE_APPLICATION_SUCCESS: {
      return { ...state, loading: false };
    }
    case fromActions.APPLICATION_ACTION_TYPES.UPDATE_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        application: {
          ...state.application,
          PromoCode: action.promoCode
        }
      };
    }
    case fromActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_SUCCESS:
    case fromActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_BY_TOKEN_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        application: { ...action.application }
      };
    }
    case fromActions.APPLICATION_ACTION_TYPES.LOAD_ACCOUNTS_FAIL: {
      return {
        ...state,
        loading: false,
        accounts: [],
      };
    }
    case fromActions.APPLICATION_ACTION_TYPES.LOAD_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        accounts: action.accounts
      };
    }
    case fromActions.APPLICATION_ACTION_TYPES.SET_STATE_SUCCESS: {
      const ApplicationState = {
        ...state.application.ApplicationState,
        stateId: action.state,
        PriorApplicationStateId: state.application.stateId
      };
      const application = state.application;
      application.stateId = action.state;

      return {
        ...state,
        application: {
          ...application,
          ApplicationState
        }
      };
    }
    case fromActions.APPLICATION_ACTION_TYPES.CANCEL_APPLICATION_SUCCESS: {
      return {
        ...state,
        application: { ...emptyApplication },
        loaded: false,
        loading: false
      };
    }
    case fromActions.APPLICATION_ACTION_TYPES.SET_APPLICATION_APPLICANTS: {
      return {
        ...state,
        application: {
          ...state.application,
          applicants: action.applicants
        }
      };
    }
    case fromActions.APPLICATION_ACTION_TYPES.SET_APPLICANTS_META: {
      return {
        ...state,
        application: {
          ...state.application,
          Applicants: action.applicants
        }
      }
    }
    // TODO: Move all this to Rules reducer
    case fromRulesActions.RULES_ACTION_TYPES.RESUME_RULES_SUCCESS:
    case fromRulesActions.RULES_ACTION_TYPES.BOARD_IN_MITEK_SUCCESS:
    case fromRulesActions.RULES_ACTION_TYPES.VALIDATE_APPLICATION_SUCCESS:
    case fromActions.APPLICATION_ACTION_TYPES.UPDATE_LOCAL_APPLICATION_STATE: {
      const ApplicationState = {
        ...state.application.ApplicationState,
        ...action.state,
        PriorApplicationStateId: state.application.stateId
      };
      return {
        ...state,
        application: {
          ...state.application,
          stateId: ApplicationState.stateId,
          decisionReason: ApplicationState.decisionReason,
          decisionStatus: ApplicationState.decisionStatus,
          ApplicationState
        }
      };
    }
    case fromRulesActions.RULES_ACTION_TYPES.GET_IDA_QUESTIONS_SUCCESS: {
      const ApplicationState = {
        ...state.application.ApplicationState,
        ...action.state,
        PriorApplicationStateId: state.application.stateId
      };
      return {
        ...state,
        application: { ...state.application, ApplicationState },
        idaQuestions: [...action.IdaQuestions]
      };
    }
    case fromRulesActions.RULES_ACTION_TYPES.GET_PENDING_ID_VERIFICATION_APPLICANTS_SUCCESS: {
      return {
        ...state,
        pendingIDVerificationApplicants: [...action.applicants]
      };
    }
    case fromActions.APPLICATION_ACTION_TYPES.GET_CONFIRMATION_NUMBER_SUCCESS: {
      return {
        ...state,
        application: {
          ...state.application,
          confirmationNumber: action.confirmationNumber
        }
      }
    }
    case fromRulesActions.RULES_ACTION_TYPES.SET_CONNECTION_ID: {
      return {
        ...state,
        application: {
          ...state.application,
          connectionId: action.connectionId
        }
      }
    }
    case fromActions.APPLICATION_ACTION_TYPES.SET_VESTING_TYPE_SUCCESS: {
      return {
        ...state,
        application: {
          ...state.application,
          Applicants: action.applicants,
          vestingTypeName: action.vestingTypeName
        }
      }
    }
    case fromActions.APPLICATION_ACTION_TYPES.SET_APPLICANTS_META: {
      return {
        ...state,
        application: {
          ...state.application,
          Applicants: action.applicants
        }
      }
    }
    case fromActions.APPLICATION_ACTION_TYPES.SAVE_BENEFICIARIES_SUCCESS:
    case fromActions.APPLICATION_ACTION_TYPES.SKIP_BENEFICIARIES_SUCCESS: {
      return{
        ...state,
        application: {
          ...state.application,
          beneficiariesIinformation: action.beneficiariesInformation
        }
      }
    }
    case fromActions.APPLICATION_ACTION_TYPES.SAVE_BENEFICIARIES_CONTINGENT_SUCCESS: {
      return{
        ...state,
        application: {
          ...state.application,
          beneficiariesContingentIinformation: action.beneficiariesInformation
        }
      }
    }
    case fromActions.APPLICATION_ACTION_TYPES.GET_BENEFICIARIES_INFORMATION_SUCCESS:
      return{
        ...state,
        application: {
          ...state.application,
          beneficiariesIinformation: action.beneficiaries
        }
      }
    case fromActions.APPLICATION_ACTION_TYPES.GET_BENEFICIARIES_CONTINGENT_INFORMATION_SUCCESS:
      return{
        ...state,
        application: {
          ...state.application,
          beneficiariesContingentIinformation: action.beneficiaries
      }
    }
    case fromActions.APPLICATION_ACTION_TYPES.SAVE_TRUST_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        application: {
          ...state.application,
          trustCertificateInformation: action.certificate
        }
      }
    }
    case fromActions.APPLICATION_ACTION_TYPES.GET_TRUST_CERTIFICATE_INFORMATION_SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          trustCertificateInformation: action.certificate
      }
    }
    case fromActions.APPLICATION_ACTION_TYPES.GET_TRUST_TYPES_SUCCESS: {
      return {
        ...state,
        trustTypes: action.trustTypes
      }
    }
    case fromActions.APPLICATION_ACTION_TYPES.GET_TRUST_TYPES_FAIL: {
      return {
        ...state,
        trustTypes: []
      }
    }
    case fromActions.APPLICATION_ACTION_TYPES.CLEAR_STATE: {
      return {
        ...initialState
      }
    }
  }
  return state;
}
