import { DisclosureList } from 'models/disclosures';

const consumerDepositAgreement = {
    id: 1,
    name: 'DepositAccountAgreement',
    title: 'Personal Deposit Account Agreement',
    subtitle: 'This document contains the business banking deposit account terms.',
    content: '',
    class: 'deposit',
    htmlUrl: 'depositaccountandolbfinal.html',
    htmlTemplate: '',
    pdf: 'depositaccountandolbfinal.pdf',
    template: '',
    isAccepted: false,
};

const businessDepositAgreement = {
    id: 1,
    name: 'BusinessDepositAccountAgreement',
    title: 'Deposit Account Agreement',
    subtitle: 'This document contains the business banking deposit account terms.',
    content: '',
    class: 'deposit',
    htmlUrl: 'depositaccount-sb.html',
    htmlTemplate: '',
    pdf: 'businessdepositaccount.pdf',
    template: '',
    isAccepted: false,
};

const privacyPolicy = {
    id: 2,
    name: 'PrivacPolicy',
    title: 'Privacy Policy',
    subtitle: 'Describes our Privacy practices and options to opt out of information sharing',
    content: '',
    class: 'privacy',
    htmlUrl: 'privacynoticeaxos.html',
    htmlTemplate: '',
    pdf: 'privacynoticeaxos.pdf',
    template: '',
    isAccepted: false,
};

const eDisclosure = {
    id: 3,
    name: 'PaperlessAgreement',
    title: 'Paperless Agreement',
    subtitle: 'Describes our Privacy practices and options to opt out of information sharing',
    content: '',
    class: 'disclosure',
    htmlUrl: 'edisclosurestatement.html',
    htmlTemplate: '',
    pdf: 'edisclosurestatement.pdf',
    template: '',
    isAccepted: false,
};


export const CONSUMER_DISCLOSURE_LIST: DisclosureList = {
     id: 1,
     allDisclosuresAccepted: false,
     doNotShareCreditPersonalInfo: false,
     doNotMarketPersonalInfo: false,
     doNotSharePersonalInfoNonAffiliates: false,
     disclosure: [
        consumerDepositAgreement,
        privacyPolicy,
        eDisclosure
    ]
}

export const BUSINESS_DISCLOSURE_LIST: DisclosureList = {
    id: 1,
    allDisclosuresAccepted: false,
    doNotShareCreditPersonalInfo: false,
    doNotMarketPersonalInfo: false,
    doNotSharePersonalInfoNonAffiliates: false,
    disclosure: [
       businessDepositAgreement,
       privacyPolicy,
       eDisclosure
   ]
}
