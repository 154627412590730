import { Action } from '@ngrx/store';
// Models
import { State, Country, AnnualSales, IGenericItem, FinancialInformationCatalog, BeneficiariesCatalog } from 'models';
import { CatalogItem } from 'models/trading/catalog-item.model';

export enum CATALOGS_ACTION_TYPES {
    GET_STATES = '[Catalogs] Get States',
    GET_STATES_SUCCESS = '[Catalogs] Get States success',
    GET_STATES_FAIL = '[Catalogs] Get States fail',
    GET_COUNTRIES = '[Catalogs] Get Countries',
    GET_COUNTRIES_SUCCESS = '[Catalogs] Get Countries success',
    GET_COUNTRIES_FAIL = '[Catalogs] Get Countries fail',
    GET_OCUPATIONS = '[Catalogs] Get Ocupations',
    GET_OCUPATIONS_SUCCESS = '[Catalogs] Get Ocupations success',
    GET_COMPANY_TITLES = '[Catalogs] Get Company titles',
    GET_COMPANY_TITLES_SUCCESS = '[Catalogs] Get Company titles success',
    GET_AFFILIATIONS = '[Catalogs] Get Affiliations',
    GET_AFFILIATIONS_SUCCESS = '[Catalogs] Get Affiliations success',
    GET_OCUPATIONS_FAIL = '[Catalogs] Get Ocupations fail',
    GET_ANNUAL_SALES = '[Catalogs] Get Annual Sales',
    GET_ANNUAL_SALES_SUCCESS = '[Catalogs] Get Annual Sales success',
    GET_ANNUAL_SALES_FAIL = '[Catalogs] Get Annual Sales fail',
    GET_FINANCIAL_CATALOG_INFORMATION = '[Catalogs] Get Financial information',
    GET_FINANCIAL_CATALOG_INFORMATION_SUCCESS = '[Catalogs] Get Financial information success',

    GET_BENEFICIARIES_CATALOG = '[Catalogs] Get Beneficiaries Catalog',
    GET_BENEFICIARIES_CATALOG_SUCCESS = '[Catalogs] Get Beneficiaries Catalog success'
}

export class GetStatesAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_STATES;
}

export class GetStatesSuccessAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_STATES_SUCCESS;
    constructor(public data: State[]) { }
}

export class GetCountriesAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_COUNTRIES;
}

export class GetCountriesSuccessAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_COUNTRIES_SUCCESS;
    constructor(public data: Country[]) { }
}

export class GetOcupationsAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_OCUPATIONS;
}

export class GetOcupationsSuccessAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_OCUPATIONS_SUCCESS;
    constructor(public data: CatalogItem[]) { }
}

export class GetCompanyTitlesAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_COMPANY_TITLES;
}

export class GetCompanyTitlesSuccessAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_COMPANY_TITLES_SUCCESS;
    constructor(public data: CatalogItem[]) { }
}

export class GetAffiliationsAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_AFFILIATIONS;
}

export class GetAffiliationsSuccessAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_AFFILIATIONS_SUCCESS;
    constructor(public data: CatalogItem[]) { }
}

export class GetAnnualSalesAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_ANNUAL_SALES;
}

export class GetAnnualSalesSuccessAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_ANNUAL_SALES_SUCCESS;
    constructor(public data: AnnualSales[]) { }
}

export class GetFinancialCatalogInformationAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_FINANCIAL_CATALOG_INFORMATION;
}

export class GetFinancialCatalogInformationSuccessAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_FINANCIAL_CATALOG_INFORMATION_SUCCESS;
    constructor(public data: FinancialInformationCatalog) {}
}

export class GetBeneficiariesCatalogAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_BENEFICIARIES_CATALOG;
}

export class GetBeneficiariesCatalogSuccessAction implements Action {
    readonly type = CATALOGS_ACTION_TYPES.GET_BENEFICIARIES_CATALOG_SUCCESS;
    constructor(public data: CatalogItem[]) {}
}

export type CatalogsAction = GetStatesAction | GetStatesSuccessAction |
    GetCountriesAction | GetCountriesSuccessAction | GetOcupationsAction |
    GetOcupationsSuccessAction | GetAnnualSalesAction | GetCompanyTitlesAction |
    GetAffiliationsAction | GetAffiliationsSuccessAction |
    GetCompanyTitlesSuccessAction | GetAnnualSalesSuccessAction |
    GetFinancialCatalogInformationAction | GetFinancialCatalogInformationSuccessAction |
    GetBeneficiariesCatalogAction | GetBeneficiariesCatalogSuccessAction;
