export const APPLICANT_INFORMATION_PARENT_PATH = 'personalInformation';

export const APPLICANT_INFORMATION_ROUTES: { [key: string]: string } = {
  EXISTING: 'existing',
  INFO: 'basic',
  DOB: 'dobssn',
  CONTACT_INFO: 'contact',
  ENTRY_METHOD: 'entry',
  FINANCIAL_INFORMATION: 'financial',
  //#region Address
  HOME_ADDRESS: 'address/home',
  HOME_ADDRESS_SAME_AS_ID: 'address/homesameasid',
  HOME_ADDRESS_SAME_AS_PRIMARY: 'addressquestion/home',
  MAILING_ADDRESS: 'address/mailing',
  MAILING_ADDRESS_SELECTION: 'addressquestion/mail',
  HOME_MAILING_ADDRESS: 'address/homeuniversal',
  //#endregion
  //#region ID
  ID_SELECTION: 'id/selection',
  ID_UPLOAD_PASSPORT: 'id/upload/passport',
  ID_UPLOAD: 'id/upload',
  ID_INFORMATION: 'id/information',
  ID_ADDRESS: 'address/id',
  ID_ADDRESS_SELECTION: 'addressquestion/id',
  //#endregion
  //#region Trading
  FEATURES: 'features',
  INVESTMENT_PROFILE: 'investmentprofile',
  BENEFICIARIES: 'beneficiaries',
  CONTINGENT_BENEFICIARIES: 'contingentbeneficiaries',
  //#endregion
  //region Identification 
  ID_DETAILS1: 'identification/upload',
  ID_DETAILS2: 'idDetails2',
  ////#endregion
  MITEK: 'upload',
  TRUST_CERTIFICATION: 'tin',
  //#region trading
  TRADING_PRIMARY: 'trading/about',
  TRADING_ADDITIONAL_OWNER: 'trading/additional',
  TRADING_PINFO: 'trading/pinfo',  
  //#endregion
}
