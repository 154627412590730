import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: 'selector-list',
    templateUrl: './selector-list.component.html',
    styleUrls: ['./selector-list.component.scss']
})
export class SelectorListComponent {
    @Input() optionList: any;
    @Output() onOptionSelected: EventEmitter<any> = new EventEmitter();
    constructor(private _sanatizer: DomSanitizer){}

    onSelect(optionSelected: any){
        this.clearSelection();
        optionSelected.selected = !optionSelected.selected;
        optionSelected.type ?
            this.onOptionSelected.emit(optionSelected.type) :
            this.onOptionSelected.emit(optionSelected.name)
    }

    getImage(icon: string){
        return this._sanatizer.bypassSecurityTrustResourceUrl(icon);
    }

    clearSelection(){
        this.optionList.forEach(option => {
            option.selected = false;
        });
    }

    setSelection(option: string) {
        const selected = this.optionList.find(x => x.type == option);
        if (selected) {
            this.onSelect(selected);
        }
    }
}
