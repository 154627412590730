import { Resolve } from '@angular/router';

import { Store } from '@ngrx/store';
import { EnrollmentState, GetElectronicDisclosureAction, GetInitialDisclosuresAction } from 'store';
import { getAxosInvestDisclosure, getElectronicDisclosure, getInitialDisclosures } from 'store/selectors/disclosure.selectors';

import { DetailDisclosure } from 'models';

import { Observable } from 'rxjs';
import { filter, first, map, tap, withLatestFrom } from 'rxjs/operators';
import { GetAxosInvestDisclosureAction, GetTaxPayerDisclosureAction } from '../../../store/actions/disclosures.action';

export class  TaxDisclosureResolver implements Resolve<Observable<DetailDisclosure>> {
    constructor(private store: Store<EnrollmentState>) {  }
    
    
    resolve(){
    return this.store.select(getAxosInvestDisclosure).pipe(
        withLatestFrom(
            this.store.select(getInitialDisclosures)
          ),
        map(disclosures => {
                const electronicDisclosure = disclosures[0];
                const initialDisclosures = disclosures[1];
                if(!electronicDisclosure){
                    this.store.dispatch(new GetAxosInvestDisclosureAction());
                }
                if(!initialDisclosures){
                    this.store.dispatch(new GetInitialDisclosuresAction());
                    this.store.dispatch(new GetTaxPayerDisclosureAction());
                }
                
            
            return electronicDisclosure;
        }),
        filter(disclosures => !!disclosures),
        first()
        );
    }
}

