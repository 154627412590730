declare var environment: any;
// Ng
import {
  NgModule,
  APP_INITIALIZER
} from '@angular/core';
import { CommonModule, TitleCasePipe, APP_BASE_HREF } from '@angular/common';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Ng Material
import { GestureConfig } from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
// Vendors
import { NgxMaskModule } from 'ngx-mask';
// UI Kit
import { UkModalModule } from '@uikit/components/uk-modal';
import { UIKitIconsModule } from '@uikit/components/uk-icons';
import { UkAuthenticationModule, UK_COOKIE_HANDLER_CONFIG, UK_IDLE_CONFIG } from '@uikit/components/uk-authentication';
// Core
import { API_URL } from 'app/core/constants';
import { FlowStepInterceptor } from 'app/auth/interceptors';
// Enrollment Modules
import { EnrollmentStoreModule } from 'store/store.module';
import { CoreServicesModule } from 'app/core/core-services.module';
import { LayoutModule } from 'app/layouts/layout.module';
import { AppRoutingModule } from 'app/layouts/app-routing.module';
import { ModalsModule } from 'app/modals/modals.module';
// Components
import { AppComponent } from 'app/app.component';
// Tokens
import { DISCLOSURES_URL } from 'app/enrollment-common/constants';
import { BASE_HREF } from 'models/navigation';
// Feature flag
import { FeatureToggleModule } from 'ngx-feature-toggle';
// Services
import { AppSettingsService } from './services/app-settings.service';
import { FlowNavigationService } from 'app/core/services';
import { DocumentRequestService } from 'app/documents';
import { IdentificationUploadService } from 'app/enrollment-common';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function settings(settingsService: AppSettingsService) {
  return () => settingsService.getSettings().subscribe();
}

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // UI Kit
    UkModalModule,
    UIKitIconsModule.forRoot('assets/icons/icons.svg'),
    UkAuthenticationModule,
    // Enrollment Modules
    EnrollmentStoreModule,
    CoreServicesModule,
    LayoutModule,
    ModalsModule,
    // Vendors
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    // Feature toogle
    FeatureToggleModule,
    //Material
    MatAutocompleteModule,
    LottieModule.forRoot({player: playerFactory})
  ],
  providers: [
    AppSettingsService,
    FlowNavigationService,
    TitleCasePipe,
    DocumentRequestService,
    IdentificationUploadService,
    { provide: API_URL, useFactory: () => (environment.apiURLs[environment.api] || '').replace('{apiDomain}', environment.apiDomain || 'axosbank') || '' },
    { provide: DISCLOSURES_URL, useValue: 'assets/disclosures/html/' },
    { provide: UK_COOKIE_HANDLER_CONFIG, useValue: { timeOut: 570, limitTime: 330, timeToTakeAction: 30, refreshToken: 450 } },
    { provide: UK_IDLE_CONFIG, useValue: { timeout: 570, pingTime: 5 } },
    { provide: APP_INITIALIZER, useFactory: settings, multi: true, deps: [AppSettingsService] },
    { provide: HTTP_INTERCEPTORS, useClass: FlowStepInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: APP_BASE_HREF, useFactory: (baseHref) => baseHref, deps: [BASE_HREF] }
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
