// Model
import { ApplicantIdentification } from './applicant-identification.model';
import { IdentificationInfo } from './identification-info.model';
import { ApplicantType } from './applicant-type.model';
import { Address } from './address.model';
import { PhoneNumber } from './phone-number.model';
// Enum
import { ADDRESS_TYPE_NAME, PHONE_TYPE, APPLICANT_TYPE, PHONE_TYPE_UNIVERSAL, APPLICANT_STATE, FLOW_STEPS, DOCUMENT_TYPE } from './enums';
// Core
import {
  IS_SAME_ADDRESS,
  GET_ADDRESS_BY_TYPE,
  SET_ADDRESS_BY_TYPE,
  GET_PHONE_BY_TYPE,
  SET_PHONE_BY_TYPE
} from 'app/core/utils';
import { PersonalInformationTrading, FinancialInformation, BeneficiariesInformation, Beneficiary } from './trading';
import { Person } from './trading/person.model';
import { Affiliation } from './trading/affiliation.model';
import { DocumentRequest } from 'app/documents/models';
import { WLApplicantIdentification } from './wl';
import { RecommendedAddress } from 'models';

export interface IApplicantRequest {
  ApplicantId?: number;
  ApplicationId?: number;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  SuffixId?: number;
  Occupation?: string;
  DOB?: Date | string;
  CitizenshipTypeId?: number;
  EmploymentTypeId?: number;
  SSN?: string;
  EmailPrimary?: string;
  TrustName?: string;
}

export class Applicant {
  ApplicantId?: number;
  applicantId?: string;
  ApplicationId?: number;
  ApplicantType?: ApplicantType;
  isExisting?: boolean;

  // Workflow
  applicantStateName?: FLOW_STEPS;
  workflowSteps?: FLOW_STEPS[];

  addresses?: Address[];
  recommend?: RecommendedAddress[];
  ApplicantPhoneNumbers?: PhoneNumber[];
  ApplicantIdentifications?: ApplicantIdentification[];

  // Personal Information
  EmploymentTypeId?: number;
  FirstName?: string;
  MiddleName?: string = ''
  LastName?: string;
  SuffixId?: number;
  Occupation?: string;
  DOB?: Date | string;
  SSN?: string;
  ConfirmSSN?: string;
  CitizenshipTypeId?: number;

  IdVerificationMethodId?: number;

  IdentificationInfo?: IdentificationInfo;

  //WL Id Details
  applicantIdentification?: WLApplicantIdentification;
  //End WL Id Details

  AcceptTypeIDCert?: any;

  userName?: string;
  email?: string;
  confirmEmail?: string;
  password?: string;
  confirmPassword?: string;
  optForTextMessages?: boolean;
  advisorId?: any;
  advisorName?: string;
  shareInfo?: any;

  annualIncomeId?: string;
  totalNetWorthId?: string;
  liquidNetWorthId?: string;
  sourceOfFundsId?: string;
  taxBracketsId?: string;
  FinancialInformation?: FinancialInformation;

  //Trust Certifictation
  TrustName: string;
  TrustFormationDate?: Date | string;
  trustTaxPayerId: string;

  ContactMethodId?: number;
  PIN?: any;
  SecretWordHint?: string;
  EmployerName?: string;
  MaidenName?: string;

  // Business
  TaxpayerIDType?: string;
  MinSales?: number;
  MaxSales?: number;
  Description?: string;
  EmailPrimary?: string;
  EmailSecondary?: string;
  Website?: string;
  ExpectedDesposit?: number;
  OwnershipPercentage?: number;
  IncorporationState?: string;

  // Only for UI Use
  manualCapture?: boolean;
  selectedTaxpayerOption?: string;
  hasDifferentName?: boolean;
  AnnualSalesId?: number;

  // Should go in Application
  ReferralSourceId?: number;
  PromoCode?: string;

  // To be deprecated
  IsJointHomeAddressSameAsPrimaryHomeAddress?: string;
  IsMinorHomeAddressSameAsPrimaryHomeAddress?: string;
  Phone?: any; //Remove whe api is ready
  Email?: string;

  //PI for Universal applicants
  PersonalInformationUniversal?: PersonalInformationTrading;
  taxPayerId?: string;
  taxPayerType?: string;
  typeId?: APPLICANT_TYPE;
  person?: Person;
  affiliations?: Affiliation[];
  type?: string;
  phones?: PhoneNumber[];

  //PI for Beneficiaries in Review and Submit
  beneficiariesForm?: { beneficiaries: Beneficiary[], totalShare: number };
  contingentsForm?: { beneficiaries: Beneficiary[], totalShare: number };

  requestedDocuments?: DocumentRequest[];

  // #region Calculated Properties
  // Addresses
  get HomeAddressInfo() {
    return GET_ADDRESS_BY_TYPE(this, ADDRESS_TYPE_NAME.Current);
  }
  set HomeAddressInfo(value: Address) {
    this.addresses = SET_ADDRESS_BY_TYPE(this.addresses, value, ADDRESS_TYPE_NAME.Current);
  }
  get MailingAddressInfo() {
    return GET_ADDRESS_BY_TYPE(this, ADDRESS_TYPE_NAME.Mailing);
  }
  set MailingAddressInfo(value) {
    this.addresses = SET_ADDRESS_BY_TYPE(this.addresses, value, ADDRESS_TYPE_NAME.Mailing);
  }
  get IdentificationAddressInfo() {
    switch (this.IdentificationInfo.IdentificationAddressType) {
      case ADDRESS_TYPE_NAME.Current:
        return this.HomeAddressInfo;
      case ADDRESS_TYPE_NAME.Mailing:
        return this.MailingAddressInfo;
    }
    return GET_ADDRESS_BY_TYPE(this, ADDRESS_TYPE_NAME.Other);
  }
  set IdentificationAddressInfo(value) {
    this.addresses = SET_ADDRESS_BY_TYPE(this.addresses, value, ADDRESS_TYPE_NAME.Other);
  }

  get isMailingAddressSameAsHomeAddress() {
    return IS_SAME_ADDRESS(this.HomeAddressInfo, this.MailingAddressInfo)
  }
  // Phone Number
  get CellPhone() {
    return GET_PHONE_BY_TYPE(this, PHONE_TYPE_UNIVERSAL.Cell).number;
  }
  set CellPhone(value: string) {
    this.ApplicantPhoneNumbers = SET_PHONE_BY_TYPE(this.phones, { number: value }, PHONE_TYPE_UNIVERSAL.Cell);
  }
  get HomePhone() {
    return GET_PHONE_BY_TYPE(this, PHONE_TYPE_UNIVERSAL.Home).number;
  }
  get BusinessPhone() {
    return GET_PHONE_BY_TYPE(this, PHONE_TYPE_UNIVERSAL.Business).number;
  }
  get BusinessPhoneExtension() {
    return GET_PHONE_BY_TYPE(this, PHONE_TYPE_UNIVERSAL.Business).PhoneExtension;
  }

  get FullName() { return `${this.FirstName} ${this.MiddleName === null ? '' : this.MiddleName} ${this.LastName}`; }
  set FullName(value) { }

  get DisplayName() { return this.FullName; }
  set DisplayName(value) { }
  // #endregion
}
