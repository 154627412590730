import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
// Rename to DestroyHook
export abstract class BaseDestroyComponent implements OnDestroy {
  protected unsubscribe$ = new Subject();
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
