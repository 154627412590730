import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// Resolvers
import { ApplicationResolver } from './application.resolver';
import { combineLatest } from 'rxjs';
import { ManagedPortfolioService } from 'services';

@Injectable()
export class PortfolioQuestionAnswerResolver implements Resolve<Observable<any>> {

    constructor(private applicationResolver: ApplicationResolver, private portfolioService: ManagedPortfolioService) { }

    resolve() {
        return this.applicationResolver.resolve().pipe(
                switchMap(app => combineLatest([
                    this.portfolioService.getRiskAnswers(app.applicationId),
                    this.portfolioService.getRiskQuestions()
                ])),
                switchMap(([answers, questions]) => of({answers, questions}))
            );
    }
}