import { NgModule } from '@angular/core';

import {
  LoadingService,
  BrowserTypeService,
  LazyLoadingService,
  EnrollmentInitilizerService,
  MarketingService
} from './services';

@NgModule({
  imports: [ ],
  declarations: [ ],
  exports: [ ],
  providers: [
    LoadingService,
    LazyLoadingService,
    EnrollmentInitilizerService,
    MarketingService,
    BrowserTypeService
  ]
})
export class CoreServicesModule { }
