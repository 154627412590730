import { Action } from '@ngrx/store';
import { DocumentRequest } from 'app/documents/models';
// Models
import { Applicant, Address, IApplicantRequest, IdentificationInfo, PhoneNumber, PersonalInformationTrading, IApplicationRequest, BeneficiariesInformation, IdentificationDetailsInfo, WLIdentificationDetailsInfo, RecommendedAddress } from 'models';
import { APPLICANT_TYPE_NAME, PHONE_TYPE, ADDRESS_TYPE_NAME } from 'models/enums';
import { Person, AdditionalPerson } from 'models/trading/person.model';
import { IdDetails } from 'models/wl/id-details.model';

export interface IUpdateApplicantAction {
  shouldNavigate: boolean;
}

export enum APPLICANTS_ACTION_TYPES {
  // Applicants
  LOAD_ALL_APPLICANTS = '[Applicants] Load All',
  LOAD_ALL_APPLICANTS_FAIL = '[Applicants] Load All fail',
  LOAD_ALL_APPLICANTS_SUCCESS = '[Applicants] Load All success',

  LOAD_APPLICANT = '[Applicants] Load Single',
  LOAD_APPLICANT_FAIL = '[Applicants] Load Single fail',
  LOAD_APPLICANT_SUCCESS = '[Applicants] Load Single success',

  SAVE_APPLICANT_IDENTIFICATION = '[Applicants] Save identifcation info',
  SAVE_APPLICANT_IDENTIFICATION_FAIL = '[Applicants] Save identifcation info fail',
  SAVE_APPLICANT_IDENTIFICATION_SUCCCESS = '[Applicants] Save identifcation info success',

  RECOMMENDED_APPLICANT_ADDRESS = '[Applicants] Recommended address',
  RECOMMENDED_APPLICANT_ADDRESS_SUCCESSS = '[Applicants] Recommended address success',
  RECOMMENDED_APPLICANT_ADDRESS_FAIL = '[Applicants] Recommended address fail',
  SAVE_APPLICANT_ADDRESS = '[Applicants] Save address',
  SAVE_APPLICANT_ADDRESS_FAIL = '[Applicants] Save address fail',
  SAVE_APPLICANT_ADDRESS_SUCCCESS = '[Applicants] Save address success',

  SAVE_APPLICANT_PHONE = '[Applicants] Save phone',
  SAVE_APPLICANT_PHONE_FAIL = '[Applicants] Save phone fail',
  SAVE_APPLICANT_PHONE_SUCCCESS = '[Applicants] Save phone success',

  UPDATE_APPLICANT = '[Applicants] Update',
  UPDATE_APPLICANT_FAIL = '[Applicants] Update fail',
  UPDATE_APPLICANT_SUCCESS = '[Applicants] Update success',

  SET_CURRENT_APPLICANT_TYPE = '[Applicants] Set current applicant type',

  SAVE_PI_UNIVERSAL = '[Applicants] Save Personal Information Universal',
  SAVE_PI_UNIVERSAL_SUCCESS = '[Applicants] Save Personal Information Universal success',
  SAVE_PI_UNIVERSAL_FAIL = '[Applicants] Save Personal Information Universal fail',

  LOAD_PERSONAL_INFORMATION_SUCCESS = '[Applicants] Load Personal Information success',

  GET_ADDRESS_INFOES = '[Applicants] Get Address Infoes',
  GET_ADDRESS_INFOES_SUCCESS = '[Applicants] Get Address Infoes Success',
  GET_ADDRESS_INFOES_FAIL = '[Applicants] Get Address Infoes Fail',

  SAVE_BASIC_INFORMATION = '[Applicants] Save basic information',
  SAVE_BASIC_INFORMATION_SUCCESS = '[Applicants] Save basic information success',

  UPDATE_TAX_PAYER_ID = '[Applicants] Update applicant taxPayerId',
  UPDATE_TAX_PAYER_ID_SUCCESS = '[Applicants] Update applicant taxPayerId success',

  UPDATE_PROXY_TAX_PAYER_ID = '[Applicants] Update proxy applicant taxPayerId',
  UPDATE_PROXY_TAX_PAYER_ID_SUCCESS = '[Applicants] Update proxy applicant taxPayerId success',

  SAVE_ADDITIONAL_OWNER_INFO = '[Applicant] Save additional owner',
  SAVE_ADDITIONAL_OWNER_INFO_SUCCESS = '[Applicant] Save additional owner success',
  SAVE_ADDITIONAL_OWNER_INFO_FAIL = '[Applicant] Save additional owner fail',

  UPDATE_ADDITIONAL_OWNER_INFO_SUCCESS = '[Applicant] Update additional owner success',
  UPDATE_ADDITIONAL_OWNER_INFO_FAIL = '[Applicant] Update additional owner fail',

  GET_REQUESTED_DOCUMENTS = '[Applicant] Get requested documents',
  GET_REQUESTED_DOCUMENTS_SUCCESS = '[Applicant] Get requested documents success',
  GET_REQUESTED_DOCUMENTS_FAIL = '[Applicant] Get requested documents fail',

  SAVE_IDENTIFICATION_DETAILS = '[Applicant] Save identification details',
  SAVE_IDENTIFICATION_DETAILS_SUCCESS = '[Applicant] Save identification details success',
  SAVE_IDENTIFICATION_DETAILS_FAIL = '[Applicant] Save identification details fail',

  SAVE_ID_DETAILS_PAGE_2 = '[Applicant] Save identification details page 2',
  SAVE_ID_DETAILS_PAGE_2_SUCCESS = '[Applicant] Save identification details page 2 success',
  SAVE_ID_DETAILS_PAGE_2_FAIL = '[Applicant] Save identification details page 2 fail',

  GET_APPLICANT_EXISTING = '[Applicant] Get Applicant Existing',
  GET_APPLICANT_EXISTING_SUCCESS = '[Applicant] Get Applicant Existing success ',
  GET_APPLICANT_EXISTING_FAIL = '[Applicant] Get Applicant Existing fail ',

  CLEAR_STATE = '[Applicants] Clear State'
}

export class SetCurrentApplicantTypeAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.SET_CURRENT_APPLICANT_TYPE;
  constructor(public applicantType: APPLICANT_TYPE_NAME) { }
}

export class LoadApplicantAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.LOAD_APPLICANT;
  constructor(public applicantId: string, public setAsCurrent = false) { }
}

export class LoadApplicantSuccessAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.LOAD_APPLICANT_SUCCESS;
  constructor(public applicant: Applicant, public setAsCurrent = false) { }
}

export class LoadAllApplicantsAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.LOAD_ALL_APPLICANTS;
  constructor() { }
}

export class LoadAllApplicantsSuccessAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.LOAD_ALL_APPLICANTS_SUCCESS;
  constructor(public applicants: Applicant[]) { }
}

export class RecommendedApplicantAddressAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.RECOMMENDED_APPLICANT_ADDRESS;
  constructor(public recaddress: RecommendedAddress, public shouldNavigate = false, public skipHistory: boolean) { }
}

export class RecommendedApplicantAddressSuccessAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.RECOMMENDED_APPLICANT_ADDRESS_SUCCESSS;
  constructor(public recaddress: RecommendedAddress, public applicantId: string, public shouldNavigate = true, public skipHistory: boolean) { }
}

export class RecommendedApplicantAddressFailAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.RECOMMENDED_APPLICANT_ADDRESS_FAIL;
  constructor(public error: string) { }
}

export class SaveApplicantAddressAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_ADDRESS;
  constructor(public address: Address, public addressType: ADDRESS_TYPE_NAME, public shouldNavigate = true, public skipHistory: boolean) { }
}

export class SaveApplicantAddressSuccessAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_ADDRESS_SUCCCESS;
  constructor(public address: Address, public applicantId: string, public shouldNavigate = true, public skipHistory: boolean) { }
}

export class SaveApplicantAddressFailAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_ADDRESS_FAIL;
  constructor(public error: string) { }
}

export class SaveApplicantPhoneAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_PHONE;
  constructor(public phone: PhoneNumber, public phoneType: PHONE_TYPE, public shouldNavigate = true) { }
}

export class SaveApplicantPhoneSuccessAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_PHONE_SUCCCESS;
  constructor(public phone: PhoneNumber, public applicantId: string, public shouldNavigate = true) { }
}

export class SaveApplicantIdentificationInfosAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_IDENTIFICATION;
  constructor(public identification: IdentificationInfo, public shouldNavigate = true) { }
}

export class SaveApplicantIdentificationInfoSuccessAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_IDENTIFICATION_SUCCCESS;
  constructor(public identification: IdentificationInfo, public applicantId: string, public shouldNavigate = true) { }
}

export class UpdateApplicantAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.UPDATE_APPLICANT;
  constructor(public applicant: Applicant, public shouldNavigate = true) { }
}

export class UpdateApplicantSuccessAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.UPDATE_APPLICANT_SUCCESS;
  constructor(public applicant: IApplicantRequest, public applicantId: string, public shouldNavigate = true) { }
}

export class SavePIUniversalAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_PI_UNIVERSAL;
  constructor(public Person: Person, public applicantId: string, public shouldNavigate = true, public skipHistory: boolean) { }
}

export class SavePIUniversalSuccessAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_PI_UNIVERSAL_SUCCESS;
  constructor(public person: Person, public applicantId: string, public shouldNavigate = true, public skipHistory: boolean) { }
}

export class SavePIUniversalFailAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_PI_UNIVERSAL_FAIL;
  constructor(public error: string) { }
}

export class LoadPersonalInformationSuccessAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.LOAD_PERSONAL_INFORMATION_SUCCESS;
  constructor(public person: Person, public applicantId: string, public shouldNavigate = true) { }
}

export class GetAddressInfoesAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.GET_ADDRESS_INFOES;
  constructor(public applicantId: string, public shouldNavigate = true) { }
}

export class GetAddressInfoesSuccessAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.GET_ADDRESS_INFOES_SUCCESS;
  constructor(public AdressInfoes: Address[], public applicantId: string, public shouldNavigate = true) { }
}

export class GetAddressInfoesFailAction implements Action, IUpdateApplicantAction {
  readonly type = APPLICANTS_ACTION_TYPES.GET_ADDRESS_INFOES_FAIL;
  constructor(public applicantId: string, public shouldNavigate = true) { }
}

export class SaveBasicInformationAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_BASIC_INFORMATION;
  constructor(public applicantId: string, public person: Person) { }
}

export class SaveBasicInformationActionSuccess implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_BASIC_INFORMATION_SUCCESS;
  constructor(public applicantId: string, public person: Person) { }
}

export class UpdateApplicantTaxPayerIdAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.UPDATE_TAX_PAYER_ID;
  constructor(public applicantId: string, public taxPayerId: string) { }
}

export class UpdateProxyApplicantTaxPayerIdAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.UPDATE_PROXY_TAX_PAYER_ID;
  constructor(public applicationId: string, public applicantId: string, public taxPayerId: string) { }
}

export class UpdateApplicantTaxPayerIdActionSuccess implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.UPDATE_TAX_PAYER_ID_SUCCESS;
  constructor(public applicantId: string, public taxPayerId: string) { }
}

export class UpdateProxyApplicantTaxPayerIdActionSuccess implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.UPDATE_PROXY_TAX_PAYER_ID_SUCCESS;
  constructor(public applicationId: string, public applicantId: string,  public taxPayerId: string) { }
}

export class SaveAdditionalOwnerAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_ADDITIONAL_OWNER_INFO;
  constructor(public additionalPerson: AdditionalPerson, public updateAdditional = false, public skipHistory: boolean) { }
}

export class SaveAdditionalOwnerSuccessAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_ADDITIONAL_OWNER_INFO_SUCCESS;
  constructor(public applicantId: string, public additionalPerson: AdditionalPerson, public shouldNavigate = true) { }
}

export class SaveAdditionalOwnerFailAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.SAVE_ADDITIONAL_OWNER_INFO_FAIL;
  constructor() { }
}

export class UpdateAdditionalOwnerSuccessAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.UPDATE_ADDITIONAL_OWNER_INFO_SUCCESS;
  constructor(public applicantId: string, public additionalPerson: AdditionalPerson) { }
}

export class UpdateAdditionalOwnerFailAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.UPDATE_ADDITIONAL_OWNER_INFO_FAIL;
  constructor() { }
}

export class GetRequestedDocumentsAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.GET_REQUESTED_DOCUMENTS;
  constructor(public applicationId: string) { }
}

export class GetRequestedDocumentsSuccessAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.GET_REQUESTED_DOCUMENTS_SUCCESS;
  constructor(public applicantId: string, public requestedDocuments: DocumentRequest[]) { }
}

export class GetRequestedDocumentsFailAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.GET_REQUESTED_DOCUMENTS_FAIL;
  constructor() { }
}

export class SaveIdentificationDetailsAction implements Action {
    readonly type = APPLICANTS_ACTION_TYPES.SAVE_IDENTIFICATION_DETAILS;
    constructor(public identificationDetailsInfo: WLIdentificationDetailsInfo, public skipHistory: boolean) { }
}

export class SaveIdentificationDetailsSuccessAction implements Action {
    readonly type = APPLICANTS_ACTION_TYPES.SAVE_IDENTIFICATION_DETAILS_SUCCESS;
    constructor(public applicantId: string, public identificationDetailsInfo: any, public shouldNavigate = true) { }
}

export class SaveIdentificationDetailsFailAction implements Action {
    readonly type = APPLICANTS_ACTION_TYPES.SAVE_IDENTIFICATION_DETAILS_FAIL;
    constructor(){}
}
export class ClearApplicantsStateAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.CLEAR_STATE;
  constructor() { }
}

export class GetApplicantExisting implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.GET_APPLICANT_EXISTING;
  constructor(public applicantId?: string) { }
}

export class GetApplicantExistingSuccessAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.GET_APPLICANT_EXISTING_SUCCESS;
  constructor(public applicantId: string, public isExisting: boolean) { }
}

export class GetApplicantExistingFailAction implements Action {
  readonly type = APPLICANTS_ACTION_TYPES.GET_APPLICANT_EXISTING_FAIL;
  constructor() { }
}

export class SaveIdDetailsPage2 implements Action{
    readonly type = APPLICANTS_ACTION_TYPES.SAVE_ID_DETAILS_PAGE_2;
    constructor(public idDetails: IdDetails, public skipHistory: boolean) { }
}

export class SaveIdDetailsPage2Success implements Action{
    readonly type = APPLICANTS_ACTION_TYPES.SAVE_ID_DETAILS_PAGE_2_SUCCESS;
    constructor(public applicantId: string, public idDetails: IdDetails) { }
}

export class SaveIdDetailsPage2Fail implements Action{
    readonly type = APPLICANTS_ACTION_TYPES.SAVE_ID_DETAILS_PAGE_2_FAIL;
    constructor(public error: string) { }
}

export type ApplicantsAction = SetCurrentApplicantTypeAction |
    LoadAllApplicantsAction | LoadAllApplicantsSuccessAction |
    LoadApplicantAction | LoadApplicantSuccessAction |
    SaveApplicantAddressAction | SaveApplicantAddressSuccessAction | SaveApplicantAddressFailAction |
    SaveApplicantPhoneAction | SaveApplicantPhoneSuccessAction |
    SaveApplicantIdentificationInfosAction | SaveApplicantIdentificationInfoSuccessAction |
    UpdateApplicantAction | UpdateApplicantSuccessAction |
    SavePIUniversalAction | SavePIUniversalSuccessAction | SavePIUniversalFailAction |
    LoadPersonalInformationSuccessAction | GetAddressInfoesAction |
    GetAddressInfoesSuccessAction | GetAddressInfoesFailAction |
    SaveBasicInformationAction | SaveBasicInformationActionSuccess |
    UpdateApplicantTaxPayerIdAction | UpdateProxyApplicantTaxPayerIdAction |
    UpdateApplicantTaxPayerIdActionSuccess | UpdateProxyApplicantTaxPayerIdActionSuccess |
    SaveAdditionalOwnerAction | SaveAdditionalOwnerSuccessAction | SaveAdditionalOwnerFailAction |
    UpdateAdditionalOwnerSuccessAction | UpdateAdditionalOwnerFailAction |
    GetRequestedDocumentsAction | GetRequestedDocumentsSuccessAction | GetRequestedDocumentsFailAction |
    SaveIdentificationDetailsAction | SaveIdentificationDetailsSuccessAction | SaveIdentificationDetailsFailAction |
    SaveIdDetailsPage2 | SaveIdDetailsPage2Success | SaveIdDetailsPage2Fail |
    GetApplicantExisting | GetApplicantExistingSuccessAction | GetApplicantExistingFailAction |
    ClearApplicantsStateAction | RecommendedApplicantAddressAction | RecommendedApplicantAddressFailAction | RecommendedApplicantAddressSuccessAction;
