declare var environment: any;
// Ng
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
// RxJs
import { filter, map, withLatestFrom } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getPageTitle, getUserApplicationId, getMarketingSelectedProducts, getUserEmail } from 'store/selectors';

import { GET_FIRST_CHILD_ROUTE } from '../utils';
import { LazyLoadingService, SCRIPT_LOAD_TYPE } from './lazy-loading.service';
import { BRAND_ID } from 'models/enums';
import { ISimpleProduct } from 'models';
import { moveBack } from 'store/workflows';

@Injectable()
export class MarketingService {
    // TODO: Get url from config
    private TealiumScripts = {
        prod: {
            axos: 'tags.tiqcdn.com/utag/bofi-bank/universalenrollment/prod/utag.js',
            nw: 'tags.tiqcdn.com/utag/bofi-bank/nationwide/prod/utag.js',
            ufb: 'tags.tiqcdn.com/utag/bofi-bank/ufbdirect/prod/utag.js',
        },
        qa: {
            axos: 'tags.tiqcdn.com/utag/bofi-bank/universalenrollment/qa/utag.js',
            nw: 'tags.tiqcdn.com/utag/bofi-bank/nationwide/qa/utag.js',
            ufb: 'tags.tiqcdn.com/utag/bofi-bank/ufbdirect/qa/utag.js',
        },
        dev: {
          axos: 'tags.tiqcdn.com/utag/bofi-bank/universalenrollment/dev/utag.js',
          nw: 'tags.tiqcdn.com/utag/bofi-bank/nationwide/dev/utag.js',
          ufb: 'tags.tiqcdn.com/utag/bofi-bank/ufbdirect/dev/utag.js',
        }
    }

    constructor(store$: Store<EnrollmentState>,
        router: Router, activatedRoute: ActivatedRoute,
        private lazyLoadingService: LazyLoadingService) {
        const navigationEvents$ = router.events;

        const navigationEnd$ = navigationEvents$.pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => GET_FIRST_CHILD_ROUTE(activatedRoute))
        );
        const navigationStart$ = navigationEvents$.pipe(
          filter(event => event instanceof NavigationStart),
        );
        // TODO: Move into another service
        navigationStart$.pipe(
          filter((event: NavigationStart) => event.navigationTrigger === 'popstate')
        ).subscribe(() => {
          store$.dispatch(moveBack());
        });
        navigationEnd$.pipe(
            filter((route: ActivatedRoute) => route.outlet === 'primary'),
            withLatestFrom(
                store$.select(getPageTitle),
                store$.select(getUserApplicationId),
                store$.select(getMarketingSelectedProducts),
                store$.select(getUserEmail))
        ).subscribe(data => {
            const pageTitle = data[1];
            const appId = data[2];
            const products = data[3];
            const email = data[4];
            this.push(pageTitle, appId, products, email);
        });
    }

    private getTealiumURL() {
      // TODO: Get url from config
      const envScript = environment.production
      ? this.TealiumScripts.prod
        : (environment.env !== 'dev'
          ? this.TealiumScripts.qa
          : this.TealiumScripts.dev);
      switch(environment.brandId) {
        case BRAND_ID.NationWide:
        case BRAND_ID.NationWideBusinessBanking:
          return envScript['nw'];
        case BRAND_ID.UFBDirect:
          return envScript['ufb'];
        default:
          return envScript['axos'];
      }
    }

    loadMarketingScript() {
      if (environment.marketing) {
        window['utag_cfg_ovrd'] = { noview: true };
        this.lazyLoadingService.loadScript(`${window.location.protocol}//${this.getTealiumURL()}`, SCRIPT_LOAD_TYPE.Defer);
      }
    }

    push(title: string, applicationId: string, products: ISimpleProduct[], email: string): void {
        var dataLayer = window['dataLayer'];

        if (dataLayer) {
            dataLayer.push({
                event: "virtualPageView",
                virtualPage: window.location.href
            });
        }

      if (typeof window['utag'] !== "undefined") {
        try {
                var utag_data = {
                  event_action: "",
                  business_unit: "",
                  gAds_conversionLabel: "",
                  page_type: "",
                  event_name: "",
                  tealium_event: "",
                  section_old: "",
                  application_id: applicationId || "",
                  product_category: "",
                  product_name: "",
                  section: window.location.pathname,
                  previous_page_name: "",
                  gAds_conversionId: "",
                  enrollment_decision: "",
                  is_conversion: "",
                  raw_section: "",
                  _pathname1: "",
                  _pathname2: "",
                  _pathname3: "",
                  _pathname4: "",
                  _pathname5: "",
                  _pathname6: "",
                  _pathname7: "",
                  _pathname8: "",
                  pathname_pageview: "",
                  lAds_conversionId: "",
                  triggerCrossDomain: "",
                  udb_un: "",
                  cifno: "",
                  socialAction: "",
                  socialNetwork: "",
                  domain_pathname: "",
                  socialTarget: "",
                  em: email || '',
                  hem: "",
                  datetime_year_utc: "",
                  datetime_month_utc: "",
                  datetime_day_utc: "",
                  datetime_day_string_utc: "",
                  datetime_hour_utc: "",
                  datetime_minute_utc: "",
                  datetime_second_utc: "",
                  datetime_epoch_utc: "",
                  datetime_string_utc: "",
                  datetime_randtemop_utc: "",
                  datetime_randtetab_utc: "",
                  enrollment_product: products ? products.map(p => p.displayName).join() : '',
                  tealium_timestamp_local: "",
                  tealium_random: "",
                  ga_session_id: "",
                  udb_section: "",
                  ulp_section: "",
                  ulp_application_id: "",
                  udb_hash: "",
                  enrollment_section: "",
                  reddit_event: "",
                  dmp_eventId: "",
                  enrollment_productid: products ? products.map(p => p.productCode).join() : '',
                  url: window.location.href,
                  page: title || 'Enrollment',
                  event_label : "",
                  uid : "",
                  Krux_user : "",
                  Krux_segments : "",
                  krux_id : "",
                  krux_segments : "",
                  fb_event_id_mortgagebot_application_submit : "",
                  ea : "",
                  product_id : "",
                  ec : "",
                  ev : ""
                }

                window['utag'].view(utag_data);
            } catch (error) {
                console.log("Oops! Tag tracking is not available.");
            }
        }
    }
}
