declare var environment: any;
declare var client: any;
//Ng
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// RxJs
import { of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom, filter, concatMap } from 'rxjs/operators';
// NgRx
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EnrollmentState } from "store";
import { UpdateBannerAction, } from "store/actions";
import { getIsExisting, getUserName, getChallengeInfo, getOtpChallengeInfo, getMarketingSelectedProducts, getSelectedVestingTypeId, getGuid, getIRAFlag, getApplicationId, getIsManagedPortfolio, getUserPhone, getNoOtpCode, getUserNoOtp, getUserLead } from "store/selectors";
import * as applicationAction from "../actions/application.action";
import * as applicantsAction from "../actions/applicants.action";
import * as userActions from "../actions/auth.action";
import { getElectronicDisclosure, getInitialDisclosures } from 'store/selectors/disclosure.selectors';
// Auth
import { AuthHandlerService } from 'app/auth/services';
import { AUTH_PARENT_ROUTE, AUTH_ROUTES, PHONE_NUMBER, Validation_TimeOut, otpTimeoutMessage, systemError } from 'app/auth/constants';
// Models
import { IExistingUser, ISignInData, ISignInRequest, IClientDeviceInfo, IChallengeInfo, IValidateOtpData, IOtpChallengeInfo, IAuthToken, IChallengeOtpData, IValidateOtpNoRequest, IValidateOtpRequest } from "models/auth";
import { SOURCE_TYPES, BRAND_ID } from "models/enums";
// Services
import { UserService, AuthService, BrandService, RegisterExistingCostumerService, UserProspectMockService } from "services";
import { StateRouteService, UtmCodeService } from "app/core/services";
import { AppSettingsService } from 'services/app-settings.service';
// Vendor
import { CookieService } from "ngx-cookie-service";
// UI Kit
import { UkHandlerService } from '@uikit/components';
import { IOtpNoUser, IUserLead, IUserLeadResponse } from 'models';
import { LANDING_ROUTES, LANDING_PARENT_PATH } from 'app/landing/routing';
import { APPLICATION_TYPE_FLOW } from 'models/enums/application-type.enum';
import { DISCLOSURE_NAMES } from 'app/disclosures/constants/disclosure-names.constanst';
import { SET_CACHE } from 'app/core/utils';

import { TOKEN_STORAGE_NAME } from 'app/auth/constants';
import { PROXY_ROUTES } from 'app/proxy/routing/constants/routes';


@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userProspectMockService: UserProspectMockService,
    private userService: UserService,
    private authHandlerService: AuthHandlerService,
    private cookieService: CookieService,
    private brandService: BrandService,
    private router: Router,
    private stateRouteService: StateRouteService,
    private store$: Store<EnrollmentState>,
    private registerExistingUserService: RegisterExistingCostumerService,
    private ukHandlerService: UkHandlerService,
    private utmCodeService: UtmCodeService,
    private appSettingsService: AppSettingsService
  ) { }

  @Effect()
  createUser$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.CREATE_USER),
    withLatestFrom(
      this.store$.select(getMarketingSelectedProducts),
      // this.store$.select(getSelectedVestingTypeId),
      this.store$.select(getElectronicDisclosure),
      this.store$.select(getGuid),
      this.store$.select(getIRAFlag),
      this.store$.select(getInitialDisclosures),
      this.store$.select(getIsManagedPortfolio),
    ),
    switchMap(([action, products, eDisclosure, guid, isIRA, initDisclosures, isManagedPortfolio]) => {
      if (action.electronicDisclosure) {
        const disclosures = [eDisclosure];
        const user = action.user;
        user.disclosures = disclosures;
      }
      action.user.productCodes = products.map(x => x.productCode);
      var utmCodes = this.utmCodeService.getUTMCampaignCodes();
      let applicationTypeName: APPLICATION_TYPE_FLOW = APPLICATION_TYPE_FLOW.Trading;
      let isIRAProduct = products.find(x => x.productCode.toLowerCase() == 'tira' || x.productCode.toLowerCase() == 'rira');

      if (isIRA || isIRAProduct) {
        if (environment.applicationTypeName == APPLICATION_TYPE_FLOW.Trading) { applicationTypeName = APPLICATION_TYPE_FLOW.TradingIRA }
        if (environment.applicationTypeName == APPLICATION_TYPE_FLOW.WhitelabelTrading) { applicationTypeName = APPLICATION_TYPE_FLOW.WhitelabelTradingIRA }
        if (environment.applicationTypeName == APPLICATION_TYPE_FLOW.ProxyTrading) { applicationTypeName = APPLICATION_TYPE_FLOW.ProxyIRA }
      } else if (isManagedPortfolio) {
        applicationTypeName = APPLICATION_TYPE_FLOW.ManagedPortfolio;
      } else {
        applicationTypeName = environment.applicationTypeName;
      }

      if (!environment.isProxy) {
        const tinCertification = initDisclosures
          .find(x => x.name == DISCLOSURE_NAMES.TIN_CERTIFICATION);
        tinCertification.accepted = true;
        action.user.disclosures.push(tinCertification);
      }

      if (!this.appSettingsService.isWHiteLabel() && !environment.isProxy) {
        const communicationsOptIn = initDisclosures
          .find(x => x.name == DISCLOSURE_NAMES.COMMUNICATIONS_OPT_IN);
        communicationsOptIn.accepted = true;
        action.user.disclosures.push(communicationsOptIn);
      }

      action.user.UtmCodes = utmCodes;

      return this.userService.create(action.user, guid ? guid : null, applicationTypeName).pipe(
        map(response => {
          if (!environment.isProxy) {
            this.authHandlerService.setToken(response.data);
          }
          return new userActions.CreateUserSuccessAction(response.metadata.applicationId);
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error ? error.error.Message : "Register Error";
          return of(new userActions.CreateUserFailAction(errorMessage));
        })
      );
    })
  );

  @Effect()
  createSuccessUser$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.CREATE_USER_SUCCESS),
    /*withLatestFrom(
      this.store$.select(getApplicantId),
    ),*/
    switchMap((data: userActions.CreateUserSuccessAction) => {
      // const action = <userActions.CreateUserSuccessAction>data[0],
      // applicantId = data[1];
      return of(
        new applicationAction.LoadApplicationAction(data.applicationId),
        //new applicationAction.LoadPersonAction(applicantId)
      )
    }
    )
  );

  @Effect({ dispatch: false })
  createUserFail$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.CREATE_USER_FAIL),
    tap(() => {
      return of(this.store$.dispatch(new applicationAction.RedirectToGeneralErrorPage()))
    })
  );

  // @Effect()
  // createSuccessUser$ = this.actions$.pipe(
  //   ofType(userActions.USER_ACTION_TYPES.CREATE_USER_SUCCESS),
  //   switchMap((action: userActions.CreateUserSuccessAction) => {
  //     return this.applicationInformationService.getApplication(action.applicationId).pipe(
  //       map(response => {
  //         console.log(response)
  //         debugger;
  //         return [
  //           new applicationAction.LoadApplicationAction(),
  //           //new applicantsAction.LoadApplicantAction(response.ApplicantId)
  //         ]
  //       })
  //     );
  //     // return this.applicationInformationService.getApplicant(action.token).pipe(
  //     //   map(response => {
  //     //     return [
  //     //       new applicantsAction.LoadApplicantAction(response.ApplicantId),
  //     //       new applicationAction.LoadApplicationAction()
  //     //     ]
  //     //   })
  //     // )
  //     // debugger;
  //     // return [
  //     //   new applicantsAction.LoadApplicantAction(action.token.applicationId),
  //     //   new applicationAction.LoadApplicationAction()
  //     // ]
  //   })
  // );

  // @Effect()
  // createLead$ = this.actions$.pipe(
  //   ofType(userActions.USER_ACTION_TYPES.CREATE_LEAD),
  //   switchMap((action: userActions.CreateLeadAction) => {
  //     return this.userProspectMockService.create(action.userProspect).pipe(
  //       map((userProspect) => {
  //         return new userActions.CreateLeadSuccessAction(userProspect);
  //       }),
  //       catchError((error: HttpErrorResponse) => {
  //         const errorMessage = error.error
  //           ? error.error.Message
  //           : "Create User Prospect Error";
  //         return of(
  //           new userActions.CreateLeadFailAction(errorMessage)
  //         );
  //       })
  //     )
  //   })
  // );

  @Effect({ dispatch: false })
  verifyExistingCustomer$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.VERIFY_EXISTING_CUSTOMER),
    withLatestFrom(this.store$.select(getGuid)),
    tap((data) => {
      const action = <userActions.VerifyExistingCustomerAction>data[0],
        guid: string = data[1];
      this.authService.verifyExistingCustomer(action.lead, guid ? guid : null).pipe(
        tap(existing => {
          if (existing) {
            if (!environment.isProxy) {
              this.router.navigateByUrl(`${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.EXISTINGLOGIN}`);
            }
            else {
              alert("This user is an existing customer. Use Existing customer flow.")
              this.router.navigateByUrl(`${PROXY_ROUTES.SEARCH_USER.path}`);
            }
          } else {
            if (guid == null || guid == "") {
              this.store$.dispatch(new userActions.CreateLeadAction(action.lead));
            } else {
              this.authService.verifyLoginCreated(action.lead, guid).subscribe(
                result => {
                  if(result) {
                    this.router.navigateByUrl(`${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.EXISTINGLOGIN}`);
                  } else {
                    this.router.navigateByUrl(LANDING_ROUTES.CREATE_USER.path);
                  }
                }, error => {
                  const errorMessage = error.error ? error.error.errorMessage : "Confirm Identity Error";
                  return throwError(new userActions.VerifyExistingCustomerFailAction(errorMessage));
                }
              );
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error ? error.error.errorMessage : "Confirm Identity Error";
          return throwError(new userActions.VerifyExistingCustomerFailAction(errorMessage));
        })
      ).subscribe({
        error: (error: userActions.VerifyExistingCustomerFailAction) => this.store$.dispatch(error)
      });
    })
  );

  // @Effect({dispatch: false})
  // verifyExistingCustomerFailAction$ = this.actions$.pipe(
  //   ofType(userActions.USER_ACTION_TYPES.VERIFY_EXISTING_CUSTOMER_FAIL),
  //   tap(() => {
  //     return of(
  //       this.store$.dispatch(new applicationAction.RedirectToGeneralErrorPage())
  //     )
  //   })
  // );

  @Effect()
  createLead$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.CREATE_LEAD),
    switchMap((action: userActions.CreateLeadAction) => {
      return this.authService.registerLead(action.userLead).pipe(
        map((leadResponse: IUserLeadResponse) => new userActions.CreateLeadSuccessAction(leadResponse)),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error
            ? error.error.Message
            : "Create User Prospect Error";
          return of(
            new userActions.CreateLeadFailAction(errorMessage)
          );
        })
      )
    })
  );

  @Effect()
  updateLead$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.UPDATE_LEAD),
    switchMap((action: userActions.UpdateLeadAction) => {
      return this.authService.updateLead(action.userLead).pipe(
        map((leadResponse: IUserLeadResponse) => new userActions.UpdateLeadSuccessAction(leadResponse)),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error
            ? error.error.Message
            : "Create User Prospect Error";
          return of(
            new userActions.UpdateLeadFailAction(errorMessage)
          );
        })
      )
    })
  );

  @Effect()
  RegisterExistingUser$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.REGISTER_EXISTING_USER),
    switchMap((action: userActions.RegisterExistingUserAction) => {
      return this.registerExistingUserService
        .registerCustomerInIdv1(action.user)
        .pipe(
          switchMap(user => {
            const userSignIn: ISignInRequest = {
              email: action.user.Email,
              password: action.user.Password,
              isExisting: false
            };
            return [
              new userActions.RegisterExistingUserSuccessAction(user),
              new userActions.LoginAction(userSignIn)
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            const errorMessage = error.error
              ? error.error.Message
              : "Register Existing User Error";
            return of(
              new userActions.RegisterExistingUserFailAction(errorMessage)
            );
          })
        );
    })
  );

  @Effect()
  createUserName$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.CREATE_USER_NAME),
    switchMap((action: userActions.CreateUserNameAction) => {
      return this.userService.createUserNameForIdv1User(action.user).pipe(
        switchMap(response => {
          const userSignIn: ISignInRequest = {
            email: action.user.UserName,
            password: action.user.Password,
            isExisting: false
          };
          return [
            new userActions.CreateUserNameSuccessAction(),
            new userActions.LoginAction(userSignIn)
          ];
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error
            ? error.error.Message
            : "Create Username Error";
          return of(new userActions.CreateUserNameFailAction(errorMessage));
        })
      );
    })
  );

  @Effect()
  cookieLogin = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.COOKIE_LOGIN),
    withLatestFrom(
      this.store$.select(getApplicationId)
    ),
    switchMap(data => {
      const action: userActions.CookieLoginAction = data[0],
        application = data[1];
      const sso = application == null ? true : false;
      const authCookie = this.cookieService.get('XSRF-TOKEN');
      return this.authService.refreshToken(authCookie, true).pipe(
        map(token => {
          this.authHandlerService.setToken(token);
          return (
            new userActions.CookieLoginSuccessAction(token),
            new userActions.ValidateExistingCustomerAction(),
            new applicationAction.LoadApplicationByTokenAction(true, sso)
          );
        }),
        catchError(() => {
          return of(new userActions.CookieLoginFailAction());
        })
      );
    })
  );

  @Effect({ dispatch: false })
  cookieLoginSucess = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.COOKIE_LOGIN_SUCCESS),
    tap(() => {
      this.ukHandlerService.initialize();
    })
  );

  @Effect()
  tokenLogin = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.TOKEN_LOGIN),
    withLatestFrom(
      this.store$.select(getApplicationId)
    ),
    switchMap(data => {
      const action: userActions.TokenLoginAction = data[0],
        application = data[1];
      const sso = application == null ? true : false;
      return this.authService.refreshTokenByUrl(action.token).pipe(
        map(token => {
          return (
            new userActions.TokenLoginSuccessAction(token),
            new userActions.ValidateExistingCustomerAction(),
            new applicationAction.LoadApplicationByTokenAction(true, true)
          );
        }),
        catchError(() => {
          return of(new userActions.TokenLoginFailAction());
        })
      );
    })
  );

  @Effect({ dispatch: false })
  tokenLoginSuccess = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.TOKEN_LOGIN_SUCCESS),
    tap(() => {
      this.ukHandlerService.initialize();
    })
  );

  @Effect()
  refreshToken$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.REFRESH_TOKEN),
    switchMap((action: userActions.RefreshTokenAction) => {
      return this.authService.refreshToken(action.refreshToken).pipe(
        map(token => {
          // this.authHandlerService.setToken(token);
          return new userActions.RefreshTokenSuccessAction(token);
        }),
        catchError(() => {
          return of(new userActions.RefreshTokenFailAction());
        })
      );
    })
  );

  @Effect({ dispatch: false })
  refreshTokenSuccess$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.REFRESH_TOKEN_SUCCESS),
    tap(() => {
      this.ukHandlerService.isTokenUpdated();
    })
  );

  @Effect()
  getUser$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.GET_USER),
    switchMap((action: userActions.GetUserAction) =>
      this.authService.getUser().pipe(
        map(user => {
          if (this.router.routerState.snapshot.url == '/auth/login' && user.CIF != null)
            this.stateRouteService.checkForInProgress = true;
          return { user, action };
        })
      )
    ),
    switchMap(data => {
      let extraActions = [];
      if (data.action.loadApplication) {
        extraActions = [new applicationAction.LoadApplicationByTokenAction()];
      }
      return [new userActions.GetUserSuccessAction(data.user), ...extraActions];
    })
  );

  @Effect({ dispatch: false })
  getUserSuccess$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.GET_USER_SUCCESS),
    filter((action: userActions.GetUserSuccessAction) => !!action.user),
    tap((action: userActions.GetUserSuccessAction) => {
      this.ukHandlerService.initialize();
      const { ExperimentVariantId, BrandId } = action.user,
        env = environment.env;
      if (BrandId === BRAND_ID.UFBDirect && ExperimentVariantId === 1) {
        let domain = 'enrollment.ufbdirect.com';
        if (!environment.production) {
          domain = `enrollment.${env}.ufbdirect.com`;
        }
        const redirectTo = `https://${domain}?auth=true`;
        window.location.href = redirectTo;
      }
    })
  );

  @Effect()
  login$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.LOGIN),
    switchMap((action: userActions.LoginAction) => {
      const deviceInfo = this.getDeviceInfo();
      const data: ISignInData = {
        UserName: action.request.email,
        Password: action.request.password,
        DeviceId: deviceInfo.DeviceId,
        DeviceSignature: deviceInfo.DeviceSignature,
        BrandId: environment.brandId,
        IsExisting: action.request.isExisting
      };
      return this.authService.signIn(data).pipe(
        map(token => {
          this.authHandlerService.setToken(token);
          // return new applicationAction.LoadApplicationByTokenAction();
          return new userActions.LoginSuccessAction(
            token,
            data.UserName,
            action.request.isExisting
          );
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err.error ? err.error.errorMessage : "Login Error";
          return of(new userActions.LoginFailAction(error));
        })
      );
    })
  );

  @Effect()
  loginSuccess$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.LOGIN_SUCCESS),
    withLatestFrom(this.store$.select(getIsExisting)),
    map((data) => {
      const action: any = <userActions.LoginSuccessAction>data[0];
      const isExisting = data[1];
      this.stateRouteService.checkForInProgress = false;
      return action.token.challengeInfo
        ? new userActions.RedirectOTPAction(action.token.challengeInfo)
        : isExisting
          ? new applicationAction.CreateApplicationAction(SOURCE_TYPES.existingEnroller)
          : new userActions.GetUserAction();
    })
  );

  @Effect({ dispatch: false })
  redirectOTP$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.REDIRECT_OTP),
    tap(() =>
      this.router.navigateByUrl(`${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.OTP}`)
    )
  );
  @Effect()
  requestMultifactorOTPNOUser$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.REQUEST_MULTIFACTOR_NoUser_OTP),
    withLatestFrom(this.store$.select(getUserPhone)),
    switchMap(data=>{
      const action:userActions.RequestMultifactorNoUserOTPAction=data[0]
      const request : IOtpNoUser={
        PhoneNumber:action.request
      }

      return this.authService.otpNoUser(request).pipe(
        map(response=>{
          this.router.navigateByUrl(`${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.OTP}`);
          const response1:any={
            data:response,
            PhoneNumber:action.request
          }
          SET_CACHE(action.request,PHONE_NUMBER)

          return new userActions.RequestMultifactorNoUserOTPSuccessAction(response1);
        }),
        catchError(e => {
          const otpTimeout = otpTimeoutMessage
          var error = e.error.errorMessage ? e.error.errorMessage : systemError;
          error = error == Validation_TimeOut ? otpTimeout : error;
          return of(new userActions.RequestMultifactorOTPFailAction(error));
        })
      )
    })
  )

    ;

  @Effect()
  requestMultifactorOTP$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.REQUEST_MULTIFACTOR_OTP),
    withLatestFrom(this.store$.select(getOtpChallengeInfo)),
    switchMap(data => {
      const action: userActions.RequestMultifactorOTPAction = data[0];
      const otpChallengeInfo: IOtpChallengeInfo = data[1];

      const request: IChallengeOtpData = {
        challengeToken: otpChallengeInfo.authenticationToken,
        ...action.request,
      };

      return this.authService.requestMultifactorOtp(request).pipe(
        map(response => {
          return new userActions.RequestMultifactorOTPSuccessAction(response);
        }),
        catchError(e => {
          const optTimeout = 'Your validation request has expired. Please attempt to log in again.';
          var error = e.error.errorMessage ? e.error.errorMessage : "System error";
          error = error == 'OTP Validation Request has timed out. Please try login in again.' ? optTimeout : error;
          return of(new userActions.RequestMultifactorOTPFailAction(error));
        })
      );
    })
  );

  @Effect()
  validateOTPCode$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.VALIDATE_OTP_CODE),
    withLatestFrom(
           this.store$.select(getChallengeInfo),
      this.store$.select(getOtpChallengeInfo),
      this.store$.select(getIsExisting)
    ),
    switchMap(data => {
      const action: userActions.ValidateOTPCodeAction = data[0];
      const challengeInfo: IChallengeInfo = data[1];
      const otpChallengeInfo: IOtpChallengeInfo = data[2];
      if (action.request.rememberDevice) {
        client.setDID(challengeInfo.DeviceId);
      }

      delete otpChallengeInfo.otp;

      const request: IValidateOtpData = {
        authenticationToken: otpChallengeInfo.authenticationToken ? otpChallengeInfo.authenticationToken : challengeInfo.challengeToken,
        DeviceId: challengeInfo.DeviceId,
        ...action.request,
        ...challengeInfo
      };

      return this.authService.evaluateOtpCode(request).pipe(
        map((token: IAuthToken) => {
          SET_CACHE(token, TOKEN_STORAGE_NAME);
          if (token.hasSecurityQuestions && token.access_token) {
            return new userActions.GetUserAction();
          } else {
            return new userActions.ValidateOTPCodeSuccessAction(token);
          }
        }),
        catchError(e => {
          const error = e.error.errorMessage ? 'Your validation request has expired. Please attempt to log in again.' : "System error";
          return of(new userActions.ValidateOTPCodeFailAction(error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  validateOTPCodeSuccess$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.VALIDATE_OTP_CODE_SUCCESS),
    tap(() => {
      this.router.navigateByUrl(
        `${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.SECURITYQUESTIONS}`
      );
    }
    )
  );

  @Effect()
  getSecurityQuestions$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.GET_SECURITY_QUESTIONS),
    switchMap((action: userActions.GetSecurityQuestionsAction) => {
      return this.authService
        .getSecurityQuestions(action.resetPasswordToken)
        .pipe(
          switchMap((response: any) => {
            return [
              new userActions.GetUserAction(false),
              new userActions.GetSecurityQuestionsSuccessAction(response)
            ];
          }),
          catchError(e => {
            return of(
              new userActions.GetSecurityQuestionsFailAction(
                "Security Questions error"
              )
            );
          })
        );
    })
  );

  @Effect()
  saveSecurityQuestions$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.SAVE_SECURITY_QUESTIONS),
    switchMap((action: userActions.SaveSecurityQuestionsAction) => {
      return this.authService
        .saveSecurityQuestions(action.questions, action.resetPasswordToken)
        .pipe(
          map((response: any) => {
            return new userActions.SaveSecurityQuestionsSuccessAction(response);
          }),
          catchError(e => {
            return of(
              new userActions.SaveSecurityQuestionsFailAction(e.error.Message)
            );
          })
        );
    })
  );

  @Effect()
  saveSecurityQuestionsSuccess$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.SAVE_SECURITY_QUESTIONS_SUCCESS),
    withLatestFrom(this.store$.select(getIsExisting)),
    map(data => {
      // const isExisting = data[1];
      // return isExisting ?
      //   new applicationAction.CreateApplicationAction(SOURCE_TYPES.existingEnroller) :
      //userActions.GetUserAction() will trigger loadApplicationByToken$ that enpoint will validate
      //if the current user has a not completed application and if he does, we will create a new one
      return new userActions.GetUserAction();
    })
  );

  @Effect()
  resetTasksStates$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.RESET_TASKS_STATES),
    switchMap((action: userActions.ResetTasksStatesAction) => {
      return this.authService.resetExistingCustomerTaskStates().pipe(
        map(response => {
          return new userActions.ResetTasksStatesSuccessAction();
        }),
        catchError((e: any) => {
          return of(
            new userActions.ResetTasksStatesFailAction(
              "Fail Reset tasks states"
            )
          );
        })
      );
    })
  );

  @Effect()
  validateExistingCustomer$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.VALIDATE_EXISTING_CUSTOMER),
    switchMap(() => {
      return this.authService.validateExistingCustomer().pipe(
        map(result => {
          const user: IExistingUser = { brandId: result.data };
          return new userActions.ValidateExistingCustomerSuccessAction(user);
        }),
        catchError(() => {
          const user: IExistingUser = { brandId: undefined };
          return of(new userActions.ValidateExistingCustomerFailAction(user));
        })
      );
    })
  );

  @Effect()
  getEnrollmentUrl$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.GET_ENROLLMENT_URL),
    switchMap((action: userActions.GetEnrollmentUrlAction) => {
      return this.brandService.getEnrollmentUrl(action.brandId).pipe(
        map((response: string) => {
          const user: IExistingUser = { enrollmentUrl: response };
          return new userActions.GetEnrollmentUrlSuccessAction(user);
        }),
        catchError(() => {
          return of(new userActions.GetEnrollmentUrlFailAction());
        })
      );
    })
  );

  @Effect()
  forgotPassword$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.FORGOT_PASSWORD),
    switchMap((action: userActions.ForgotPasswordAction) => {
      return this.userService.forgotPassword(action.username).pipe(
        map(response => {
          return new userActions.ForgotPasswordSuccessAction(action.resend);
        }),
        catchError((e: any) => {
          return of(
            new userActions.ForgotPasswordFailAction("Forgot Password Failed")
          );
        })
      );
    })
  );

  @Effect()
  forgotUsername$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.FORGOT_USERNAME),
    switchMap((action: userActions.ForgotUsernameAction) => {
      return this.userService.forgotUserName(action.email).pipe(
        map(response => {
          return new userActions.ForgotUsernameSuccessAction(action.resend);
        }),
        catchError((e: any) => {
          return of(
            new userActions.ForgotUsernameFailAction("Forgot Username Failed")
          );
        })
      );
    })
  );

  @Effect()
  validateResetPassword$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.VALIDATE_RESET_PASSWORD),
    switchMap((action: userActions.ValidateResetPasswordAction) => {
      return this.authService.validateResetPasswordLink(action.idToken, action.usernameToken).pipe(
        switchMap((response: any) => {
          const isUserTriggeredReset = response.userName != null;
          return isUserTriggeredReset ? [
            new userActions.ValidateResetPasswordSuccessAction(response),
            new userActions.GetRandomSecurityQuestionAction(
              response.userIdToken
            ),
            new UpdateBannerAction({ title: "Security Question" })
          ] : [
            new userActions.ValidateResetPasswordSuccessAction(response),
            new UpdateBannerAction({ title: "Enter New Password" })
          ];
        }),
        catchError((e: any) => {
          const error = e.error
            ? e.error.ErrorMessage
              ? e.error.ErrorMessage
              : e.error.Message
            : "Validate Reset Password failed";
          return of(new userActions.ValidateResetPasswordFailAction(error));
        })
      );
    })
  );

  @Effect()
  getRandomSecurityQuestion$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.GET_RANDOM_SECURITY_QUESTION),
    switchMap((action: userActions.GetRandomSecurityQuestionAction) => {
      return this.authService.getRandomSecurityQuestion(action.token).pipe(
        map((response: any) => {
          return new userActions.GetRandomSecurityQuestionSuccessAction(
            response
          );
        }),
        catchError((response: any) => {
          const error = response.error
            ? response.error.stepMessage
              ? response.error.stepMessage
              : "There was an unexpected error trying to obtain your security question."
            : "Get Security Question Failed";
          return of(new userActions.GetRandomSecurityQuestionFailAction(error));
        })
      );
    })
  );

  @Effect()
  verifySecurityQuestion$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.VERIFY_SECURITY_QUESTION_ANSWER),
    withLatestFrom(this.store$.select(getUserName)),
    switchMap(data => {
      const action: any = data[0];
      const userName = data[1];
      return this.authService
        .verifySecurityQuestionAnswer(userName, action.questions)
        .pipe(
          switchMap(response => {
            return [
              new userActions.VerifySecurityQuestionAnswerSuccessAction(
                response
              ),
              new UpdateBannerAction({ title: "Enter New Password" })
            ];
          }),
          catchError(e => {
            const error = e.error
              ? e.error.errorMessage
              : "Verify Security question fail";
            return of(
              new userActions.VerifySecurityQuestionAnswerFailAction(error)
            );
          })
        );
    })
  );

  @Effect()
  changePassword$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.CHANGE_PASSWORD),
    withLatestFrom(
      this.store$.select(getUserName)
    ),
    switchMap(data => {
      const action: any = data[0];
      const model = {
        Password: action.password
      };

      return this.authService.changePassword(model, action.unlockTocken, action.usernameToken).pipe(
        switchMap(response => {
          const action: any = data[0];
          const userName: any = data[1];
          const request: ISignInRequest = {
            password: action.password,
            email: userName,
            registrationDate: null,
            isExisting: false
          };
          return [
            new userActions.ChangePasswordSuccessAction(),
            new userActions.LoginAction(request)
          ];
        }),
        catchError(e => {
          const error = e.error
            ? e.error.errorMessage
              ? e.error.errorMessage
              : e.error.message
            : "Change Password fail";
          return of(new userActions.ChangePasswordFailAction(error));
        })
      );
    })
  );


  @Effect()
  checkValidGuid$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.CHECK_GUID_VALID),
    switchMap((action: userActions.CheckGuidValidAction) => {
      return this.authService.checkGuidvalid(action.guid).pipe(
        map((response: any) => {
          var isExisting = response.data ? true : false;
          return new userActions.CheckGuidValidSuccessAction(isExisting)
        }),
        catchError(e => {
          const error =
            e.error ?
              e.error.guid
                ? e.error.guid
                : 'Check Valid Guid Failed'
              : 'Check Valid Guid Failed'
          return of(new userActions.CheckGuidValidFailAction(error))
        })
      )
    })
  );

  @Effect()
  checkAppValidity$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.CHECK_APP_VALID),
    switchMap((action: userActions.CheckAppValidityAction) => {
      return this.authService.checkGuidvalid(action.guid).pipe(
        map((response: any) => {
          var isExisting = response.data ? true : false;
          return new userActions.CheckAppValiditySuccessAction(isExisting)
        }),
        catchError(e => {
          const error =
            e.error ?
              e.error.guid
                ? e.error.guid
                : 'Check Valid App Failed'
              : 'Check Valid App Failed'
          return of(new userActions.CheckAppValidityFailAction(error))
        })
      )
    })
  );

  @Effect({ dispatch: false })
  guidValidFail$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.CHECK_GUID_VALID_FAIL),
    tap(() =>
      this.router.navigateByUrl(
        `${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.LINK_EXPIRED}`
      )
    )
  );

  @Effect({ dispatch: false })
  appValidityFail$ = this.actions$.pipe(
    ofType(userActions.USER_ACTION_TYPES.CHECK_APP_VALID_FAIL),
    tap(() =>
      this.router.navigateByUrl(
        `${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.LINK_EXPIRED}`
      )
    )
  );

  //Check for Existing Customer
  // @Effect()
  // validateUserExist$ = this.actions$.pipe(
  //   ofType(userActions.USER_ACTION_TYPES.VALIDATE_USER_EXIST),
  //   concatMap((action: userActions.ValidateUserExistAction) => {
  //     return this.userService.exists(action.user).pipe(
  //       map((respond) => {
  //         if(respond.UserProspectId) {
  //           action.user.UserProspectId = respond.UserProspectId;
  //         }
  //         return new userActions.ValidateUserExistSuccessAction(action.user, action.applicationOptions, respond.RedirectToLogin, respond.UserState);
  //       }),
  //       catchError((error: HttpErrorResponse) => {
  //         const shouldCreate = error.status === 404;
  //         return of(new userActions.ValidateUserExistFailAction(action.user, action.applicationOptions, shouldCreate, shouldCreate? null : error.error.Message));
  //       })
  //     )
  //   })
  // );

  // @Effect({ dispatch: false })
  // validateUserExistSuccess$ = this.actions$.pipe(
  //   ofType(userActions.USER_ACTION_TYPES.VALIDATE_USER_EXIST_SUCCESS),
  //   tap((action: userActions.ValidateUserExistSuccessAction) => {
  //     if(action.redirectToLogin) {
  //       this.router.navigateByUrl(`${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.LOGIN}`);
  //     } else {
  //       this.store$.dispatch(new userActions.CreateUserAction(action.user));
  //     }
  //   })
  // );

  // @Effect({ dispatch: false })
  // validateUserExistFail$ = this.actions$.pipe(
  //   ofType(userActions.USER_ACTION_TYPES.VALIDATE_USER_EXIST_FAIL),
  //   tap((action: userActions.ValidateUserExistFailAction) => {
  //     if(action.shouldCreate) {
  //       this.store$.dispatch(new userActions.CreateUserAction(action.user));
  //     }
  //   })
  // );

  private getDeviceInfo(): IClientDeviceInfo {
    client.processDNA();
    const deviceInfo: IClientDeviceInfo = {
      DeviceSignature: client.getDNA().replace(/\s/g, ''),
      DeviceId: client.getDID()
    };
    return deviceInfo;
  }
}
