import { Injectable, Inject } from '@angular/core';
import { BaseService } from './abstracts';
import { HttpClient } from '@angular/common/http';
// RxJs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoadingService } from 'app/core/services';
import { API_URL } from 'app/core/constants';
import { IUserRequest, IUser } from 'models';

@Injectable()
export class RegisterExistingCostumerService extends BaseService {

  constructor(
    http: HttpClient,
    @Inject(API_URL) apiURL: string,
    loadingService: LoadingService) {
    super(http, loadingService, apiURL);
  }

  get(cif, brandName) {
    return this._get(`v2/Customer/Existing/Info/{brandName}/{cif}`);
  }

  getCif() {
    return this._get('v2/Customer/Existing/Cif');
  }

  verify(ssn, model, brandName) {
    var body = {
      phoneNumberLastDigits: model.phoneNumberLastDigits,
      accountNumber: model.accountNumber,
      balance: model.balance,
      brand: brandName
    }
    return this._get(`v2/customer/existing/verify/{ssn.replace(/-/g, '')}`, body);
  }

  create(brandId, productVesting) {
    return this._post(`v2/Customer/Existing/Add/${brandId}`, productVesting);
  }

  createProxy(customerInformation) {
    return this._post('v2/Customer/Existing/Add', customerInformation);
  }

  getCustomerInformation(customerInformation) {
    return this._post('ExistingCustomer/GetCustomerInformation', customerInformation);
  }

  registerCustomerInIdv1(customerInformation: IUserRequest): Observable<IUser> {
    return this._post<IUser>('v2/Customer/Existing/Register', customerInformation).pipe(
      map((userLookup: any) => {
        const user: IUser = {
          ApplicationId: null,
          ApplicationStatusId: null,
          BrandId: null,
          AdvisorId: null,
          VestingTypeId: null,
          UserName: userLookup.FirstName,
          FirstName: userLookup.FirstName,
          LastName: userLookup.LastName,
          Email: userLookup.Email,
          PhoneNumber: userLookup.HomePhone,
          CIF: userLookup.CIF,
        }
        return user;
      })
    );
  }
}
