declare var environment: any;

import { Resolve } from '@angular/router';

import { Store } from '@ngrx/store';
import { EnrollmentState, GetProductsByOrganizationAction } from 'store';

import { ISimpleProduct } from 'models';

import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { getOrganizationProducts } from 'store/selectors';

export class OrganizationProductsResolver implements Resolve<Observable<ISimpleProduct[]>> {
    constructor(private store: Store<EnrollmentState>) {  }

    resolve(){
        return this.store.select(getOrganizationProducts).pipe(
            tap(products => {
                if(!products || products.length === 0){
                    this.store.dispatch(new GetProductsByOrganizationAction(environment.applicationTypeName));
                }
                return products
            }),
            filter(products => !!products),
            first()
        );
    }
}