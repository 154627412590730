import { Directive, ElementRef, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UkBaseFormControl, UkFieldError, UkUserAgentDetectorService } from '@uikit/components';
import { Mask, MASK_SECTION_VALIDATOR } from '../components/abstracts/mask.component';

export const INVALID_ITIN_ERROR: UkFieldError = {
    name: 'ssn',
    message() { return 'The Trust Tax Number must be ##-#######.' }
};

export const ITIN_SEPARATOR = '-';
export const ITIN_MASK_SECTIONS = [2, 7];
export const ITIN_VALIDATOR = MASK_SECTION_VALIDATOR(ITIN_MASK_SECTIONS, ITIN_SEPARATOR, true);

@Directive({
  selector: 'input[itin]',
  host: { 'class': 'uk-input' },
  providers: [
    { provide: UkBaseFormControl, useExisting: ItinDirective }
  ]
})

export class ItinDirective extends Mask { 
  constructor(
    @Optional() @Self() ngControl: NgControl, 
    elementRef: ElementRef, 
    userAgentDetectorService: UkUserAgentDetectorService
  ) {
    super(elementRef, ngControl, 'input-ssn', userAgentDetectorService);
    this.userAgentDetectorService = userAgentDetectorService;
    this.type = 'password';
    this.separator = ITIN_SEPARATOR;
    this.maskSections = ITIN_MASK_SECTIONS;
    this.setPlaceHolder();
    //VALIDATOR
    this.validationError = INVALID_ITIN_ERROR;
    this.validator = ITIN_VALIDATOR;
   }

  format(value: string) {
    return super.format(value).replace(this.mask, `$1${this.separator}$2`);
  }
}
