import { Address, Applicant, RecommendedAddress } from 'models';
import { ADDRESS_TYPE, ADDRESS_TYPE_NAME } from 'models/enums';
import { ContactInformation } from 'models/proxy';

export const EMPTY_ADDRESS: Address = {
  AddressId: 0,
  applicantId: '',
  addressType: ADDRESS_TYPE_NAME.Current,
  street: '',
  street2: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  zipPlus4: ''
}

export function IS_SAME_ADDRESS(address1: Address, address2: Address) {
  return address1.street === address2.street &&
    address1.street2 === address2.street2 &&
    address1.city === address2.city &&
    address1.state === address2.state &&
    address1.country === address2.country &&
    address1.zip === address2.zip;
}

export function GET_ADDRESS_BY_TYPE(applicant: Applicant, addressType: ADDRESS_TYPE_NAME) {
  if (applicant) {
    const address = applicant.addresses.find(address => address.addressType === addressType);
    return address ? address : { ...EMPTY_ADDRESS };
  }
  return { ...EMPTY_ADDRESS };
}

export function SET_ADDRESS_BY_TYPE(addressInfoes: Address[], newAddress: Address, AddressTypeId: ADDRESS_TYPE_NAME) {
  return [
    ...addressInfoes.filter(address => address.addressType !== AddressTypeId),
    { ...EMPTY_ADDRESS, ...newAddress, addressType: AddressTypeId }
  ];
}
export function GET_RECOMMEND_ADDRESS_BY_TYPE(applicant: any) {
  if (applicant) {
    const address = applicant.recommend;
    return address ? address : { ...EMPTY_ADDRESS };
  }
  return { ...EMPTY_ADDRESS };
}
export function SET_RECOMMEND_ADDRESS_BY_TYPE(addressInfoes: RecommendedAddress[], newAddress: RecommendedAddress) {

  return newAddress;
}

export function zipLeadingZeros(number: Number) {
  if(number['length'] > 5){
    return number;
  }else{
    return ("0000000" + number).slice(-5);
  }
  
}

export function GET_TRUSTED_CONTACT_ADDRESS_BY_TYPE(trustedContact: ContactInformation) {
  if (trustedContact) {
    const address = trustedContact.addresses[0];
    return address ? address : { ...EMPTY_ADDRESS };
  }
  return { ...EMPTY_ADDRESS };
}
