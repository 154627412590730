import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, withLatestFrom, map } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { LoadApplicantAction } from 'store/actions';
import { getApplicantsMetadata, getApplicationId, getBusinessApplicant } from 'store/selectors';
// Models
import { Applicant } from 'models';
import { APPLICANT_TYPE } from 'models/enums';

@Injectable()
export class ApplicantBusinessResolver implements Resolve<Observable<Applicant>> {

  constructor(private store$: Store<EnrollmentState>) { }

  resolve() {
    return this.store$.select(getBusinessApplicant).pipe(
      withLatestFrom(this.store$.select(getApplicationId), this.store$.select(getApplicantsMetadata)),
      map(data => {
          const applicant = data[0];
          const applicationId = data[1];
          const applicantsMetadata = data[2];
          const mApplicant = applicantsMetadata.find(applicant => applicant.ApplicantType.ApplicantTypeId === APPLICANT_TYPE.Business);
          if(!applicant) {
                this.store$.dispatch(new LoadApplicantAction(mApplicant.applicantId));
          }
          return applicant;
      }),
      filter(applicant => !!applicant)
    );
  }
}
