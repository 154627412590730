import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NgRx
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getApplicationId, getCurrentApplicant } from 'store/selectors';
// DateFns
import {
  parse,
  addYears,
  addMonths,
  differenceInYears,
} from 'date-fns';
// RxJs
import { Observable } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import { LoadingService } from 'app/core/services';
import { AxosResult } from 'models/enums/trading';
import { BaseService } from 'services/abstracts';
import { GET_CACHE, SET_CACHE } from 'app/core/utils';
import {
  RiskQuestion,
  GoalsKeyValue,
  PortfolioRiskAnswer,
  ManagePortfolioStorageModel,
  SaveAnswerModel,
} from 'models';
import { API_URL } from 'app/core/constants';
import { INVESTMENT_GOALS_ENUM, NAVIGATION_DIRECTION } from 'models/enums';

@Injectable({
  providedIn: 'root'
})
export class ManagedPortfolioService extends BaseService {
  goalsData: any[];
  riskQuestions: RiskQuestion[];
  riskAnswers: PortfolioRiskAnswer[];
  investmentGoalsInformation: any;
  datePipe = new DatePipe('en-US');
  managePortfolioInformation: ManagePortfolioStorageModel = {
    investment_goals: {
      selectedGoal: '',
      selections: []
    },
    investment_experience: ''
  };

  questionsDirection: NAVIGATION_DIRECTION = NAVIGATION_DIRECTION.Back;
  applicantData$ = this.store$.select(getCurrentApplicant)
    .pipe(
      withLatestFrom(this.store$.select(getApplicationId)),
      map(([{ person }, appId]) => ({
        appId,
        age: person.age,
        DOB: person.birthDate,
        annual_income: 10000, // TODO: Check where is located
      }),
    ));

  constructor(
    private store$: Store<EnrollmentState>,
    http: HttpClient,
    @Inject(API_URL) apiURL: string,
    loadingService: LoadingService) {
    super(http, loadingService, apiURL);
    this.managePortfolioInformation = this.getCached('manage_portolfio_storage') || {};
  }

  GetGoals(birthDate: Date, income: number): Observable<AxosResult<any[]>> {
    const date = this.datePipe.transform(birthDate, 'yyyy-MM-dd');
    income = (income === undefined) ? 0 : income;
    return this._get<AxosResult<any[]>>(`managedPortfolio/goals?birthDate=${date}&income=${income}`).pipe(
      tap(result => this.goalsData = result.data)
    );
  }

  setMainGoal(key: string, value: string){
    this.managePortfolioInformation = {
      ...this.managePortfolioInformation,
      investment_goals :{
        ...this.managePortfolioInformation.investment_goals,
        selectedGoal: value
      }
    }
    this.setLocal({ ...this.managePortfolioInformation });
  }

  resetGoalFields(): void {
    let { investment_goals } = this.managePortfolioInformation;
    investment_goals = {
      ...investment_goals,
      selections: []
    };
    this.managePortfolioInformation.investment_goals = investment_goals;
    this.setLocal({ ...this.managePortfolioInformation });
  }

  setGoalField(key: string, value: string) {
    let { investment_goals } = this.managePortfolioInformation;
    const selections = investment_goals.selections || [];
    investment_goals = {
        ...investment_goals,
        selections: [
            ...selections
                .filter(item => item.key !== key),
            { key, value, fieldType: '' },
        ],
    };
    this.managePortfolioInformation.investment_goals = investment_goals;
    this.setLocal({ ...this.managePortfolioInformation });
  }

  formGoalsObject() {
    const { investment_goals } = this.managePortfolioInformation;
    let obj: any = {};
    if(investment_goals && investment_goals.selections) {
      investment_goals.selections
        .forEach(({ key, value }) =>
          obj = { ...obj, [key]: value });
    }
    return obj;
  }

  getGoalField(key: string): GoalsKeyValue {
    const { investment_goals } = this.managePortfolioInformation;
    if(!investment_goals.selections) {
      return;
    }
    return investment_goals.selections.find(f => f.key === key);
  }

  buildGoals(DOB?: Date): { targetDate: Date, targetAmount: number } {
    const goal = this.managePortfolioInformation.investment_goals.selectedGoal;
    let targetDate = new Date();
    let targetAmount = 0;
    const {
      target_date,
      target_amount,
      months_to_goal,
      retirement_age,
      spend_per_year,
    } = this.formGoalsObject();
    if(target_amount) {
      // TODO: Have a more elegant way to transform to number
      targetAmount = +target_amount.replace(/,/g, '');
    }
    switch(goal) {
      case INVESTMENT_GOALS_ENUM.Retirement:
        const targetAge = +retirement_age;
        const age = differenceInYears(new Date(), DOB);
        targetDate = addYears(DOB, targetAge);
        targetAmount = +spend_per_year.replace(/,/g, '') * (targetAge - age);
        break;
      case INVESTMENT_GOALS_ENUM.BuildWealth:
        targetDate = addYears(targetDate, 10);
        targetAmount = 12500;
        break;
      case INVESTMENT_GOALS_ENUM.RainyDay:
        targetDate = addMonths(targetDate, +months_to_goal);
        break;
      case INVESTMENT_GOALS_ENUM.Custom:
        targetDate = parse(target_date);
        break;
    }
    return { targetAmount, targetDate };
  }

  setLocal(information: ManagePortfolioStorageModel){
    SET_CACHE(information, 'manage_portolfio_storage', true);
  }

  GetStorage(name: string){
    return GET_CACHE(name);
  }

  saveGoals(applicationId: string){
    return this._post<AxosResult<any>>(`managedPortfolio/goals/${applicationId}`, {
      ...this.managePortfolioInformation.investment_goals,
      available_milestones: this.goalsData.map(x => x.dto)
    });
  }

  getRiskQuestions(experienceLevel?: string) {
    const level = experienceLevel || this.managePortfolioInformation.investment_experience;
    return this._get<AxosResult<RiskQuestion[]>>(`managedPortfolio/questions/${level}`).pipe(map(({ data }) => {
      this.riskQuestions = data;
      return data;
    }));
  }

  getRiskAnswers(applicationId: string) {
    return this._get(`managedPortfolio/answers/${applicationId}`).pipe(map(({ data }) => {
      this.riskAnswers = data;
      return data;
    }));
  }

  saveRiskScore(applicationId: string, recommended: string, selected: string) {
    return this._post(`managedPortfolio/riskScore/${applicationId}`, {
      riskScore: selected,
      recommendedRiskScore: recommended,
    });
  }

  saveAnswers(applicationId: string, saveAnswersInformation: SaveAnswerModel) {
    return this._post(`managedPortfolio/answers/${applicationId}`, saveAnswersInformation).pipe(tap(() => this.riskAnswers = saveAnswersInformation.answers));
  }

  getInvestmentGoals(applicationId: string){
    return this._get(`managedPortfolio/${applicationId}`).pipe(map(({data}) => {
      this.investmentGoalsInformation = data ? data : [];
      if(this.managePortfolioInformation == undefined || this.managePortfolioInformation.investment_experience == undefined){
      this.managePortfolioInformation = {
        investment_goals: {
          selectedGoal: data ? data["selectedGoal"] : null,
          selections: data ? data["selections"] : null
        },
        investment_experience:  data && data.productValue ? data.productValue.investing_level : null
      };
      this.setLocal(this.managePortfolioInformation);
    }
      return data ? data : [];
    }))
  }
}
