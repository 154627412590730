export enum IDENTIFICATION_DOCUMENT_TYPE {
  DriversLicense = '1',
  StateID = '8',
  Selfie = '9',
  IDBarCode = '10'
}

export enum IDENTIFICATION_DOCUMENT_TYPE_NAME {
  DriversLicense = "Driver's License",
  StateID = "State ID",
  Selfie = 'Selfie'
}
