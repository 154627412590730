import {
  createSelector,
  createFeatureSelector
} from '@ngrx/store';

import { BannerState } from '../states';
import { FEATURE_NAMES } from '../constants';

export const getRouterState = createFeatureSelector(FEATURE_NAMES.Router);
export const getBannerState = createFeatureSelector(FEATURE_NAMES.Banner);

export const getPageTitle = createSelector(
  getBannerState, (state: BannerState) => state.pageTitle
);

export const getBanner = createSelector(
  getBannerState, (state: BannerState) => state.banner
);

export const getSubBanner = createSelector(
  getBannerState, (state: BannerState) => state.subBanner
);

export const getProgress = createSelector(
  getBannerState, (state: BannerState) => state.progress
);

export const getHeaderIsMain = createSelector(
  getBanner, banner => banner.isMain
);

export const getBannerIsExisting = createSelector(
  getBanner, banner => banner.isExisting
);

