// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetECMDisclosuresAction } from 'store/actions';
// Model
import { ECMDisclosure } from 'models';
import { getECMDisclosures } from 'store/selectors/disclosure.selectors';
import { getApplicationLoaded } from 'store/selectors';

@Injectable()
export class ECMDisclosuresResolver implements Resolve<Observable<ECMDisclosure[]>> {

    constructor(private store$: Store<EnrollmentState>) { }

    resolve() {
        return this.store$.select(getApplicationLoaded).pipe(
            filter(loaded => loaded),
            switchMap(x => this.store$.select(getECMDisclosures)),
            map(disclosures => {
                if (!disclosures) {
                    this.store$.dispatch(new GetECMDisclosuresAction());
                }
                return disclosures;
            }),
            filter(disclosures => !!disclosures),
            first()
        );
    }
}
