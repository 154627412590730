import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { Country, IInvestmentObjetive, IGenericItem, Applicant, IApplicantRequest, IdentificationInfo, PhoneNumber, PersonalInformationTrading, ApplicantRequest, PersonalInformationResponse, IApplication, IAuthToken } from 'models';
import {
  getCountries,
  selectInvestmentObjetives,
  getFinancialCatalogInformation,
  getOccupations
} from 'store/selectors';

// RxJs
import { map, take } from 'rxjs/operators';
import { of } from 'rxjs';
//Services
import { BaseService } from 'services/abstracts';
import { HttpClient } from '@angular/common/http';
//Core
import { LoadingService } from 'app/core/services';
import { API_URL } from 'app/core/constants';
import { ID_VERIFICATION_METHOD } from 'models/enums/id-verification-method.enum';
import { APPLICANT_IDENTIFICATION_STORAGE_NAME } from 'app/applicant-identification/models';
import { PHONE_TYPE, APPLICATION_STATE } from 'models/enums';
import { CatalogItem } from 'models/trading/catalog-item.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInformationService extends BaseService {
  countries: Country[];
  investmentObjectives: IInvestmentObjetive[];
  annualIncomes: CatalogItem[];
  totalNetWorths: CatalogItem[];
  liquidNetWorths: CatalogItem[];
  sourceOfFunds: CatalogItem[];
  taxBrackets: CatalogItem[];
  occupations: CatalogItem[];

  constructor(
    private store$: Store<EnrollmentState>,
    http: HttpClient,
    loadingService: LoadingService,
    @Inject(API_URL) baseUrl: string) {
    super(http, loadingService, baseUrl);
    this.store$.select(getCountries).pipe(take(1)).subscribe(items => this.countries = items);
    this.store$.select(getOccupations).pipe(take(1)).subscribe(occ => this.occupations = occ);
    this.store$.select(getFinancialCatalogInformation).pipe(take(1)).subscribe(information => {
      this.annualIncomes = information.annualIncomes;
      this.totalNetWorths = information.totalNetWorths;
      this.sourceOfFunds = information.sourceOfFunds;
      this.liquidNetWorths = information.liquidNetWorths;
      this.taxBrackets = information.taxBrackets;
    });

    this.store$.select(selectInvestmentObjetives).pipe(take(1)).subscribe(items => this.investmentObjectives = items);
  }

  getCountryName(code: string) {
    const country = this.countries.find(country => country.code === code);
    return country ? country.displayName  : "United States of America"
  }

  getAnnualIncome(id: string) {
    return this.annualIncomes.find(item => item.name === id).displayName;
  }

  getTotalNetWorth(id: string) {
    return this.totalNetWorths.find(item => item.name === id).displayName;
  }

  getLiquidNetWorth(id: string) {
    return this.liquidNetWorths.find(item => item.name === id).displayName;
  }

  getSourceOfFunds(id: string) {
    return this.sourceOfFunds.find(item => item.name === id).displayName;
  }

  getTaxBrackets(id: string) {
    return this.taxBrackets.find(item => item.name === id).displayName;
  }

  getInvestmentObjective(id: string) {
    const objective = this.investmentObjectives.find(objective => objective.name === id);
    return objective ? objective.displayName : '';
  }

  getOccupation(id: string) {
    return this.occupations.find(occ => (occ.name === id || occ.displayName === id)).displayName;
  }

  // #region New HTTP end-points

  getApplicant(token: IAuthToken) {
    return this._get<Applicant>(`GetApplicant/${token}`)
      .pipe(
        map(applicant => Object.assign(new Applicant(), applicant))
      )
  }

  getApplication(applicationId: string) {
    return this._get<IApplication>(`application/${applicationId}`);
  }

  // getApplicantById(applicantId: number){
  //   return this._get<Applicant>(`GetApplicant/${token}`)
  //     .pipe(
  //       map(applicant => Object.assign(new Applicant(), applicant))
  //     )
  // }


  // getApplicant(applicantId: number) {
  //   return this._get<Applicant>(`consumer/personal/applicant/${applicantId}`)
  //     .pipe(
  //       map(applicant => Object.assign(new Applicant(), applicant))
  //     )
  // }

  // getAllApplicants() {
  //   return this._get<Applicant[]>('consumer/personal/applicant')
  //     .pipe(
  //       map(applicants => applicants.map(applicant => Object.assign(new Applicant(), applicant)))
  //     );
  // }

  // updateApplicant(applicant: IApplicantRequest) {
  //   return this._post<IApplicantRequest>('consumer/personal/applicant',
  //     {
  //       ...applicant,
  //       SSN: this.cleanData(applicant.SSN)
  //     });
  // }

  updateApplicantIdentification(applicantId: number, identification: IdentificationInfo) {
    return this._post<IdentificationInfo>(`consumer/personal/pip/${applicantId}/identification`, identification);
  }

  updateApplicantPhone(applicantId: number, phone: PhoneNumber) {
    phone = {
      ...phone,
      PhoneNumber: this.cleanValue(phone.PhoneNumber)
    }
    return this._post<PhoneNumber>(`consumer/personal/pip/${applicantId}/phone`, phone);
  }

  saveApplicantPIUniversal(PersonalInformationUniversal: PersonalInformationTrading) {
    return of(PersonalInformationUniversal)
  }

  public getApplicantIdentificationMethod(applicantId: number): ID_VERIFICATION_METHOD {
    const allApplicantIdSessions: any = this.getCached(APPLICANT_IDENTIFICATION_STORAGE_NAME),
      applicantIdSession: any = allApplicantIdSessions.find(id => id.ApplicantId === applicantId);

    let verificationMethod: ID_VERIFICATION_METHOD = ID_VERIFICATION_METHOD.Manual;
    if (applicantIdSession) {
      verificationMethod = (applicantIdSession.VerificationMethod) ? applicantIdSession.VerificationMethod : ID_VERIFICATION_METHOD.Manual;
    }
    return verificationMethod;
  }

  private ToModel(application: any, applicants: Applicant[], newApplicationState: APPLICATION_STATE) {
    if (!application.Beneficiaries) {
      application.Beneficiaries = [];
    }
    const beneficiariesModel = application.Beneficiaries.map(beneficiary => {
      return {
        Ordinal: beneficiary.Ordinal,
        ApplicationId: beneficiary.ApplicantId,
        FirstName: beneficiary.FirstName,
        MiddleName: beneficiary.MiddleName,
        LastName: beneficiary.LastName,
        DOB: beneficiary.DOB,
        RelationshipId: beneficiary.RelationshipId,
        RelationshipName: beneficiary.RelationshipName,
        RelationshipOther: beneficiary.RelationshipOther,
      };
    });

    const applicantsModel: ApplicantRequest[] = applicants.map(applicant => {
      const ApplicantId = applicant.ApplicantId;
      const applicantPhoneNumbers: PhoneNumber[] = [
        {
          ApplicantId,
          PhoneTypeId: PHONE_TYPE.Business,
          PhoneNumber: this.cleanData(applicant.BusinessPhone),
          PhoneExtension: this.cleanData(applicant.BusinessPhoneExtension)
        },
        { PhoneTypeId: PHONE_TYPE.Home, ApplicantId, PhoneNumber: this.cleanData(applicant.HomePhone) },
        { PhoneTypeId: PHONE_TYPE.Cell, ApplicantId, PhoneNumber: this.cleanData(applicant.CellPhone) },
      ];

      return <ApplicantRequest>{
        ApplicantId: applicant.ApplicantId,
        ApplicationId: applicant.ApplicationId,
        ApplicantTypeId: applicant.ApplicantType.ApplicantTypeId,
        FirstName: applicant.FirstName,
        MiddleName: applicant.MiddleName,
        LastName: applicant.LastName,
        EmailPrimary: applicant.EmailPrimary,
        EmailSecondary: applicant.EmailSecondary,
        SuffixId: applicant.SuffixId,
        DOB: applicant.DOB,
        SSN: this.cleanData(applicant.SSN),
        ConfirmSSN: this.cleanData(applicant.ConfirmSSN),
        CitizenshipTypeId: applicant.CitizenshipTypeId,
        ContactMethodId: applicant.ContactMethodId,
        ApplicantPhoneNumbers: applicantPhoneNumbers,
        IdentificationTypeId: applicant.IdentificationInfo.IdentificationTypeId,
        DLNum: (applicant.IdentificationInfo.DLNum || '').replace(/[^0-9A-Za-z*]/g, ''),
        DLState: applicant.IdentificationInfo.DLState,
        DLIssued: applicant.IdentificationInfo.DLIssued,
        DLExpire: applicant.IdentificationInfo.DLExpire,
        IdentificationAddressId: null,
        EmploymentTypeId: applicant.EmploymentTypeId,
        MaidenName: applicant.MaidenName,
        PIN: applicant.PIN,
        SecretWordHint: applicant.SecretWordHint,
        EmployerName: applicant.EmployerName,
        Occupation: applicant.Occupation,
        TrustName: applicant.TrustName,
        Description: applicant.Description,
        TaxpayerIDType: applicant.TaxpayerIDType,
        MinSales: applicant.MinSales,
        MaxSales: applicant.MaxSales,
        OwnershipPercentage: applicant.OwnershipPercentage,
        IncorporationState: applicant.IncorporationState,
        Website: applicant.Website,
      };
    });


    const model = {
      Application: {
        ApplicationId: application.ApplicationId,
        ReferralSourceId: application.ReferralSourceId,
        ReferredBy: application.ReferredBy,
        PromoCode: application.PromoCode,
        UserProfileId: application.UserProfileId,
        IdentityUserId: application.IdentityUserId,
        Beneficiaries: beneficiariesModel,
        Accounts: application.Accounts
      },
      Applicants: applicantsModel,
      NewApplicationState: newApplicationState,
      // SkipValidation: (application.Action || enums.actionTypes.save) === enums.actionTypes.save ? false : true
      SkipValidation: true
    };
    return model;
  }

  private cleanData(value: string): string {
    return value ? value.replace(/-|\)|\(/g, '') : null;
  }

  // #region HTTPp
  get(applicationId: number) {
    return this._get<PersonalInformationResponse>(`v2/pip/${applicationId}`).pipe(
      map(data => {
        return {
          ...data,
          Applicants: data.Applicants.map(applicant => {
            if (!applicant.Description) { applicant.Description = ''; }
            const names = applicant.FirstName ? applicant.FirstName.split(/ |,/) : [];
            if (names.length > 1 && !applicant.MiddleName) {
              applicant.FirstName = names[0];
              applicant.MiddleName = names[names.length - 1][0];
            }
            return applicant;
          })
        };
      })
    );
  }

  // #endregion

  // TODO: Posible risk since js don't have applicants (Review and Submit and PIP pages)
  update(application: IApplication, applicants: Applicant[], newApplicationState: APPLICATION_STATE) {
    const model = this.ToModel(application, applicants, newApplicationState);
    return this._put('v2/pip', model);
  }

  // canApplicantChangeSecurityWord(applicantId) {
  //   return this._get<boolean>(`Applicant/${applicantId}/SecurityWordChanged`);
  // }

  // canApplicantChangeMaidenName(applicationId) {
  //   return this._get(`Applicant/${applicationId}/MaidenNameChanged`);
  // }
  // #endregion
}
