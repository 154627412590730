declare var MitekMobileWeb: any;
// Ng
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
// RxJs
import { of } from 'rxjs';
import { concatAll, filter, first, map, tap } from 'rxjs/operators';
// Services
import { LazyLoadingService, SCRIPT_LOAD_TYPE } from '../services/lazy-loading.service';

@Injectable()
export class MitekScriptsGuard implements CanActivate {

    constructor(private lazyScripting: LazyLoadingService) {  }

    canActivate() {
      return this.lazyScripting.loadScript('vendor/mitek/mitek-bundle-min.js', SCRIPT_LOAD_TYPE.Defer).pipe(filter(loaded => loaded), first());
      /*
      const scripts$ = of(
        this.lazyScripting.loadScript('vendor/mitek/MitekAPIUtils.js').pipe(filter(loaded => loaded), first()),
        this.lazyScripting.loadScript('vendor/mitek/MitekCaptureParams.js').pipe(filter(loaded => loaded), first()),
        this.lazyScripting.loadScript('vendor/mitek/cv.js').pipe(filter(loaded => loaded), first()),
        this.lazyScripting.loadScript('vendor/mitek/jsfeat-min.js').pipe(filter(loaded => loaded), first()),
        this.lazyScripting.loadScript('vendor/mitek/piexif.js').pipe(filter(loaded => loaded), first()),
        this.lazyScripting.loadScript('vendor/mitek/MitekMobileWebAPI.js').pipe(filter(loaded => loaded), first()))
      );
      let scriptLoaded = 0;

      return scripts$.pipe(
        concatAll(),
        map(() => ++scriptLoaded >= 6),
        filter(loaded => loaded),
        tap(() => console.log('All scripts loaded', MitekMobileWeb.mitekWorker)),
        first()
      );
      */
    }
}
