declare var environment: any;

import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getHeaderIsMain, getBrandIsUFB, getMarketingSelectedProducts } from 'store/selectors';
// Services
import { LoadingService } from 'app/core/services';

@Component({
  selector: 'app-consumer-mobile-layout',
  templateUrl: './consumer-mobile-layout.component.html'
})
export class ConsumerMobileLayoutComponent {

  isMain$ = this.store$.select(getHeaderIsMain);
  loading$ = this.loadingService.loading$;
  isUFB$ = this.store$.select(getBrandIsUFB);
  showChatBot$ = this.store$
  .select(getMarketingSelectedProducts)
  .pipe(
    map(data => {
      if (!data || data.length == 0) {
        return false;
      }

      let validProduct = data.find((p: any) => p.productCode === 'MPO' || p.productCode === 'TRAD');
      let isValidInstance = !environment.applicationTypeName.startsWith('whitelabel');

      return !!validProduct && isValidInstance;
    })
  );

  constructor(
    private store$: Store<EnrollmentState>,
    private loadingService: LoadingService,) { }
}
