import { LayoutState } from '../states';
import * as fromActions from '../actions/layout.action';

export const initialState: LayoutState = {
     isMobile: false
}

export function reducer(
     state = initialState,
     action: fromActions.LayoutAction
): LayoutState{
     switch(action.type){
          case  fromActions.LAYOUT_ACTION_TYPES.SET_MOBILE_LAYOUT: {
               return { ...state };
          }
          case fromActions.LAYOUT_ACTION_TYPES.SET_MOBILE_LAYOUT_SUCCESS: {
               return { ...state, isMobile: action.isMobile }
          }
     }
     return state;
}
