declare var environment: any;
// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetChatSettingsAction } from 'store/actions';
import { getChatSettings } from 'store/selectors';
// Model
import { ChatSettings } from 'models';

@Injectable()
export class ChatResolver implements Resolve<Observable<ChatSettings>> {

  constructor(private store$: Store<EnrollmentState>) {  }

  resolve() {
    return this.store$.select(getChatSettings).pipe(
      tap(settings => {
        if(!settings) {
          this.store$.dispatch(new GetChatSettingsAction(environment.brandName));
        }
      }),
      filter(settings => !!settings),
      first()
    );
  }
}
