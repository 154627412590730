
import { Injectable } from '@angular/core';
// NgRx
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EnrollmentState } from '../states';
import { getApplicationId, getApplicationStates, getApplication, getIsManagedPortfolio } from '../selectors';
import { fetchWorkflow, setWorkflow, setWorkflowStep, moveBack, selectWorkflowInitialized } from './workflow.store';
// RxJs
import { switchMap, map, tap, withLatestFrom, filter, first } from 'rxjs/operators';
// Models & Enums
import { FLOW_STEPS, APPLICATION_DECISION_STATUS, APPLICATION_STATE, APPLICANT_TYPE_TO_NAME, APPLICANT_TYPE_NAME } from 'models/enums';
// Services
import { ApplicationFlowService } from 'services/http/application-flow.service';
import { FlowNavigationService } from 'app/core/services/flow-navigation.service';
import { RULES_ACTION_TYPES, APPLICATION_ACTION_TYPES } from '../actions';

@Injectable()
export class EnrollmentWorkflowEffects {

  appId$ = this.store$.select(getApplicationId);
  applicationStates$ = this.store$.select(getApplicationStates);
  isManagePortfolio$ = this.store$.select(getIsManagedPortfolio);
  initialized$ = this.store$.select(selectWorkflowInitialized);
  currentApplicantType$ = this.store$.select(getApplication)
    .pipe(map(app => APPLICANT_TYPE_TO_NAME[app.applicantTypeId] || APPLICANT_TYPE_NAME.Primary));

  constructor(private actions$: Actions,
    private store$: Store<EnrollmentState>,
    private flowService: FlowNavigationService,
    private service: ApplicationFlowService) { }

  // TODO: Get State and decision
  stepNotComplete(
    step: FLOW_STEPS,
    state: APPLICATION_STATE,
    decision: APPLICATION_DECISION_STATUS) {
    if(step !== FLOW_STEPS.Complete) {
      return true;
    }
    this.flowService.navigateToState(state, decision);
    return false;
  }

  fetchWorkflow$ = createEffect(() => this.actions$.pipe(
    ofType(fetchWorkflow),
    withLatestFrom(this.appId$, this.currentApplicantType$),
    switchMap(([{ applicationId }, appId, applicantType]) =>
      this.service.getFlow(applicationId || appId, applicantType)),
    map(workflow =>
      setWorkflow(workflow)),
  ));

  setWorkflow$ = createEffect(() => this.actions$.pipe(
    ofType(setWorkflow),
    withLatestFrom(this.applicationStates$, this.appId$, this.currentApplicantType$, this.initialized$,this.isManagePortfolio$),
    tap(([{ steps, lastStep, nextStep }, { stateId, decisionStatus }, appId, applicantType, initialized, isMP]) => {
      let currentStep = initialized ? nextStep : lastStep;
      if(this.flowService.hasSubFlow(steps, lastStep)) {
        currentStep = lastStep;
      }
      if(currentStep === FLOW_STEPS.Complete) {
        this.flowService.isMP = isMP;
        this.flowService.navigateToState(stateId, decisionStatus);
        return;
      }
      this.flowService.initialize(steps, appId, applicantType, currentStep, stateId, decisionStatus);
    }),
  ), { dispatch: false });

  setStep$ = createEffect(() => this.actions$.pipe(
    ofType(setWorkflowStep),
    withLatestFrom(this.applicationStates$, this.appId$, this.currentApplicantType$),
    filter(([{ step, lastStep }, { stateId, decisionStatus }]) =>
      // TODO:
      this.stepNotComplete(step, stateId, decisionStatus)),
    tap(([{ step, lastStep }, states, appId, applicantType]) =>
      this.flowService.setState(step, appId, applicantType)),
  ), { dispatch: false });

  stateRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(
      RULES_ACTION_TYPES.VALIDATE_APPLICATION_SUCCESS,
      APPLICATION_ACTION_TYPES.CORRECT_ROUTE),
    withLatestFrom(this.applicationStates$, this.isManagePortfolio$),
    tap(([action, { stateId, decisionStatus }, isMP]) => {
      this.flowService.isMP = isMP;
      //TODO comment navigation
      /*if(action['type'] === RULES_ACTION_TYPES.VALIDATE_APPLICATION_SUCCESS && action['state']) {
        this.flowService.navigateToState(stateId, decisionStatus);
        return;
      }*/
      this.flowService.navigateToState(stateId, decisionStatus);
    }),
  ), { dispatch: false });

  moveBack$ = createEffect(() => this.actions$.pipe(
    ofType(moveBack),
    withLatestFrom(this.appId$, this.currentApplicantType$),
    tap(([_, appId, applicantType]) => {
      this.flowService.moveBack();
      this.service.goBack(appId, applicantType)
        .pipe(first())
        .subscribe();
    })
  ),{ dispatch: false });
}
