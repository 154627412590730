import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FEATURE_FLAG } from "models/enums";
import { FEATURE_NAMES } from "store/constants";
import { FeatureFlagsState } from "store/states/feature-flags.state";

export const getFeatureFlagsState = createFeatureSelector(FEATURE_NAMES.FeatureFlags);

export const getFeatureFlags = createSelector(
    getFeatureFlagsState, (state: FeatureFlagsState) => state ? state.featureFlags : null
);

export const getIdDetailsFeatureFlag = createSelector(
    getFeatureFlagsState, (state: FeatureFlagsState) => state ? state.featureFlags.find(x => x.name == FEATURE_FLAG.IdDetails) : null
);
