import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { withLatestFrom, first, map } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetTrustCertificateInformationAction } from 'store/actions';
import { getTrustCertificateInformation, getApplicationId, getApplicationVestingTypeName } from 'store/selectors';

import { TrustCertificate } from 'models';
import { VESTING_TYPE_NAME } from 'models/enums';

@Injectable()
export class TrustCertificateResolver implements Resolve<Observable<TrustCertificate>> {

  constructor(private store$: Store<EnrollmentState>) { }

  resolve() {
      return this.store$.select(getTrustCertificateInformation).pipe(
      withLatestFrom(
        this.store$.select(getApplicationId),
        this.store$.select(getApplicationVestingTypeName)
      ),
      map(data => {
        const trustCertificate: TrustCertificate = data[0],
            applicationId = data[1],
            vestingTypeName = data[2];
        if(vestingTypeName === VESTING_TYPE_NAME.Trust && applicationId) {
          this.store$.dispatch(new GetTrustCertificateInformationAction(applicationId))
        }
        return trustCertificate;
      }),
      map(trustCertificate => trustCertificate),
      first()
    );
  }
}
