export interface ChatBotSettings {
    BrandName: string;
    BaseUrl :string;
    ChannelEndpoint:string;
    Component: string;
    VerifyToken: string;
    WidgetChatBotUrl:string;
  }
  
  export class ChatbotBrandColorProperties {
    chatbotPrimary: string;
    chatbotSecondary: string;
    chatbotAccent: string;
  }
  
  export class ChatbotBrandColors {
    axos: ChatbotBrandColorProperties;
    nationwide: ChatbotBrandColorProperties;
    ufb: ChatbotBrandColorProperties;
  }
  
  export const brandColorConfigurations: ChatbotBrandColors = {
    axos: {
      chatbotPrimary: '#ffffff',
      chatbotSecondary: '#1e3860',
      chatbotAccent: '#faa74a',
    },
    nationwide: {
      chatbotPrimary: '#ffffff',
      chatbotSecondary: '#007d8a',
      chatbotAccent: '#faa74a',
    },
    ufb: {
      chatbotPrimary: '#ffffff',
      chatbotSecondary: '#323339',
      chatbotAccent: '#d67b50',
    },
  };