import { Injectable, Inject } from "@angular/core";
import { Observable, Subject, interval, of } from "rxjs";
import { switchMap, map, tap, takeWhile, catchError } from "rxjs/operators";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AxosResult } from "models/enums/trading";
import { API_URL } from "app/core/constants";
//Services
import { BaseService } from "../abstracts/base.service";
import { LoadingService } from "app/core/services/loading.service";
import { Router } from "@angular/router";
import { LANDING_ROUTES } from "app/landing/routing";
import { EnrollmentState, BoardInProgressComplete, BoardApplicationFailAction } from "store";
import { Store } from "@ngrx/store";
import { BOARDING_RESULT } from "store/constants";
import { OUTCOME_PARENT_ROUTE, OUTCOME_ROUTES } from 'app/outcome';

@Injectable({
  providedIn: 'root'
})
export class BoardingSignalService extends BaseService {
  private _boardingResult$: Subject<any> = new Subject();
  private _connectionId$: Subject<string> = new Subject();
  private retries: number = 0;
  loginToken: any;

  get connectionId$() {
    return this._connectionId$.asObservable();
  }

  get boardingResult$() {
    return this._boardingResult$.asObservable();
  }

  constructor(
    http: HttpClient,
    loadingService: LoadingService,
    @Inject(API_URL) baseUrl: string,
    private router: Router,
    private store$: Store<EnrollmentState>
  ) {
    super(http, loadingService, baseUrl);
  }

  public listenToBoarding() {

    const MAX_NUMBER_OF_RETRIES = 15;

    const poolingSubscription$ = interval(15000).pipe(
      switchMap(() => {
        return this._get<AxosResult<any>>('application/board/ping', null, false)
          .pipe(
            map(res => res.data),
            tap(state => {
              this.retries++;
              if (state == "approved" || state == "not_approved") {
                return state;
              }

              if (this.retries >= MAX_NUMBER_OF_RETRIES) {
                console.warn(`Application reached MAX_NUMBER_OF_RETRIES ${MAX_NUMBER_OF_RETRIES}`);
                this.router.navigateByUrl(`${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.APPROVED_FAILURE}`);
                state = "not_approved";
              }
              return state;
            })
          )
      }
      ));

    poolingSubscription$.pipe(takeWhile(reducer => reducer != "approved" && reducer != "not_approved", true)).subscribe((data) => {
      if (data == "approved") {
        this.store$.dispatch(new BoardInProgressComplete(BOARDING_RESULT.COMPLETED))
      }

      if (data == "not_approved") {
        this.router.navigateByUrl(`${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.APPROVED_FAILURE}`);
      }
    });
  }

  board(): Observable<any> {
    return this._post<AxosResult<any>>('application/board', { connectionId: "NA" }, false)
      .pipe(
        map(res => res.data),
        tap(status => {
          if (status === 'in_progress') {
            this.listenToBoarding();
          }
          if (status === 'completed') {
            return of(BOARDING_RESULT.COMPLETED)
          }
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error ? error.error.errorMessage : "Board Application Error";
          return of(BOARDING_RESULT.BOARDING_ERROR);
        })
      )
  }
}
