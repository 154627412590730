import { ID_VERIFICATION_METHOD, IMAGE_TYPE } from '../enums';
import { IdVerificationIdentifierDto } from './id-verification-identifier-dto.model';
  
  export class  IdVerificationLoggingDto extends IdVerificationIdentifierDto {
    Message: string;
    DocumentType?: IMAGE_TYPE;
    Errors?: any;
  
    constructor(message: string, verificationMethod: ID_VERIFICATION_METHOD, dmdToken?: string, applicantId?: number, documentType? : IMAGE_TYPE, errors?: any) {
        super(verificationMethod, dmdToken, applicantId);
        this.Message = message;
        this.DocumentType = documentType;
        this.Errors = errors;
    }
}
  
  