import { Action } from '@ngrx/store';
// Models
import { Brand, ChatSettings } from 'models';
import { ChatBotSettings } from 'models/brand/chatbot-settings.model';

export enum BRAND_ACTION_TYPES {
    GET_BRAND = '[Brand] Get',
    GET_BRAND_SUCCESS = '[Brand] Get success',
    GET_BRAND_FAIL = '[Brand] Get fail',
    GET_CHAT_SETTINGS = '[Brand] Get Chat Settings',
    GET_CHAT_SETTINGS_SUCCESS = '[Brand] Get Chat Settings success',
    GET_CHAT_SETTINGS_FAIL = '[Brand] Get Chat Settings fail',
    GET_CHAT_BOT_SETTINGS = '[Brand] Get ChatBot Settings',
    GET_CHAT_BOT_SETTINGS_SUCCESS = '[Brand] Get ChatBot Settings success',
    GET_CHAT_BOT_SETTINGS_FAIL = '[Brand] Get ChatBot Settings fail',
    GET_BRAND_LOGO = '[Brand] Get brand logo',
    GET_BRAND_LOGO_SUCCESS = '[Brand] Get brand logo success',
    GET_BRAND_LOGO_FAIL = '[Brand] [Brand] Get brand logo fail',
}

export class GetBrandAction implements Action {
  readonly type = BRAND_ACTION_TYPES.GET_BRAND;
  constructor(public brandName: string) { }
}

export class GetBrandSuccessAction implements Action {
  readonly type = BRAND_ACTION_TYPES.GET_BRAND_SUCCESS;
  constructor(public data: Brand) { }
}

export class GetChatSettingsAction implements Action {
  readonly type = BRAND_ACTION_TYPES.GET_CHAT_SETTINGS;
  constructor(public brandName: string) { }
}

export class GetChatSettingsSuccessAction implements Action {
  readonly type = BRAND_ACTION_TYPES.GET_CHAT_SETTINGS_SUCCESS;
  constructor(public data: ChatSettings) { }
}


export class GetChatSettingsFailAction implements Action {
  readonly type = BRAND_ACTION_TYPES.GET_CHAT_SETTINGS_FAIL;
}

export class GetChatBotSettingsAction implements Action {
  readonly type = BRAND_ACTION_TYPES.GET_CHAT_BOT_SETTINGS;
  constructor(public brandId: number) { }
}

export class GetChatBotSettingsSuccessAction implements Action {
  readonly type = BRAND_ACTION_TYPES.GET_CHAT_BOT_SETTINGS_SUCCESS;
  constructor(public data: ChatBotSettings) { }
}

export class GetChatBotSettingsFailAction implements Action {
  readonly type = BRAND_ACTION_TYPES.GET_CHAT_BOT_SETTINGS_FAIL;
}

export class GetBrandLogoAction implements Action {
  readonly type = BRAND_ACTION_TYPES.GET_BRAND_LOGO;
  constructor(public brandName: string) { }
}

export class GetBrandLogoSuccessAction implements Action {
  readonly type = BRAND_ACTION_TYPES.GET_BRAND_LOGO_SUCCESS;
  constructor(public data: string) { }
}

export type BrandAction = GetBrandAction | GetBrandSuccessAction |
  GetChatSettingsAction | GetChatSettingsFailAction | GetChatSettingsSuccessAction |
  GetBrandLogoAction | GetBrandLogoSuccessAction | GetChatBotSettingsAction | 
  GetChatBotSettingsSuccessAction | GetChatBotSettingsFailAction;
