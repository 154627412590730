declare var environment: any;

import { Injectable } from '@angular/core';
import { BaseService } from '../abstracts';
import { of, Observable } from 'rxjs';
import { DisclosuresRequest, DisclosuresSaveRequest, DisclosureList, ApplicationSummary, DetailDisclosure, ECMDisclosure, ECMDisclosureDocument } from 'models';
import { BRAND_CONTEXT } from 'models/enums';
import { BUSINESS_DISCLOSURE_LIST, CONSUMER_DISCLOSURE_LIST } from 'app/disclosures/mocks';

import { map } from "rxjs/operators";
import { AxosResult } from 'models/enums/trading/axos-result.model';

@Injectable({
  providedIn: 'root'
})
export class DisclosuresUniversalService extends BaseService {
  getDetailDisclosures(applicationId: string): Observable<DetailDisclosure[]> {
    return this._get<DetailDisclosure[]>(`disclosure/${applicationId}`).pipe(map((res) => res));
  }

  getAxosClearingDisclosures(): Observable<DetailDisclosure[]> {
    return this._get<any>(`disclosure/axosclearing/organization/${environment.organizationName}`).pipe(
      map((res) => res.data));
  }

  getElectronicDisclosure(): Observable<DetailDisclosure> {
    return this._get<DetailDisclosure>(`disclosure/eDisclosure/${environment.organizationId}`);
  }

  getInitialDisclosures(): Observable<DetailDisclosure[]> {
    return this._get<DetailDisclosure[]>(`disclosure/initialDisclosures/${environment.organizationId}`);
  }

  getApplicationDisclosure(name: string): Observable<DetailDisclosure> {
    return this._get<AxosResult<DetailDisclosure>>(`application/disclosure/${name}/${environment.organizationId}`).pipe(
      map(result => result.data)
    );
  }

  getApplicationApplicantDisclosure(name: string): Observable<DetailDisclosure> {
    return this._get<AxosResult<DetailDisclosure>>(`application/disclosures/${name}/${environment.organizationId}`).pipe(
      map(result => result.data)
    );
  }

  saveDisclosures(appId: string, applicantId: string, summary: ApplicationSummary) {
    return this._post(`application/${appId}/applicant/${applicantId}/summary`, summary);
  }

  getDisclosuresCatalog(): Observable<DetailDisclosure[]> {
    return this._get<DetailDisclosure[]>(`disclosure/detail/${environment.organizationId}`).pipe(map((res) => res));
  }

  updateDisclosure(applicationId: string, disclosure: DetailDisclosure) {
    return this._post(`disclosure/update/${applicationId}`, disclosure);
  }

  saveApplicantDisclosures(applicationId: string, disclosures: DetailDisclosure[]) {
    return this._post(`disclosure/save/${applicationId}`, disclosures);
  }

  postTerms(request: DisclosuresRequest) {
    return this._post('v2/pip/Terms', request);
  }

  confirmDisclosures(applicationId: string) {
    return this._post(`v2/Disclosures/Accept/${applicationId}`);
  }

  save(request: DisclosuresSaveRequest) {
    return this._post('v2/Disclosures', request);
  }

  getECMDisclosures(applicationId: string): Observable<AxosResult<ECMDisclosure[]>> {
    return this._get(`disclosure/ecm/${applicationId}`);
  }

  getECMDisclosureDocument(documentId: string): Observable<AxosResult<ECMDisclosureDocument>> {
    return this._get(`disclosure/ecm/document/${documentId}`);
  }

  getECMDisclosureDocumentByDisclosureId(disclosureId: string): Observable<AxosResult<ECMDisclosureDocument>> {
   return this._get(`disclosure/ecm/disclosure/${disclosureId}`);
  }

  // get(): DisclosureList {
  //   return environment.brandContext === BRAND_CONTEXT.Business ?
  //     BUSINESS_DISCLOSURE_LIST : CONSUMER_DISCLOSURE_LIST;
  // }
}
