import { BrokerState } from "store/states/broker.state";
import { createReducer, on, Action } from "@ngrx/store";
import * as fromActions from '../actions/broker.actions';

export const initialState: BrokerState = {
  broker: null
}

export const brokerReducer = createReducer(
  initialState,
  on(
      fromActions.getBrokerInformation, state => ({ ...state, broker: null })
  ),
  on(
      fromActions.getBrokerInformationSuccess, (state, { broker }) => ({ ...state, broker: broker })
  ),
  on(
      fromActions.getBrokerInformationFail, state => ({ ...state, broker: null })
  )
)

export function reducer(state: BrokerState | undefined, action: Action){
  return brokerReducer(state, action);
}
