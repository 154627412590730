import { ModuleRouteConfig } from 'models/navigation';

export const BUSINESS_PARENT_PATH = 'business';
export const BUSINESS_MODULE_PREFIX = 'BIZ';

export const BUSINESS_ROUTES: { [key: string]: ModuleRouteConfig} = {
    TIN: {
        name: `${BUSINESS_MODULE_PREFIX}_TIN-SELECTION`,
        path: 'tin'
    },
    BASIC_INFO: {
        name: `${BUSINESS_MODULE_PREFIX}_BASIC-INFORMATION`,
        path: 'info'
    },
    CONTACT_INFO: {
        name: `${BUSINESS_MODULE_PREFIX}_CONTACT-INFORMATION`,
        path: 'contact'
    },
    DIFF_NAME_SELECTION: {
        name: `${BUSINESS_MODULE_PREFIX}_DIFF-NAME_SELECTION`,
        path: 'diffname'
    },
    DIFF_NAME_FORM: {
        name: `${BUSINESS_MODULE_PREFIX}_DIFF-NAME-FORM`,
        path: 'name'
    },
    ADDRESS: {
        name: `${BUSINESS_MODULE_PREFIX}_ADDRESS`,
        path: 'address'
    },
    MAILING_SAME_ADDRESS: {
        name: `${BUSINESS_MODULE_PREFIX}_MAILING-ADDRESS-SELECTION`,
        path: 'mailing'
    },
    MAILING_ADDRESS: {
        name: `${BUSINESS_MODULE_PREFIX}_MAILING-ADDRESS`,
        path: 'mailingAddress'
    },
    ANNUAL_SALES: {
        name: `${BUSINESS_MODULE_PREFIX}_ANNUAL-SALES`,
        path: 'annualSales'
    },
    DEPOSIT_AMOUNT: {
        name: `${BUSINESS_MODULE_PREFIX}_DEPOSIT-AMOUNT`,
        path: 'depositAmount'
    },
}