import { ActionReducerMap } from '@ngrx/store';

import { EnrollmentState } from '../states';
// Reducers
import * as fromRouter from '@ngrx/router-store';
import * as fromAuth from './auth.reducer';
import * as fromApplicants from './applicants.reducer';
import * as fromApplication from './application.reducer';
import * as fromBrand from './brand.reducer';
import * as fromBanner from './banner.reducer';
import * as fromCatalogs from './catalogs.reducer';
import * as fromProducts from './products.reducer';
import * as fromLayout from './layout.reducer';
import * as fromTrading from './trading.reducer';
import * as fromDisclosures from './disclosure.reducer';
import * as fromOrganization from './organization.reducer';
import * as fromLanding from './landing.reducer';
import * as fromWorkflows from '../workflows';
import * as fromProxy from './proxy.reducer';
import * as fromBroker from './broker.reducer';
import * as fromWhiteLabel from './white-label.reducer';
import * as fromFeatureFlags from './features.reducer';

export const reducers: ActionReducerMap<EnrollmentState> = {
    applicants: fromApplicants.reducer,
    application: fromApplication.reducer,
    auth: fromAuth.reducer,
    brand: fromBrand.reducer,
    banner: fromBanner.reducer,
    catalogs: fromCatalogs.reducer,
    disclosures: fromDisclosures.reducer,
    products: fromProducts.reducer,
    layout: fromLayout.reducer,
    router: fromRouter.routerReducer,
    trading: fromTrading.reducer,
    organization: fromOrganization.reducer,
    landing: fromLanding.reducer,
    EnrollmentWorkflows: fromWorkflows.reducer,
    proxy: fromProxy.reducer,
    broker: fromBroker.reducer,
    whiteLabel: fromWhiteLabel.reducer,
    featureFlags: fromFeatureFlags.reducer
}
