// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getFinancialInformation } from 'store/actions';
import { selectFinancialInformation, getApplicationLoaded } from 'store/selectors';
import { FinancialInformation } from 'models';

@Injectable()
export class FinancialInformationResolver implements Resolve<Observable<FinancialInformation>> {

  constructor(private store$: Store<EnrollmentState>) { }

  resolve() {
    return this.store$.select(getApplicationLoaded).pipe(
      filter(loaded => loaded),
      switchMap(() => this.store$.select(selectFinancialInformation)),
      map((information) => {
        if (!information) {
          this.store$.dispatch(getFinancialInformation());
        }
        return information
      }),
      filter(information => !!information),
      first()
    );
  }
}
