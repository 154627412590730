import { Injectable } from "@angular/core";
// Services
import { BaseService } from "./abstracts";
import { Website } from "models/website.model";

@Injectable()
export class WebsiteService extends BaseService {
    getWebsite(websiteName: string) {
        return this._get<Website>(`Website/${websiteName}`);
    }
}