import { ProxyState } from "store/states/proxy.state";
import { createReducer, on, Action } from "@ngrx/store";
import * as fromActions from '../actions/proxy.actions';

export const initialState: ProxyState = {
    customerSelected: null,
    customers: [],
    trustedContact: null,
    repCodes: [],
    selectedRepCode: null, 
}

export const proxyReducer = createReducer(
    initialState,
    on(
        fromActions.setCustomerSuccess, (state, { customer }) => ({ ...state, customerSelected: customer })
    ),
    on(
        fromActions.clearCustomer, state => ({ ...state, customerSelected: null })
    ),
    on(
        fromActions.searchCustomersSuccess, (state, { customers }) => ({ ...state, customers: customers })
    ),
    on(
        fromActions.getCustomersSuccess, (state, { customers }) => ({ ...state, customers: customers })
    ),
    on(
        fromActions.searchCustomersFail,
        fromActions.getCustomersFail,
        fromActions.clearCustomers,
        state => ({ ...state, customers: [] })
    ),
    on(
        fromActions.saveContactSucces, (state, { contact }) => ({ ...state, trustedContact: contact })
    ),
    on(
        fromActions.getRepCodesSuccess, (state, { repCodes }) => ({ ...state, repCodes: repCodes })
    ),
    on(
        fromActions.getRepCodesFail, state => ({ ...state, repCodes: [] })
    ),
    on(
        fromActions.setRepCode, (state, { repCode }) => ({ ...state, selectedRepCode: repCode })
    ),
    on(
        fromActions.clearProxyState, () => ({ ...initialState })
    ),
    on(
        fromActions.setProxyDeclinedTrustedSelection, 
        (state, { declinedTrustedFlag }) => ({...state, declinedTrustedContact: declinedTrustedFlag, trustedContact: declinedTrustedFlag ? null : state.trustedContact})
    ),
)

export function reducer(state: ProxyState | undefined, action: Action){
    return proxyReducer(state, action);
}