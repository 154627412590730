import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FEATURE_NAMES } from "store/constants";
import { ProxyState } from "store/states/proxy.state";


export const getProxyState = createFeatureSelector(FEATURE_NAMES.Proxy);

export const getSelectedCustomer = createSelector(
    getProxyState,
    (state: ProxyState) => state.customerSelected
);

export const customers = createSelector(
    getProxyState,
    (state: ProxyState) => state.customers
);

export const repCodes = createSelector(
    getProxyState,
    (state: ProxyState) => state.repCodes
);

export const selectedRepCode = createSelector(
    getProxyState,
    (state: ProxyState) => state.selectedRepCode
);

export const selectTrustedContactProxy = createSelector(
    getProxyState,
    (state: ProxyState) => state.trustedContact ? state.trustedContact : null
);

export const selectProxyDeclinedTrustedDecision = createSelector(
    getProxyState,
    (state: ProxyState) => state.declinedTrustedContact
);