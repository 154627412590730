export * from './user.resolver';
export * from './application.resolver';
export * from './accounts.resolver';
export * from './vesting-types.resolver';
export * from './selected-products.resolver';
export * from './security-questions.resolver';
// Brand
export * from './brand.resolver';
export * from './chat-settings.resolver';
// Applicants
export * from './applicant.resolver';
export * from './applicants.resolver';
export * from './applicant-primary.resolver';
export * from './applicant-business.resolver';
export * from './address.resolver';
// Catalogs
export * from './detail-disclosure.resolver';
export * from './annual-sales.resolver';
export * from './country.resolver';
export * from './company-title.resolver';
export * from './financial-information-catalog.resolver';
export * from './affiliation.resolver';
export * from './occupation.resolver';
export * from './state.resolver';
export * from './investment-objetive.resolver';
export * from './axos-clearing-disclosures.resolver';
export * from './electronic-disclosure.resolver';
export * from './tax-disclosure.resolver';
export * from './disclosures-catalog.resolver';
export * from './ecm-disclosures.resolver';
// Rules
export * from './ida-questions.resolver';
export * from './pending-verification-applicants.resolver';

//Trading
export * from './account-features.resolver';
export * from './investment-profile.resolver.';
export * from './personal-information.resolver';
export * from './financial-information.resolver';

//Beneficiaries
export * from './relationships.resolver';
export * from './beneficiaries-information.resolver';
export * from './beneficiaries-contingent-info.resolver';

//Organization
export * from './organization.resolver';
export * from './broker.resolver';

//Trust certificate
export * from './trust-certificate.resolver';
export * from './trust-types.resolver';

//Managed Portfolio
export * from './portfolio-question-answer.resolver';
export * from './investment-goals.resolver';

//Product
export * from './marketing-product.resolver';
export * from './organization-products.resolver';

//ChatBot
export * from './chat-bot-settings.resolvers';

//WL
export * from './trusted-contact.resolver';

//Feature Flags
export * from './feature-flags.resolver';
