// NgRx
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { moveBack } from 'store/workflows';
// RxJs
import { Observable } from 'rxjs';
// Components
import { BaseFormComponent } from './base-form.component';

export abstract class BaseFlowFormComponent<T> extends BaseFormComponent<T> {
  constructor(protected store$: Store<EnrollmentState>, protected value$?: Observable<T>) {
    super(value$);
  }

  goBack() {
    this.store$.dispatch(moveBack());
   }
}
