import {
    createSelector,
    createFeatureSelector
} from '@ngrx/store';

import { Brand } from 'models';

import { BrandState } from '../states';
import { FEATURE_NAMES } from '../constants';
import { BRAND_NAME } from 'models/enums';

export const getBrandState = createFeatureSelector(FEATURE_NAMES.Brand);

export const getBrand = createSelector(
    getBrandState, (state: BrandState) => state.brand
);

export const getChatSettings = createSelector(
  getBrandState, (state: BrandState) => state.chatSettings
);

export const getChatBotSettings = createSelector(
  getBrandState, (state: BrandState) => state.chatBotSettings
);

export const getBrandLoaded = createSelector(
  getBrandState, (state: BrandState) => state.loaded
);

export const getBrandLoading = createSelector(
    getBrandState, (state: BrandState) => state.loading
);

export const getForceDmd = createSelector(
    getBrand, (brand: Brand) => brand ? brand.forceDmd : false
);

export const getDmdEnabled = createSelector(
  getBrand, (brand: Brand) => brand ? brand.dmdEnabled : true
);

export const getBrandName = createSelector(
    getBrand, (brand: Brand) => brand ? brand.name : ''
);

export const getBrandId = createSelector(
    getBrand, (brand: Brand) => brand ? brand.id : null
);

export const getBrandIsUFB = createSelector(
  getBrandName, (brandId: string) => brandId ?
    brandId === BRAND_NAME.UFBDirect :
    false
);

export const getBrandIsAxos = createSelector(
  getBrandName, (brandId: string) => brandId ?
    brandId === BRAND_NAME.Axos || brandId === BRAND_NAME.AxosBusinessBanking || brandId === BRAND_NAME.AxosSecondChance :
    false
);

export const getBrandIsNationwide = createSelector(
  getBrandName, (brandId: string) => brandId ?
    brandId === BRAND_NAME.NationWide || brandId === BRAND_NAME.NationWideBusinessBanking :
    false
);

export const getBrandPhone = createSelector(
  getBrand, (brand: Brand) => brand ? brand.phone : ''
);

export const getBrandEmail = createSelector(
  getBrand, (brand: Brand) => brand ? brand.email : ''
);

export const getBrandLogo = createSelector(
  getBrand, (brand: Brand) => brand ? brand.logo : ''
);
