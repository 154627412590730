import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import * as tradingActions from '../actions/trading.action';
import { switchMap, withLatestFrom, map, catchError, take, tap } from "rxjs/operators";
import { TradingService } from "services/trading.service";
import { of } from "rxjs";
import { InvestmentProfileService, IdentityVerificationService, DisclosuresUniversalService, AuditLogService } from "services";
import { getApplicationId, getUserApplicationId } from "store/selectors";
import { Store } from "@ngrx/store";
import { EnrollmentState } from "store";
import { APPLICATION_STATE } from "models/enums";
import * as applicationActions from '../actions/application.action';
import { ValidateApplicationAction, LoadPersonalInformationSuccessAction, GetDetailDisclosuresAction } from "store/actions";
import { IInvestmentObjetive, IInvestmentProfile } from "models";
import { GET_VALUES_FROM_CATALOG } from "app/core/utils";
import { HttpErrorResponse } from "@angular/common/http";

declare var environment: any;

@Injectable()
export class TradingEffects {
    constructor(
        private actions$: Actions,
        private store$: Store<EnrollmentState>,
        private identityVerificationService: IdentityVerificationService,
        private tradingService: TradingService,
        private disclosuresService: DisclosuresUniversalService,
        private investmentProfileService: InvestmentProfileService,
        private logsService: AuditLogService) { }

    setAccountFeature$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.setAccountFeature),
            withLatestFrom(
                this.store$.select(getApplicationId)
            ),
            switchMap(data => {
                const action = data[0];
                const applicationId = data[1];
                const featureList: string[] = action.feature.TradingFeatures.map(feature => feature.name);
                return this.tradingService.saveAccountFeature(featureList, applicationId).pipe(
                    map(feature => {
                        if (!environment.isProxy) {
                            this.store$.dispatch(new GetDetailDisclosuresAction());
                        }
                        return tradingActions.setAccountFeatureSuccess({ feature: action.feature })
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const errorMessage = error.error ? error.error.Message : "Save Account Features Error";
                        return of(tradingActions.setAccountFeatureFail(errorMessage))
                    })
                )
            })
        )
    );
    
    setAccountFeatureFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.setAccountFeatureFail),
            tap(() => {
                return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
            }),
        ),
        { dispatch: false }
    );

    saveFinancialInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.saveFinancialInformation),
            switchMap(data => {
                return this.tradingService.saveFinancialInformation(data.information, data.applicationId, data.skipHistory).pipe(
                    map(() => tradingActions.saveFinancialInformationSuccess(data.information)),
                    catchError((error: HttpErrorResponse) => {
                        const errorMessage = error.error ? error.error.Message : "Save Financial Information Error";
                        return of(tradingActions.saveFinancialInformationFail(errorMessage))
                    })
                )
            })
        )
    );

    saveFinancialInformationFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.saveFinancialInformationFail),
            tap(() => {
                return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
            }),
        ),
        { dispatch: false }
    );

    updateApplicant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.setPersonalInformation),
            switchMap(action => {
                const personalInformation = action.personalInformation;
                return this.tradingService.savePesonalInformation(personalInformation).pipe(map(response => {
                    return tradingActions.setPersonalInformationSuccess(response)
                }))
            })
        )
    );

    saveInvestmentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.saveInvestmentProfile),
            withLatestFrom(
                this.store$.select(getApplicationId)
            ),
            switchMap(data => {
                const action = data[0];
                const applicationId = data[1];

                return this.tradingService.saveInvestmentProfile(action.investmentProfile, applicationId, action.skipHistory).pipe(
                    map((res) => {
                        const investment = res;
                        const investemntAdvanced = investment.tradingExperiences ? investment.tradingExperiences : [];
                        const investmentProfileInformation: IInvestmentProfile = {
                            overallInvestmentExperience: investment.overallInvestmentExperience,
                            investmentObjective: investment.investmentObjective,
                            timeHorizon: investment.timeHorizon,
                            riskTolerance: investment.riskTolerance,
                            dividendInstructions: investment.dividendInstructions,
                            tradingExperiences: investemntAdvanced
                        }
                        return tradingActions.saveInvestmentProfileSuccess(investmentProfileInformation)
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const errorMessage = error.error ? error.error.Message : "Save Investment Profile Error";
                        return of(tradingActions.saveInvestmentProfileFail(errorMessage))
                    })
                )
            })
        )
    );

    saveInvestmentProfileFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.saveInvestmentProfileFail),
            tap(() => {
                return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
            }),
        ),
        { dispatch: false }
    );

    getFinancialInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.getFinancialInformation),
            withLatestFrom(
                this.store$.select(getApplicationId)
            ),
            switchMap((data) => {
                const applicationId = data[1];
                return this.tradingService.getFinancialInformation(applicationId).pipe(
                    map(information =>
                        tradingActions.getFinancialInformationSuccess({ information: GET_VALUES_FROM_CATALOG(information) })
                    ))
            })
        )
    );

    // @Effect()
    // checkTradingDmd$ = this.actions$.pipe(
    //     ofType(tradingActions.TRADING_ACTION_TYPES.SAVE_INVESTMENT_PROFILE_SUCCESS),
    //     withLatestFrom(
    //         this.store$.select(shouldNavigateToDMD)
    //     ),
    //     map(([action, dmdFlow]) => dmdFlow ? new applicationActions.SetApplicationStateAction(APPLICATION_STATE.IdVerificationMethod):
    //         new applicationActions.SetApplicationStateAction(APPLICATION_STATE.reviewAndSubmit))
    // );

    getInvestmentObjetives$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.getInvestmentObjetives),
            switchMap(() => {
                return this.investmentProfileService.getInvestmentObjetives().pipe(map(objetives => {
                    const investmentObjective: IInvestmentObjetive[] = objetives.investmentObjectives;
                    return tradingActions.getInvestmentObjetivesActionSuccess({ objetives: investmentObjective })
                }
                ))
            })
        )
    );

    getAccountFeature$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.getAccountFeature),
            withLatestFrom(
                this.store$.select(getApplicationId)
            ),
            switchMap((data) => {
                const applicationId = data[1];
                return this.tradingService.getAccountFeature(applicationId).pipe(
                    map(accountFeature => {
                        return tradingActions.getAccountFeatureSuccess({ accountFeature })
                    }
                    ))
            })
        )
    );

    getFeatures$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.getFeatures),
            withLatestFrom(
                this.store$.select(getApplicationId)
            ),
            switchMap((data) => {
                if (environment.isProxy) {
                    /*
                        Due that getFeatures endpoint get application info based on user's claims
                        we call getFeaturesByApplication endpoint just for proxy to solve this issue
                     */
                    const applicationId = data[1];
                    return this.tradingService.getFeaturesByApplication(applicationId).pipe(
                        map(features => tradingActions.getFeaturesSuccess({ features }))
                    );
                }

                return this.tradingService.getFeatures().pipe(
                    map(features => tradingActions.getFeaturesSuccess({ features }))
                );
            })
        )
    );

    getAllPersonalInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.getPersonalInformation),
            tap(action => {
                this.tradingService.getPersonalInformation(action.applicantId).pipe(map(person => {
                    this.store$.dispatch(new LoadPersonalInformationSuccessAction(person, action.applicantId, false));
                })).subscribe();
            })
        ),
        { dispatch: false }
    );

    getInvestmentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.getInvestmentProfile),
            withLatestFrom(
                this.store$.select(getApplicationId)
            ),
            switchMap((data) => {
                const applicationId = data[1];
                return this.tradingService.getInvestmentProfile(applicationId).pipe(
                    map(profile => {
                        profile.tradingExperiences = profile.tradingExperiences.map(e => {
                            e.experience = e.experience.toLocaleLowerCase();
                            return e;
                        });
                        return tradingActions.getInvestmentProfileSuccess({ profile })
                    }
                ))
            })
        )
    );

    skipVerification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.setSkipVerification),
            switchMap(action => {
                return this.identityVerificationService.skipVerification(action.applicantId, action.desicion).pipe(
                    map((desicion) => {
                        if (action.navigate) {
                            this.store$.dispatch(new applicationActions.SetApplicationStateAction(APPLICATION_STATE.ReviewAndSubmit));
                            //Trading new states
                            //this.store$.dispatch(new applicationActions.SetApplicationStateAction(APPLICATION_STATE.ReviewAndSubmit));
                        }

                        return tradingActions.setSkipVerificationSuccess({ desicion })
                    })
                );
            })
        )
    );

    saveDisclosures$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.saveTradingDisclosures),
            switchMap(action => {
                return this.disclosuresService.saveDisclosures(action.appId, action.applicantId, action.summary).pipe(
                    map(() => tradingActions.saveTradingDisclosuresSuccess()),
                    catchError((error: HttpErrorResponse) => {
                        const errorMessage = error.error ? error.error.Message : "Save Disclosures Error";
                        return of(tradingActions.saveTradingDisclosuresFail(errorMessage))
                    })
                );
            })
        )
    );

    saveDisclosuresSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.saveTradingDisclosuresSuccess),
            map(() => new ValidateApplicationAction())
        )
    );

    saveDisclosuresFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.saveTradingDisclosuresFail),
            tap(() => {
                return of(this.store$.dispatch(new applicationActions.RedirectToGeneralErrorPage()))
            }),
        ),
        { dispatch: false }
    );

    logAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tradingActions.logAction),
            tap(action => {
                this.logsService.logAction(action.message, action.applicantType, 6).subscribe();
            })
        ),
        { dispatch: false }
    );
}
