import { Resolve } from '@angular/router';

import { DetailDisclosure } from 'models';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EnrollmentState, GetDisclosuresCatalogAction } from 'store';
import { getDisclosuresCatalog } from 'store/selectors/disclosure.selectors';

export class DisclosuresCatalogResolver implements Resolve<Observable<DetailDisclosure[]>> {
    constructor(private store: Store<EnrollmentState>) { }

    resolve() {
        return this.store.select(getDisclosuresCatalog).pipe(
            tap(disclosures => {
                if (!disclosures) {
                    this.store.dispatch(new GetDisclosuresCatalogAction());
                }
            }),
            filter(disclosures => !!disclosures),
            first()
        )
    }
}