import { APPLICANT_INFORMATION_ROUTES } from './route.constants';
import { APPLICANT_TYPE_NAME, APPLICATION_STATE } from 'models/enums';

const primaryBaseFlow = [
  APPLICATION_STATE.PersonalInformationPrimary,
  APPLICATION_STATE.AddressInformation,
  APPLICATION_STATE.MailingAddressQuestionPrimary,
  APPLICATION_STATE.MailingAddressInfoPrimary,
  APPLICATION_STATE.DobSSNInfoPrimary,
];

const primaryIDFirstBaseFlow = [
  APPLICATION_STATE.PersonalInformationPrimary,
  // Missing State
  // APPLICANT_INFORMATION_ROUTES.HOME_ADDRESS_SAME_AS_ID.name,
  APPLICATION_STATE.HomeAddressInfoPrimary,
  APPLICATION_STATE.MailingAddressQuestionPrimary,
  APPLICATION_STATE.MailingAddressInfoPrimary,
  APPLICATION_STATE.DobSSNInfoPrimary,
  APPLICATION_STATE.IdInformationPrimary,
  APPLICATION_STATE.IdAddressQuestionPrimary,
  APPLICATION_STATE.IdAddressInfoPrimary
];

const primaryTradingFlow = [
  APPLICATION_STATE.PersonalInformation,
  APPLICATION_STATE.AddressInformation,
  APPLICATION_STATE.FinancialInformation,
  APPLICATION_STATE.InvestmentProfile,
  //APPLICATION_STATE.Beneficiaries,
  //APPLICATION_STATE.ContingentBeneficiaries
];

const primaryAdditionalOwnerFlow = [
  APPLICATION_STATE.PersonalInformation,
  APPLICATION_STATE.AddressInformation,
  APPLICATION_STATE.AdditionalOwner,
  APPLICATION_STATE.FinancialInformation,
  APPLICATION_STATE.InvestmentProfile
];

const jointTradingFlow = [
  APPLICATION_STATE.JointPersonalInformation,
  APPLICATION_STATE.AffiliationInformation,
  APPLICATION_STATE.HomeAddressQuestionJoint,
  APPLICATION_STATE.HomeAddressInfoJoint,
  APPLICATION_STATE.FinancialInformation,
  APPLICATION_STATE.InvestmentProfile
];

const jointBaseFlow = [
  APPLICATION_STATE.PersonalInformationJoint,
  APPLICATION_STATE.ContactInformationJoint,
  APPLICATION_STATE.HomeAddressQuestionJoint,
  APPLICATION_STATE.HomeAddressInfoJoint,
  APPLICATION_STATE.MailingAddressQuestionJoint,
  APPLICATION_STATE.MailingAddressInfoJoint,
  APPLICATION_STATE.DobSSNInfoJoint
];

const jointIDFirstBaseFlow = [
  APPLICATION_STATE.PersonalInformationJoint,
  APPLICATION_STATE.ContactInformationJoint,
  // Missing State
  // APPLICANT_INFORMATION_ROUTES.HOME_ADDRESS_SAME_AS_ID.name,
  APPLICATION_STATE.HomeAddressInfoJoint,
  APPLICATION_STATE.MailingAddressQuestionJoint,
  APPLICATION_STATE.MailingAddressInfoJoint,
  APPLICATION_STATE.DobSSNInfoJoint,
  APPLICATION_STATE.IdInfoJoint,
  APPLICATION_STATE.IdAddressQuestionJoint,
  APPLICATION_STATE.IdAddressInfoJoint
];

export const APPLICANT_INFORMATION_FLOWS = {
  [APPLICANT_TYPE_NAME.Primary]: {
    EXISTING: [APPLICATION_STATE.PersonalInformationPrimary],
    MANUAL: [
      // Missing State
      // APPLICANT_INFORMATION_ROUTES.ENTRY_METHOD.name,
      ...primaryBaseFlow,
      APPLICATION_STATE.IdTypeSelectionManualPrimary,
      APPLICATION_STATE.IdInformationPrimary,
      APPLICATION_STATE.IdAddressQuestionPrimary,
      APPLICATION_STATE.IdAddressInfoPrimary
    ],
    MITEK: [
      // Missing State
      // APPLICANT_INFORMATION_ROUTES.ENTRY_METHOD.name,
      APPLICATION_STATE.IdTypeSelectionManualPrimary,
      // Missing State
      // APPLICANT_INFORMATION_ROUTES.MITEK.name,
      ...primaryIDFirstBaseFlow,
      APPLICATION_STATE.IdInformationPrimary,
      APPLICATION_STATE.IdAddressQuestionPrimary,
      APPLICATION_STATE.IdAddressInfoPrimary
    ],
    ADVISOR_OR_DMD_MANUAL: [
      ...primaryBaseFlow,
      APPLICATION_STATE.IdTypeSelectionManualPrimary,
      APPLICATION_STATE.IdInformationPrimary,
      APPLICATION_STATE.IdAddressQuestionPrimary,
      APPLICATION_STATE.IdAddressInfoPrimary
    ],
    DMD: [...primaryIDFirstBaseFlow],
    BUSINESS: [
      APPLICATION_STATE.IdTypeSelectionManualPrimary,
      // Missing state ??
      // APPLICANT_INFORMATION_ROUTES.ID_UPLOAD.name,
      // APPLICANT_INFORMATION_ROUTES.ID_UPLOAD_PASSPORT.name,
      APPLICATION_STATE.IdInformationPrimary,
      APPLICATION_STATE.IdAddressQuestionPrimary,
      APPLICATION_STATE.IdAddressInfoPrimary,
      ...primaryBaseFlow
    ],
    TRADING: [
      ...primaryTradingFlow
    ],
    ADDITIONAL_OWNER: [
      ...primaryAdditionalOwnerFlow
    ]
  },
  [APPLICANT_TYPE_NAME.Joint]: {
    MANUAL: [
      // Missing State
      // APPLICANT_INFORMATION_ROUTES.ENTRY_METHOD,
      ...jointBaseFlow,
      APPLICATION_STATE.IdTypeSelectionManualJoint,
      APPLICATION_STATE.IdInfoJoint,
      APPLICATION_STATE.IdAddressQuestionJoint,
      APPLICATION_STATE.IdAddressInfoJoint
    ],
    MITEK: [
      // Missing State
      // APPLICANT_INFORMATION_ROUTES.ENTRY_METHOD,
      APPLICATION_STATE.IdTypeSelectionManualJoint,
      // Missing State
      // APPLICANT_INFORMATION_ROUTES.MITEK.name,
      ...jointIDFirstBaseFlow,
      APPLICATION_STATE.IdInfoJoint,
      APPLICATION_STATE.IdAddressQuestionJoint,
      APPLICATION_STATE.IdAddressInfoJoint
    ],
    ADVISOR_OR_DMD_MANUAL: [
      ...jointBaseFlow,
      APPLICATION_STATE.IdTypeSelectionManualJoint,
      APPLICATION_STATE.IdInfoJoint,
      APPLICATION_STATE.IdAddressQuestionJoint,
      APPLICATION_STATE.IdAddressInfoJoint
    ],
    DMD: [...jointIDFirstBaseFlow],
    TRADING: [
      ...jointTradingFlow
    ]
  },
  [APPLICANT_TYPE_NAME.Teen]: [
    APPLICATION_STATE.PersonalInformationTeen,
    APPLICATION_STATE.ContactInformationTeen,
    APPLICATION_STATE.HomeAddressQuestionTeen,
    APPLICATION_STATE.HomeAddressInfoTeen,
    APPLICATION_STATE.MailingAddressQuestionTeen,
    APPLICATION_STATE.MailingAddressInfoTeen,
    APPLICATION_STATE.DobSSNInfoTeen
  ],
  [APPLICANT_TYPE_NAME.Minor]: [
    APPLICATION_STATE.PersonalInformationMinor,
    APPLICATION_STATE.HomeAddressQuestionMinor,
    APPLICATION_STATE.HomeAddressInfoMinor,
    APPLICATION_STATE.DobSSNInfoMinor
  ],
  [APPLICANT_TYPE_NAME.Trust]: [APPLICATION_STATE.TrustInfo]
};
