import { Inject, Injectable } from '@angular/core';

import { BaseService } from 'services/abstracts';
import { SelectedVestingType, VestingType, Applicant, Organization, Brand } from 'models';
import { Observable } from 'rxjs';
import { AxosResult } from 'models/enums/trading/axos-result.model';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'app/core/constants';
import { LoadingService, BrowserTypeService } from 'app/core/services';
import { EnrollmentState } from 'store';
import { Store } from '@ngrx/store';
import { getApplicationId } from 'store/selectors';

declare var environment: any;

@Injectable({
  providedIn: 'root'
})
export class VestingTypeService extends BaseService {

  getVestingTypes(organizationId: string, brandName: string, applicationTypeName: string): Observable<VestingType[]> {
    organizationId = environment.organizationId;
    return this._get<AxosResult<VestingType[]>>(`generic/vestingtype/application/organization/${organizationId}/branch/${brandName}/applicationType/${applicationTypeName}`).pipe(map(response => response.data));
  }

  saveVestingType(selectedVestingType: SelectedVestingType) {
    return this._post<AxosResult<any>>(`vestingType`, selectedVestingType)
      .pipe(
        map(wrappedResponse => wrappedResponse.data)
      );
  }
}
