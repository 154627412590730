// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { switchMap, filter, first } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetAnnualSalesAction } from 'store/actions';
import { getAnnualSales, getAnnualSalesLoaded } from 'store/selectors';
// Model
import { AnnualSales } from 'models';

@Injectable()
export class AnnualSalesResolver implements Resolve<Observable<AnnualSales[]>> {

     constructor(private store: Store<EnrollmentState>) {  }

     resolve() {
          return this.store.select(getAnnualSalesLoaded).pipe(
               switchMap(loaded => {
                    if(!loaded) {
                         this.store.dispatch(new GetAnnualSalesAction());
                    }
                    return this.store.select(getAnnualSales).pipe(
                         filter(countries => countries.length > 0)
                    );
               }),
               first()
          );
     }
}
