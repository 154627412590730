import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// RxJs
import { Observable, BehaviorSubject } from 'rxjs';

export enum SCRIPT_LOAD_TYPE {
  None,
  Async,
  Defer
}

@Injectable()
export class LazyLoadingService {
  private renderer: Renderer2;
  private scripts$: { [url: string]: BehaviorSubject<boolean> } = {};

  constructor(@Inject(DOCUMENT) private document: Document, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadStyles(url: string) {
    const style = this.renderer.createElement('link');
    this.renderer.setAttribute(style, 'rel', 'stylesheet');
    this.renderer.setAttribute(style, 'href', url);
    this.renderer.appendChild(this.document.head, style);
  }

  loadScript(
    url: string,
    loadType: SCRIPT_LOAD_TYPE = SCRIPT_LOAD_TYPE.None,
    attributes?: { name: string, value: string }[]
  ): Observable<boolean> {
    if (this.scripts$[url]) {
      return this.scripts$[url].asObservable();
    }
    this.scripts$[url] = new BehaviorSubject(false);
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'text/javascript');
    if(loadType !==  SCRIPT_LOAD_TYPE.None) {
      this.renderer.setAttribute(script, loadType ===  SCRIPT_LOAD_TYPE.Async ? 'async' : 'defer', '');
    }
    this.renderer.setAttribute(script, 'src', url);
    if (attributes) {
      attributes.forEach(attr => {
        this.renderer.setAttribute(script, attr.name, attr.value);
      });
    }
    this.renderer.listen(script, 'load', () => {
      this.scripts$[url].next(true);
    });
    this.renderer.appendChild(this.document.head, script);
    return this.scripts$[url].asObservable();
  }
}
