// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first, map, withLatestFrom, switchMap, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getPersonalInformation } from 'store/actions';
import { selectPersonalInformation, getCurrentApplicantId, getCurrentApplicant } from 'store/selectors';
import { PersonalInformationTrading, Applicant } from 'models';
import { Person } from 'models/trading/person.model';
import { CountryResolver } from './country.resolver';

@Injectable()
export class PersonalInformationResolver implements Resolve<Observable<Person>> {

     constructor(private store$: Store<EnrollmentState>, private countryResolver: CountryResolver) {  }

     resolve() {
      return this.countryResolver.resolve().pipe(
        withLatestFrom(this.store$.select(getCurrentApplicant)),
        map(data => {
          const zipPlus4 =data[1].person.employment.address['zipPlus4'];
          const zip = data[1].person.employment.address.zip;
          if(zipPlus4 && zipPlus4 !=''){
            if(!zip.includes('-')){
              let combineZip = `${zip}-${zipPlus4}`;
              data[1].person.employment.address.zip = combineZip;
            }
          }
          const applicant = data[1];
          if(!applicant.person){
            const applicantId = applicant.applicantId;
            this.store$.dispatch(getPersonalInformation({applicantId}));
          }
          return applicant.person;
        }),
        filter(person => !!person),
        first()
      );
    }
}
