declare var environment: any;
import { Injectable } from '@angular/core';
// Services
import { BaseService } from './abstracts';
import { ProductSelectionRequest, VestingType } from 'models';

@Injectable()
export class ProductsService extends BaseService {

    get(applicationId: number) {
        return this._get(`v2/applications/productSelection/${applicationId}`);
    }

    post(productSelection: ProductSelectionRequest) {
        return this._post(`v2/applications/productSelection/${productSelection.ApplicationId}`, productSelection);
    }

    UpdateOrderedCards(products) {
        return this._put('v2/applications/UpdateOrderedCards', products);
    }

    getVestings() {
        return this._get<VestingType[]>('v2/vesting');
    }

    saveVesting(productSelection: ProductSelectionRequest) {
        return this._post('v2/vesting', productSelection);
    }

    getByOrganization(applicationTypeName){
        const organization = environment.organizationName || 'axos';
        return this._get<any>(`generic/products/organization/${organization}/applicationType/${applicationTypeName}`);
    }
}
