import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, first, tap } from "rxjs/operators";
import { EnrollmentState, GetTrustTypesAction } from "store";
import { getTrustTypes } from "store/selectors";

@Injectable()
export class TrustTypesResolver implements Resolve<Observable<any>> {

     constructor(private store$: Store<EnrollmentState>) {  }

     resolve() {
      return this.store$.select(getTrustTypes).pipe(        
        tap(trustTypes => {
          if(trustTypes.length === 0){
            this.store$.dispatch(new GetTrustTypesAction());
          }
        }),
        filter(trustTypes => trustTypes.length> 0),
        first()
      );
    }
}