import { IBanner } from 'models';
import { BannerState } from '../states';
import * as fromActions from '../actions/banner.action';
import { GET_BRAND_TITLE } from 'app/core/utils';

const defaultBanner: IBanner = {
  isMain: false,
  showIcon: false,
  showProducts: false,
  subTitle: []
}

export const initialState: BannerState = {
  pageTitle: GET_BRAND_TITLE(),
  isMobile: false,
  banner: {
    title: '',
    headerIcon: '',
    ...defaultBanner
  },
  subBanner: {
    show: false,
    title: '',
    label: ''
  },
  progress: {
    show: false,
    value: 0
  },
  // Applicant Title
  showApplicantTitle: false
}

export function reducer(
  state = initialState,
  action: fromActions.BannerAction
): BannerState {
  switch(action.type) {
    case fromActions.BANNER_ACTION_TYPES.UPDATE_BANNER: {
      return {
        ...state,
        banner: {
          ...state.banner,
          ...defaultBanner,
          ...action.banner
        }
      };
    }
    case fromActions.BANNER_ACTION_TYPES.UPDATE_SUB_BANNER: {
      return {
        ...state,
        subBanner: {
          show: action.show,
          label: action.label,
          title: action.title,
        }
      };
    }
    case fromActions.BANNER_ACTION_TYPES.UPDATE_PAGE_TITLE: {
      return { ...state, pageTitle: action.title };
    }
    case fromActions.BANNER_ACTION_TYPES.UPDATE_PROGRESS: {
      return {
        ...state,
        progress: {
          show: action.show,
          value: action.progress
        }
      };
    }
    case fromActions.BANNER_ACTION_TYPES.SHOW_APPLICANT_TITLE: {
      return {
        ...state,
        showApplicantTitle: action.show
      };
    }
  }
  return state;
}
