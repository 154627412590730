import { Injectable } from "@angular/core";
import { BaseService } from "services";


// TODO: Replace for TS version
declare var client: any;


@Injectable()
export class  OTPService extends BaseService {
    
    deviceInformation:any={};

    initializeDDNA() {
        client.processDNA();
        this.deviceInformation.deviceSignature = client.getDNA().replace(/\s/g, '');
        this.deviceInformation.deviceId = client.getDID();
    }

    getDeviceInfo() {
        return this.deviceInformation;
    }

    updateDeviceInformation(deviceInfo) {        
        this.deviceInformation.advice = deviceInfo.advice;
        this.deviceInformation.deviceId = deviceInfo.deviceId;
        this.deviceInformation.deviceType = deviceInfo.deviceType;
        this.deviceInformation.matchedRule = deviceInfo.matchedRule;
        this.deviceInformation.ruleAnnotation = deviceInfo.ruleAnnotation;
        this.deviceInformation.score = deviceInfo.score;
        this.deviceInformation.transactionId = deviceInfo.transactionId;
    }

    requestMultifactorOtp(request) {        
        return this._post('auth/otp', request);                
    }

    evaluateCode(request) {
        request={...request,...this.deviceInformation};           
        return this._post('auth/otp/validate', request);                       
    }

    setDid(did) {
        client.setDID(did);
    }


}