declare var environment: any;
// Ng
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CanActivate } from '@angular/router';
// RxJs
import { map, filter, switchMap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { SetProductsSelectionAction } from 'store/actions';
import {
  getMarketingSelectedProducts,
  getMarketingProductsValidated
} from 'store/selectors';
// Core
import { GET_URL_ALL_PARAMETERS } from '../utils';
import { ProductTypeCode } from '../enums/product-type.enum';

@Injectable()
export class MarketingProductsGuard implements CanActivate {

  constructor(private store$: Store<EnrollmentState>, @Inject(DOCUMENT) private document: Document) { }

  canActivate() {
    if(environment.isProxy){
      return true;
    }
    return this.store$.select(getMarketingProductsValidated).pipe(
      filter(validated => {
        if (!validated) { this.resolve(); }
        return validated;
      }),
      switchMap(() => this.store$.select(getMarketingSelectedProducts).pipe(
        map(products => {
          const _canActivate = (products && products.length > 0 && products[0].productTypeCodeId.toUpperCase() == ProductTypeCode.Trading)
          || window.location.search.indexOf('?ut=') > -1;
          if (!_canActivate) {
            this.document.location.href = environment.marketingURL;
          }
          return _canActivate;
        }))
      )
    );
  }

  resolve() {
    const stateParams = GET_URL_ALL_PARAMETERS();
    const productCodes = stateParams['products[]'];
    let vestingType = stateParams['vestingtype'] || 1;
    let advisorId = stateParams['advisorid'];
    const scId = stateParams['sc_id'];
    
    /*
    if (scId) {
        sitecoreVisitorProfileService.getProfile(scId, appConstants.env).then(function (result) {
          ngUserService.businessExistingCustomer(result).subscribe();
        });
    }
    */

    if (vestingType) {
      const vestingTypes = {
        individual: 1,
        joint: 2,
        minor: 3,
        trust: 4,
        teen: 5
      };
      vestingType = vestingTypes[vestingType] || 1;
    }
    this.store$.dispatch(new SetProductsSelectionAction(productCodes, vestingType, advisorId));
  }
}
