export enum REVIEW_AND_SUBMIT_SECTION {
    None = '',
    PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
    ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
    PERSONAL_AFFILIATIONS = 'PERSONAL_AFFILIATIONS',
    ADDRESS_INFORMATION = 'ADDRESS_INFORMATION',
    FINANCIAL_INFORMATION = 'FINANCIAL_INFORMATION',
    INVESTMENT_PROFILE = 'INVESTMENT_PROFILE',
    INVESTMENT_EXPERIENCE = 'INVESTMENT_EXPERIENCE',
    BENEFICIARIES_INFORMATION = 'BENEFICIARIES_INFORMATION',
    BENEFICIARIES_CONTINGENT_INFORMATION = 'BENEFICIARIES_CONTINGENT_INFORMATION',
    TRUST_CERTIFICATION = 'TRUST_CERTIFICATION',
    ADDITIONAL_OWNER_INFORMATION = 'ADDITIONAL_OWNER_INFORMATION',
    PRIMARY_OWNER_INFORMATION = 'PRIMARY_OWNER_INFORMATION',
    INVESTMENT_GOAL_INFORMATION = 'INVESTMENT_GOAL_INFORMATION',
    TRUSTED_CONTACT_INFORMATION = 'TRUSTED_CONTACT_INFORMATION',
    ID_DETAILS = 'ID_DETAILS',
}
