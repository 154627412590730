declare var environment: any;

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Core
import { API_URL, PLATFORM_CONFIG_TOKEN } from 'app/core/constants';
import { BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Organization } from 'models';
import { EnrollmentState, GetOrganizationSuccessAction } from 'store';
import { Store } from '@ngrx/store';
import { APPLICATION_TYPE_NAME } from 'models/enums/application-type.enum';

@Injectable()
export class AppSettingsService {
  private _organization = new BehaviorSubject<Organization>(null);
  private readonly ORGANIZATION_SETTINGS$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private store: Store<EnrollmentState>,
    @Inject(API_URL) private baseUrl: string,
    @Inject(PLATFORM_CONFIG_TOKEN) public orgInj: any
  ) {
    this.ORGANIZATION_SETTINGS$.next(orgInj);
  }

  organization = () => {
    return this._organization.pipe(
      filter(organization => !!organization),
      map(organization => organization)
    );
  };

  settings = () => {
    return this._organization.pipe(
      filter(organization => !!organization),
      map(organization => organization.settings)
    );
  };

  getSettings() {
    this._organization.next(this.orgInj);
    return this.ORGANIZATION_SETTINGS$.asObservable().pipe(
      filter((payload) => !!payload),
    );
  }

  getFooter() {
    return this._organization.pipe(
      filter(organization => !!organization),
      map(settings => {
        const wlSettings = settings.settings;
        const footer =  wlSettings.find(x => x.name === 'footer');
        return footer? footer.value : null;
      })
    );
  }

  isWHiteLabel(){
    return environment.applicationTypeName === APPLICATION_TYPE_NAME.whitelabelTrading 
      || environment.applicationTypeName === APPLICATION_TYPE_NAME.whitelabelTradingIRA;
  }

  isProxy(){
    return environment.applicationTypeName === APPLICATION_TYPE_NAME.proxyTrading;
  }
}
