import { Injectable } from "@angular/core";
import { Actions, ofType, Effect } from "@ngrx/effects";
import { BrowserTypeService } from "app/core/services";
// RxJs
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

//Actions
import * as layoutActions from '../actions/layout.action';

@Injectable()
export class LayoutEffects {
    constructor(
        private actions$: Actions,
        private browserTypeService: BrowserTypeService) { }

    @Effect()
    setMobileLayout$ = this.actions$.pipe(
        ofType(layoutActions.LAYOUT_ACTION_TYPES.SET_MOBILE_LAYOUT),
        map((action: layoutActions.SetMobileLayout) => {
             return new layoutActions.SetMobileLayoutSuccess(this.browserTypeService.isBrowserOnMobileDevice())
        }),
        catchError(() => {
             return of(new layoutActions.SetMobileLayoutFail);
        })
    );
}
