// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { LoadAccountsAction } from 'store/actions';
import { getAccounts } from 'store/selectors';
// Model
import { Account } from 'models';

@Injectable()
export class AccountsResolver implements Resolve<Observable<Account[]>> {

    constructor(private store: Store<EnrollmentState>) {  }

    resolve() {
        return this.store.select(getAccounts).pipe(
            filter(accounts => {
                const loaded = accounts !== null;
                if(!loaded) {
                    this.store.dispatch(new LoadAccountsAction());
                }
                return loaded;
            }),
            first()
        );
    }
}
