declare var environment: any;
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'axos-dmd-otp-link',
  templateUrl: './dmd-otp-link.component.html',
  styleUrls: ['./dmd-otp-link.component.scss']
})
export class DmdOtpLinkComponent {
  public dmdGuid: string;
  constructor(
    private currentRoute: ActivatedRoute,
  ) {}

  getUrlString(): string{
    if(environment.env !== 'local')
      return 'https://' + window.location.host + '/id/verify/' + this.currentRoute.snapshot.queryParams['dmdGuid'];
    return 'http://' + window.location.host + '/id/verify/' + this.currentRoute.snapshot.queryParams['dmdGuid'];
  }
}
