import { Resolve } from '@angular/router';

import { Store } from '@ngrx/store';
import { EnrollmentState, GetECMDisclosureDocumentByDisclosureAction, GetElectronicDisclosureAction, GetInitialDisclosuresAction } from 'store';
import { getECMDisclosureDocuments, getElectronicDisclosure, getInitialDisclosures } from 'store/selectors/disclosure.selectors';

import { DetailDisclosure } from 'models';

import { Observable } from 'rxjs';
import { filter, first, map, tap, withLatestFrom } from 'rxjs/operators';

export class ElectronicDisclosureResolver implements Resolve<Observable<DetailDisclosure>> {
    constructor(private store: Store<EnrollmentState>) {  }

    resolve(){
        return this.store.select(getElectronicDisclosure).pipe(
            withLatestFrom(
                this.store.select(getInitialDisclosures),
                this.store.select(getECMDisclosureDocuments)
              ),
            map(data => {
                const electronicDisclosure = data[0],
                initialDisclosures = data[1],
                ecmDisclosureDocuments = data[2]

                if(electronicDisclosure && electronicDisclosure.cabinetName && electronicDisclosure.ecmName && !electronicDisclosure.link){
                    const ecmElectronicDisclosure = ecmDisclosureDocuments.find(x => x.disclosureName == electronicDisclosure.name);
                    if (!ecmElectronicDisclosure){
                        this.store.dispatch(new GetECMDisclosureDocumentByDisclosureAction(electronicDisclosure.disclosureId));
                    }                    
                }
                if(!electronicDisclosure){
                    this.store.dispatch(new GetElectronicDisclosureAction());
                }
                if(!initialDisclosures){
                    this.store.dispatch(new GetInitialDisclosuresAction());
                }
                return electronicDisclosure
            }),
            filter(disclosure => !!disclosure),
            first()
        );
    }
}
