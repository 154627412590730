import { Action } from '@ngrx/store';
import { DisclosuresRequest, DisclosuresSaveRequest, DetailDisclosure, ECMDisclosure, ECMDisclosureDocument } from 'models';

export enum DISCLOSURES_ACTION_TYPES {
  GET_DETAIL_DISCLOSURES = '[Disclosures] Get detail disclosures',
  GET_DETAIL_DISCLOSURES_SUCCESS = '[Disclosures] Get detail disclosures success',

  GET_AXOS_CLEARING_DISCLOSURES = '[Disclosures] Get AxosClearing disclosures',
  GET_AXOS_CLEARING_DISCLOSURES_SUCCESS = '[Disclosures] Get AxosClearing disclosures success',

  POST_TERMS = '[Disclosures] Post Terms',
  POST_TERMS_SUCCESS = '[Disclosures] Post Terms success',
  POST_TERMS_FAIL = '[Disclosures] Post Terms fail',

  CONFIRM = '[Disclosures] Confirm',
  CONFIRM_SUCCESS = '[Disclosures] Confirm success',
  CONFIRM_FAIL = '[Disclosures] Confirm fail',

  SAVE = '[Disclosures] Save',
  SAVE_SUCCESS = '[Disclosures] Save success',
  SAVE_FAIL = '[Disclosures] Save fail',

  GET_ELECTRONIC_DISCLOSURE = '[Disclosures] Get electronic disclosure',
  GET_ELECTRONIC_DISCLOSURE_SUCCESS = '[Disclosures] Get electronic disclosure success',

  GET_AXOS_INVEST_DISCLOSURE = '[Disclosures] Get axos invest disclosure',
  GET_AXOS_INVEST_DISCLOSURE_SUCCESS = '[Disclosures] Get axosinvest disclosure success',

  GET_TAXPAYER_DISCLOSURE = '[Disclosures] Get taxpayer disclosure',
  GET_TAXPAYER_DISCLOSURE_SUCCESS = '[Disclosures] Get taxpayer disclosure success',


  GET_INITIAL_DISCLOSURES = '[Disclosures] Get initial disclosures',
  GET_INITIAL_DISCLOSURES_SUCCESS = '[Disclosures] Get initial disclosures success',

  UPDATE_ELECTRONIC_DISCLOSURE = '[Disclosures] Update electronic disclosure',

  UPDATE_INITIAL_DISCLOSURE = '[Disclosures] Update initial disclosure',

  UPDATE_DISCLOSURE = '[Disclosures] Update disclosure',
  UPDATE_DISCLOSURE_SUCCESS = '[Disclosures] Update disclosure success',

  SAVE_APPLICANT_DISCLOSURES = '[Disclosures] Save applicant disclosures',
  SAVE_APPLICANT_DISCLOSURES_SUCCESS = '[Disclosures] Save applicant disclosures success',

  GET_DISCLOSURES_CATALOG = '[Disclosures] Get disclosures catalog',
  GET_DISCLOSURES_CATALOG_SUCCESS = '[Disclosures] Get disclosures catalog success',

  GET_ECM_DISCLOSURES = '[Disclosures] Get ECM disclosures',
  GET_ECM_DISCLOSURES_SUCCESS = '[Disclosures] Get ECM disclosures success',
  GET_ECM_DISCLOSURE_DOCUMENT = '[Disclosures] Get ECM disclosure document',
  GET_ECM_DISCLOSURE_DOCUMENT_SUCCESS = '[Disclosures] Get ECM disclosure document success',
  OPEN_ECM_DISCLOSURE_DOCUMENT = '[Disclosures] Open ECM disclosure document',
  GET_AND_OPEN_ECM_DISCLOSURE_DOCUMENT = '[Disclosures] Get and open ECM disclosure document',
  GET_ECM_DISCLOSURE_DOCUMENT_BY_DISCLOSURE = '[Disclosures] Get ECM disclosure document by disclosure',
  GET_ECM_DISCLOSURE_DOCUMENT_BY_DISCLOSURE_SUCCESS = '[Disclosures] Get ECM disclosure document by disclosure success',

  SET_CASH_SWEEP_DISCLOSURES = '[Disclosures] Set cash sweep disclosures',

  CLEAR_STATE = '[Disclosures] Clear State'
}

export class GetElectronicDisclosureAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_ELECTRONIC_DISCLOSURE;
}

export class GetElectronicDisclosureSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_ELECTRONIC_DISCLOSURE_SUCCESS;
  constructor(public disclosure: DetailDisclosure) { }
}

export class GetInitialDisclosuresAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_INITIAL_DISCLOSURES;
}

export class GetInitialDisclosuresSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_INITIAL_DISCLOSURES_SUCCESS;
  constructor(public disclosures: DetailDisclosure[]) { }
}

export class GetAxosInvestDisclosureAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_AXOS_INVEST_DISCLOSURE;
}

export class GetAxosInvestDisclosureSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_AXOS_INVEST_DISCLOSURE_SUCCESS;
  constructor(public disclosure: DetailDisclosure) { }
}

export class GetTaxPayerDisclosureAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_TAXPAYER_DISCLOSURE;
}

export class GetTaxPayerDisclosureSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_TAXPAYER_DISCLOSURE_SUCCESS;
  constructor(public disclosure: DetailDisclosure) { }
}

export class GetDetailDisclosuresAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_DETAIL_DISCLOSURES;
}

export class GetDetailDisclosuresSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_DETAIL_DISCLOSURES_SUCCESS;
  constructor(public disclosures: DetailDisclosure[]) { }
}

export class GetAxosClearingDisclosuresAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_AXOS_CLEARING_DISCLOSURES;
}

export class GetAxosClearingDisclosuresSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_AXOS_CLEARING_DISCLOSURES_SUCCESS;
  constructor(public disclosures: DetailDisclosure[]) { }
}

export class PostTermsAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.POST_TERMS;
  constructor(public request: DisclosuresRequest) { }
}

export class PostTermsSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.POST_TERMS_SUCCESS;
}

export class ConfirmDisclosuresAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.CONFIRM;
}

export class ConfirmDisclosuresSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.CONFIRM_SUCCESS;
}

export class SaveDisclosuresAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.SAVE;
  constructor(public request: DisclosuresSaveRequest) { }
}

export class SaveDisclosuresSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.SAVE_SUCCESS;
}

export class UpdateElectronicDisclosure implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.UPDATE_ELECTRONIC_DISCLOSURE;
  constructor(public accepted: boolean) { }
}

export class UpdateInitialDisclosureAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.UPDATE_INITIAL_DISCLOSURE;
  constructor(public name: string, public accepted: boolean) { }
}

export class UpdateDisclosuresAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.UPDATE_DISCLOSURE;
  constructor(public disclosure: DetailDisclosure) { }
}

export class UpdateDisclosuresSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.UPDATE_DISCLOSURE_SUCCESS;
}

export class SaveApplicantDisclosuresAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.SAVE_APPLICANT_DISCLOSURES;
  constructor(public disclosures: DetailDisclosure[]) { }
}

export class SaveApplicantDisclosuresSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.SAVE_APPLICANT_DISCLOSURES_SUCCESS;
}

export class GetDisclosuresCatalogAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_DISCLOSURES_CATALOG;
}

export class GetDisclosuresCatalogSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_DISCLOSURES_CATALOG_SUCCESS;
  constructor(public disclosures: DetailDisclosure[]) { }
}

export class GetECMDisclosuresAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURES;
}

export class GetECMDisclosuresSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURES_SUCCESS;
  constructor(public disclosures: ECMDisclosure[]) { }
}

export class GetECMDisclosureDocumentAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURE_DOCUMENT;
  constructor(public documentId: string) { }
}

export class GetECMDisclosureDocumentSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURE_DOCUMENT_SUCCESS;
  constructor(public disclosure: ECMDisclosureDocument) { }
}

export class OpenECMDisclosureAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.OPEN_ECM_DISCLOSURE_DOCUMENT;
  constructor(public disclosureName: string) { }
}

export class GetAndOpenECMDisclosureDocumentAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_AND_OPEN_ECM_DISCLOSURE_DOCUMENT;
  constructor(public documentId: string) { }
}

export class SetCashSweepDisclosures implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.SET_CASH_SWEEP_DISCLOSURES;
  constructor(public disclosure: DetailDisclosure) { }
}

export class GetECMDisclosureDocumentByDisclosureAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURE_DOCUMENT_BY_DISCLOSURE;
  constructor(public disclosureId: string) { }
}

export class GetECMDisclosureDocumentByDisclosureSuccessAction implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURE_DOCUMENT_BY_DISCLOSURE_SUCCESS;
  constructor(public disclosure: ECMDisclosureDocument) { }
}

export class ClearDisclosuresState implements Action {
  readonly type = DISCLOSURES_ACTION_TYPES.CLEAR_STATE;
  constructor() { }
}

export type DisclosuresAction = PostTermsAction | PostTermsSuccessAction |
  ConfirmDisclosuresAction | ConfirmDisclosuresSuccessAction |
  SaveDisclosuresAction | SaveDisclosuresSuccessAction |
  SaveApplicantDisclosuresAction | SaveApplicantDisclosuresSuccessAction |
  GetDetailDisclosuresAction | GetDetailDisclosuresSuccessAction |
  GetAxosClearingDisclosuresAction | GetAxosClearingDisclosuresSuccessAction |
  GetElectronicDisclosureAction | GetElectronicDisclosureSuccessAction |
  UpdateElectronicDisclosure | UpdateInitialDisclosureAction |
  GetInitialDisclosuresAction | GetInitialDisclosuresSuccessAction |
  UpdateDisclosuresAction | UpdateDisclosuresSuccessAction |
  GetDisclosuresCatalogAction | GetDisclosuresCatalogSuccessAction |
  GetECMDisclosuresAction | GetECMDisclosuresSuccessAction |
  GetAxosInvestDisclosureSuccessAction | GetAxosInvestDisclosureAction |
  GetTaxPayerDisclosureAction | GetTaxPayerDisclosureSuccessAction |
  GetECMDisclosureDocumentAction | GetECMDisclosureDocumentSuccessAction |
  OpenECMDisclosureAction |
  SetCashSweepDisclosures |
  GetAndOpenECMDisclosureDocumentAction |
  GetECMDisclosureDocumentByDisclosureAction | GetECMDisclosureDocumentByDisclosureSuccessAction |
  ClearDisclosuresState;
