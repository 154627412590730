import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalEvents, UkModalService } from '@uikit/components/uk-modal';
import { ApplicationService } from 'services';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getApplication } from 'store/selectors';
// RxJs
import { BaseDestroyComponent } from 'app/core/components';
import { OUTCOME_PARENT_ROUTE, OUTCOME_ROUTES } from 'app/outcome';

@Component({
  selector: 'axos-cancel-application-popup',
  templateUrl: './cancel-application-popup.component.html',
  styleUrls: ['./cancel-application-popup.component.scss']
})
export class CancelApplicationPopupComponent extends BaseDestroyComponent {

  applicationId: string;

  constructor(
    protected store$: Store<EnrollmentState>,
    private router: Router,
    protected modalService: UkModalService,
    private applicationService: ApplicationService) {
    super();
    this.store$.select(getApplication)
      .subscribe(application => this.applicationId = application.applicationId);
  }

  cancelApplicationPopup() {
    this.modalService.closeModal(ModalEvents.close);
  }

  cancelApplication() {
    this.applicationService
      .cancelApplication(this.applicationId)
      .subscribe(() => {
        this.modalService.closeModal(ModalEvents.close);
        this.router.navigateByUrl(`${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.CANCELED}`);
      });
  }
}
