import {
    createSelector,
    createFeatureSelector
} from '@ngrx/store';
// Models
import { IApplication, ApplicationStatus } from 'models';

import { ApplicationState } from '../states';
import { FEATURE_NAMES } from '../constants';
import { VESTING_TYPE_NAME } from 'models/enums';
import { GET_CACHE } from '@uikit/components';
import { PROXY_APPLICATION_ID } from 'app/auth/constants';
declare var environment: any;

export const getApplicationState = createFeatureSelector(FEATURE_NAMES.Application);

export const getApplicationLoading = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.loading
);

export const getApplicationLoaded = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.loaded
);

export const getApplication = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.application
);

export const getAccounts = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.accounts
);

export const getApplicationId = createSelector(
    getApplication,
    (application: IApplication) => (!!environment.isProxy && application.applicationId == null) ? GET_CACHE(PROXY_APPLICATION_ID) : application.applicationId
);

export const getApplicationPublicId = createSelector(
    getApplication,
    (application: IApplication) => application.applicationId
);

export const getApplicationStates = createSelector(
    getApplication,
    (application: IApplication) => !application ? null :
      application.ApplicationState || {
        status: application.status,
        stateId: application.stateId,
        decisionStatus: application.decisionStatus,
        decisionReason: application.decisionReason,
    }
);

export const getApplicationStateId = createSelector(
    getApplication,
  (application: IApplication) => application ? application.stateId : null
);

export const getPriorApplicationStateId = createSelector(
  getApplicationStates,
  (states: ApplicationStatus) => states ? states.PriorApplicationStateId : null
);

export const getApplicationVestingTypeName = createSelector(
    getApplication,
    (application: IApplication) => application.vestingTypeName ? application.vestingTypeName  : null
);

export const getApplicationVestingTypeId = createSelector(
    getApplication,
    (application: IApplication) => application.vestingTypeId ? application.vestingTypeId  : null
);

export const getApplicantsMetadata = createSelector(
    getApplication,
    (application: IApplication) => application.Applicants
);

export const getIDAQuestions = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.idaQuestions
);

export const getPendingVerificationApplicants = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.pendingIDVerificationApplicants
);

export const  getConnectionId = createSelector(
    getApplication,
    (application: IApplication) => application.connectionId
)

export const selectBeneficiariesInformation = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.application.beneficiariesIinformation || null
)

export const selectBeneficiariesContingentInformation = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.application.beneficiariesContingentIinformation
)

export const getTrustCertificateInformation = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.application.trustCertificateInformation
)

export const getApplicationTypeName = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.application.applicationTypeName
);

export const getTrustTypes = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.trustTypes
);

export const getApplicationProducts = createSelector(
    getApplicationState,
    (state: ApplicationState) => state.application.productNames
);
