declare var window: any, liveagent: any;
// Ng
import { Component } from '@angular/core';
// RxJs
import { takeUntil, filter, combineLatest } from 'rxjs/operators';
// NgRx
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getPrimaryApplicant, getChatSettings, getUser } from 'store/selectors';
// Services
import { LazyLoadingService } from 'app/core/services';
import { BaseDestroyComponent } from 'app/core/components';
import { ChatSettings, IUser } from 'models';
import { CREATE_UUID } from 'app/core/utils';

@Component({
  selector: 'axos-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss']
})
export class LiveChatComponent extends BaseDestroyComponent {

  settings: ChatSettings;

  enableLogging = '';
  isLiveChatOpen = false;
  htmlSettings = {
    onlineButtonId: '',
    offlineButtonId: '',
    ButtonId: '',
    IsActive: true
  };

  primaryApplicant$ = this.store$.select(getPrimaryApplicant);

  chatSettings$ = this.store$.select(getChatSettings)
    .pipe(filter(settings => !!settings));
  user$ = this.store$.select(getUser);

  constructor(
    private store$: Store<EnrollmentState>,
    private lazyLoading: LazyLoadingService
  ) {
    super();
    this.chatSettings$.pipe(
      takeUntil(this.unsubscribe$),
      filter(settings => settings.IsActive),
      combineLatest(this.user$),
    ).subscribe(data => {
      this.settings = data[0];
      if(this.settings) { this.setChat(data[1]); }
    });
  }

  toggleLiveChat() { this.isLiveChatOpen = !this.isLiveChatOpen; };

  private setLoggedinChat(user: IUser) {
    this.htmlSettings.ButtonId = this.settings.LoginButtonId;
    if (user.FirstName) {
      liveagent.addCustomDetail('FirstName', user.FirstName, false);
    }
    if (user.LastName) {
      liveagent.addCustomDetail('LastName', user.LastName, false);
    }
    if (user.Email) {
      liveagent.addCustomDetail('Email', user.Email, false);
    }
    if (user.CIF) {
      liveagent.addCustomDetail('CIF', user.CIF, false);
    }
    if (user.PhoneNumber) {
      liveagent.addCustomDetail('PhoneNumber', user.PhoneNumber , false);
    }
    if (user.ApplicationId) {
      liveagent.addCustomDetail('ApplicationId', user.ApplicationId, false);
    }
    liveagent.addCustomDetail('UserType', user.CIF ? 'Existing Customer' : 'New Enroller', false);
    /*
    if (applicationInfo.Accounts && applicationInfo.Accounts.length) {
      let product: any = applicationInfo.Accounts.shift();
      let productName = product && product.ProductName;
      if (productName)
        liveagent.addCustomDetail('Product','" + productName + "', false);
    }
    */
  }

 private setChat(user: IUser) {
  const buttonId = user ? this.settings.LoginButtonId : this.settings.ButtonId;

  // Detach prev button click listeners
  this.htmlSettings.onlineButtonId = `liveagent_button_online_${buttonId}`;
  this.htmlSettings.offlineButtonId = `liveagent_button_offline_${buttonId}`;

  delete window.liveagent;
  delete window.liveAgentDeployment;
  // Load SalesForce Script
  this.lazyLoading.loadScript(`${this.settings.DeploymentUrl}?${CREATE_UUID()}`)
    .pipe(filter(loaded => loaded))
    .subscribe(() => {
      if (this.enableLogging) { eval(this.enableLogging); }
      liveagent.init(this.settings.Url, this.settings.DeploymentId, this.settings.OrgId);
      if (user) { this.setLoggedinChat(user); }

      const onlineBtn = document.getElementById(this.htmlSettings.onlineButtonId),
        offlineBtn = document.getElementById(this.htmlSettings.offlineButtonId),
        closeBtn = document.getElementById(`${this.htmlSettings.onlineButtonId}_close`),
        openChatFn = () => liveagent.startChatWithWindow(buttonId, 'live-chat__frame');

      liveagent.showWhenOnline(buttonId, onlineBtn);
      liveagent.showWhenOffline(buttonId, offlineBtn);

      onlineBtn.addEventListener('click', openChatFn);
      closeBtn.addEventListener('click', openChatFn);
    });
  }
}
