import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'axos-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {

  constructor() { }

  @Input() progress: number;

  get width() {
    return this.progress ? `${Math.floor(this.progress)}%` : '0';
  }

}
