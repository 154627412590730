import { Component, Inject, OnInit } from '@angular/core';
import { BaseFormComponent } from 'app/core/components';
// Modal
import { ModalEvents, UkModalService } from '@uikit/components/uk-modal';
import { Address, RecommendedAddress } from 'models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// NGRX store
import { ApplicationState, EnrollmentState, RecommendedApplicantAddressAction } from 'store';
import { Store } from '@ngrx/store';
import { ADDRESS_TYPE_NAME } from 'models/enums/address-type.enum';
import { getRecommendAddress, selectAddressInfoes, selectRecommendAddressInfoes } from 'store/selectors';
import { Observable } from 'rxjs';



@Component({
  selector: 'axos-address-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: ['./address-popup.component.scss']
})
export class AddressPopupComponent extends  BaseFormComponent<Address> {
  address:any={};
  isRecommendAddress:boolean=true;
  recommendedAddress:RecommendedAddress;
  recommendedAddressFixedStreet:string;
  label:any;
  dynamicHeader:string;
  enteredData:any={};
  isBoxOne:boolean=true;
  isBoxTwo:boolean=false;

  constructor(protected modalService: UkModalService,
              private store$: Store<ApplicationState>,
              public dialogRef: MatDialogRef<AddressPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    
    this.store$.select(selectRecommendAddressInfoes).subscribe(response =>{
      if(response && Array.isArray(response) ){
        this.recommendedAddress = response[0];
        this.recommendedAddressFixedStreet = this.recommendedAddress.streetAddress2 ? 
          (`${this.recommendedAddress.streetAddress2} ${this.recommendedAddress.streetAddress1}`) :
          this.recommendedAddress.streetAddress1;
        this.isRecommendAddress = true;
        
      }else{
        this.isRecommendAddress = false;
      }
    });
   }

  ngOnInit() {
      this.label=this.data.label;
      this.dynamicHeader = this.label ? `Which address should we use for ${this.label}?` : 'Which address should we use?';
      this.address = {...this.data};
  }
  //formating address
  setAddress(address?){

    let homeAddress={
      street:address.streetAddress1,
      street2:address.streetAddress2,
      zip:address.zip5,
      zipPlus4:address.zip4,
      city:address.city,
      state:address.state
    };
    return homeAddress;
  }
// if choose recommended address
  onCorrectAddress() {
    if(this.isBoxOne){
     let homeAddress = this.setAddress(this.recommendedAddress)
      this.dialogRef.close(homeAddress);
    }else{
     this.onAddress();
    }
   
  }
  onAddress(){
    let homeAddress = this.setAddress(this.address);
    this.dialogRef.close(homeAddress)
  }
  
  onClose(){
    this.dialogRef.close(false);
  }
  //selecting box
  onClick(check:string){
    if(check ==='isBoxOne'){
      this.isBoxOne = true;
      this.isBoxTwo = false;
    }else{
      this.isBoxOne = false;
      this.isBoxTwo = true;
    }
  }
}
