import { Injectable } from '@angular/core';
// Utils
import { GET_URL_ALL_PARAMETERS, OBJECT_TO_ARRAY, GET_QUERY_PARAM } from 'app/core/utils';

import { CacheService } from './abstracts';

@Injectable()
export class UtmCodeService extends CacheService {
  utmProperties = [
    'utm_campaign',
    'utm_medium',
    'utm_source',
    'utm_content',
    'utm_term',
    'utm_promocode',
    'experience_id',
    'hem',
    'identity',
    'siteID',
    'ranMID',
    'ranEAID',
    'ranSiteID',
    'gclid',
    'fbclid',
    'msclkid'
  ];

  get() { return this.getCached('UTM'); }

  getUTMCampaignCodes() {
    const cached = this.get() || [];
    const codes = cached.filter(param => this.utmProperties.some(x => x.toLowerCase() == param.code.toLowerCase()));
    return codes || [];
  }

  set() {
    const params = OBJECT_TO_ARRAY(GET_URL_ALL_PARAMETERS(false));
    // Condition for avoid save guid param instead products when refresh the browser
    if (params.length > 0 && !GET_QUERY_PARAM('guid')) {
      const tracking = params.map(param => {
        return { code: param.key, value: param.value.toString() }
      });
      return this.setCache(tracking, 'UTM');
    }
  }
}
