declare var environment: any;


import { Applicant } from 'models';
import {
    APPLICANT_TYPE_NAME,
    VESTING_TYPE_ID,
    BRAND_CONTEXT
} from 'models/enums';

export function GET_APPLICANT_TITLE(
    vestingTypeID: VESTING_TYPE_ID,
    applicant: Applicant,
    ignoreTeenOrMinor = false,
    possessive = false,
    prefix = false,
    yourself = false
) {
    if (possessive) { prefix = true; }
    if (applicant.type === APPLICANT_TYPE_NAME.Primary && yourself) {
        return 'yourself';
    }
    return `${prefix ? 'the' : ''} ${GET_APPLICANT_TYPE_NAME(vestingTypeID, applicant, ignoreTeenOrMinor)}${!!environment.isAxosUniversal ? '' : ` applicant${possessive ? '’s' : ''}`}`;
}

export function IS_BUSINESS() { return environment.brandContext === BRAND_CONTEXT.Business; }

export function GET_APPLICANT_TYPE_NAME(vestingTypeID: VESTING_TYPE_ID, applicant: Applicant, ignoreTeenOrMinor = false) {
    switch (vestingTypeID) {
        case VESTING_TYPE_ID.Trust:
            switch (applicant.type) {
                case APPLICANT_TYPE_NAME.Primary:
                    return !!environment.isAxosUniversal ? 'primary' : 'trustee 1';
                case APPLICANT_TYPE_NAME.Joint:
                    return !!environment.isAxosUniversal ? 'Trustee #2' : 'trustee 2';
            }
        case VESTING_TYPE_ID.Joint:
            if (!!environment.isAxosUniversal && applicant.type == APPLICANT_TYPE_NAME.Joint) {
                return applicant.type.toLowerCase() + ' applicant';
            }
            break;
        case VESTING_TYPE_ID.Teen:
        case VESTING_TYPE_ID.UTMA:
            if (applicant.type === APPLICANT_TYPE_NAME.Primary && !ignoreTeenOrMinor) {
                return 'parent / guardian';
            }
    }

    if (IS_BUSINESS() && applicant.type === APPLICANT_TYPE_NAME.Primary) { return 'owner'; }

    return applicant.type.toLowerCase();
}

export function IS_TEEN_OR_MINOR(vestingTypeId: VESTING_TYPE_ID) {
    return vestingTypeId === VESTING_TYPE_ID.UTMA || vestingTypeId === VESTING_TYPE_ID.Teen;
}

export function IS_TEEN(vestingTypeId: VESTING_TYPE_ID) {
    return vestingTypeId === VESTING_TYPE_ID.Teen;
}

export function IS_TRUST(vestingTypeId: VESTING_TYPE_ID) {
    return vestingTypeId === VESTING_TYPE_ID.Trust;
}

export function GET_APPLICANT_POSSESSIVE(vestingTypeID: VESTING_TYPE_ID, applicant: Applicant): { title: string, pronoun: string } {
    if (applicant) {
        if (applicant.type === APPLICANT_TYPE_NAME.Primary && !IS_TEEN_OR_MINOR(vestingTypeID)) {
            return { title: 'your', pronoun: 'your' };
        } else {
            return { title: GET_APPLICANT_TITLE(vestingTypeID, applicant, true, true), pronoun: 'their' };
        }
    } else {
        return { title: '', pronoun: '' };
    }
}
