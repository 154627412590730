import {
    IDENTIFICATION_DOCUMENT_TYPE,
    IDENTIFICATION_DOCUMENT_TYPE_NAME
} from "../enums";

export const ISSUED_IDENTIFICATION_DOCUMENT_TYPES = [
    {
        name: IDENTIFICATION_DOCUMENT_TYPE_NAME.DriversLicense,
        value: IDENTIFICATION_DOCUMENT_TYPE.DriversLicense
    },
    {
        name: IDENTIFICATION_DOCUMENT_TYPE_NAME.StateID,
        value: IDENTIFICATION_DOCUMENT_TYPE.StateID
    }
]
