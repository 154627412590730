import {
  ID_VERIFICATION_METHOD,
  MANUAL_ID_VERIFICATION_REASON
} from '../enums'

import {
  IdentificationDocument,
  ApplicantIdentificationStep
} from '../models'

export class ApplicantIdentification {
  IdentificationInProgress: boolean;
  ApplicantType?: string;
  ApplicationId?: number;
  ApplicantId?: number;
  ApplicantDisplayName?: string;
  DmdToken?: string;
  IdentificationComplete: boolean;
  VerificationMethod: ID_VERIFICATION_METHOD;
  ManualIdVerificationReason?: MANUAL_ID_VERIFICATION_REASON;
  Documents: IdentificationDocument[];
  IdentificationSteps: ApplicantIdentificationStep[];

  constructor(
    verificationMethod: ID_VERIFICATION_METHOD,
    applicantType?:string,
    applicationId?: number,
    applicantId?: number,
    applicantName?: string,
    dmdToken?: string,
    manualIdVerificationReason?: MANUAL_ID_VERIFICATION_REASON
  ) {
    this.VerificationMethod = verificationMethod;
    this.ApplicantType = (typeof (applicantType) !== undefined && applicantType !== null) ? applicantType : null;
    this.ApplicationId = (typeof (applicationId) !== undefined && applicationId !== null) ? applicationId : null;
    this.ApplicantId = (typeof (applicantId) !== undefined && applicantId !== null) ? applicantId : null;
    this.ApplicantDisplayName = (typeof (applicantName) !== undefined && applicantName !== null) ? applicantName : null;
    this.DmdToken = (typeof (dmdToken) !== undefined && dmdToken !== null) ? dmdToken : null;
    this.ManualIdVerificationReason = (typeof (manualIdVerificationReason) !== undefined && manualIdVerificationReason !== null) ? manualIdVerificationReason : null;
    this.IdentificationComplete = false;
    this.Documents = [];
    this.IdentificationSteps = [];
  }
}
