import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, filter, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { EnrollmentState, getBrokerInformation } from 'store';
import { getBroker } from 'store/selectors';
import { GET_CACHE, SET_CACHE } from '../utils';

@Injectable()
export class BrokerResolver implements Resolve<Observable<any>> {
    constructor(private store$: Store<EnrollmentState>) { }

    resolve() {
      const token = GET_CACHE('guid');
      if (token)
      {
        SET_CACHE(token, 'guid');
        return this.store$.select(getBroker).pipe(
          tap(broker => {
            if (!broker) {
              this.store$.dispatch(getBrokerInformation({ guid: token }));
            }
          }),
          filter(broker => !!broker),
          first()
        );
      } else {
        return null;
      }
    }
}
