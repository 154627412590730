declare var environment: any;

import { Component } from '@angular/core';
import { UkModalService } from '@uikit/components/uk-modal';
@Component({
  selector: 'axos-footer-modals-popup',
  templateUrl: './footer-modals-popup.component.html',
  styleUrls: ['./footer-modals-popup.component.scss']
})
export class FooterModalsPopupComponent {
  redirectUrl: string;
  organizationName: string;
  constructor(protected modalService: UkModalService) {
    this.organizationName = environment.organizationName != 'axos' ? 'Axos' : environment.organizationDisplayName;
  }
}
