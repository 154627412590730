// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { switchMap, filter, first } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetOcupationsAction } from 'store/actions';
import { getOccupations, getOccupationsLoaded } from 'store/selectors';
import { CatalogItem } from 'models/trading/catalog-item.model';

@Injectable()
export class OccupationResolver implements Resolve<Observable<CatalogItem[]>> {

     constructor(private store: Store<EnrollmentState>) {  }

     resolve() {
          return this.store.select(getOccupationsLoaded).pipe(
               switchMap(loaded => {
                    if(!loaded) {
                         this.store.dispatch(new GetOcupationsAction());
                    }
                    return this.store.select(getOccupations).pipe(
                         filter(occupations => occupations.length > 0)
                    );
               }),
               first()
          );
     }
}
