// Ng
import { Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
// RxJs
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, filter, switchMap, startWith, take, map } from 'rxjs/operators';

import { BaseDestroyComponent } from './base-destroy.component';

// TODO: Create new Class with only Form and Value and extend from it (Decouple flow navigation service)
export abstract class BaseFormComponent<T> extends BaseDestroyComponent implements OnInit {

  protected _value: T;
  protected hasPendingUpdate = false;
  protected useFormValue = false;
  protected submitForm$ = new Subject();

  submitInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input() // TODO: Deprecate in future
  get value() { return this._value; }
  set value(val: T) { this.updateValue(val); }

  private _form: FormGroup;
  get form() { return this._form; }
  set form(val: FormGroup) {
    this._form = val;
    this._form.reset();
    this.setFormEnabled(this._form);
    this.useFormValue = true;
    if (this.hasPendingUpdate) {
      this.updateFormValue();
    }
  }

  constructor(protected value$?: Observable<T>) {
    super()
    if (value$) {
      value$.pipe(
        filter(value => !!value),
        takeUntil(this.unsubscribe$)
      )
        .subscribe(value => this.updateValue(value))
    }
  }

  ngOnInit() {
    this.submitInProgressListener();
    this.submitForm$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap(() => this.listenFormValidity(this.form)),
    ).subscribe(isValid => {
      this.onFormSubmitted(isValid)
    }

    );
  }

  //step2
  private submitStart() {
    if (!this.submitInProgress$.value) {
      this.submitInProgress$.next(true);
    } else {
      this.submitInProgress$.next(false);
      this.submitInProgress$.next(true);
    }
  }
  //step 6
  protected submitEnd() {
    this.submitInProgress$.next(false);
  }

  protected submitInProgressListener() {
    this.submitInProgress$.pipe(
      takeUntil(this.unsubscribe$),
      filter(inProgress => inProgress)
    ).subscribe(() => this.onSubmitStart());
  }

  protected updateValue(value: T) {
    // TODO: Deprecate
    this._value = value;
    this.form ?
      this.updateFormValue() :
      this.hasPendingUpdate = true;
  }

  protected updateFormValue() {
    this.form.patchValue(this.value);
    this.hasPendingUpdate = false;
  }

  protected listenFormValidity(form: FormGroup): Observable<boolean> {
    return form.statusChanges.pipe(
      startWith(form.status),
      filter(status => status !== 'PENDING'),
      map(status => status === 'VALID'),
      take(1)
    );
  }

  getFormControls(form) { return (<any>Object).values(form.controls); }

  setFormEnabled(form: FormGroup) {
    this.getFormControls(form).forEach((control: any) => {
      if (control) {
        control.enable();
        if (control.controls) {
          this.setFormEnabled(control);
        }
      }
    });
  }

  setFormTouched(form: FormGroup) {
    this.getFormControls(form).forEach((control: any) => {
      if (control) {
        control.markAsTouched({ onlySelf: true });
        if (control.controls) {
          this.setFormTouched(control);
        }
      }
    });
  }

  onFormInvalid() {
    this.setFormTouched(this._form);
    this.submitEnd();
  }
  //step1 click on save button
  onContinue(value?: any) {
    if (value && !this.useFormValue) {
      this._value = this.value ? Object.assign(this.value, value) : value;
    }
    this.submitStart();
  }
  //step5
  onSave() {
    this.submitEnd();
  }
  //step3 if submitInProgress true
  onSubmitStart() {
    this.form ? this.submitForm$.next() : this.onSave();
  }
  //step4
  onFormSubmitted(isValid: boolean) {
    if (isValid) {
      if (this.useFormValue) {
        this._value = Object.assign(this.value ? this.value : {}, this.form.value);
      }
      this.onSave();
    } else {
      this.onFormInvalid();
    }
  }
}
