// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
// Navigation
import { FlowNavigationService } from '../services/flow-navigation.service';


export abstract class FlowBaseGuard {
    constructor(protected store$: Store<EnrollmentState>, protected navService: FlowNavigationService) { }

    getData() { throw Error('Method not implemented'); }

    shouldNavigate(activate: boolean): boolean {
        if(!activate) { this.navService.move(); }
        return activate;
    }
}
