declare var environment: any

import { Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// RxJs
import { Observable, throwError } from 'rxjs';
// Token
import { API_SUB_ROUTE, API_URL } from 'app/core/constants';
import { CacheService } from 'app/core/services/abstracts/cache.service';
import { LoadingService } from 'app/core/services/loading.service';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
// Core Utils
import { CREATE_UUID } from 'app/core/utils';

export abstract class BaseService extends CacheService {
  apiUrlSub: string = API_SUB_ROUTE.DEFAULT_SUB;
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(LoadingService) private loadingService: LoadingService,
    @Inject(API_URL) protected baseUrl: string
  ) {
    super();
    if(environment.isProxy){this.apiUrlSub = this.apiUrlSub + API_SUB_ROUTE.PROXY_SUB}
  }

  protected cleanValue(value: string): string {
    return value ? value.replace(/-|\)|\(/g, '') : null;
  }

  protected _get<T>(
    url: string,
    params?: HttpParams | { [param: string]: string | string[] },
    loadingListener = true,
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    }
  ): Observable<T> {
    const request = this.http.get<T>(`${this.baseUrl}${this.buildRoute(url)}`, { params, headers });
    return loadingListener ? this.loadingListener(request) : request;
  }

  protected _getStringResponse<T>(
    url: string,
    params?: HttpParams | { [param: string]: string | string[] },
    loadingListener = true
  ): Observable<T> {
    const request = this.http.get<T>(`${this.baseUrl}${this.buildRoute(url)}`, { params: params, responseType: 'text' as 'json' });
    return loadingListener ? this.loadingListener(request) : request;
  }

  protected _post<T>(url: string, data?: any, loadingListener = true): Observable<T> {
    const request = this.http.post<T>(`${this.baseUrl}${this.buildRoute(url)}`, data);
    request.pipe(
      tap(resp => console.log(request, resp)));
    return loadingListener ? this.loadingListener(request) : request;
  }

  protected _postAndGetStringResponse<T>(url: string, data: any, loadingListener = true): Observable<T> {
    const request = this.http.post<T>(`${this.baseUrl}${this.buildRoute(url)}`, data, {responseType: 'text' as 'json'});
    return loadingListener ? this.loadingListener(request) : request;
  }

  protected _put<T>(url: string, data: any, loadingListener = true): Observable<T> {
    const request = this.http.put<T>(`${this.baseUrl}${this.buildRoute(url)}`, data);
    return loadingListener ? this.loadingListener(request) : request;
  }

  private loadingListener<T>(request: Observable<T>): Observable<T> {
    const request_UUID = CREATE_UUID();
    this.loadingService.requestStart(request_UUID);
    return request.pipe(
        finalize(() => this.loadingService.requestStop(request_UUID)),
        catchError(err => {
            this.loadingService.requestStop(request_UUID);
            return throwError(err);
        }),
    );
  }

  protected _postWithParams<T>(url: string, params: any, data?: any, loadingListener = true): Observable<T> {
    const request = this.http.post<T>(`${this.baseUrl}${this.buildRoute(url)}`, data, { params });
    request.pipe(
      tap(resp => console.log(request, resp)));
    return loadingListener ? this.loadingListener(request) : request;
  }

  public buildRoute(url): string{
    if(url.includes(API_SUB_ROUTE.GENERIC_SUB)){
      url =  url.replace(API_SUB_ROUTE.GENERIC_SUB, '');
      return `${this.apiUrlSub}${url}`.replace(API_SUB_ROUTE.PROXY_SUB, '');
    }

    return `${this.apiUrlSub}/${url}`;
  }
}
