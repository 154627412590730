import { Injectable } from '@angular/core';
// RxJs
import { switchMap, map, catchError } from 'rxjs/operators';
// NgRx Effects
import { Effect, Actions, ofType } from '@ngrx/effects';
// Services
import { BrandService } from 'services';
// Models
import { Brand, ChatSettings } from 'models';
// Actions
import * as brandActions from '../actions/brand.action';
import { of } from 'rxjs';
import { ChatBotSettings } from 'models/brand/chatbot-settings.model';

@Injectable()
export class BrandEffects {
  constructor(
    private actions$: Actions,
    private brandService: BrandService) { }

  @Effect()
  getBrand$ = this.actions$.pipe(
    ofType(brandActions.BRAND_ACTION_TYPES.GET_BRAND),
    switchMap((action: brandActions.GetBrandAction) => {
      return this.brandService.getBrand(action.brandName)
        .pipe(
          map((response: Brand) => new brandActions.GetBrandSuccessAction(response))
        );
    })
  );

  // @Effect()
  // getChatSettings$ = this.actions$.pipe(
  //   ofType(brandActions.BRAND_ACTION_TYPES.GET_CHAT_SETTINGS),
  //   switchMap((action: brandActions.GetChatSettingsAction) => {
  //     return this.brandService.getLiveChatSettings(action.brandName)
  //       .pipe(
  //         map((response: ChatSettings) => new brandActions.GetChatSettingsSuccessAction(response)),
  //         catchError(() => of(new brandActions.GetChatSettingsFailAction()))
  //       );
  //   })
  // );

  @Effect()
  getChatBotSettings$ = this.actions$.pipe(
    ofType(brandActions.BRAND_ACTION_TYPES.GET_CHAT_BOT_SETTINGS),
    switchMap((action: brandActions.GetChatBotSettingsAction) => {
      return this.brandService.getChatBotSettings(action.brandId)
        .pipe(
          map((response: ChatBotSettings) => 
            new brandActions.GetChatBotSettingsSuccessAction(response)
          ),
          catchError(() => 
            of(new brandActions.GetChatBotSettingsFailAction())
          )
        );
    })
  );

  @Effect()
  getBrandLogo$ = this.actions$.pipe(
    ofType(brandActions.BRAND_ACTION_TYPES.GET_BRAND_LOGO),
    switchMap((action: brandActions.GetBrandAction) => {
      return this.brandService.getLogo(action.brandName)
        .pipe(
          map((response: string) => new brandActions.GetBrandLogoSuccessAction(response))
        );
    })
  );
}
