export enum BRAND_ID {
    Axos = 1,
    UFBDirect = 2,
    AxosBetaTesters = 3,
    AxosAdvisor = 4,
    AxosSecondChance = 5,
    AxosBusinessBanking = 6,
    NationWideBusinessBanking = 7,
    NationwidePremier = 8,
    NationWide = 9,
    NationwideSecondChance = 10,
    AxosPremier = 11
}

export enum BRAND_NAME{
    Axos = 'axos',
    UFBDirect = 'ufbdirect',
    AxosBetaTesters = 'axosbetatesters',
    AxosAdvisor = 'axosadvisor',
    AxosSecondChance = 'axossecondchance',
    AxosBusinessBanking = 'axosbusinessbanking',
    NationWideBusinessBanking = 'nationwidebusinessbanking',
    NationwidePremier = 'nationwidepremier',
    NationWide = 'nationwide',
    NationwideSecondChance = 'nationwidesecondchange',
    AxosPremier = 'axospremier'
}

export enum BRAND_CONTEXT {
    All = 'all',
    Business = 'business',
    Consumer = 'consumer',
}
