export * from './primary';
export * from './joint';
export * from './teen';
export * from './minor';
export * from './trust';
export * from './joint';

// import { PRIMARY_PIP_STATES, PRIMARY_PIP_TRADING_STATES } from './primary';
import { PRIMARY_PIP_TRADING_STATES, PRIMARY_PIP_ADDITIONAL_OWNER_STATES } from './primary';
import { JOINT_PIP_STATES, JOINT_PIP_TRADING_STATES } from './joint';
import { TEEN_PIP_STATES } from './teen';
import { MINOR_PIP_STATES } from './minor';
import { TRUST_PIP_STATES } from './trust';

export const PIP_STATES = [
  // ...PRIMARY_PIP_STATES,
  ...PRIMARY_PIP_TRADING_STATES,
  ...PRIMARY_PIP_ADDITIONAL_OWNER_STATES,
  ...JOINT_PIP_STATES,
  ...JOINT_PIP_TRADING_STATES,
  ...TEEN_PIP_STATES,
  ...MINOR_PIP_STATES,
  ...TRUST_PIP_STATES
];
