export enum PHONE_TYPE {
    Home = 1,
	Business = 2,
	Cell = 3
}

export enum PHONE_TYPE_UNIVERSAL {
	Home = 'home',
	Work = 'work',
	Cell = 'cell',
	Fax = 'fax',
	Business = 'business'
}