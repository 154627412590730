import { Component } from '@angular/core';

@Component({
  selector: 'axos-missing-answers-popup',
  templateUrl: './missing-answers-popup.component.html',
  styleUrls: ['./missing-answers-popup.component.scss']
})
export class MissingAnswersPopupComponent {

  constructor() { }
}
