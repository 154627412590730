declare var environment: any;
// Ng
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
// RxJs
import { tap, take, withLatestFrom } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState, SetApplicationStateAction, SetCurrentApplicantTypeAction, logAction } from 'store';
// Routes
import { PRODUCTS_PARENT_PATH } from 'app/products/routing';
import { RULES_PARENT_ROUTE, RULES_ROUTES } from 'app/rules/constants';
import { OUTCOME_PARENT_ROUTE, OUTCOME_ROUTES } from 'app/outcome/constants';
import { DISCLOSURES_PARENT_PATH } from 'app/disclosures/routing';
import { BUSINESS_PARENT_PATH } from 'app/business-information/routing';
import { APPLICANT_INFORMATION_PARENT_PATH, APPLICANT_INFORMATION_ROUTES } from 'app/applicant-information/routing';
import { REVIEWSUBMIT_PARENT_ROUTE, REVIEWSUBMIT_ROUTE } from 'app/review-submit/route.constants';
// Models
import { IApplication, ISimpleProduct } from 'models';
import {
  BRAND_CONTEXT,
  SOURCE_TYPES,
  APPLICANT_TYPE_NAME,
  APPLICANT_TYPE_TO_NAME,
  APPLICATION_DECISION_STATUS,
} from 'models/enums';
import {
  APPLICATION_STATE,
  DMD_STATES,
  PIP_STATES,
  // PRIMARY_PIP_STATES,
  JOINT_PIP_STATES,
  TEEN_PIP_STATES,
  MINOR_PIP_STATES,
  TRUST_PIP_STATES,
  JOINT_PIP_TRADING_STATES,
  PRIMARY_PIP_TRADING_STATES,
  APPLICATION_STATUS
} from 'models/enums/appliation';
// Services
import { WebsiteService } from 'services/website.service';
// Utils
import { IS_TEEN } from '../utils';
import { APPLICANT_TYPE_NAME_PARAM } from '../constants';
import { AUTH_PARENT_ROUTE, AUTH_ROUTES } from 'app/auth/constants';
import { DOCUMENTS_PARENT_ROUTE, DOCUMENTS_ROUTES } from 'app/documents/constants';
import { FEATURES_PARENT_PATH } from 'app/trading-features/routing/constants';
import { getApplicationId } from 'store/selectors';
import { APPLICATION_INFORMATION_SECTIONS } from 'app/review-submit/components/constants';

@Injectable()
export class StateRouteService {
  isAxosUniversal: boolean = !!environment.isAxosUniversal;
  private firstCheck = true;
  private forceNavigation = false;
  private ignoreInFlightStates: APPLICATION_STATE[] = [
    APPLICATION_STATE.DocumentsRequested
  ]
  checkForInProgress = true;

  constructor(
    private store$: Store<EnrollmentState>,
    private router: Router,
    private websiteService: WebsiteService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  areProuctsSame(marketingProducts: ISimpleProduct[], appAccounts: { Id: number, ApplicationId: number, ProductCode: string }[]) {
    const appProducts = appAccounts.map(account => account.ProductCode).sort(),
      markProducts = marketingProducts.map(products => products.productCode).sort(),
      max = Math.max(appProducts.length, markProducts.length);
    for (let i = 0; i < max; i++) {
      const appProd = appProducts[i],
        markProd = markProducts[i],
        areSame = appProd === markProd;
      if (!areSame) { return false; }
    }
    return true;
  }

  correctRoute(application: IApplication,
    applicantType: APPLICANT_TYPE_NAME = APPLICANT_TYPE_NAME.Primary,
    marketingProducts: ISimpleProduct[],
    dmdEnabled: boolean) {
    if (this.forceNavigation) { return; }

    let url: string,
      actualStateId = application.stateId;

    if (this.firstCheck) {
      // if (actualStateId === APPLICATION_STATE.WaitingForCustomerToLiftFreeze) {
      //   url = `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.RETRY}`;
      // } else
      if (this.isExpiredApplication(application)) {
        url = `${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.EXPIREDAPPLICATION}`;
      } 
      // else if (DMD_STATES.includes(actualStateId) && dmdEnabled) {
      //   actualStateId = application.stateId = this.updateState(APPLICATION_STATE.VerifyYourIdentity, actualStateId);
      // }
      // if (!this.ignoreInFlightStates.includes(actualStateId) && !this.areProuctsSame(marketingProducts, application.Accounts)) {
      //   url = `${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.INPROGRESS}`;
      //   this.forceNavigation = true;
      // }
    }

    //Check if User is InFlight
    if (this.checkForInProgress) {
      this.checkForInProgress = false;
      // if (this.isInFligh(application) && !this.areProuctsSame(marketingProducts, application.Accounts)) {
      //   url = `${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.INPROGRESS}`;
      // }
    }


    // if (actualStateId === APPLICATION_STATE.AccountType && IS_TEEN(application.typeId)) {
    //   actualStateId = application.stateId = this.updateState(dmdEnabled
    //     // typeid has to be enrollertypeid
    //     && application.typeId !== SOURCE_TYPES.existingEnroller
    //     ? APPLICATION_STATE.VerifyYourIdentity
    //     : APPLICATION_STATE.PersonalInformation, actualStateId);
    // }

    // If DMD is disabled and Actual State is DMD
    if (DMD_STATES.includes(actualStateId) && !dmdEnabled) {
      if(!this.isAxosUniversal){
        //actualStateId = application.ApplicationState.ApplicationStateId = this.updateState(APPLICATION_STATE.reviewAndSubmit)
        actualStateId = application.stateId = this.updateState(APPLICATION_STATE.PersonalInformation, actualStateId);
      }
    }

    // Manage PIP States Routes
    if (PIP_STATES.includes(actualStateId) && !url) {
      const pipResult = this.getPIPStateRoute(application);
      if (applicantType !== pipResult.applicantType) {
        applicantType = pipResult.applicantType;
        this.store$.dispatch(new SetCurrentApplicantTypeAction(applicantType));
      }
      url = pipResult.url;
    }

    // If there is no URL get it from Application State
    if (!url) {
      url = this.getStateRoute(application, dmdEnabled
        && application.channelId !== SOURCE_TYPES.existingEnroller);
    }

    if (this.firstCheck) {
      this.firstCheck = false;
    }

    // If has URL navigate to it and replace params
    if (url) {
      const applicantName = applicantType;
      url = url.replace(`:${APPLICANT_TYPE_NAME_PARAM}`, applicantName);

      this.router.navigateByUrl(url)
        .then(() => {
          if (this.forceNavigation) {
            this.forceNavigation = false;
          }
          // console.log('Succesfully route to', url)
        })
        .catch((err) => {
          if (this.forceNavigation) {
            this.forceNavigation = false;
          }
          console.log('Error in route', url, err)
        });
    }
  }

  redirectToOnlineBanking() {
    return this.websiteService.getWebsite('OLB').pipe(
      withLatestFrom(this.store$.select(getApplicationId)),
      tap(data => {
        const website = data[0];
        const appId = data[1];
        this.store$.dispatch(logAction(`SSO to OLB, Applicationid: ${appId}`));
        this.document.location.href = website.site;
      })
    );
  }

  isInFligh(application: IApplication): boolean {
    const stateId = application.stateId,
      status = application.decisionStatus;
    const notInFlightStates: APPLICATION_STATE[] = [
      APPLICATION_STATE.DocumentsRequested,
      // APPLICATION_STATE.Cancelled,
      APPLICATION_STATE.Complete,
      // APPLICATION_STATE.ReferredFromAnotherBrand,
      APPLICATION_STATE.SystemError,
      // APPLICATION_STATE.AccountNumberError,
    ]
    // typeid has to be enrollertypeid
    return application.typeId !== SOURCE_TYPES.newEnroller &&
      !notInFlightStates.includes(stateId) &&
      status !== APPLICATION_DECISION_STATUS.Cancelled;
  }

  isExpiredApplication(application: IApplication): boolean {
    //TODO Include validations to trigger decline returning
    const stateId = application.stateId,
      status = application.decisionStatus;
    return (stateId === APPLICATION_STATE.Complete && 
        (status === APPLICATION_DECISION_STATUS.AutoDeclined || status === APPLICATION_DECISION_STATUS.ManuallyDeclined)) ||
      status === APPLICATION_DECISION_STATUS.Cancelled;
      // ||
      // stateId === APPLICATION_STATE.Cancelled;
  }

  private updateState(stateId: APPLICATION_STATE, currentStateId?: APPLICATION_STATE) {
    if (stateId !== currentStateId) {
      this.store$.dispatch(new SetApplicationStateAction(stateId));
    }
    return stateId;
  }

  private getStateRoute(application: IApplication, showDMD = false) {
    const declinedURL = `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.DECLINED}`,
      approvedURL = `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.APPROVED}`,
      reviewURL = `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.INREVIEW}`;
    switch (application.stateId) {
      case APPLICATION_STATE.AccountType:
        if (IS_TEEN(application.typeId)) {
          return showDMD ? `id/verificationmethod/:${APPLICANT_TYPE_NAME_PARAM}` : `${APPLICANT_INFORMATION_PARENT_PATH}/:${APPLICANT_TYPE_NAME_PARAM}/${APPLICANT_INFORMATION_ROUTES.INFO}`;
        }
        return PRODUCTS_PARENT_PATH;
      // case APPLICATION_STATE.disclosures:
      //   return DISCLOSURES_PARENT_PATH;
      // case APPLICATION_STATE.PersonalInformation:
      //   return environment.brandContext === BRAND_CONTEXT.Business ?
      //     BUSINESS_PARENT_PATH : APPLICANT_INFORMATION_PARENT_PATH;
      case APPLICATION_STATE.ReviewAndSubmit:
        if(application.status === APPLICATION_STATUS.SystemFailure){
          return 'systemError';
        }
        return REVIEWSUBMIT_PARENT_ROUTE;
      case APPLICATION_STATE.EditInformation:
        return 'reviewAndSubmit/edit';
      //case APPLICATION_STATE.KBAStillToBeAnswered:
      case APPLICATION_STATE.KBAToBeRequested:
        return `${RULES_PARENT_ROUTE}/${RULES_ROUTES.IDA_QUESTIONS}`;
      // case APPLICATION_STATE.PendingMitekUpload:
      // case APPLICATION_STATE.MitekUploadPage:
      //   return `${RULES_PARENT_ROUTE}/${RULES_ROUTES.ID_UPLOAD}`;
      case APPLICATION_STATE.DocumentsRequested:
        return `${DOCUMENTS_PARENT_ROUTE}/${DOCUMENTS_ROUTES.NEEDED}`;
      // case APPLICATION_STATE.TaskListPreBoarding:
      // case APPLICATION_STATE.Funding:
      // case APPLICATION_STATE.PingsSent:
        return approvedURL;
      case APPLICATION_STATE.Complete:
        switch (application.decisionStatus) {
          case APPLICATION_DECISION_STATUS.AutoApproved:
          case APPLICATION_DECISION_STATUS.ManuallyApproved:
          case APPLICATION_DECISION_STATUS.ApprovedAutoPendingPrincipalReview:
            return this.firstCheck ? approvedURL : `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.APPROVED_FAILURE}`;
          case APPLICATION_DECISION_STATUS.AutoDeclined:
          case APPLICATION_DECISION_STATUS.ManuallyDeclined:
            return declinedURL;
          case APPLICATION_DECISION_STATUS.InReview:
            return reviewURL;
        }
        return 'Route Not Mapped';
      // case APPLICATION_STATE.ApprovedbyManager:
      //   return `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.SUBMITTED}`;
      // DMD
      // case APPLICATION_STATE.VerifyYourIdentity:
      //   return `id/verificationmethod/:${APPLICANT_TYPE_NAME_PARAM}`;
      // REVIEW
      case APPLICATION_STATE.Submitted:
      case APPLICATION_STATE.RMNewApplications:
      //case APPLICATION_STATE.PendingCompletionKBA:
      case APPLICATION_STATE.PendingDocumentReview:
      case APPLICATION_STATE.PendingContactInfoReview:
      case APPLICATION_STATE.PendingApplicationReview:
      case APPLICATION_STATE.InReview:
        return reviewURL;
      // case APPLICATION_STATE.PendingBankReview:
      case APPLICATION_STATE.WaitingforManagerApproval:
      case APPLICATION_STATE.PendingApplicationReviewRM:
      case APPLICATION_STATE.PendingApplicationReviewBSA:
      //case APPLICATION_STATE.ResponseNeededFromCustomer:
      //case APPLICATION_STATE.WaitingForCustomerToLiftFreeze:
      // case APPLICATION_STATE.PendingApplicationReviewManager:
      //   if (application.ApplicationState.PriorApplicationStateId === application.ApplicationState.stateId &&
      //     application.ApplicationState.stateId === APPLICATION_STATE.WaitingForCustomerToLiftFreeze) {
      //     return `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.RETRY}`;
      //   }
      //   else {
      //     return reviewURL;
      //   }
      // case APPLICATION_STATE.TaskListPostBoarding:
      //   this.redirectToOnlineBanking().subscribe();
      //   return null;
      // System Error
      case APPLICATION_STATE.SystemError:
      case APPLICATION_STATE.AccountNumberError:
        return 'systemError';
      case APPLICATION_STATE.Boarding:
      case APPLICATION_STATE.DecisionComplete:
      //case APPLICATION_STATE.PendingBusinessOlbRegistration:
        return 'Route Not Mapped';
      // Trading
      case APPLICATION_STATE.AccountFeature:
        return FEATURES_PARENT_PATH;
      // case APPLICATION_STATE.PendingAdditionalOwnerSubmit:
      //   return `${OUTCOME_PARENT_ROUTE}/${OUTCOME_ROUTES.PENDING_ADDITIONAL_OWNER}`;
    }
  }

  getPIPStateRoute(application: IApplication): { url: string, applicantType: APPLICANT_TYPE_NAME } {
    const stateId = application.stateId,
      applicantType = this.getPIPApplicantType(stateId);
    let path = this.getPIPPath(stateId);
    if (applicantType === APPLICANT_TYPE_NAME.Primary && application.typeId === SOURCE_TYPES.existingEnroller) {
      path = APPLICANT_INFORMATION_ROUTES.EXISTING;
    }
    return {
      url: `${APPLICANT_INFORMATION_PARENT_PATH}/:${APPLICANT_TYPE_NAME_PARAM}/${path}`,
      applicantType
    };
  }

  private getPIPApplicantType(stateId: APPLICATION_STATE): APPLICANT_TYPE_NAME {
    if (PRIMARY_PIP_TRADING_STATES.includes(stateId)) {
      return APPLICANT_TYPE_NAME.Primary;
    } else if (JOINT_PIP_STATES.includes(stateId) || JOINT_PIP_TRADING_STATES.includes(stateId)) {
      return APPLICANT_TYPE_NAME.Joint;
    } else if (TEEN_PIP_STATES.includes(stateId)) {
      return APPLICANT_TYPE_NAME.Teen;
    } else if (MINOR_PIP_STATES.includes(stateId)) {
      return APPLICANT_TYPE_NAME.Minor;
    } else if (TRUST_PIP_STATES.includes(stateId)) {
      return APPLICANT_TYPE_NAME.Trust;
    }
    return APPLICANT_TYPE_NAME.Primary;
  }

  private getPIPPath(stateId: APPLICATION_STATE): string {
    switch (stateId) {
    //   case APPLICATION_STATE.PersonalInformationPrimary:
    //   case APPLICATION_STATE.PersonalInformationJoint:
    //   case APPLICATION_STATE.PersonalInformationTeen:
    //   case APPLICATION_STATE.PersonalInformationMinor:
    //     return APPLICANT_INFORMATION_ROUTES.INFO;
    //   case APPLICATION_STATE.DobSSNInfoPrimary:
    //   case APPLICATION_STATE.DobSSNInfoJoint:
    //   case APPLICATION_STATE.DobSSNInfoTeen:
    //   case APPLICATION_STATE.DobSSNInfoMinor:
    //     return APPLICANT_INFORMATION_ROUTES.DOB;
    //   case APPLICATION_STATE.ContactInformationJoint:
    //   case APPLICATION_STATE.ContactInformationTeen:
    //     return APPLICANT_INFORMATION_ROUTES.CONTACT_INFO;
    //   case APPLICATION_STATE.HomeAddressInfoPrimary:
    //   case APPLICATION_STATE.HomeAddressInfoJoint:
    //   case APPLICATION_STATE.HomeAddressInfoTeen:
    //   case APPLICATION_STATE.HomeAddressInfoMinor:
    //     return APPLICANT_INFORMATION_ROUTES.HOME_ADDRESS;
    //   case APPLICATION_STATE.HomeAddressQuestionJoint:
    //   case APPLICATION_STATE.HomeAddressQuestionTeen:
    //   case APPLICATION_STATE.HomeAddressQuestionMinor:
    //     return APPLICANT_INFORMATION_ROUTES.HOME_ADDRESS_SAME_AS_PRIMARY;
    //   case APPLICATION_STATE.MailingAddressInfoPrimary:
    //   case APPLICATION_STATE.MailingAddressInfoJoint:
    //   case APPLICATION_STATE.MailingAddressInfoTeen:
    //     return APPLICANT_INFORMATION_ROUTES.MAILING_ADDRESS;
    //   case APPLICATION_STATE.MailingAddressQuestionPrimary:
    //   case APPLICATION_STATE.MailingAddressQuestionJoint:
    //   case APPLICATION_STATE.MailingAddressQuestionTeen:
    //     return APPLICANT_INFORMATION_ROUTES.MAILING_ADDRESS_SELECTION;
    //   case APPLICATION_STATE.IdTypeSelectionManualPrimary:
    //   case APPLICATION_STATE.IdTypeSelectionManualJoint:
    //     return APPLICANT_INFORMATION_ROUTES.ID_SELECTION;
    //   case APPLICATION_STATE.IdInformationPrimary:
    //   case APPLICATION_STATE.IdInfoJoint:
    //     return APPLICANT_INFORMATION_ROUTES.ID_INFORMATION;
    //   case APPLICATION_STATE.IdAddressInfoPrimary:
    //   case APPLICATION_STATE.IdAddressInfoJoint:
    //     return APPLICANT_INFORMATION_ROUTES.ID_ADDRESS;
    //   case APPLICATION_STATE.IdAddressQuestionPrimary:
    //   case APPLICATION_STATE.IdAddressQuestionJoint:
    //     return APPLICANT_INFORMATION_ROUTES.ID_ADDRESS_SELECTION;
      // case APPLICATION_STATE.TrustInfo:
      //   return APPLICANT_INFORMATION_ROUTES.TRUST_CERTIFICATION;
      case APPLICATION_STATE.FinancialInformation:
        return APPLICANT_INFORMATION_ROUTES.FINANCIAL_INFORMATION;
      case APPLICATION_STATE.PersonalInformation:
      case APPLICATION_STATE.JointPersonalInformation:
        return APPLICANT_INFORMATION_ROUTES.TRADING_PRIMARY;
      case APPLICATION_STATE.InvestmentProfile:
        return APPLICANT_INFORMATION_ROUTES.INVESTMENT_PROFILE;
      case APPLICATION_STATE.Beneficiaries:
        return APPLICANT_INFORMATION_ROUTES.BENEFICIARIES;
      case APPLICATION_STATE.ContingentBeneficiaries:
        return APPLICANT_INFORMATION_ROUTES.CONTINGENT_BENEFICIARIES;
      case APPLICATION_STATE.AddressInformation:
        return APPLICANT_INFORMATION_ROUTES.HOME_MAILING_ADDRESS
      case APPLICATION_STATE.AdditionalOwner:
        return APPLICANT_INFORMATION_ROUTES.TRADING_ADDITIONAL_OWNER;
      // No state for this routes
      /* case 9999:
        return APPLICANT_INFORMATION_ROUTES.HOME_ADDRESS_SAME_AS_ID;
      case 99999:
        return APPLICANT_INFORMATION_ROUTES.MITEK;
      case 999999:
        return APPLICANT_INFORMATION_ROUTES.ENTRY_METHOD;
      case 9999999:
        return APPLICANT_INFORMATION_ROUTES.ID_UPLOAD_PASSPORT;
      case 99999999:
        return APPLICANT_INFORMATION_ROUTES.ID_UPLOAD; */
    }
    return APPLICANT_INFORMATION_ROUTES.INFO;
  }
}
