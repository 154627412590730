import { CatalogsState } from '../states';
import * as fromActions from '../actions/catalogs.action';

const initialCatalogState = {
    loaded: false,
    loading: false,
    data: []
}

export const initialState: CatalogsState = {
    occupations: { ...initialCatalogState },
    companyTitles: { ...initialCatalogState },
    affiliations: { ...initialCatalogState },
    states: { ...initialCatalogState },
    countries: { ...initialCatalogState },
    annualSales: { ...initialCatalogState },
    financialInformation: null,
    relationShips: { ...initialCatalogState }
}

export function reducer(
    state = initialState,
    action: fromActions.CatalogsAction
): CatalogsState {
    switch(action.type) {
        // States
        case fromActions.CATALOGS_ACTION_TYPES.GET_STATES: {
            return {
                ...state,
                states: {
                    ...state.states,
                    loading: true
                }
            };
        }
        case fromActions.CATALOGS_ACTION_TYPES.GET_STATES_SUCCESS: {
            return {
                ...state,
                states: {
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        }
        // Countries
        case fromActions.CATALOGS_ACTION_TYPES.GET_COUNTRIES: {
            return {
                ...state,
                countries: {
                    ...state.countries,
                    loading: true
                }
            };
        }
        case fromActions.CATALOGS_ACTION_TYPES.GET_COUNTRIES_SUCCESS: {
            return {
                ...state,
                countries: {
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        }
        // Occupations
        case fromActions.CATALOGS_ACTION_TYPES.GET_OCUPATIONS: {
            return {
                ...state,
                occupations: {
                    ...state.occupations,
                    loading: true
                }
            };
        }
        case fromActions.CATALOGS_ACTION_TYPES.GET_OCUPATIONS_SUCCESS: {
            return {
                ...state,
                occupations: {
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        }
        case fromActions.CATALOGS_ACTION_TYPES.GET_AFFILIATIONS: {
            return {
                ...state,
                affiliations: {
                    ...state.affiliations,
                    loading: true
                }
            };
        }
        case fromActions.CATALOGS_ACTION_TYPES.GET_AFFILIATIONS_SUCCESS: {
            return {
                ...state,
                affiliations: {
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        }
        case fromActions.CATALOGS_ACTION_TYPES.GET_COMPANY_TITLES: {
            return {
                ...state,
                companyTitles: {
                    ...state.companyTitles,
                    loading: true
                }
            };
        }
        case fromActions.CATALOGS_ACTION_TYPES.GET_COMPANY_TITLES_SUCCESS: {
            return {
                ...state,
                companyTitles: {
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        }
        // Annual Sales
        case fromActions.CATALOGS_ACTION_TYPES.GET_ANNUAL_SALES: {
            return {
                ...state,
                annualSales: {
                    ...state.annualSales,
                    loading: true
                }
            };
        }
        case fromActions.CATALOGS_ACTION_TYPES.GET_ANNUAL_SALES_SUCCESS: {
            return {
                ...state,
                annualSales: {
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        }
        case fromActions.CATALOGS_ACTION_TYPES.GET_FINANCIAL_CATALOG_INFORMATION_SUCCESS: {
            return { ...state, financialInformation: action.data }
        }
        case fromActions.CATALOGS_ACTION_TYPES.GET_BENEFICIARIES_CATALOG_SUCCESS: {
            return { 
                ...state, 
                relationShips:{
                    loaded: true,
                    loading: false,
                    data: action.data
                } 
            }
        }
    }
    return state;
}
