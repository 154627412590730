import { createReducer, on } from '@ngrx/store';
import { LandingState } from '../states';
import * as fromActions from '../actions/landing.action';

export const reducer = createReducer<LandingState>({
        isExisting: false
    },
    on(fromActions.SetLandingIsExistingAction, (state, { isExisting }) => ({
        ...state,
        isExisting,
    })),
);