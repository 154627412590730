declare var environment: any;
import { Injectable } from '@angular/core';
// Services
import { BaseService } from './abstracts';
// Models
import { Broker, Organization } from 'models';
import { map } from 'rxjs/operators';
import { AxosResult } from 'models/enums/trading';

@Injectable()
export class OrganizationService extends BaseService {
  get(organizationName: String, settings: boolean = false) {
    return this._get<Organization>(`organization/${organizationName}?settings=${settings}`);
  }

  getBrokerInformation(token: string) {
    const organizationName = environment.organizationName || 'axos';
    return this._get<AxosResult<Broker>>(`organization/${organizationName}/broker/${token}`).pipe(
      map(result => {
        return result.data ? result.data : false;
      })
    );
  }
}
