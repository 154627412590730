import { Component } from '@angular/core';

@Component({
  selector: 'axos-credit-freeze-popup',
  templateUrl: './credit-freeze-popup.component.html',
  styleUrls: ['./credit-freeze-popup.component.scss']
})
export class CreditFreezePopupComponent {
  constructor() {}
}
