import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJs
import { Observable } from 'rxjs';
// Core
import { LoadingService } from 'app/core/services';
import { API_URL } from 'app/core/constants';
// Models
import { IAuthToken } from 'models';
import {
  IdaResult,
  RulesResult,
  IdaUserQuestionnaireAnswers,
  ApplicantTypesMitek,
  IRulesDataResponse
} from 'models/rules';
import { BaseService } from './abstracts';

// RxJs
import { map } from "rxjs/operators";
import { AxosResult } from 'models/enums/trading';

@Injectable()
export class RulesService extends BaseService {

  constructor(
    http: HttpClient,
    @Inject(API_URL) apiURL: string,
    loadingService: LoadingService) {
    super(http, loadingService, apiURL);
  }

  runMitekRules(applicationId: string): Observable<RulesResult> {
    return this._post<RulesResult>(`v2/applications/runMitekRules/${applicationId}`);
  }

  getApplicantsMitekDocuments(applicationId: string): Observable<ApplicantTypesMitek> {
    return this._get<ApplicantTypesMitek>(`v2/applicantMitekDocuments/${applicationId}`);
  }

  parser(applicationId: string): Observable<number> {
    return this._get<number>(`rulesetlog/application/${applicationId}`);
  }

  validateApplication(applicationId: string): Observable<IRulesDataResponse> {
    return this._post<AxosResult<IRulesDataResponse>>(`application/${applicationId}/validate`).pipe(
      map(res =>  res.data)
    );
  }

  getIdaQuestions(applicationId: string, isRetry): Observable<IdaResult> {
    return this._post<IdaResult>(`v2/applications/initiateIda/${applicationId}/${isRetry}`);
  }

  resumeRules(applicationId: string, answers?: IdaUserQuestionnaireAnswers[]): Observable<RulesResult> {
    return this._post<RulesResult>(`v2/applications/runResumeRules/${applicationId}`, answers);
  }

  updateToPendingKBA(applicationId) {
    return this._put<boolean>(`v2/Applications/KBA/Pending/${applicationId}`, {});
  }

  updateToPendingBankReviewXMLHttp(applicationId: string, token: IAuthToken) {
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open('PUT', `${this.baseUrl}/api/v2/Applications/KBA/PendingBankReview/${applicationId}`, false);//the false is for making the call synchronous
    this.XML_Http_Request(xmlhttp, token);
  }

  updateToPendingKBAXMLHttp(applicationId: string, token: IAuthToken) {
    var xmlhttp = new XMLHttpRequest();
    //the false is for making the call synchronous
    xmlhttp.open('PUT', `${this.baseUrl}/api/v2/Applications/KBA/Pending/${applicationId}`, false);
    this.XML_Http_Request(xmlhttp, token);
  }

  private XML_Http_Request(xmlhttp: XMLHttpRequest, token: IAuthToken) {
    xmlhttp.setRequestHeader('Content-type', 'application/json');
    xmlhttp.setRequestHeader('Authorization', 'Bearer ' + token.access_token);
    xmlhttp.onreadystatechange = state_change;
    xmlhttp.send();
    function state_change() {
      //Request finished and response is ready
      if (xmlhttp.readyState === 4) {
        console.log(xmlhttp.status === 200 ? xmlhttp.responseText : this.xmlhttp);
      }
    }
  }
}

