import { ModuleRouteConfig } from "models/navigation";

export const PROXY_PARENT_PATH = '';
export const PROXY_MODULE_PREFIX = 'proxy';

export const PROXY_ROUTES: { [key: string]: ModuleRouteConfig } = {
    LANDING: {
        name: `${PROXY_MODULE_PREFIX}_LANDING`,
        path: ''
    },
    SEARCH_USER: {
        name: `${PROXY_MODULE_PREFIX}_SEARCH_CUSTOMER`,
        path: 'searchcustomer'
    },
    PRODUCT_SELECTION: {
        name: `${PROXY_MODULE_PREFIX}_PRODCUCT_SELECTION`,
        path: 'productselection'
    },
    REGISTRATION: {
        name: `${PROXY_MODULE_PREFIX}_REGISTRATION`,
        path: 'registration'
    },
    CONTACT_INFO: {
        name: `${PROXY_MODULE_PREFIX}_CONTACT`,
        path: 'contact'
    },
    HANDOFF_SUCCESS: {
        name: `${PROXY_MODULE_PREFIX}_HANDOFF_SUCCESS`,
        path: 'handoffSuccess'
    }
}