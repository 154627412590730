import { Resolve } from '@angular/router';

import { DetailDisclosure } from 'models';
import { Observable } from 'rxjs';
import { filter, first, tap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EnrollmentState, GetDetailDisclosuresAction } from 'store';
import { getDetailDisclosures } from 'store/selectors/disclosure.selectors';
import { getApplicationLoaded } from 'store/selectors';

export class DetailDisclosureResolver implements Resolve<Observable<DetailDisclosure[]>> {
    constructor(private store$: Store<EnrollmentState>) {  }

   resolve(){
        return this.store$.select(getApplicationLoaded).pipe(
          filter(loaded => loaded),
          switchMap(() => this.store$.select(getDetailDisclosures)),
          tap(disclosures => {
              if(!disclosures){
                  this.store$.dispatch(new GetDetailDisclosuresAction());
              }
          }),
          filter(disclosures => !!disclosures),
          first()
        )
   }
}
