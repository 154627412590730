export enum VESTING_TYPE_ID {
    None = 0,
    Individual = 1,
    Joint = 2,
    Trust = 3,
    UTMA = 4,
    Teen = 5,
    LLC = 6,
    Corporation = 7,
    SoleProprietorship = 8,
    GeneralPartnership = 9,
    LLP = 10
}

export enum VESTING_TYPE_NAME {
    None = 0,
    Individual = 'individual',
    Joint = 'joint',
    Trust = 'trust',
    UTMA = 'UTMA',
    Teen = 'Teen',
    LLC = 'LLC',
    Corporation = 'Corporation',
    SoleProprietorship = 'Sole Proprietorship',
    GeneralPartnership = 'General Partnership',
    LLP = 'LLP'
}