import { Injectable } from '@angular/core';

import { of, Observable } from 'rxjs';

import { IUserProspect } from 'models';
import { BaseService } from 'services/abstracts';

@Injectable()
export class UserProspectMockService extends BaseService {

  create(user: IUserProspect): Observable<IUserProspect> {
    return of(user);
  }
}
