import {
    createSelector,
    createFeatureSelector
} from '@ngrx/store';

import { OrganizationState } from '../states';
import { FEATURE_NAMES } from '../constants';

export const getOrganizationState = createFeatureSelector(FEATURE_NAMES.Organization);

export const getOrganization = createSelector(
    getOrganizationState, (state: OrganizationState) => state.organization
);

export const getOrganizationEmail = createSelector(
    getOrganizationState, (state: OrganizationState) => state.organization ? state.organization.email : ''
);

export const getOrganizationPhone = createSelector(
    getOrganizationState, (state: OrganizationState) => state.organization ? state.organization.phone : ''
);

export const getOrganizationDisplayNameOnly = createSelector(
    getOrganizationState, (state: OrganizationState) => state.organization ? state.organization.displayName: ''
)

export const getOrganizationDisplayName = createSelector(
    getOrganizationState, (state: OrganizationState) => state.organization ? state.organization.displayName + ' | Enroll' : ''
)