import { Directive } from '@angular/core';

@Directive({
  selector: '[axosSuffix]'
})
export class SuffixDirective {

  constructor() { }

}
