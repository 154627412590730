import { ModuleRouteConfig } from "models";

export const FEATURES_PARENT_PATH = 'features';

export const FEATURES_ROUTES: { [key: string]: ModuleRouteConfig } = {
    ACCOUNT_FEATURES: {
        name: 'ACCOUNT_FEATURES',
        path: ''
    },
    OPTION_LEVELS: {
        name: 'OPTION_LEVELS',
        path: 'optionslevel'
    },
}