export * from './banner/banner.component';
export * from './footer/footer.component';
export * from './footer-mobile/footer-mobile.component';
export * from './header/header.component';
export * from './header-mobile/header-mobile.component';
export * from './progress-bar/progress-bar.component';
export * from './loading-spinner/loading-spinner.component';
export * from './live-chat/live-chat.component';
export * from './layout-template/layout-template.component';
export * from './browser-banner/browser-banner.component';
export * from './chat-bot/chat-bot.component'

import { BannerComponent } from './banner/banner.component';
import { FooterComponent } from './footer/footer.component';
import { FooterMobileComponent } from './footer-mobile/footer-mobile.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { LayoutTemplateComponent } from './layout-template/layout-template.component';
import { BrowserBannerComponent } from './browser-banner/browser-banner.component';
import { ChatBotComponent } from './chat-bot/chat-bot.component'

export const components = [
    LoadingSpinnerComponent,
    // Layout Components
    LayoutTemplateComponent,
    HeaderComponent,
    FooterComponent,
    HeaderMobileComponent,
    FooterMobileComponent,
    // Banner
    BannerComponent,
    ProgressBarComponent,
    LiveChatComponent,
    BrowserBannerComponent,
    ChatBotComponent
]
