// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetProductsAction } from 'store/actions';
import { getSelectedProducts } from 'store/selectors';
// Model
import { VestingType, ISimpleProduct } from 'models';

@Injectable()
export class SelectedProductsResolver implements Resolve<Observable<ISimpleProduct[]>> {

    constructor(private store$: Store<EnrollmentState>) {  }

     resolve() {
        return this.store$.select(getSelectedProducts).pipe(
            tap(products => {
                if(products.length <= 0) {
                    this.store$.dispatch(new GetProductsAction());
                }
            }),
            filter(products => products.length > 0),
            first()
        );
    }
}
