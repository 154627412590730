declare var environment: any;
// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getOrganization } from 'store/selectors';
// Model
import { Organization } from 'models';

@Injectable()
export class OrganizationResolver implements Resolve<Observable<Organization>> {

  constructor(private store$: Store<EnrollmentState>) {  }

  resolve() {
    return this.store$.select(getOrganization).pipe(
      map(data => {        
        return {
            name: data.name,
            displayName: data.displayName,
            url: data.url,
            email: data.email,
            phone: data.phone,
            settings: data.settings
        } as Organization
      })
    );
  }
}
