import { createAction, props } from "@ngrx/store";
import { Broker } from "models";

export const getBrokerInformation = createAction(
  '[Broker] Get Broker Information',
  props<{ guid: string }>()
)

export const getBrokerInformationSuccess = createAction(
  '[Broker] Get Broker Information success',
  props<{ broker: Broker }>()
)

export const getBrokerInformationFail = createAction(
  '[Broker] Get Broker Information fail'
)
