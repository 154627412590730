import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'axos-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {

  @Input() info: string;

  constructor() { }

}
