import { AfterContentInit, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { GET_NUMBER_CHAR, IS_DELETE_KEY, IS_DIGIT_KEY, IS_ENTER_KEY, IS_SYSTEM_KEY, UkFieldError, UkUserAgentDetectorService } from '@uikit/components';
import { BaseInputComponent } from './base-input.component';

export function MASK_SECTION_VALIDATOR(maskSections: number[], separator: string, optionalSeparator: boolean = false) {
    if (optionalSeparator === void 0) { optionalSeparator = false; }
    let _validator = [];
    maskSections.forEach((part, idx) => {
		const _partExp = `(\\d{${part}})`;
        _validator.push(_partExp);
        if(idx < maskSections.length - 1) {
			_validator.push(`(\\${separator})${optionalSeparator ? '?' : ''}`) 
		}
	});
    return new RegExp(`^${_validator.join('')}$`);
}

export interface MaskInputValue {
    value: string;
    position: number;
}
  
export abstract class Mask extends BaseInputComponent implements AfterContentInit {
    private _maskSections: number[];
    private _separator: string;
    private _secondSeparator = '';
    private separatorReg: RegExp;
    private secondSeparatorReg: RegExp = new RegExp('');
    protected leftToRight = true;
    protected mask: RegExp;
    protected maxLength: number;
    protected validationError: UkFieldError;
  
    /**
    * Function to transform the value if this is assigned on initialize
    */
    protected functionAfterContentInit: Function = new Function();
  
    @Input() validator: RegExp;
    private _showMaskTyped = false;
    
    @Input()
    get showMaskTyped() { return this._showMaskTyped; }
    set showMaskTyped(value) {
        if (value) { this._showMaskTyped = value; }
        this.separator = this._separator;
    }
  
    @Input()
    get separator() { return this._separator; }
    set separator(value: string) {
        this._separator = value;
        this.separatorReg = new RegExp(this.showMaskTyped ?
            `[\\${this._separator}\\\_]` :
            `\\${this._separator}`    
        , 'g');
    }
      
    @Input()
    get secondSeparator() { return this._secondSeparator; }
    set secondSeparator(value: string) {
        this._secondSeparator = value;
        this.secondSeparatorReg = new RegExp(this.showMaskTyped ?
        `[\\${this._secondSeparator}\\\_]` :
        `\\${this._secondSeparator}`
        , 'g');
    }
  
    @HostListener('keyup', ['$event'])
    onKeyUp($event: KeyboardEvent) {
        const input = $event.target as HTMLInputElement;
        const value = input.value;
        if (IS_DELETE_KEY($event.key)) {
            this.keyUpHandler(input, value);
        }
    }
  
    @HostListener('input', ['$event'])
    oninput($event/*: InputEvent is commented because fails in IE11 */) {
        if (this.userAgentDetectorService.getUserAgent() === 'ANDROID'){
            const input = $event.target as HTMLInputElement;
            if ($event.isComposing && $event.data) {
                const pos = $event.data.length - 1;
                const key = String($event.data.charCodeAt(pos));
                if (IS_DIGIT_KEY($event.data[pos])) {
                    this.keyDownHandler(input, key);
                }
            } else {
                this.keyUpHandler(input, input.value);
            }
        }
    }
  
    @HostListener('keydown', ['$event'])
    onKeyDown($event: KeyboardEvent) {
        const input = $event.target as HTMLInputElement;
        const key = $event.key;

        if (!this.isSystemKey($event)) { $event.preventDefault(); }

        if (this.isValidKey($event)) {
            this.keyDownHandler(input, key);
        }
    }
  
    constructor(
        elementRef: ElementRef,
        public ngControl: NgControl,
        componentName: string,
        protected userAgentDetectorService: UkUserAgentDetectorService
    ) {
        super(elementRef, ngControl, componentName);
    }
  
    // Angular LifeCycle Hooks
    ngAfterContentInit() { 
        if (this.value) { 
            this.functionAfterContentInit(); 
        } 
    }

    registerAfterContentInit(fn: Function) { 
        this.functionAfterContentInit = fn; 
    }
  
    get maskSections() { 
        return this._maskSections; 
    }
    set maskSections(value: number[]) {
        this._maskSections = value;
        this.setMaxLength();
        this.createRegExp();
    }
  
    protected keyUpHandler(input: HTMLInputElement, value: string) {
        const cPos = input.selectionStart;
        const cTxt = this.cleanText(value, cPos, cPos);
        this.setFormatedText(cTxt.value, cTxt.position, input, true);
    }
  
    protected keyDownHandler(input: HTMLInputElement, key: string) {
        let value = input.value;
        let cPos = input.selectionStart;

        const cTxt = this.cleanText(value, cPos, input.selectionEnd);
        value = cTxt.value;
        cPos = cTxt.position;

        if (value.length < this.maxLength) {
            const before = value.substring(0, cPos);
            const after = value.substring(cPos);
            const newValue = this.proccessKeyDown(before, after, key);
            this.setFormatedText(newValue, cPos + 1, input, true);
        }

        if (IS_ENTER_KEY(key)) {
            if (input.form) {
                if (!this.invalid && input.form.checkValidity()) { 
                    input.form.submit(); 
                }
            }
        }
    }
  
    protected createRegExp(){
        const mask = [];
        this.maskSections.forEach((part) => {
            const partExp = `(\\d{${part}})`;
            mask.push(partExp);
        });
        this.mask = new RegExp(`^${mask.join('')}.*`);
    }
  
    protected setPlaceHolder() {
        const pad = new Array(this.maxLength + 1).join('0');
        this.placeholder = this.format(pad).replace(new RegExp('0','g'), '#');
    }
  
    private setMaxLength() {
        const sepChars = this.separator.length * (this.maskSections.length - 1);
        this.maxLength = this.maskSections.indexOf(-1) >= 0 ? 50 :
            this.maskSections.reduce((p, c) => p + c) + sepChars;
    }
  
    private setFormatedText(value: string, position: number, input: HTMLInputElement, end: boolean = false) {
        const newValues = this.spaceFormat(value, position);
        const newPos = newValues.position;
        // Set value in control without mask
        this.value = newValues.value;
        // Set value in view with mask
        const newVal = this.showMask(newValues.value);
        input.value = newVal;
        input.selectionStart = newPos;
        if (end) { 
            input.selectionEnd = newPos; 
        }
    }
  
    proccessKeyDown(before: string, after: string, key: string) {
        return before + GET_NUMBER_CHAR(key) + after;
    }
  
    cleanText(value: string, cPos: number, cEnd: number): MaskInputValue {
        let newValue = value;
        // Remove Selected text
        if (cPos !== cEnd) { 
            newValue = value.substring(0, cPos) + value.substring(cEnd); 
        }

        cPos = this.spaceCaretPosition(value, cPos);
        return {
            position: cPos,
            value: newValue.replace(this.separatorReg, '')
        };
    }
  
    // Add spaces
    spaceFormat(value: string, position: number): MaskInputValue {
        let secondValue = '';
        
        // Check if is dynamic mask(a negative value in this.maskSections)
        const dynamicMask = this.maskSections.indexOf(-1) >= 0 ? true : false;
        
        // Check if secondValue and secondSeparator exist
        if (
            dynamicMask && this.maskSections.length === 3 &&
            this.secondSeparator && value.indexOf(this.secondSeparator) >= 0
        ) {
            secondValue = value.substr(value.indexOf(this.secondSeparator) + this.secondSeparator.length, this.maskSections[2]);
            value.replace(this.secondSeparatorReg, '');
            value = value.substring(0, value.indexOf(this.secondSeparator));
        }
        
        // Populate maskSections if is dynamic
        let sections: Array<number>;
        if (dynamicMask){
            sections = new Array(Math.ceil(value.length / this.maskSections[0]));
            for (let i = 0; i < sections.length; i++) {
                sections[i] = this.maskSections[0];
            }
        } else {
            sections = this.maskSections.slice();
        }
        
        let newValue = []
        let cIdx = this.leftToRight ? 0 : value.length;

        while (sections.length > 0) {
            const secMax = this.leftToRight ?
                cIdx + sections.shift() :
                cIdx - sections.shift();

            if (cIdx < value.length || dynamicMask) {
                if (position > secMax && secMax > 0) { 
                    position++; 
                }
                const start = this.leftToRight ? cIdx : secMax;
                const end = this.leftToRight ? secMax : cIdx;
                newValue.push(value.substring(start, end));
            }

            cIdx = secMax;
        }

        // Order values according to leftToRight property
        if (!this.leftToRight) { 
            newValue = newValue.reverse(); 
        }
        const organizedValue = newValue.join(this.separator) +
            (this.secondSeparator && secondValue !== '' ? this.secondSeparator + secondValue : '');

        return {
            value: organizedValue,
            position
        };
    }
      
    // Put a guide of spaces to type according to maskSections
    showMask(value: string): string {
        if (this.showMaskTyped && this.type !== 'password') {
            const oldValues = value.split(this.separator);
            const newVal = [];
            this.maskSections.forEach((section: number, idx: number) => {
                let tmpValue = oldValues[idx] ? oldValues[idx] : '';
                while (tmpValue.length < section) {
                    tmpValue += '_';
                }
                newVal.push(tmpValue);
            });            
            return newVal.join(this.separator);
        }
        return value.toString();
    }
  
    // Get the right caret position without the spaces
    spaceCaretPosition(value: string, cPos: number) {
        if (cPos > 0 && value.substring(cPos - 1, cPos) === this.separator) {
            cPos -= 1;
        }

        if (cPos > 0) {
            const matchs = value.substring(0, cPos).match(this.separatorReg);            
            if (matchs) { 
                cPos -= matchs.length; 
            }
        }
        return cPos;
    }
  
    checkErrors(forceUpdate?: boolean) {
        (this.validationError && this.isFormatInvalid && this.value) ?
            this.setError(this.validationError.name, this.validationError) :
            this.removeError(this.validationError.name);
        super.checkErrors();
    }

    get valueString(): string { return this.value as string; }
    
    get isFormatInvalid() { return this.validator ? !this.validator.test(this.valueString) : false; }
    
    format(value: string) { return this.cleanText(value, 0, 0).value; }

    isValidKey($event: KeyboardEvent) { return IS_DIGIT_KEY($event.key); }

    isSystemKey($event: KeyboardEvent) { return IS_SYSTEM_KEY($event); }
}
