import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, filter, first } from 'rxjs/operators';

import { FinancialInformationCatalog } from 'models';

import { Store } from '@ngrx/store';
import { EnrollmentState, GetFinancialCatalogInformationAction } from 'store';
import { getFinancialCatalogInformation } from 'store/selectors';

@Injectable()
export class FinancialInformationCatalogResolver implements Resolve<Observable<FinancialInformationCatalog>> {
    constructor(private store$: Store<EnrollmentState>) { }

    resolve() {
        return this.store$.select(getFinancialCatalogInformation).pipe(
            tap(information => {
                if(!information) {
                    this.store$.dispatch(new GetFinancialCatalogInformationAction());
                }
            }),
            filter(information => !!information),
            first()
        );
    }
}