
import {
    createSelector,
    createFeatureSelector
} from '@ngrx/store';

import { CatalogsState, BaseCatalogState } from '../states';
import { FEATURE_NAMES } from '../constants';

import { State, Country, AnnualSales } from 'models';
import { CatalogItem } from 'models/trading/catalog-item.model';

const statesToExclude = []
// Exlude States should be only for Business
// ['AE', 'AA', 'AP', 'AS', 'FM', 'GU', 'MH', 'MP', 'PR', 'PW', 'VI'];

export const getCatalogsState = createFeatureSelector(FEATURE_NAMES.Catalogs);

// States
export const getStatesState = createSelector(
    getCatalogsState, (state: CatalogsState) => state.states
);

export const getAllStates = createSelector(
    getStatesState, (state: BaseCatalogState<State>) => state.data
);

export const getStates = createSelector(
    getStatesState, (state: BaseCatalogState<State>) => state.data.filter(state => !statesToExclude.includes(state.Abbr))
);

export const getStatesLoaded = createSelector(
    getStatesState, (state: BaseCatalogState<State>) => state.loaded
);

// Affiliations
export const getAffiliations = createSelector(
    getCatalogsState, (state: CatalogsState) => state.affiliations.data
);

export const getAffiliationsLoaded = createSelector(
    getCatalogsState, (state: CatalogsState) => state.affiliations.loaded
);

// Company Titles
export const getCompanyTitles = createSelector(
    getCatalogsState, (state: CatalogsState) => state.companyTitles.data
);

export const getCompanyTitlesLoaded = createSelector(
    getCatalogsState, (state: CatalogsState) => state.companyTitles.loaded
);

// Countries
export const getCountriesState = createSelector(
    getCatalogsState, (state: CatalogsState) => state.countries
);

export const getCountries = createSelector(
    getCountriesState, (state: BaseCatalogState<Country>) => state.data
);

export const getCountriesLoading = createSelector(
  getCountriesState, (state: BaseCatalogState<Country>) => state.loading
);

export const getCountriesLoaded = createSelector(
    getCountriesState, (state: BaseCatalogState<Country>) => state.loaded
);

// Ocupations
export const getOccupationsState = createSelector(
    getCatalogsState, (state: CatalogsState) => state.occupations
);

export const getOccupations = createSelector(
    getOccupationsState, (state: BaseCatalogState<CatalogItem>) => state.data
);

export const getOccupationsLoaded = createSelector(
    getOccupationsState, (state: BaseCatalogState<CatalogItem>) => state.loaded
);

// Annual Sales
export const geAnnualSalesState = createSelector(
    getCatalogsState, (state: CatalogsState) => state.annualSales
);

export const getAnnualSales = createSelector(
    geAnnualSalesState, (state: BaseCatalogState<AnnualSales>) => state.data
);

export const getAnnualSalesLoaded = createSelector(
    geAnnualSalesState, (state: BaseCatalogState<AnnualSales>) => state.loaded
);

// Financial Information
export const getFinancialCatalogInformation = createSelector(
    getCatalogsState, (state: CatalogsState) => state.financialInformation
);

//Beneficiaries Information
export const getRelationShips = createSelector(
    getCatalogsState, (state: CatalogsState) => state.relationShips.data
);

export const getRelationShipsLoaded = createSelector(
    getCatalogsState, (state: CatalogsState) => state.relationShips.loaded
);
