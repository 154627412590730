declare var environment: any;

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
// RxJs
import { Observable, throwError } from 'rxjs';
// Services
import { catchError } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { API_URL } from 'app/core/constants';
import { GET_CACHE } from 'app/core/utils';
import { TOKEN_STORAGE_NAME } from '../constants';
import { IAuthToken } from 'models';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    @Inject(API_URL) private enrollmentUrl: string,
    private cookieService: CookieService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!request.url.startsWith(this.enrollmentUrl)) {
      return next.handle(request)
    }
    let newHeader: any = { };

    if(request.headers.has('x-ax-applicationId')) {
      newHeader = {
        ...newHeader,
        'x-ax-applicationId': request.headers.get('x-ax-applicationId'),
      }
    }
    const cookie = this.cookieService.get('XSRF-TOKEN');
    if (cookie) {
      const tokenValue = cookie;
      newHeader = {
        ...newHeader,
        'Authorization': `Bearer ${tokenValue}`,
      }

     if(environment.bizUnitId){
      newHeader = {...newHeader ,'ax-bizUnitId': `${environment.bizUnitId.toString()}`}
     } 

    } else {
      let token: IAuthToken = GET_CACHE(TOKEN_STORAGE_NAME);
      if (token) {
        newHeader = {
          ...newHeader,
          'Authorization': `Bearer ${token.access_token}`,
        }
      }
    }
    if (environment && environment.brandId) {
      newHeader = { ...newHeader, 'X-Brand-Id': environment.brandId.toString() };
    }
    request = request.clone({ setHeaders: newHeader, withCredentials: true });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) =>  this.handleAuthError(error))
    );
  }

  private handleAuthError(error: HttpErrorResponse) {
    if (error.status === 401 || error.status === 403) {
      //Existing variable prevent to redirecto to login page if the user or password is incorrect in the existing login
      const isExistingSignin = error.url.includes('SignIn') && error.url.includes('isExisting=true');
      const pagePath = error.url.includes('proxy') ? 'access-restricted' : 'login';
      if(!isExistingSignin){
        this.router.navigateByUrl(`/auth/${pagePath}`);
      }
    }
    return throwError(error);
  }
}
