declare var environment: any;
// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { switchMap, filter, first } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetBrandAction } from 'store/actions';
import { getBrand, getBrandLoaded } from 'store/selectors';
// Model
import { Brand } from 'models';

@Injectable()
export class BrandResolver implements Resolve<Observable<Brand>> {

  constructor(private store$: Store<EnrollmentState>) {  }

  resolve() {
    return this.store$.select(getBrandLoaded).pipe(
      switchMap(loaded => {
        if(!loaded) {
          this.store$.dispatch(new GetBrandAction(environment.brandName));
        }
        return this.store$.select(getBrand).pipe(filter(brand => !!brand));
      }),
      first()
    );
  }
}
