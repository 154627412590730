import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptor } from './interceptors';
import { AuthHandlerService } from './services';
import { AuthGuard } from './guards';

@NgModule({
  imports: [ ],
  declarations: [ ],
  exports: [ ],
  providers: [
    AuthHandlerService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ]
})
export class AuthServicesModule { }
