// Ng
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
// Services
import { AuthHandlerService } from '../services';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild  {

  constructor(private cookieService: CookieService, private authHandler: AuthHandlerService) { }

  checkActiveToken(): boolean {
    const hasCookie = !!this.cookieService.get('XSRF-TOKEN');
    if(!hasCookie) { this.authHandler.logOut(true); }
    return hasCookie;
  }

  canActivate() {
    return this.checkActiveToken();
  }

  canActivateChild() {
    return this.checkActiveToken();
  }
}
