import { Action } from '@ngrx/store';
// Models
import {
  ApplicationStatus,
  IdaUserQuestionnaireAnswers,
  ApplicantIdaQuestionSessionValues } from 'models/rules';
import { APPLICANT_TYPE } from 'models/enums';
import { AxosResult } from 'models/enums/trading';

export enum RULES_ACTION_TYPES {
  VALIDATE_APPLICATION = '[Application] Validate',
  VALIDATE_APPLICATION_FAIL = '[Application] Validate fail',
  VALIDATE_APPLICATION_SUCCESS = '[Application] Validate success',

  BOARD_APPLICATION = '[Application] Board',
  BOARD_APPLICATION_FAIL = '[Application] Board fail',
  BOARD_APPLICATION_SUCCESS = '[Application] Board success',

  BOARD_IN_MITEK = '[Application] Board in Mitek',
  BOARD_IN_MITEK_FAIL = '[Application] Board in Mitek fail',
  BOARD_IN_MITEK_SUCCESS = '[Application] Board in Mitek success',

  RESUME_RULES = '[Application] Resume Rules',
  RESUME_RULES_FAIL = '[Application] Resume Rules fail',
  RESUME_RULES_SUCCESS = '[Application] Resume Rules success',

  UPDATE_PENDING_BANK_REVIEW = '[Application] Update Pending Bank Review',
  UPDATE_PENDING_BANK_REVIEW_FAIL = '[Application] Update Pending Bank Review fail',
  UPDATE_PENDING_BANK_REVIEW_SUCCESS = '[Application] Update Pending Bank Review success',

  UPDATE_PENDING_KBA = '[Application] Update Pending KBA',
  UPDATE_PENDING_KBA_FAIL = '[Application] Update Pending KBA fail',
  UPDATE_PENDING_KBA_SUCCESS = '[Application] Update Pending KBA success',

  GET_IDA_QUESTIONS = '[Application] Get IDA questions',
  GET_IDA_QUESTIONS_FAIL = '[Application] Get IDA questions fail',
  GET_IDA_QUESTIONS_SUCCESS = '[Application] Get IDA questions success',

  GET_PENDING_ID_VERIFICATION_APPLICANTS = '[Application] Get Pending ID Verification Applicants',
  GET_PENDING_ID_VERIFICATION_APPLICANTS_FAIL = '[Application] Get Pending ID Verification Applicants fail',
  GET_PENDING_ID_VERIFICATION_APPLICANTS_SUCCESS = '[Application] Get Pending ID Verification Applicants success',

  PARSE_APPLICATION = '[Application] Parse Rules Response',
  PARSE_APPLICATION_FAIL = '[Application] Parse Rules Response fail',
  PARSE_APPLICATION_SUCCESS = '[Application] Parse Rules Response success',

  SET_CONNECTION_ID = '[Application] Set Connection Id on Boarding',

  SET_BOARDING_HUB = '[Application] Set boardind hub',
  SET_BOARDING_HUB_SUCCESS = '[Application] Set boardind hub success',
  SET_BOARDING_HUB_FAIL = '[Application] Set boardind hub fail',

  BOARD_INPROGRESS_COMPLETE = '[Application] Board in progress complete'
}

export class ValidateApplicationAction implements Action {
  readonly type = RULES_ACTION_TYPES.VALIDATE_APPLICATION;
  constructor() { }
}

export class ValidateApplicationSuccessAction implements Action {
  readonly type = RULES_ACTION_TYPES.VALIDATE_APPLICATION_SUCCESS;
  constructor(public state: ApplicationStatus){}
}

export class ValidateApplicationFailAction implements Action {
  readonly type = RULES_ACTION_TYPES.VALIDATE_APPLICATION_FAIL;
  constructor(error: string) { }
}

export class ResumeRulesAction implements Action {
  readonly type = RULES_ACTION_TYPES.RESUME_RULES;
  constructor(public questionnaire?: IdaUserQuestionnaireAnswers[]) { }
}

export class ResumeRulesSuccessAction implements Action {
  readonly type = RULES_ACTION_TYPES.RESUME_RULES_SUCCESS;
  constructor(public state: ApplicationStatus){}
}

export class ResumeRulesFailAction implements Action {
  readonly type = RULES_ACTION_TYPES.RESUME_RULES_FAIL;
}

export class BoardApplicationAction implements Action {
  readonly type = RULES_ACTION_TYPES.BOARD_APPLICATION;
  constructor(public connectionId?: string){}
}

export class BoardApplicationSuccessAction implements Action {
  readonly type = RULES_ACTION_TYPES.BOARD_APPLICATION_SUCCESS;
  constructor(public status: string) { }
}

export class BoardApplicationFailAction implements Action {
  readonly type = RULES_ACTION_TYPES.BOARD_APPLICATION_FAIL;
  constructor(error: string) { }
}

export class BoardInMitekAction implements Action {
  readonly type = RULES_ACTION_TYPES.BOARD_IN_MITEK;
}

export class BoardInMitekSuccessAction implements Action {
  readonly type = RULES_ACTION_TYPES.BOARD_IN_MITEK_SUCCESS;
  constructor(public state: ApplicationStatus) { }
}

export class BoardInMitekFailAction implements Action {
  readonly type = RULES_ACTION_TYPES.BOARD_IN_MITEK_FAIL;
}

export class UpdatePendingBankReviewAction implements Action {
  readonly type = RULES_ACTION_TYPES.UPDATE_PENDING_BANK_REVIEW;
  constructor() { }
}

export class UpdatePendingBankReviewSuccessAction implements Action {
  readonly type = RULES_ACTION_TYPES.UPDATE_PENDING_BANK_REVIEW_SUCCESS;
  constructor() { }
}

export class UpdatePendingBankReviewFailAction implements Action {
  readonly type = RULES_ACTION_TYPES.UPDATE_PENDING_BANK_REVIEW_FAIL;
  constructor() { }
}

export class UpdatePendingKBAAction implements Action {
  readonly type = RULES_ACTION_TYPES.UPDATE_PENDING_KBA;
  constructor() { }
}

export class UpdatePendingKBASuccessAction implements Action {
  readonly type = RULES_ACTION_TYPES.UPDATE_PENDING_KBA_SUCCESS;
  constructor() { }
}

export class UpdatePendingKBAFailAction implements Action {
  readonly type = RULES_ACTION_TYPES.UPDATE_PENDING_KBA_FAIL;
  constructor() { }
}

export class GetIDAQuestionsAction implements Action{
  readonly type = RULES_ACTION_TYPES.GET_IDA_QUESTIONS;
  constructor(public isRetry: boolean = false) { }
}

export class GetIDAQuestionsFailAction implements Action{
  readonly type = RULES_ACTION_TYPES.GET_IDA_QUESTIONS_FAIL;
  constructor(public isRetry: boolean = false) { }
}

export class GetIDAQuestionsSuccessAction implements Action{
  readonly type = RULES_ACTION_TYPES.GET_IDA_QUESTIONS_SUCCESS;
  constructor(public state: ApplicationStatus, public IdaQuestions: ApplicantIdaQuestionSessionValues[]) {}
}

export class GetPendingIDVerificationApplicantsAction implements Action {
  readonly type = RULES_ACTION_TYPES.GET_PENDING_ID_VERIFICATION_APPLICANTS;
}

export class GetPendingIDVerificationApplicantsSuccessAction implements Action {
  readonly type = RULES_ACTION_TYPES.GET_PENDING_ID_VERIFICATION_APPLICANTS_SUCCESS;
  constructor(public applicants: APPLICANT_TYPE[]) {}
}

export class ParserApplicationAction implements Action {
  readonly type = RULES_ACTION_TYPES.PARSE_APPLICATION;
}

export class ParserApplicationSuccessAction implements Action {
  readonly type = RULES_ACTION_TYPES.PARSE_APPLICATION_SUCCESS;
}

export class ParserApplicationFailAction implements Action {
  readonly type = RULES_ACTION_TYPES.PARSE_APPLICATION_FAIL;
}

export class SetConnectionIdAction implements Action {
  readonly type = RULES_ACTION_TYPES.SET_CONNECTION_ID;
  constructor(public connectionId: string){}
}

export class SetBoardingHubAction implements Action {
  readonly type = RULES_ACTION_TYPES.SET_BOARDING_HUB;
}

export class SetBoardingHubSuccessAction implements Action {
  readonly type = RULES_ACTION_TYPES.SET_BOARDING_HUB_SUCCESS;
}

export class SetBoardingHubFailAction implements Action {
  readonly type = RULES_ACTION_TYPES.SET_BOARDING_HUB_FAIL;
}

export class BoardInProgressComplete implements Action {
  readonly type = RULES_ACTION_TYPES.BOARD_INPROGRESS_COMPLETE;
  constructor(public status: string){}
}

export type RulesAction =
    ValidateApplicationAction | ValidateApplicationSuccessAction | ValidateApplicationFailAction |
    ResumeRulesAction | ResumeRulesSuccessAction | ResumeRulesFailAction |
    BoardApplicationAction | BoardApplicationSuccessAction | BoardApplicationFailAction |
    BoardInMitekAction | BoardInMitekSuccessAction | BoardInMitekFailAction |
    UpdatePendingKBAAction | UpdatePendingKBASuccessAction | UpdatePendingKBAFailAction |
    UpdatePendingBankReviewAction | UpdatePendingBankReviewSuccessAction | UpdatePendingBankReviewFailAction |
    GetPendingIDVerificationApplicantsAction | GetPendingIDVerificationApplicantsSuccessAction |
    GetIDAQuestionsAction | GetIDAQuestionsSuccessAction | GetIDAQuestionsFailAction |
    ParserApplicationAction | ParserApplicationSuccessAction | ParserApplicationFailAction | SetConnectionIdAction |
    SetBoardingHubAction | SetBoardingHubSuccessAction | SetBoardingHubFailAction | BoardInProgressComplete;
