import { PHONE_TYPE, PHONE_TYPE_UNIVERSAL } from 'models/enums';
import { Applicant, PhoneNumber } from 'models';

export const EMPTY_PHONE: PhoneNumber = {
    PhoneTypeId: PHONE_TYPE.Cell,
    ApplicantId: 0,
    PhoneNumber: '',
    PhoneExtension: '',
}

export function GET_PHONE_BY_TYPE(applicant: Applicant, phoneTypeName: PHONE_TYPE_UNIVERSAL) {
    const phone = applicant.phones.find(phone => phone.phoneType == phoneTypeName);
    return phone ? phone : { ...EMPTY_PHONE };
}

export function SET_PHONE_BY_TYPE(phoneNumbers: PhoneNumber[], newPhone: PhoneNumber | any, PhoneTypeId: PHONE_TYPE_UNIVERSAL) {
  return [
    ...phoneNumbers.filter(phone => phone.phoneType !== PhoneTypeId),
    { ...EMPTY_PHONE, ...newPhone, PhoneTypeId }
  ];
}
