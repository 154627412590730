declare var environment: any;

import { Component } from '@angular/core';

@Component({
  selector: 'axos-age-restriction-popup',
  templateUrl: './age-restriction-popup.component.html',
  styleUrls: ['./age-restriction-popup.component.scss']
})
export class AgeRestrictionPopupComponent {

  minAge: number;
  changeDOB = false;
  marketingURL = environment.marketingURL;

  constructor() { }
}
