import {
  on,
  props,
  Action,
  createAction,
  createReducer,
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
// Models & Enums
import { FLOW_STEPS } from 'models/enums';
import { AxosWorkflow } from 'services/http/application-flow.service';

const namespace = 'EnrollmentWorkflows';

export interface AxosFlowStep {
  name: FLOW_STEPS;
  subWorkflowName: string;
  steps?: AxosFlowStep[];
}

// State
export interface EnrollmentWorkflowState {
  workflowName: string;
  workflow: AxosFlowStep[];
  level: number;
  lastStep?: FLOW_STEPS;
  currentStep?: FLOW_STEPS;
  initialized: boolean;
}

// Actions
export const moveBack = createAction(`[${namespace}] Move Back`);

export const fetchWorkflow = createAction(`[${namespace}] Fetch`,
  props<{ applicationId?: string }>());

export const setWorkflow = createAction(`[${namespace}] Set`,
  props<AxosWorkflow>());

export const setWorkflowStep = createAction(`[${namespace}] Set State`,
  props<{ step: FLOW_STEPS, lastStep?: FLOW_STEPS }>());

export const clearWorkflowState = createAction(`[${namespace}] Clear State`);

// Reducers
const iniitialState = { workflowName: '', workflow: [], level: 0, initialized: false };

const workflowReducer = createReducer<EnrollmentWorkflowState>(
  iniitialState,
  on(setWorkflow, (s, { workflowName, lastStep, nextStep, steps }) => ({
    ...s,
    workflowName,
    workflow: steps,
    lastStep: lastStep || s.lastStep,
    currentStep: (s.initialized ? nextStep : lastStep) || s.currentStep,
    initialized: true
  })),
  on(setWorkflowStep, (s, { step }) => ({...s, currentStep: step})),
  on(clearWorkflowState, () => ({...iniitialState})),
);
export function reducer(state: EnrollmentWorkflowState | undefined, action: Action) {
  return workflowReducer(state, action);
}

// Selectors
const selectState = createFeatureSelector(namespace);
export const selectWorkflow = createSelector(selectState, ({ workflow }) => workflow);
export const selectWorkflowInitialized = createSelector(selectState, ({ initialized }) => initialized);
export const selectWorkflowState = createSelector(selectState, ({ currentStep }) => currentStep);
