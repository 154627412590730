// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { switchMap, filter, first, withLatestFrom } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetCountriesAction } from 'store/actions';
import { getCountries, getCountriesLoaded, getCountriesLoading } from 'store/selectors';
// Model
import { Country } from 'models';

@Injectable()
export class CountryResolver implements Resolve<Observable<Country[]>> {

  constructor(private store$: Store<EnrollmentState>) {  }

  resolve() {
    return this.store$.select(getCountriesLoaded).pipe(
      withLatestFrom(this.store$.select(getCountriesLoading)),
      filter(([loaded, loading]) => {
        if(!loaded && !loading) {
          this.store$.dispatch(new GetCountriesAction());
        }
        return loaded;
      }),
      switchMap(([loaded]) => this.store$.select(getCountries)),
      first()
    );
  }
}
