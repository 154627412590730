import {
    createSelector,
    createFeatureSelector
} from '@ngrx/store';

import { LandingState } from '../states';
import { FEATURE_NAMES } from '../constants';


export const getLandingState = createFeatureSelector<LandingState>(FEATURE_NAMES.Landing);

export const getLandingIsExisting = createSelector(
    getLandingState,
    (state: LandingState) => state.isExisting
);
