export enum APPLICANT_IDENTIFICATION_STEP {
  DmdVerifyOtp = 1,
  IdTypeSelection,
  IDFront,
  IDBack,
  Selfie,
  ReviewAndSubmit,
  DmdFinish,
  FailedOtpValidation
}
