import { Injectable } from '@angular/core';
// RxJs
import { switchMap, map, concatMap } from 'rxjs/operators';
// NgRx Effects
import { Effect, Actions, ofType } from '@ngrx/effects';
// Services
import { GenericCatalogsService } from 'services';
// Models
import { State, AnnualSales, Country, FinancialInformationCatalog, BeneficiariesCatalog } from 'models';
// Actions
import * as catalogsActions from '../actions/catalogs.action';
import { CatalogItem } from 'models/trading/catalog-item.model';

@Injectable()
export class GenericCatalogsEffects {
    constructor(
        private actions$: Actions,
        private catalogsService: GenericCatalogsService) { }

    @Effect()
    getStates$ = this.actions$.pipe(
        ofType(catalogsActions.CATALOGS_ACTION_TYPES.GET_STATES),
        switchMap(() => {
            return this.catalogsService.getStates()
                .pipe(
                    map((response: State[]) => new catalogsActions.GetStatesSuccessAction(response))
                );
        })
    );

    @Effect()
    getCountries$ = this.actions$.pipe(
        ofType(catalogsActions.CATALOGS_ACTION_TYPES.GET_COUNTRIES),
        concatMap(() => {
            return this.catalogsService.getCountries()
                .pipe(
                    map((response: Country[]) => new catalogsActions.GetCountriesSuccessAction(response))
                );
        })
    );

    @Effect()
    getOccupations$ = this.actions$.pipe(
        ofType(catalogsActions.CATALOGS_ACTION_TYPES.GET_OCUPATIONS),
        switchMap(() => {
            return this.catalogsService.getOccupations()
                .pipe(
                    map((response: CatalogItem[]) => new catalogsActions.GetOcupationsSuccessAction(response))
                );
        })
    );

    @Effect()
    getAffiliations$ = this.actions$.pipe(
        ofType(catalogsActions.CATALOGS_ACTION_TYPES.GET_AFFILIATIONS),
        switchMap(() => {
            return this.catalogsService.getAffiliations()
                .pipe(
                    map((response: CatalogItem[]) => {
                        return new catalogsActions.GetAffiliationsSuccessAction(response.filter(res => res.name != "none"))
                    })
                );
        })
    );

    @Effect()
    getCompanyTitles$ = this.actions$.pipe(
        ofType(catalogsActions.CATALOGS_ACTION_TYPES.GET_COMPANY_TITLES),
        switchMap(() => {
            return this.catalogsService.getCompanyTitles()
                .pipe(
                    map((response: CatalogItem[]) => new catalogsActions.GetCompanyTitlesSuccessAction(response.filter(res => res.name != "none")))
                );
        })
    );

    @Effect()
    getAnnualSales$ = this.actions$.pipe(
        ofType(catalogsActions.CATALOGS_ACTION_TYPES.GET_ANNUAL_SALES),
        switchMap(() => {
            return this.catalogsService.getAnnualSalesRange()
                .pipe(
                    map((response: AnnualSales[]) => new catalogsActions.GetAnnualSalesSuccessAction(response))
                );
        })
    );

    @Effect()
    getFinancialInformation$ = this.actions$.pipe(
        ofType(catalogsActions.CATALOGS_ACTION_TYPES.GET_FINANCIAL_CATALOG_INFORMATION),
        switchMap(() => {
            return this.catalogsService.getFinancialCatalogInfo()
                .pipe(
                    map((response: FinancialInformationCatalog) => new catalogsActions.GetFinancialCatalogInformationSuccessAction(response))
                );
        })
    );

    @Effect()
    getRelationShipsInformation$ = this.actions$.pipe(
        ofType(catalogsActions.CATALOGS_ACTION_TYPES.GET_BENEFICIARIES_CATALOG),
        switchMap(() => {
            return this.catalogsService.getBeneficiariesCatalogInfo()
                .pipe(
                    map((response: CatalogItem[]) => new catalogsActions.GetBeneficiariesCatalogSuccessAction(response))
                );
        })
    );
}
