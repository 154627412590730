export enum APPLICATION_TYPE {
    New = 1,
    Existing = 2,
    ProxyNew = 3,
    ProxyExisting = 4
}

export enum APPLICATION_TYPE_NAME {
    trading = 'trading',
    whitelabelTrading = 'whitelabelTrading',
    proxyTrading = 'proxyTrading',
    whitelabelTradingIRA = 'whitelabelTradingIRA'
}

export enum APPLICATION_TYPE_FLOW {
    ConsumerDeposit = 'consumerDeposit',
    Sbb = 'sbb',
    Trading = 'trading',
    TradingIRA = 'tradingIRA',
    WhitelabelTrading = 'whitelabelTrading',
    WhitelabelTradingIRA = 'whitelabelTradingIRA',
    ProxyTrading  = "proxyTrading",
    ProxyIRA = "proxyIRA",
    ManagedPortfolio = 'managedPortfolio',
    ManagedPortfolioGoal = 'managedPortfolioGoal'
}

export enum APPLICATION_TYPE_FLOW_ID {
    ConsumerDeposit = 1,
    Sbb = 2,
    Trading = 3,
    TradingIRA = 4,
    WhitelabelTrading = 5,
    WhitelabelTradingIRA = 6,
    ProxyTrading  = 7,
    ProxyIRA = 8,
    ManagedPortfolio = 9,
    ManagedPortfolioGoal = 10,
    Mobile = 11
    
}