import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJs
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
// Core
import { LoadingService } from 'app/core/services';
import { API_URL } from 'app/core/constants';
// Services
import { BaseService } from './abstracts';
// Models
import {
  Applicant,
  PersonalInformationResponse,
  ApplicantRequest,
  PhoneNumber,
  IApplication,
  IApplicantRequest,
  IdentificationInfo,
  PersonalInformationTrading,
  IAuthToken,
  BeneficiariesInformation,
  TrustCertificate
} from 'models';
import {
  APPLICATION_STATE,
  PHONE_TYPE
} from 'models/enums';

import { ID_VERIFICATION_METHOD } from 'app/applicant-identification/enums';
import { APPLICANT_IDENTIFICATION_STORAGE_NAME } from 'app/applicant-identification/models';
import { PersonAbout } from 'models/trading/person-about.model';
import { Person, AdditionalPerson } from 'models/trading/person.model';
import { AxosResult } from 'models/enums/trading';

@Injectable()
export class ApplicantInformationService extends BaseService {
  // api/consumer/personal/pip

  // TODO: remove everything related to store
  constructor(http: HttpClient, loadingService: LoadingService, @Inject(API_URL) baseUrl: string) {
    super(http, loadingService, baseUrl);
  }

  // #region New HTTP end-points
  getApplicant(applicantId: string) {
    return this._get<AxosResult<Applicant>>(`applicant/${applicantId}`)
      .pipe(
        map(result => Object.assign(new Applicant(), result.data))
      )
  }

  getAllApplicants(applicationId: string) {
    return this._get<AxosResult<Applicant[]>>(`applicant/application/${applicationId}`)
      .pipe(
        map(result => result.data)
      );
  }

  getApplicantExisting(applicantId: string) {
    return this._get<AxosResult<any>>(`user/${applicantId}/existing`).pipe(map(result => result.data))
  }

  // updateApplicant(applicant: IApplicantRequest){
  //   return this._post<IApplicantRequest>('consumer/personal/applicant',
  //   {
  //     ...applicant,
  //     SSN: this.cleanData(applicant.SSN)
  //   });
  // }

  updateApplicantIdentification(applicantId: string, identification: IdentificationInfo){
    return this._post<IdentificationInfo>(`consumer/personal/pip/${applicantId}/identification`, identification);
  }

  updateApplicantPhone(applicantId: string, phone: PhoneNumber) {
    phone = {
      ...phone,
      number: this.cleanValue(phone.number)
    }
    return this._post<PhoneNumber>(`applicantDetails/${applicantId}/phone`, phone);
  }

  // #endregion
  //Universal Enrollment

  // saveApplicantPIUniversal(applicantId: number, personalInformationUniversal: PersonalInformationTrading){
  //   return this._post<PersonalInformationTrading>(`consumer/personal/pip/${applicantId}/universal`, personalInformationUniversal)
  // }

  saveApplicantPIUniversal(person: Person, applicationId: string, applicantId: string, skipHistory: boolean = false){
    return this._postWithParams(`application/${applicationId}/applicant/${applicantId}/about`, {skipHistory}, person);
  }

  saveBasicInformation(applicantId: string, person: Person) {
    return this._post(`person/${applicantId}/basic`, person);
  }

  updateTaxpayerId(applicantId: string, taxPayerId: string) {
    return this._post(`generic/applicant/${applicantId}/taxPayerId/${taxPayerId.replace(/-/g, "")}`);
  }

  updateProxyTaxpayerId(applicationId: string, applicantId: string, taxPayerId: string) {
    return this._post(`generic/applicant/${applicationId}/${applicantId}/taxPayerId/${taxPayerId.replace(/-/g, "")}`);
  }

  saveAdditionalOwner(applicationId: string, applicantId: string, additionalPerson: AdditionalPerson, skipHistory: boolean = false) {
    return this._postWithParams(`application/${applicationId}/applicant/${applicantId}/saveadditional`, {skipHistory}, additionalPerson)
      .pipe(map((res) =>res));
  }

  // #endregion

  public getApplicantIdentificationMethod(applicantId: number): ID_VERIFICATION_METHOD {
    const allApplicantIdSessions: any = this.getCached(APPLICANT_IDENTIFICATION_STORAGE_NAME),
      applicantIdSession: any = allApplicantIdSessions.find(id => id.ApplicantId === applicantId);

    let verificationMethod: ID_VERIFICATION_METHOD = ID_VERIFICATION_METHOD.Manual;
    if (applicantIdSession) {
      verificationMethod = (applicantIdSession.VerificationMethod) ? applicantIdSession.VerificationMethod : ID_VERIFICATION_METHOD.Manual;
    }
    return verificationMethod;
  }

  private ToModel(application: any, applicants: Applicant[], newApplicationState: APPLICATION_STATE) {
    if(!application.Beneficiaries) {
      application.Beneficiaries = [];
    }
    const beneficiariesModel = application.Beneficiaries.map(beneficiary => {
      return {
        Ordinal: beneficiary.Ordinal,
        ApplicationId: beneficiary.ApplicantId,
        FirstName: beneficiary.FirstName,
        MiddleName: beneficiary.MiddleName,
        LastName: beneficiary.LastName,
        DOB: beneficiary.DOB,
        RelationshipId: beneficiary.RelationshipId,
        RelationshipName: beneficiary.RelationshipName,
        RelationshipOther: beneficiary.RelationshipOther,
      };
    });

    const applicantsModel: ApplicantRequest[] = applicants.map(applicant => {
      const ApplicantId = applicant.ApplicantId;
      const applicantPhoneNumbers: PhoneNumber[] = [
        {
          ApplicantId,
          PhoneTypeId: PHONE_TYPE.Business,
          PhoneNumber: this.cleanData(applicant.BusinessPhone),
          PhoneExtension: this.cleanData(applicant.BusinessPhoneExtension)
        },
        { PhoneTypeId: PHONE_TYPE.Home, ApplicantId, PhoneNumber: this.cleanData(applicant.HomePhone) },
        { PhoneTypeId: PHONE_TYPE.Cell, ApplicantId, PhoneNumber: this.cleanData(applicant.CellPhone) },
      ];

      return <ApplicantRequest>{
        ApplicantId: applicant.ApplicantId,
        ApplicationId: applicant.ApplicationId,
        ApplicantTypeId: applicant.ApplicantType.ApplicantTypeId,
        FirstName: applicant.FirstName,
        MiddleName: applicant.MiddleName,
        LastName: applicant.LastName,
        EmailPrimary: applicant.EmailPrimary,
        EmailSecondary: applicant.EmailSecondary,
        SuffixId: applicant.SuffixId,
        DOB: applicant.DOB,
        SSN: this.cleanData(applicant.SSN),
        ConfirmSSN: this.cleanData(applicant.ConfirmSSN),
        CitizenshipTypeId: applicant.CitizenshipTypeId,
        ContactMethodId: applicant.ContactMethodId,
        ApplicantPhoneNumbers: applicantPhoneNumbers,
        IdentificationTypeId: applicant.IdentificationInfo.IdentificationTypeId,
        DLNum: (applicant.IdentificationInfo.DLNum || '').replace(/[^0-9A-Za-z*]/g, ''),
        DLState: applicant.IdentificationInfo.DLState,
        DLIssued: applicant.IdentificationInfo.DLIssued,
        DLExpire: applicant.IdentificationInfo.DLExpire,
        IdentificationAddressId: null,
        EmploymentTypeId: applicant.EmploymentTypeId,
        MaidenName: applicant.MaidenName,
        PIN: applicant.PIN,
        SecretWordHint: applicant.SecretWordHint,
        EmployerName: applicant.EmployerName,
        Occupation: applicant.Occupation,
        TrustName: applicant.TrustName,
        Description: applicant.Description,
        TaxpayerIDType: applicant.TaxpayerIDType,
        MinSales: applicant.MinSales,
        MaxSales: applicant.MaxSales,
        OwnershipPercentage: applicant.OwnershipPercentage,
        IncorporationState: applicant.IncorporationState,
        Website: applicant.Website,
      };
    });


    const model = {
      Application: {
        ApplicationId: application.ApplicationId,
        ReferralSourceId: application.ReferralSourceId,
        ReferredBy: application.ReferredBy,
        PromoCode: application.PromoCode,
        UserProfileId: application.UserProfileId,
        IdentityUserId: application.IdentityUserId,
        Beneficiaries: beneficiariesModel,
        Accounts: application.Accounts
      },
      Applicants: applicantsModel,
      NewApplicationState: newApplicationState,
      // SkipValidation: (application.Action || enums.actionTypes.save) === enums.actionTypes.save ? false : true
      SkipValidation: true
    };
    return model;
  }

  private cleanData(value: string): string {
    return value ? value.replace(/-|\)|\(/g, '') : null;
  }

  // #region HTTPp
  get(applicationId: number) {
    return this._get<PersonalInformationResponse>(`v2/pip/${applicationId}`).pipe(
      map(data => {
        return {
          ...data,
          Applicants: data.Applicants.map(applicant => {
            if (!applicant.Description) { applicant.Description = ''; }
            const names = applicant.FirstName ? applicant.FirstName.split(/ |,/) : [];
            if (names.length > 1 && !applicant.MiddleName) {
              applicant.FirstName = names[0];
              applicant.MiddleName = names[names.length - 1][0];
            }
            return applicant;
          })
        };
      })
    );
  }

  // TODO: Posible risk since js don't have applicants (Review and Submit and PIP pages)
  update(application: IApplication, applicants: Applicant[], newApplicationState: APPLICATION_STATE) {
    const model = this.ToModel(application, applicants, newApplicationState);
    return this._put('v2/pip', model);
  }

  canApplicantChangeSecurityWord(applicantId) {
    return this._get<boolean>(`Applicant/${applicantId}/SecurityWordChanged`);
  }

  canApplicantChangeMaidenName(applicationId) {
    return this._get(`Applicant/${applicationId}/MaidenNameChanged`);
  }
  // #endregion
}
