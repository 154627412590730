import { Injectable } from '@angular/core';
// RxJs
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { EnrollmentDocument } from 'models';
import { DOCUMENT_TYPE } from 'models/enums';
import { BaseService } from 'services';
import { IDocument, DocumentRequest } from '../models';

@Injectable()
export class UploadDocumentService extends BaseService {
  upload(data: any) {
    return this.http.post('/Document/UploadDocuments', data);
  }

  uploadPassport(file: string, fileName: string, applicantId: number) {
    const passport: EnrollmentDocument = {
      ApplicantId: applicantId,
      DocumentName: fileName,
      DocumentTypeId: DOCUMENT_TYPE.Passport,
      File: file
    }
    return this._post('v2/documents/passport', passport).pipe(
      map(() => { return { success: true } }),
      catchError(() => of({ success: false }))
    );
  }

  uploadDocument(document: DocumentRequest) {
    return this._post('applicantdocument/upload', document);
  }

  uploadDocumentForm(formData: FormData) {
    return this._post('applicantdocument/uploadDocumentFromForm', formData);
  }
}
