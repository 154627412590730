import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// UI Kit
import { UkModalModule } from '@uikit/components/uk-modal';
import { UIKitIconsModule } from '@uikit/components/uk-icons';
import { NO_ERRORS_SCHEMA } from '@angular/core';
// Enrollment Common
import { EnrollmentCommonModule } from 'app/enrollment-common/enrollment-common.module';
// Components

// Services
import { ApplicationService } from 'services';

import {
  SmsPopupComponent,
  AgeRestrictionPopupComponent,
  DmdOtpLinkComponent,
  MissingAnswersPopupComponent,
  FooterModalsPopupComponent,
  SessionTimeOutComponent,
  CancelApplicationPopupComponent,
  CreditFreezePopupComponent,
  AddressPopupComponent
} from './components';


@NgModule({
  imports: [
    CommonModule,
    UkModalModule,
    UIKitIconsModule,
    EnrollmentCommonModule
  ],
  declarations: [
    SmsPopupComponent,
    AgeRestrictionPopupComponent,
    MissingAnswersPopupComponent,
    DmdOtpLinkComponent,
    FooterModalsPopupComponent,
    CancelApplicationPopupComponent,
    SessionTimeOutComponent,
    CreditFreezePopupComponent,
    AddressPopupComponent
  ],
  entryComponents: [
    SmsPopupComponent,
    AgeRestrictionPopupComponent,
    MissingAnswersPopupComponent,
    CancelApplicationPopupComponent,
    DmdOtpLinkComponent,
    FooterModalsPopupComponent,
    SessionTimeOutComponent,
    CreditFreezePopupComponent,
    AddressPopupComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [ApplicationService]
})
export class ModalsModule { }
