// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetUserAction } from 'store/actions';
import { getUser } from 'store/selectors';
// Model
import { IUser } from 'models';

@Injectable()
export class UserResolver implements Resolve<Observable<IUser>> {
  constructor(private store$: Store<EnrollmentState>) { }

  resolve() {
    return this.store$.select(getUser).pipe(
      filter(user => {
        if(!user) { this.store$.dispatch(new GetUserAction(false)); }
        return !!user;
      }),
      first()
    );
  }
}
