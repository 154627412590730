import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
     name: 'SSN'
})
export class SSN implements PipeTransform{
     transform(value: string){
          let result: any;
          if(!value || value.indexOf('-') > -1){
               return value;
          }
          result = value.split('');
          if(result.length > 3 && result < 6){
               result.splice(3, 0, '-');
               return result.join('');     
          }else
          if(result.length >= 6){
               result.splice(3, 0, '-');
               result.splice(6, 0, '-');
               return result.join('');
          }else
               return value;
     }
}