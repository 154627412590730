import { APPLICATION_STATE } from '../states.enum';

export const JOINT_PIP_STATES = [
  APPLICATION_STATE.PersonalInformationJoint,
  APPLICATION_STATE.ContactInformationJoint,
  APPLICATION_STATE.HomeAddressQuestionJoint,
  APPLICATION_STATE.HomeAddressInfoJoint,
  APPLICATION_STATE.MailingAddressQuestionJoint,
  APPLICATION_STATE.MailingAddressInfoJoint,
  APPLICATION_STATE.DobSSNInfoJoint,
  APPLICATION_STATE.IdTypeSelectionManualJoint,
  APPLICATION_STATE.IdInfoJoint,
  APPLICATION_STATE.IdAddressQuestionJoint,
  APPLICATION_STATE.IdAddressInfoJoint
];

export const JOINT_PIP_TRADING_STATES = [
  APPLICATION_STATE.JointPersonalInformation,
  APPLICATION_STATE.AffiliationInformation,
  APPLICATION_STATE.HomeAddressQuestionJoint,
  APPLICATION_STATE.HomeAddressInfoJoint,
  APPLICATION_STATE.FinancialInformation,
  APPLICATION_STATE.InvestmentProfile
];

//Trading new states
// export const JOINT_PIP_TRADING_STATES = [
//   APPLICATION_STATE.JointPersonalInformation,
//   APPLICATION_STATE.AffiliationInformation,
//   APPLICATION_STATE.JointAddressQuestion,
//   APPLICATION_STATE.JointAdressInformation
// ];