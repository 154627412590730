declare var environment: any;
// Ng
import { Inject, Injectable } from '@angular/core';
// Models
import { Account, IApplication, IApplicationRequest, IUser, ProductVestingType, Beneficiary, TrustCertificate, TrustTypes } from 'models';
import { APPLICATION_STATE, BENEFICIARY_TYPE } from 'models/enums';
// RxJs
import { Observable, of } from 'rxjs';
// Services
import { BaseService } from './abstracts';
import { map, switchMap } from 'rxjs/operators';
import { AxosResult } from 'models/enums/trading/axos-result.model';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'app/core/constants';
import { LoadingService } from 'app/core/services';
import { Store } from '@ngrx/store';
import { ClearApplicantsStateAction, ClearApplicationStateAction, ClearDisclosuresState, ClearProductState, clearProxyState, clearTradingState, clearWhiteLabelState, EnrollmentState } from 'store';
import { getApplicationId } from 'store/selectors';
import { clearWorkflowState } from 'store/workflows/workflow.store';
import { CLEAR_CACHE } from 'app/core/utils';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ApplicationService extends BaseService {
  // postTerms() this method has been move to disclosures service
  // confirmDisclosures() this method has been move to disclosures service

  isTrad = environment.applicationTypeName === 'trading';

  constructor(
    http: HttpClient,
    loadingService: LoadingService,
    @Inject(API_URL) baseUrl: string,
    private store$: Store<EnrollmentState>,
    private cookieService: CookieService
  ) {
    super(http, loadingService, baseUrl);
  }

  createApplication(request: IApplicationRequest) {
    return this._post<AxosResult<IUser>>('generic/application/existing', request).pipe(map(x => x.data));
  }

  getApplication(applicationId: string): Observable<IApplication> {
    return this._get<AxosResult<IApplication>>(`application/${applicationId}`).pipe(map(x => x.data));
  }

  getApplicationByToken(createNew?: boolean, productCodes?: string[], sso?: boolean, applicationTypeName?: string, utmCodes?: any[]) {
    applicationTypeName = applicationTypeName || environment.applicationTypeName;
    let route = `generic/application/?bootstrap=${createNew}&applicationTypeName=${applicationTypeName}&productCodes=${productCodes}&organizationId=${environment.organizationId}&organization=${environment.organizationName}`;

    if (sso) {
      route = `${route}&sso=true`;
    }
    if (utmCodes && utmCodes.length > 0)
    {
      const codes = utmCodes.map(x => `${x.code}=${x.value}`);
      route += `&utmCodes=${codes.join(',')}`;
    }

    return this._get<AxosResult<IApplication>>(route).pipe(map(x => x.data));
  }

  updatePromoCode(PromoCode: string) {
    return this._post<IApplication>(`consumer/application/promoCode`, { PromoCode });
  }

  getConfirmationNumber(applicationId: string): Observable<string> {
    return this._get<AxosResult<string>>(`generic/application/${applicationId}/confirmation`).pipe(map(res => res.data));
  }

  getApplicationState() {
    return this._get<IApplication>('consumer/application/State');
  }

  getAccounts(applicationId?: string) {
    let headers = applicationId ? {'x-ax-applicationId' : applicationId} : null;
    return this._get<AxosResult<Account[]>>('accounts', null, true, headers)
      .pipe(map(({ data }) => data));
  }

  getUserApplicationData() {
    return this._get<any>('Applications');
  }

  get(applicationId: string): Observable<any> {
    return this._get(`v2/Application/${applicationId}`);
  }

  getApplicationAndDocumentsRequest(applicationId: string) {
    return this._get(`v2/Applications/${applicationId}`);
  }

  getApprovedApplication(applicationId: string) {
    return this._get(`v2/ApprovedApplication/${applicationId}`);
  }

  getCurrentApplicationState(): Observable<APPLICATION_STATE> {
    return this._get<APPLICATION_STATE>('v2/Application/State');
  }

  getAllByUsername(username: string) {
    return this._get(`v2/Application/Proxy/${username}`);
  }

  getExperianApplicants(applicationId: string) {
    return this._get(`v2/applications/getExperianApplicants/${applicationId}`);
  }

  getApplicantsMitekDocuments(applicationId: string) {
    return this._get(`v2/applicantMitekDocuments/${applicationId}`);
  }

  failedApplication(email: string) {
    return this._get('Application/IsApplicationDeclined', { email });
  }

  failedApplicationByUsername(userName: string) {
    return this._get('Application/IsApplicationDeclinedByUsername', { userName });
  }

  create(userProfile: any) {
    return this._post('v2/Application', userProfile);
  }

  setState(stateId: APPLICATION_STATE) {
    return of(true);
    // return this._post(`consumer/application/State`, stateId);
  }

  resetApplication(applicationId: string) {
    return this._post(`Applications/UpdateResetApplication/${applicationId}`);
  }

  // board(applicationId: string, rulesResult: any): Observable<BoardResult> {
  //   rulesResult.ApplicationId = applicationId;
  //   return this._post<BoardResult>('v2/Applications/Board', rulesResult);
  // }

  board(connectionId?: string): Observable<any> {
    return this._post<AxosResult<any>>('application/board', { connectionId }).pipe(
      map(res => {
        return res.data;
      })
    )
  }

  routeToCorrectUrl(applicationId: string, currentRoute: any, isRetry = false) {
    //Fix proxy issue
    if (currentRoute.url) {
      currentRoute = currentRoute.url;
    }
    const payload = {
      CurrentRoute: currentRoute,
      ApplicationId: applicationId
    };
    return this._post(`Applications/CorrectStateRoute/${isRetry}`, payload);
  }

  routeToApplicationState(userName: boolean, isRetry = false) {
    const payload = { userName };
    return this._post(`Applications/StateRoute/${isRetry}`, payload);
  }

  updateApplicationStatusAndState(
    ApplicationId: string,
    ApplicationStatusId: string,
    ApplicationStateId: number,
    ApplicationDecisionReasonId: string,
    ApplicationDecisionStatusId: string = '') {
    return this._put(`Applications/UpdateApplicationStatusAndState`, {
      ApplicationId,
      ApplicationStatusId,
      ApplicationStateId,
      ApplicationDecisionStatusId,
      ApplicationDecisionReasonId
    });
  }

  cancelApplication(applicationId: string) {
    return this._put(`v2/Applications/CancelByCustomer/${applicationId}`, {});
  }

  createApplicationForAnExistingUser(brandId: number) {
    return this._post(`v2/Customer/Existing/Add/${brandId}`);
  }

  checkInFlight(brandId: number) {
    return this._get(`Applications/InflightApplication/${brandId}`);
  }

  cancelInflight(brandId: number) {
    return this._put('v2/Applications/CancelInflight', brandId);
  }

  cancelCreateNew(
    applicationId: string,
    productVestingType: ProductVestingType
  ) {
    return this._post(
      `v2/Customer/CancelExisting/${applicationId}/createNew`,
      productVestingType
    );
  }

  saveBeneficiaries(beneficiariesInformation: Beneficiary[], beneficiarieType: BENEFICIARY_TYPE, stepsToSkip?: any, skipHistory?: boolean) {
    let beneficiaryType = beneficiarieType === BENEFICIARY_TYPE.Contingent ? `contingents` : `beneficiaries`;
    if (environment.isProxy) {
      return this.store$.select(getApplicationId).pipe(
        switchMap(applicationId => {
          let route = `${beneficiaryType}/application/${applicationId}`;
          if (skipHistory){
            route = `${beneficiaryType}/application/${applicationId}?skipHistory=${skipHistory}`;
          } else {
            if (stepsToSkip != null) {
              route = `${beneficiaryType}/application/${applicationId}?stepsToSkip=${stepsToSkip}`;
            }
          }
          return this._post<AxosResult<Beneficiary[]>>(route, beneficiariesInformation).pipe(
            map((res) => res.data)
          );
        })
      );
    }
    else {
      let route = `${beneficiaryType}`;
      if(skipHistory){
        route = `${beneficiaryType}?skipHistory=${skipHistory}`
      }else{
        if (stepsToSkip != null){
          route = `${beneficiaryType}?stepsToSkip=${stepsToSkip}`;
        } 
      }
      return this._post<AxosResult<Beneficiary[]>>(route, beneficiariesInformation).pipe(map((res) => res.data));
    }
  }

  getBeneficiaries(typeName: string): Observable<Beneficiary[]> {
    if (environment.isProxy) {
      return this.store$.select(getApplicationId).pipe(
        switchMap(applicationId => {
          return this._get<AxosResult<Beneficiary[]>>(`beneficiaries/application/${applicationId}?beneficiaryTypeName=${typeName}`).pipe(map(res => {
            return res.data;
          }));
        })
      );
    }
    else {
      return this._get<AxosResult<Beneficiary[]>>(`beneficiaries?beneficiaryTypeName=${typeName}`).pipe(map(res => {
        return res.data;
      }))
    }
  }

  declinedBeneficiariesSelection(beneficiarieType: BENEFICIARY_TYPE, applicationId: string, applicantId: string, declinedBeneficiaries: boolean, isProxy: boolean){
    let type = beneficiarieType === BENEFICIARY_TYPE.Contingent ? `contingents` : `beneficiaries`;
    let route = `${type}/disclosure`;
    if(isProxy){
      route = `${route}/application/${applicationId}?beneficiaryDeclined=${declinedBeneficiaries}`
    }else{
      route = `${route}/${applicantId}?beneficiaryDeclined=${declinedBeneficiaries}`;
    }
    return this._post<any>(route).pipe(
        map(response => response.success)
    )
  }  

  saveTrustCertificate(applicationId: string, trustCertificate: TrustCertificate, skipHistory: boolean = false) {
    return this._postWithParams(`application/${applicationId}/trustcertificate`, { skipHistory }, trustCertificate);
  }

  getTrustCertificate(applicationId: string): Observable<TrustCertificate> {
    return this._get<AxosResult<TrustCertificate>>(`application/${applicationId}/trustcertificate`).pipe(map(res => {
      return res.data;
    }))
  }

  getTrustTypes(){
    return this._get<AxosResult<TrustTypes[]>>('generic/trustcertificate/trusttypes').pipe(map(response => {
      return response;
    }))
  }

  clearCache(){
    if(!environment.isProxy){
      this.cookieService.delete('XSRF-TOKEN');
    }

    CLEAR_CACHE();
    this.store$.dispatch(new ClearApplicantsStateAction());
    this.store$.dispatch(new ClearApplicationStateAction());
    this.store$.dispatch(new ClearDisclosuresState());
    this.store$.dispatch(clearTradingState());
    this.store$.dispatch(clearProxyState());
    this.store$.dispatch(clearWhiteLabelState());
    this.store$.dispatch(clearWorkflowState());
  }

  //TODO create cancel service

  //TODO create "create application for existing user"

  /*
  function cancelByUser(applicationId) {
      return apiRestangular
          .all('Applications')
          .one('Cancel', applicationId)
          .one('', 1)
          .post('');
  }

  function cancelByUserOnMitekPage(applicationId) {
      return apiRestangular
          .one('v2/Applications/CancelByCustomer', applicationId)
          .customPUT({});
  }

  function cancel(applicationId) {
      return apiRestangular
          .one('v2/Applications/Cancel', applicationId)
          .customPUT(undefined, undefined, undefined, {});
  }

  function addComments(applicationId, comment) {
      return apiRestangular
          .all('v2/Applications')
          .one('Comments', applicationId)
          .post('', { comment: comment });
  }
  */
}

