import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
// Services
import { BaseService } from './abstracts';
// Models
import { Brand, ChatSettings } from 'models';
import { BRAND_DISCLOSURES } from 'models/enums';
import { of } from 'rxjs/internal/observable/of';
import { ChatBotSettings } from 'models/brand/chatbot-settings.model';

@Injectable()
export class BrandService extends BaseService {
  get(brandName: string) {
    return this._get<Brand>(`generic/branch/${brandName}`);
  }

  getBrand(brandName: string) {
    return this._get<Brand>(`generic/branch/${brandName}`);
  }

  validatePromoCode(brandId: string, promoCode: string){
    return this._get<boolean>(`consumer/brand/${brandId}/PromoCode/${promoCode}/Valid`, null, false);
  }

  getUdbLoginUrl(brandName: string) {
    const params = new HttpParams().set('brandName', brandName);
    return this._get<string>('UDBLoginUrl', params);
  }

  getEnrollmentUrl(brandId: number) {
    return this._get<string>(`EnrollmentUrl/${brandId}`);
  }

  getUdbUrl() {
    return this._get<string>('UDB');
  }

  getLiveChatSettings(brandId: string) {
    return this._get<ChatSettings>(`v2/Brand/liveAgentInfo/${brandId}`);
  }

  getChatBotSettings(brandId: number) {
    return this._get<ChatBotSettings>(`generic/branch/chatbot/${brandId}`);
  }

  getDisclosure(brandId: number, brandDisclosure: BRAND_DISCLOSURES) {
    return this._get<string>(`Disclosures/${brandDisclosure}/Brand/${brandId}`);
  }

  getLogo(brandId: string){
    return of('assets/header/logo.svg');
  }
}
