import {
    IDENTIFICATION_DOCUMENT_TYPE,
} from '../enums'

import {
    IdentificationDocumentImage
}from '../models'

export class IdentificationDocument {
    DocumentType: IDENTIFICATION_DOCUMENT_TYPE;
    Images: IdentificationDocumentImage[];

    constructor(documentType: IDENTIFICATION_DOCUMENT_TYPE) {
        this.DocumentType = documentType;
        this.Images = [];
    }
}
