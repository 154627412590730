import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { LoginSuccessAction } from 'store/actions';

import { addSeconds } from 'date-fns';

import { IAuthToken, IUser } from 'models/auth';
import { TOKEN_STORAGE_NAME, AUTH_PARENT_ROUTE, AUTH_ROUTES } from '../constants';
import { CacheService } from 'app/core/services';
import { ISignInRequest, ISignInData } from 'models';
import { AuthService } from 'services';


@Injectable()
export class AuthHandlerService extends CacheService {

  constructor(
    private router: Router,
    private store$: Store<EnrollmentState>,
    private authService:AuthService
  ) { super(); }

   setToken(token: IAuthToken) {
    const maximumExpirationInSeconds = 1800;
    token.expires_in = token.expires_in < maximumExpirationInSeconds ? token.expires_in : maximumExpirationInSeconds;
    if (!token.expires_date) {
      token.expires_date = addSeconds(new Date(),token.expires_in || 1799);
    }

    const storageToken = this.getCachedToken();
    if (storageToken !== null) {
      // && appConstants.fromProxy
      if (storageToken.refresh_token !== null && token.refresh_token === null) {
        token.refresh_token = storageToken.refresh_token;
      }
    }
    this.currentToken = token;
  }

  //#region Token Data
  private _currentToken: IAuthToken;
  private get currentToken(): IAuthToken {
    if (!this._currentToken) { this._currentToken = this.getCachedToken(); }
    return this._currentToken;
  }
  private set currentToken(value: IAuthToken) {
    this._currentToken = value;
    this.setCachedToken(this._currentToken);
  }

  setNewIdentityToken(accessToken: string): void {
    const token: IAuthToken = {
      access_token: accessToken,
      challengeInfo: null,
      expires_date: null,
      expires_in: 1800,
      hasSecurityQuestions: false,
      password_standard_met: false,
      refresh_token: null,
      signin_type:1
    }
    this.setToken(token);
  }

  get token(): string { return this.currentToken ? this.currentToken.access_token : ''; }
  //#endregion

  //#region User Data
  private _currentUser: IUser;
  private get currentUser(): IUser {
    // if (!this._currentUser) { this._currentUser = this.getCachedUser(); }
    return this._currentUser;
  }
  private set currentUser(value: IUser) {
    this._currentUser = value;
    // this.setCachedUser(this.currentUser);
  }

  get userName(): string { return this.currentUser ? this.currentUser.UserName : null; }
  get applicationId(): string { return this.currentUser ? this.currentUser.ApplicationId : null; }
  //#endregion

  get loginURL() { return `${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.LOGIN}`; }

  logOut(redirect = false) {
    this.currentToken = null;
    this.currentUser = null;
    if (redirect) { this.router.navigateByUrl(this.loginURL); }
  }

  updateToken(token: IAuthToken) {
    this.store$.dispatch(new LoginSuccessAction(token));
  }


  //#region Cached Data
  getCachedToken(): IAuthToken { return this.getCached(TOKEN_STORAGE_NAME); }
  setCachedToken(value: IAuthToken) { this.setCache(value, TOKEN_STORAGE_NAME); }
  //#endregion
}
