import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { tap, filter, first } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState, GetPendingIDVerificationApplicantsAction } from 'store';
import { getPendingVerificationApplicants } from 'store/selectors';
import { APPLICANT_TYPE } from 'models/enums';

@Injectable()
export class PendingVerificationApplicantsResolver implements Resolve<Observable<APPLICANT_TYPE[]>> {

    constructor(private store$: Store<EnrollmentState>) { }

    resolve() {
      return this.store$.select(getPendingVerificationApplicants).pipe(
        filter(applicants => {
          if(!applicants) {
            this.store$.dispatch(new GetPendingIDVerificationApplicantsAction());
          }
          return !!applicants;
        }),
        first()
      );
   }
}
