// Ng
import { Injectable } from '@angular/core';
// RxJs
import { Observable } from 'rxjs';
// Services
import { BaseService } from '../abstracts';
import { map } from 'rxjs/operators';
import { AxosResult } from 'models/enums/trading/axos-result.model';
import { FLOW_STEPS } from 'models/enums';
declare var environment: any;

export interface AxosWorkflow {
  lastStep: FLOW_STEPS;
  nextStep: FLOW_STEPS;
  workflowName: string;
  steps: {
    name: FLOW_STEPS,
    subWorkflowName: string;
  }[];
}

@Injectable()
export class ApplicationFlowService extends BaseService {

  getFlow(appId: string, applicantType?: string): Observable<AxosWorkflow> {
    // Add Applicant Type
    let params = '';
    if(applicantType) {
      params = `?applicantType=${applicantType}`;
    }
    return super._get<AxosResult<any>>(`workflow/application/${appId}${params}`)
      .pipe(map(({ data }) => data));
  }

  getSubflow(workflowName: string, applicationId: string, applicantType: string): Observable<AxosWorkflow> {
    let params = '';
    if (applicantType) {
      params = `?applicantType=${applicantType}`;
    }
    if (environment.isProxy) {
      return super._get<AxosResult<any>>(`workflow/${workflowName}/application/${applicationId}${params}`)
        .pipe(map(({ data }) => data));
    }
    else {
      return super._get<AxosResult<any>>(`workflow?workflowName=${workflowName}&applicantType=${applicantType}`)
        .pipe(map(({ data }) => data));
    }
  }

  goBack(appId: string, applicantType: string): Observable<AxosWorkflow> {
    return super._get<AxosResult<any>>(`generic/workflow/application/${appId}/back?applicantType=${applicantType}`)
      .pipe(map(({ data }) => data));
  }
}
