export enum MARITAL_STATUS_ID {
    Single = 'single',
    Married = 'married',
    Divorced = 'divorced',
    Widower = 'widower'
}

export enum MARITAL_STATUS_NAME {
    Single = 'Single',
    Married = 'Married',
    Divorced = 'Divorced',
    Widower = 'Widower'
}

export const MARITAL_TO_NAME = {
    [MARITAL_STATUS_ID.Single]: MARITAL_STATUS_NAME.Single,
    [MARITAL_STATUS_ID.Married]: MARITAL_STATUS_NAME.Married,
    [MARITAL_STATUS_ID.Divorced]: MARITAL_STATUS_NAME.Divorced,
    [MARITAL_STATUS_ID.Widower]: MARITAL_STATUS_NAME.Widower
}

export const MARITAL_TO_ID = {
    [MARITAL_STATUS_NAME.Single]: MARITAL_STATUS_ID.Single,
    [MARITAL_STATUS_NAME.Married]: MARITAL_STATUS_ID.Married,
    [MARITAL_STATUS_NAME.Divorced]: MARITAL_STATUS_ID.Divorced,
    [MARITAL_STATUS_NAME.Widower]: MARITAL_STATUS_ID.Widower
}

export const MARITAL_STATUS = [
    { id: MARITAL_STATUS_ID.Single, name: MARITAL_STATUS_NAME.Single },
    { id: MARITAL_STATUS_ID.Married, name: MARITAL_STATUS_NAME.Married },
    { id: MARITAL_STATUS_ID.Divorced, name: MARITAL_STATUS_NAME.Divorced },
    { id: MARITAL_STATUS_ID.Widower, name: MARITAL_STATUS_NAME.Widower }
]