export enum FLOW_STEPS {
  // For me these don't make sense
  registrationPage = 'registrationPage',
  usernamePassword = 'usernamePassword',
  editInformation = '',
  NumberOfTrustees = 'numberOfTrustees',

  NotStarted = 'notStarted',
  AccountType = 'accountType',
  AccountFeature = 'accountFeature',
  OptionsLevel = 'optionsLevel',
  PersonalInformation = 'personalInformation',
  AddressInformation = 'addressInformation',
  AdditionalOwnerInformation = 'additionalOwnerInformation',
  FinancialInformation = 'financialInformation',
  InvestmentProfile = 'investmentProfile',
  IdDetails1 = 'idDetails1',
  IdDetails2 = 'idDetails2',
  //IdentificationUpload = 'identificationUpload',
  Beneficiaries = 'beneficiaries',
  ContingentBeneficiaries = 'contingentBeneficiary',
  TrustCertificate = 'trustCertificate',
  ReviewAndSubmit = 'reviewAndSubmit',
  ConfirmIdentity = 'confirmIdentity',
  Complete = 'complete',
  PendingDocumentReview = 'pendingDocumentReview',
  DocumentsRequested = 'documentsRequested',
  DocumentsReceived = 'documentsReceived',
  WaitingForSecondary = 'waitingForSecondary',
  TrustedContactProxy = 'trustedContact',
  investmentGoal = 'investmentGoal',
  investingHistory = 'investingHistory',
  recommendedPortfolio = 'recommendedPortfolio',
  portfolioReviewAndSubmit = 'portfolioReviewAndSubmit',
  // MP inner flow steps
  goalSelection = 'goalSelection',
  timeHorizon = 'timeHorizon',
  accountSelection = 'accountSelection',
  riskQuestions = 'riskQuestions'
}
