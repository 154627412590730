export const TOKEN_STORAGE_NAME = 'token';
export const USER_STORAGE_NAME = 'user';
export const UTM_STORAGE_NAME = 'UTM';
export const DECLINED_TRUST ='declinedTrust';
export const PROXY_APPLICATION_ID = 'ProxyApplicationId';
export const PHONE_NUMBER ='PhoneNumber';
export const IS_VALIDATED ='isValidated';
export const IS_INVEST = -4;
export const IS_EMAIL = 'email';
export const IS_SMS = 'sms'; 
export const otpTimeoutMessage= "Your validation request has expired. Please attempt to log in again.";
export const systemError= "System error"
export const Validation_TimeOut= "OTP Validation Request has timed out. Please try login in again."
