// RxJs
import { filter, withLatestFrom, map, switchMap, first } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { LoadApplicantAction } from 'store/actions';
import { getApplicantByType } from 'store/selectors';
// Models
import { APPLICANT_TYPE_NAME } from 'models/enums';
import { ApplicationResolver } from '../application.resolver';

export abstract class ApplicantBaseResolver {

    constructor(
      private store$: Store<EnrollmentState>,
      private applicationResolver: ApplicationResolver) { }

    getApplicant(applicantType: APPLICANT_TYPE_NAME) {
      console.log('getApplicant Resolver', applicantType);
      if(!applicantType) { applicantType = APPLICANT_TYPE_NAME.Primary }
      const applicant$ = this.store$.select(getApplicantByType(applicantType));

      return this.applicationResolver.resolve().pipe(
        map(application => application.Applicants),
        switchMap(applicantsMetadata => applicant$.pipe(
          map(applicant => {
            return { applicantsMetadata, applicant };
          }))
        ),
        map(data => {
          if(data.applicantsMetadata.length > 0 && !data.applicant) {
            const mApplicant = data.applicantsMetadata.find(applicant => applicant.type === applicantType);
            //TODO: Have a way to identify if is already being loaded
            this.store$.dispatch(new LoadApplicantAction(mApplicant.applicantId));
          }
          return data.applicant;
        }),
        filter(applicant => !!applicant),
        first()
      );
    }
}
