import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ContentChildren, Input, OnInit, QueryList, ViewEncapsulation } from '@angular/core';
import { UkBaseFormControl, UkFieldError, UkFormFieldControl, UkInput } from '@uikit/components';
import { ErrorDirective } from 'app/enrollment-common/directives/error.directive';
import { HintDirective } from 'app/enrollment-common/directives/hint.directive';
import { LabelDirective } from 'app/enrollment-common/directives/label.directive';
import { PrefixDirective } from 'app/enrollment-common/directives/prefix.directive';
import { SuffixDirective } from 'app/enrollment-common/directives/suffix.directive';

const NO_CONTROL_FOUND = 'No control of type BaseInput was found inside the axos-form-field.';

@Component({
  selector: 'axos-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'uk-form-field',
    '[class.uk-form-field-disabled]': 'control?.disabled',
    '[class.uk-form-field-focus]': 'control?.focused',
    '[class.uk-form-field-invalid]': 'control?.invalid && control?.touched',
  }
})

export class FormFieldComponent implements AfterContentInit {
    isPassword = false;
    isValueShown = false;
    controlErrors: UkFieldError[];

    constructor(private cdRef: ChangeDetectorRef) { }

    @Input() hint: string;
    @Input() label: string;
    @Input() tooltip: string;
    @Input() fieldName: string;
    @Input() hideLabel: boolean;
    @Input() optionalText: string = 'Optional'; 
    @ContentChild(UkBaseFormControl, { static: true }) control: UkBaseFormControl<any> | UkFormFieldControl<any>;
    @ContentChildren(ErrorDirective) errorChildren: QueryList<ErrorDirective>;
    @ContentChildren(HintDirective) hintChildren: QueryList<HintDirective>;
    @ContentChildren(LabelDirective) labelChildren: QueryList<LabelDirective>;
    @ContentChildren(PrefixDirective) prefixChildren: QueryList<PrefixDirective>;
    @ContentChildren(SuffixDirective) suffixChildren: QueryList<SuffixDirective>;

    toggleShow() {
        this.isValueShown = !this.isValueShown;
        (this.control as UkInput).type = this.isValueShown ? 'text' : 'password';
    }

    /** ===> Error Handling <=== */
    getDefaultErrors() {
        if (this.control.errors) {
            this.controlErrors = Object.keys(this.control.errors).map(key => this.control.errors[key]);
        }
    }

    get shouldDisplayError(): boolean {
        this.getDefaultErrors();
        return (
            (
                (this.errorChildren && this.errorChildren.length > 0) ||
                (this.controlErrors && this.controlErrors.length > 0)
            ) &&
            (this.control.invalid && this.control.touched)
        );
    }

    /** ===> Angular LifeCycle Hooks <=== */
    ngAfterContentInit() {
        this.validateControl();
        if (this.control.controlType === 'input' || this.control.controlType === 'input-ssn') {
            const inputControl = this.control as UkInput;
            this.isPassword = inputControl.type === 'password';
        }
        // To Update UI
        this.control.statusChange.subscribe(() => {
            this.cdRef.markForCheck();
        });
        this.control.focusedChange.subscribe(() => { this.cdRef.markForCheck(); });
        this.control.valueChange.subscribe(() => { this.cdRef.markForCheck(); });
    }

    /** Throws an error if no control (UkFormFieldControl) is found/contained */
    validateControl(): void {
        if (!this.control) {
            throw Error(NO_CONTROL_FOUND);
        }
    }
}
