import { Injectable } from '@angular/core';
// Services
import { BaseService } from './abstracts';
import { AccountFeature, Feature } from 'models/trading/account-feature.model';
import { of, Observable } from 'rxjs';
// RxJs
import { map } from "rxjs/operators";
import { PersonalInformationTrading } from 'models/trading/personal-information.model';
import { EMPLOYMENT_STATUS_ID } from 'models/enums/trading/employment-status.enum';
import { IInvestmentProfile } from 'models/trading/investment-profile.model';
import { EXPERIENCE_LEVEL_NAME } from 'models/enums/trading/investment.enum';
import { MARITAL_STATUS_ID } from 'models/enums/trading/marital-status.enum';
import { AxosResult } from 'models/enums/trading/axos-result.model';
import { FinancialInformation } from 'models';
import { PersonAbout } from 'models/trading/person-about.model';
import { Person } from 'models/trading/person.model';

@Injectable()
export class TradingService extends BaseService {

    saveAccountFeature(features: string[], applicationId: string) {
        return this._post(`application/${applicationId}/features`, features);
    }

    savePesonalInformation(personalInformation: PersonalInformationTrading) {
        // return this._post<IdentificationInfo>(`personal/pip/${applicantId}/personalInformation`, personalInformation);
        return of(personalInformation);
    }

    getInvestmentProfile(applicationId: string): Observable<any> {
        return this._get<AxosResult<any>>(`application/${applicationId}/trading/investmentprofile`).pipe(map((res) => {
            const investmentData = {
                overallInvestmentExperience: res.data.overallInvestmentExperience.name,
                investmentObjective: res.data.investmentObjective.name,
                timeHorizon: res.data.timeHorizon.name,
                riskTolerance: res.data.riskTolerance.name,
                dividendInstructions: res.data.dividendInstructions.name,
                cashSweepProgram: res.data.cashSweepProgram != null ? res.data.cashSweepProgram : '',
                tradingExperiences: res.data.tradingExperiences
            }
            return investmentData
        }));
    }

    getAccountFeature(applicationId: string): Observable<AccountFeature> {
        return this._get<AxosResult<Feature[]>>(`application/${applicationId}/features`).pipe(map((res) => {
            return ({ TradingFeatures: res.data })
        }));
    }

    getFeatures(): Observable<Feature[]> {
        return this._get<AxosResult<Feature[]>>('generic/account/trading/features').pipe(map((res) => res.data));
    }

    getFeaturesByApplication(applicationId: string): Observable<Feature[]> {
        return this._get<AxosResult<Feature[]>>(`generic/account/trading/features/${applicationId}`)
                   .pipe(map(res => res.data));
    }

    saveFinancialInformation(information: FinancialInformation, applicationId: string, skipHistory: boolean = false) {
        return this._postWithParams<FinancialInformation>(`application/${applicationId}/trading/financialinformation`, {skipHistory}, information);
    }

    getPersonalInformation(applicantId: string): Observable<Person> {
        return this._get<Person>(`person/${applicantId}/basic`);
    }

    getFinancialInformation(applicantId: string): Observable<FinancialInformation> {
        return this._get<AxosResult<FinancialInformation>>(`application/${applicantId}/trading/financialinformation`)
            .pipe(map((res) => res.data));
    }

    saveInvestmentProfile(investmentProfileInfo: IInvestmentProfile, applicationId: string, skipHistory: boolean = false): Observable<any> {
        return this._postWithParams<AxosResult<IInvestmentProfile>>(`application/${applicationId}/trading/investmentprofile`, {skipHistory}, investmentProfileInfo)
            .pipe(map(res => res.data));
    }
}
