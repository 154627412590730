import {
  IDENTIFICATION_DOCUMENT_TYPE, ID_VERIFICATION_METHOD,
} from '../enums'

export class IdVerificationIdentifierDto {
  DmdToken: string;
  ApplicantId: number;

  constructor(verificationMethod: ID_VERIFICATION_METHOD, dmdToken?: string, applicantId?: number) {
    switch (verificationMethod) {
      case ID_VERIFICATION_METHOD.Instant:
        this.ApplicantId = applicantId;
        this.DmdToken = null;
        break;
      case ID_VERIFICATION_METHOD.Instant_DMD:
        this.DmdToken = dmdToken;
        this.ApplicantId = null;
        break;
      default:
        this.DmdToken = null;
        this.ApplicantId = null;
        break;
    }
  }
}
