import { Action } from "@ngrx/store";

export enum FEATURES_ACTION_TYPES {
    GET_FEATURE_STATUS = '[Features] Get feature status',
    GET_FEATURE_STATUS_SUCCESS = '[Features] Get feature status success',
    GET_FEATURE_STATUS_FAIL = '[Features] Get feature status fail'
}

export class GetFeatureStatusAction implements Action {
    readonly type = FEATURES_ACTION_TYPES.GET_FEATURE_STATUS;
    constructor(public featureName: string) { }
  }
  
  export class GetFeatureStatusSuccessAction implements Action {
    readonly type = FEATURES_ACTION_TYPES.GET_FEATURE_STATUS_SUCCESS;
    constructor(public featureName: string, public isEnabled: boolean) { }
  }

  export class GetFeatureStatusFailAction implements Action {
    readonly type = FEATURES_ACTION_TYPES.GET_FEATURE_STATUS_FAIL;
    constructor(public error: string) {}
}

export type FeaturesAction = GetFeatureStatusAction | GetFeatureStatusSuccessAction | GetFeatureStatusFailAction;