import { SECURE_APP_ROUTES } from './app-routes.constant';

export const APP_CONSUMER_SECURE_ROUTES = [
    //#region Lazy Loading
    {
        path: 'id',
        loadChildren: () => import('app/applicant-identification/applicant-identification.module').then(m => m.ApplicantIdentificationModule)
    },
    //#endregion
    ...SECURE_APP_ROUTES
];
