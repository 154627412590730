import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
// RxJs
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store/states';
import { setWorkflowStep } from 'store/workflows';

@Injectable()
export class FlowStepInterceptor implements HttpInterceptor {
  constructor(private store$: Store<EnrollmentState>) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((res: HttpResponse<any>) =>  {
        const { body } = res;
        if(body && body.metadata) {
          const { nextStep, lastStep } = body.metadata;
          if(nextStep) {
            this.store$.dispatch(setWorkflowStep({ step: nextStep, lastStep }));
          }
        }
      })
    );
  }
}
