// Module Routes
import { BUSINESS_PARENT_PATH } from 'app/business-information/routing';

import { SECURE_APP_ROUTES } from './app-routes.constant';

export const APP_BUSINESS_ROUTES = [
    //#region Lazy Loading
    {
        path: BUSINESS_PARENT_PATH,
        loadChildren: () => import('app/business-information/business-information.module').then(m => m.BusinessInformationModule)
    },
    //#endregion
    ...SECURE_APP_ROUTES
];
