export class Disclosure{
     id: number;
     name?: string;
     title: string;
     subtitle: string;
     content: string;
     class: string;
     htmlUrl: string;
     htmlTemplate: any;
     pdf: string;
     template: any;
     isAccepted: boolean;
     [key: string]: any;
}