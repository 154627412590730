export enum EMPLOYMENT_STATUS_ID {
    Employed = 'employed',
    SelfEmployed = 'selfemployed',
    Retired = 'retired',
    Homemaker = 'homemaker',
    Student = 'student',
    Unemployed = 'unemployed'
}

export enum EMPLOYMENT_STATUS_NAME {
    Employed = 'Employed',
    SelfEmployed = 'Self Employed',
    Retired = 'Retired',
    Homemaker = 'Homemaker',
    Student = 'Student',
    Unemployed = 'Unemployed'
}

export const EMPLOYMENT_TO_NAME = {
    [EMPLOYMENT_STATUS_ID.Employed]: EMPLOYMENT_STATUS_NAME.Employed,
    [EMPLOYMENT_STATUS_ID.SelfEmployed]: EMPLOYMENT_STATUS_NAME.SelfEmployed,
    [EMPLOYMENT_STATUS_ID.Retired]: EMPLOYMENT_STATUS_NAME.Retired,
    [EMPLOYMENT_STATUS_ID.Homemaker]: EMPLOYMENT_STATUS_NAME.Homemaker,
    [EMPLOYMENT_STATUS_ID.Student]: EMPLOYMENT_STATUS_NAME.Student,
    [EMPLOYMENT_STATUS_ID.Unemployed]: EMPLOYMENT_STATUS_NAME.Unemployed,
}

export const EMPLOYMENT_TO_ID = {
    [EMPLOYMENT_STATUS_NAME.Employed]: EMPLOYMENT_STATUS_ID.Employed,
    [EMPLOYMENT_STATUS_NAME.SelfEmployed]: EMPLOYMENT_STATUS_ID.SelfEmployed,
    [EMPLOYMENT_STATUS_NAME.Retired]: EMPLOYMENT_STATUS_ID.Retired,
    [EMPLOYMENT_STATUS_NAME.Homemaker]: EMPLOYMENT_STATUS_ID.Homemaker,
    [EMPLOYMENT_STATUS_NAME.Student]: EMPLOYMENT_STATUS_ID.Student,
    [EMPLOYMENT_STATUS_NAME.Unemployed]: EMPLOYMENT_STATUS_ID.Unemployed,
}

export const EMPLOYMENT_STATUS = [
    { id: EMPLOYMENT_STATUS_ID.Employed, name: EMPLOYMENT_STATUS_NAME.Employed },
    { id: EMPLOYMENT_STATUS_ID.SelfEmployed, name: EMPLOYMENT_STATUS_NAME.SelfEmployed },
    { id: EMPLOYMENT_STATUS_ID.Retired, name: EMPLOYMENT_STATUS_NAME.Retired },
    { id: EMPLOYMENT_STATUS_ID.Homemaker, name: EMPLOYMENT_STATUS_NAME.Homemaker },
    { id: EMPLOYMENT_STATUS_ID.Student, name: EMPLOYMENT_STATUS_NAME.Student },
    { id: EMPLOYMENT_STATUS_ID.Unemployed, name: EMPLOYMENT_STATUS_NAME.Unemployed }
]