import { Applicant, Address, IdentificationInfo, PhoneNumber, RecommendedAddress } from 'models';
import { APPLICANT_TYPE_NAME, PHONE_TYPE_UNIVERSAL, APPLICANT_TYPE_TO_NAME, APPLICANT_TYPE_TO_ID } from 'models/enums';

import { ApplicantsState } from '../states';
import * as fromActions from '../actions/applicants.action';
import { SET_ADDRESS_BY_TYPE, SET_PHONE_BY_TYPE, SET_RECOMMEND_ADDRESS_BY_TYPE } from 'app/core/utils';

export const initialState: ApplicantsState = {
  applicants: [],
  currentApplicantType: APPLICANT_TYPE_NAME.Primary,
  loaded: false,
  loading: false
}

function UPDATE_APPLICANT(applicants: Applicant[], applicantId: string, newData: any): Applicant[] {
  let applicant: Applicant = applicants.find(applicant => applicant.applicantId === applicantId);
  applicant = Object.assign({}, applicant, { ...newData });
  applicants = [
    ...applicants.filter(applicant => applicant.applicantId !== applicantId),
    applicant
  ];
  return applicants.sort((a, b) => a.applicantId > b.applicantId ? 1 : -1)
}

function UPDATE_STORE_APPLICANT(applicants: Applicant[], applicantId: string, newData: any): Applicant[] {
  const applicant: Applicant = applicants.find(applicant => applicant.applicantId === applicantId);
  const newApplicant = { ...applicant, ...newData };
  const newApplicants = [
    ...applicants.filter(applicant => applicant.applicantId !== applicantId),
    newApplicant
  ];
  return newApplicants.sort((a, b) => APPLICANT_TYPE_TO_ID[a.type] > APPLICANT_TYPE_TO_ID[b.type] ? 1 : -1)
}

export function reducer(
  state = initialState,
  action: fromActions.ApplicantsAction
): ApplicantsState {
  switch (action.type) {
    case fromActions.APPLICANTS_ACTION_TYPES.LOAD_APPLICANT:
    case fromActions.APPLICANTS_ACTION_TYPES.LOAD_ALL_APPLICANTS: {
      return { ...state, loading: true };
    }
    case fromActions.APPLICANTS_ACTION_TYPES.SET_CURRENT_APPLICANT_TYPE: {
      return { ...state, currentApplicantType: action.applicantType };
    }
    // case fromActions.APPLICANTS_ACTION_TYPES.UPDATE_APPLICANT_SUCCES:
    case fromActions.APPLICANTS_ACTION_TYPES.LOAD_APPLICANT_SUCCESS: {
      const newApplicants = [
        ...state.applicants.filter(applicant => applicant.applicantId !== action.applicant.applicantId),
        action.applicant
      ];

      return {
        ...state,
        loading: false,
        currentApplicantType: action.setAsCurrent ? APPLICANT_TYPE_NAME[action.applicant.type] : state.currentApplicantType,
        applicants: newApplicants.sort((a, b) => a.applicantId > b.applicantId ? 1 : -1)
      };
    }
    case fromActions.APPLICANTS_ACTION_TYPES.LOAD_ALL_APPLICANTS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        applicants: action.applicants
      };
    }
    case fromActions.APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_PHONE_SUCCCESS: {
      const newPhone: PhoneNumber = action.phone;
      let applicant: Applicant = state.applicants.find(applicant => applicant.applicantId === action.applicantId);
      return {
        ...state,
        applicants: UPDATE_APPLICANT(state.applicants, action.applicantId, {
          phones: SET_PHONE_BY_TYPE(applicant.phones, newPhone, PHONE_TYPE_UNIVERSAL[newPhone.phoneType])
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_ADDRESS_SUCCCESS: {
      const newAddress: Address = action.address;
      let applicant: Applicant = state.applicants.find(applicant => applicant.applicantId === action.applicantId);
      let addressInfoes: Address[] = applicant.addresses ? applicant.addresses : [newAddress];
      return {
        ...state,
        applicants: UPDATE_STORE_APPLICANT(state.applicants, action.applicantId, {
          addresses: SET_ADDRESS_BY_TYPE(addressInfoes, newAddress, newAddress.addressType)
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.RECOMMENDED_APPLICANT_ADDRESS_SUCCESSS: {
      const newAddress: RecommendedAddress = action.recaddress;
      let applicant: Applicant = state.applicants.find(applicant => applicant.applicantId === action.applicantId);
      let addressInfoes: RecommendedAddress[] = applicant.recommend ? applicant.recommend : [newAddress];
      return {
        ...state,
        applicants: UPDATE_STORE_APPLICANT(state.applicants, action.applicantId, {
          recommend: SET_RECOMMEND_ADDRESS_BY_TYPE(addressInfoes, newAddress)
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.SAVE_APPLICANT_IDENTIFICATION_SUCCCESS: {
      const newIdentification: IdentificationInfo = action.identification;
      let applicant: Applicant = state.applicants.find(applicant => applicant.applicantId === action.applicantId);
      return {
        ...state,
        applicants: UPDATE_APPLICANT(state.applicants, action.applicantId, {
          IdentificationInfo: {
            ...applicant.IdentificationInfo,
            ...newIdentification
          }
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.GET_ADDRESS_INFOES: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }
    case fromActions.APPLICANTS_ACTION_TYPES.GET_ADDRESS_INFOES_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_STORE_APPLICANT(state.applicants, action.applicantId, {
          addresses: action.AdressInfoes
        }),
        loaded: true,
        loading: false
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.LOAD_PERSONAL_INFORMATION_SUCCESS:
    case fromActions.APPLICANTS_ACTION_TYPES.SAVE_PI_UNIVERSAL_SUCCESS:
    case fromActions.APPLICANTS_ACTION_TYPES.SAVE_BASIC_INFORMATION_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_STORE_APPLICANT(state.applicants, action.applicantId, {
          person: action.person
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.SAVE_ADDITIONAL_OWNER_INFO_SUCCESS:
    case fromActions.APPLICANTS_ACTION_TYPES.UPDATE_ADDITIONAL_OWNER_INFO_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_STORE_APPLICANT(state.applicants, action.applicantId, {
          person: action.additionalPerson
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.UPDATE_TAX_PAYER_ID_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_STORE_APPLICANT(state.applicants, action.applicantId, {
          taxPayerId: action.taxPayerId
        })
      };
    }
    case fromActions.APPLICANTS_ACTION_TYPES.GET_ADDRESS_INFOES_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_APPLICANT(state.applicants, action.applicantId, {
          addresses: action.AdressInfoes
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.GET_REQUESTED_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_APPLICANT(state.applicants, action.applicantId, {
          requestedDocuments: action.requestedDocuments
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.GET_APPLICANT_EXISTING_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_APPLICANT(state.applicants, action.applicantId, {
          isExisting: action.isExisting
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.GET_ADDRESS_INFOES_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_APPLICANT(state.applicants, action.applicantId, {
          addresses: action.AdressInfoes
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.GET_REQUESTED_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_APPLICANT(state.applicants, action.applicantId, {
          requestedDocuments: action.requestedDocuments
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.SAVE_IDENTIFICATION_DETAILS_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_APPLICANT(state.applicants, action.applicantId, {
          applicantIdentification: { ...state.applicants.find(x => x.applicantId === action.applicantId).applicantIdentification, ...action.identificationDetailsInfo }
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.GET_APPLICANT_EXISTING_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_APPLICANT(state.applicants, action.applicantId, {
          isExisting: action.isExisting
        })
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.CLEAR_STATE: {
      return {
        ...initialState
      }
    }
    case fromActions.APPLICANTS_ACTION_TYPES.SAVE_ID_DETAILS_PAGE_2_SUCCESS: {
      return {
        ...state,
        applicants: UPDATE_APPLICANT(state.applicants, action.applicantId, {
          applicantIdentification: { ...state.applicants.find(x => x.applicantId === action.applicantId).applicantIdentification, ...action.idDetails }
        })
      }
    }
  }
  return state;
}
