import { Injectable } from '@angular/core';
// RxJs
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
//
import { BaseService } from './abstracts';

@Injectable()
export class AdvisorService extends BaseService {
    verifyAdvisorId(AdvisorId: string) {
        return super._get(`Advisors/${AdvisorId}`).pipe(
            map((res: any) => res.Data.PreferredName),
            catchError(() => of(null))
        );
    }
}
