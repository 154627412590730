import {
    createSelector,
    createFeatureSelector
} from '@ngrx/store';

import { FEATURE_NAMES } from 'store/constants';
import { DisclosureState } from 'store/states/disclosure.state';

export const getDisclosureState = createFeatureSelector(FEATURE_NAMES.Disclosures);

export const getDetailDisclosures = createSelector(
    getDisclosureState, (state: DisclosureState) => state.detailDisclosures ? state.detailDisclosures : null
);

export const getAxosClearingDisclosures = createSelector(
    getDisclosureState,
    (state: DisclosureState) => state.axosClearingDisclosures ? state.axosClearingDisclosures : null
);

export const getElectronicDisclosure = createSelector(
    getDisclosureState,
    (state: DisclosureState) => state.electronicDisclosure
);

export const getAxosInvestDisclosure = createSelector(
    getDisclosureState,
    (state: DisclosureState) => state.electronicDisclosure
);
export const getTaxPayerDisclosure = createSelector(
    getDisclosureState,
    (state: DisclosureState) => state.initialDisclosures
);

export const getInitialDisclosures = createSelector(
    getDisclosureState,
    (state: DisclosureState) => state.initialDisclosures
);

export const getDisclosuresCatalog = createSelector(
    getDisclosureState,
    (state: DisclosureState) => state.disclosuresCatalog ? state.disclosuresCatalog : null
);

export const getECMDisclosures = createSelector(
    getDisclosureState,
    (state: DisclosureState) => state.ecmDisclosures ? state.ecmDisclosures : null
);

export const getECMDisclosureDocuments = createSelector(
    getDisclosureState,
    (state: DisclosureState) => state.ecmDisclosureDocuments ? state.ecmDisclosureDocuments : []
);

export const getCashSweepDisclosures = createSelector(
    getDisclosureState,
    (state: DisclosureState) => state.cashSweepDisclosures ? state.cashSweepDisclosures : null
);
