import { Injectable } from '@angular/core';
// Services
import { BaseService } from './abstracts';
// Models
import { ZipCode } from 'models';
@Injectable()
export class ZipService extends BaseService {
    get(zipCode) { 
        console.log(zipCode)
        return super._get<ZipCode>(`generic/common/zip/${zipCode}`, null, false); 
    }
}