import { Component, Input, EventEmitter, Output, ViewChild, HostListener, HostBinding, ChangeDetectorRef } from '@angular/core';

import { FileGroupDirective } from '../../directives';
import { FileGroupItem } from 'models';
import { DomSanitizer } from '@angular/platform-browser';
let unique_id = 0;

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {

  fileUploaded: boolean;
  id: string;
  isPdf: boolean;

  @Input() imageURL: string;
  @Input() fileName: string;
  @Input() maxSize = 5;
  @Input() validExtension: string[] = ['pdf', 'jpg', 'jpeg', 'png'];
  @Input() required = false;
  @Input() multiple = false;
  @Input() preview = !this.multiple;
  @Input() defaultEventHandler = true;

  @Output() onError: EventEmitter<string> = new EventEmitter();
  @Output() onFileSelectionStart: EventEmitter<void> = new EventEmitter();
  @Output() onChanged: EventEmitter<FileGroupItem[]> = new EventEmitter();

  private _fileItems: FileGroupItem[] = [];
  get fileItems(): FileGroupItem[] { return this._fileItems; }
  set fileItems(value: FileGroupItem[]) {
    if(this._fileItems !== value) {
      this._fileItems = value;
      this.onChanged.emit(this._fileItems);
    }
  }

  private _previewURL: any;
  get previewURL(): any { return this._previewURL; }
  get acceptedExtensions(): string {
    return this.validExtension.map(ext => `.${ext}`).join(', ');
  }

  pdfSrc: any;

  @ViewChild(FileGroupDirective, {static: false}) fileElement: FileGroupDirective;

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    event.dataTransfer.dropEffect = 'copy';
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  onDrop(event) {
    this.onFilesChanged(event.dataTransfer.files);
    event.preventDefault();
    event.stopPropagation();
  }

  @HostBinding('class.error') errorState = false;

  constructor(private cdr: ChangeDetectorRef, private sanitizer: DomSanitizer) {
    this.id = `enrollment_file_upload_${unique_id++}`
  }

  setPreview(url: string) { this._previewURL = url; }

  removeFiles() {
    this.fileItems = [];
    this._previewURL = '';
    this.isPdf = false;
  }

  selectionStart() {
    this.onFileSelectionStart.emit();
  }

  onFilesChanged(list: FileList) {
    let respond: FileGroupItem[] = [];
    // TODO: Add dialogs on ther warnings
    if (list.length > 0) {
      if (!this.multiple && list.length > 1) {
        console.warn(`User can't select more than one file for ${this.fileName}.`);
      } else {
        for (let i = list.length - 1; i >= 0; i--) {
          const file = list.item(i);
          this._previewURL = '';
          const extension = this.getExtension(file.name);
          if (this.validExtension.indexOf(extension) < 0) {
            this.onError.emit(`${file.name} is wrong file type. Please upload a file in either of the following formats ${this.validExtension.join(' | ')}.`);
            respond = [];
            break;
          }
          if (!this.fileSizeValidate(file.size)) {
            this.errorState = true;
            this.onError.emit(
              `${file.name} is too large. The maximum file size for upload is limited to ${this.maxSize}MB.`
            );
            respond = [];
            break;
          }

          this.errorState = false;

          const fileItem: FileGroupItem = {
            name: `${this.fileName}.${this.getExtension(file.name)}`,
            fileName: file.name,
            file: file
          };

          // Get Preview File
          if (this.preview) {
            if (extension === 'pdf') {
              this.isPdf = true;
              const reader = new FileReader();
              reader.onload = e => {
               this._previewURL = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
               this.cdr.markForCheck();
              };
              reader.readAsDataURL(file);

              let arrayReader = new FileReader();

              arrayReader.onload = (e: any) => {
                this.pdfSrc = e.target.result;
              };

              arrayReader.readAsArrayBuffer(file);
            } else {
              this.isPdf = false;
              this._previewURL = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
            }
          }
          respond.push(fileItem);
        }
      }
    } else if (this.required) {
      console.warn(`User haven't select a file for ${this.fileName}.`);
    }
    this.fileItems = respond;
  }

  private getExtension(filename: string): string {
    return filename.split('.').pop().toLowerCase();
  }

  private fileSizeValidate(size: number): boolean {
    const sizeMB = size / 1024 / 1024;
    return sizeMB < this.maxSize;
  }
}
