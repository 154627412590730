import {
  IMAGE_TYPE
} from '../enums'

export class IdentificationDocumentImage {
  
  get DisplayUrl() { return `url(data:image/jpeg;base64,${this.Image === null ? '' : this.Image})`; }
  get ImageSource() { return `data:image/jpeg;base64,${this.Image === null ? '' : this.Image}`; }

  constructor(public ImageType: IMAGE_TYPE, public Image: string) { }
}
