declare var environment: any;

import { Component } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'axos-browser-banner',
  templateUrl: './browser-banner.component.html',
  styleUrls: ['./browser-banner.component.scss']
})
export class BrowserBannerComponent {
  isAxosUniversal: boolean = !!environment.isAxosUniversal;

  constructor(public platform: Platform) { }
}
