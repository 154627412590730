import { Action } from '@ngrx/store';
import { ISimpleProduct, VestingType } from 'models';
import { VESTING_TYPE_ID } from 'models/enums';

export enum PRODUCTS_ACTION_TYPES {
    GET = '[Products] Get',
    GET_FAIL = '[Products] Get fail',
    GET_SUCCESS = '[Products] Get success',

    GET_VESTINGS = '[Products] Get vestings',
    GET_VESTINGS_FAIL = '[Products] Get vestings fail',
    GET_VESTINGS_SUCCESS = '[Products] Get vestings success',

    SET_VESTING_TYPE = '[Products] Set Vesting type',
    SET_VESTING_TYPE_FAIL = '[Products] Set Vesting type fail',

    SET_PRODUCT_SELECTION = '[Products] Set Selection',
    SET_PRODUCT_SELECTION_FAIL = '[Products] Set Selection fail',
    SET_PRODUCT_SELECTION_SUCCESS = '[Products] Set Selection success',

    UPDATE_PRODUCT_SELECTION = '[Products] Update Selection',
    UPDATE_PRODUCT_SELECTION_FAIL = '[Products] Update Selection fail',
    UPDATE_PRODUCT_SELECTION_SUCCESS = '[Products] Update Selection success',

    REDIRECTO_PRODUCT_SELECTION = '[Products] Redirecto product selection',

    SET_IRA_FLAG = '[Product] Set IRA flag',

    GET_PROXY_PRODUCTS = '[Product] Get products by organization',
    GET_PROXY_PRODUCTS_SUCCESS = '[Product] Get products by organization success',
    CLEAR_PROXY_PRODUCTS = '[Product] Clear proxy product',
    SET_PROXY_PRODUCT_SUCCESS = '[Product] Set proxy product success',

    GET_PRODUCTS_BY_ORGANIZATION = '[Products] Get products by organization',
    GET_PRODUCTS_BY_ORGANIZATION_SUCCESS = '[Products] Get products by organization success',
    GET_PRODUCTS_BY_ORGAIZATION_FAIL = '[Products] Get products by organization fail',

    CLEAR_STATE = '[Products] Clear State'
}

export class GetVestingsAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.GET_VESTINGS;
    constructor() { }
}

export class GetVestingsSuccessAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.GET_VESTINGS_SUCCESS;
    constructor(public vestings: VestingType[]) { }
}

export class SetVestingTypeAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.SET_VESTING_TYPE;
    constructor(public vestingTypeName: string, public numberOfApplicants: number) { }
}

export class SetVestingTypeFailAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.SET_VESTING_TYPE_FAIL;
    constructor(public error: string) { }
}

export class GetProductsAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.GET;
    constructor() { }
}

export class GetProductsSuccessAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.GET_SUCCESS;
    constructor(public products: ISimpleProduct[]) { }
}

export class SetProductsSelectionAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.SET_PRODUCT_SELECTION;
    constructor(public productCodes: string[],
        public vestingType: VESTING_TYPE_ID = VESTING_TYPE_ID.Individual,
        public  advisorId: string = '') { }
}

export class SetProductsSelectionSuccessAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.SET_PRODUCT_SELECTION_SUCCESS;
    constructor(public products: ISimpleProduct[]) { }
}

export class SetProductsSelectionFailAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.SET_PRODUCT_SELECTION_FAIL;
    constructor(public products: ISimpleProduct[]) { }
}

export class UpdateProductSelectionAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.UPDATE_PRODUCT_SELECTION;
    constructor(public product: ISimpleProduct) { }
}

export class UpdateProductSelectionSuccessAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.UPDATE_PRODUCT_SELECTION_SUCCESS;
    constructor(public products: ISimpleProduct[]) { }
}

export class RedirecProductSelectionAction implements Action {
    readonly type = PRODUCTS_ACTION_TYPES.REDIRECTO_PRODUCT_SELECTION;
    constructor() { }
}


export class SetIRAFlag implements Action{
    readonly type = PRODUCTS_ACTION_TYPES.SET_IRA_FLAG;
    constructor(public isIRA: boolean) { }
}

export class GetProxyProductsAction{
    readonly type = PRODUCTS_ACTION_TYPES.GET_PROXY_PRODUCTS;
    constructor(public applicationTypeName: string) { }
}

export class GetProxyProductsSuccesAction{
    readonly type = PRODUCTS_ACTION_TYPES.GET_PROXY_PRODUCTS_SUCCESS;
    constructor(public products: ISimpleProduct[]) { }
}

export class ClearProxyProducts{
    readonly type = PRODUCTS_ACTION_TYPES.CLEAR_PROXY_PRODUCTS;
    constructor(public product: string) { }
}

export class SetProxyProductSuccess{
    readonly type = PRODUCTS_ACTION_TYPES.SET_PROXY_PRODUCT_SUCCESS;
    constructor(public products: ISimpleProduct[]) { }
}

export class GetProductsByOrganizationAction{
    readonly type = PRODUCTS_ACTION_TYPES.GET_PRODUCTS_BY_ORGANIZATION;
    constructor(public applicationTypeName: string) { }
}


export class GetProductsByOrganizationSuccesAction{
    readonly type = PRODUCTS_ACTION_TYPES.GET_PRODUCTS_BY_ORGANIZATION_SUCCESS;
    constructor(public products: ISimpleProduct[]) { }
}

export class ClearProductState{
    readonly type = PRODUCTS_ACTION_TYPES.CLEAR_STATE;
    constructor() { }
}

export type ProductsAction =
    GetProductsAction | GetProductsSuccessAction |
    GetVestingsAction | GetVestingsSuccessAction |
    SetVestingTypeAction | SetVestingTypeFailAction |
    SetProductsSelectionAction | SetProductsSelectionSuccessAction | SetProductsSelectionFailAction | SetIRAFlag |
    UpdateProductSelectionAction | UpdateProductSelectionSuccessAction | RedirecProductSelectionAction |
    GetProxyProductsAction | GetProxyProductsSuccesAction | GetProductsByOrganizationAction | GetProductsByOrganizationSuccesAction | 
    ClearProxyProducts | SetProxyProductSuccess |
    ClearProductState;
