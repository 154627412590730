import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';

import {
  Applicant,
  FileGroupItem
} from 'models';
import {
  IDENTIFICATION_TYPE_ID,
  DOCUMENT_TYPE
} from 'models/enums';
import { LoadingService } from 'app/core/services';

import { FileProcessingService } from 'app/documents';
import { mitekModel } from 'app/applicant-identification/models';
import { IdentificationUploadService } from 'app/enrollment-common/services/identification-upload.service';

import { UploadFileComponent } from '../upload-file/upload-file.component';

declare var MitekMobileWeb: any;
declare var MitekUtil: any;

@Component({
  selector: 'app-identification-upload',
  templateUrl: './identification-upload.component.html',
  styleUrls: ['./identification-upload.component.scss'],
  providers: [ FileProcessingService, IdentificationUploadService ]
})
export class IdentificationUploadComponent {

  private _frontFileBase64: string;
  private _backFileBase64: string;

  fileErrors: string;

  @Input() allowExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
  @Input() maxFileSize = 5;
  @Input() showDefaultNote = true;
  @Input() mitekEnabled = false;
  @Input() extractData = false;
  @Input() applicant: Applicant;

  @Output() onIdentificationUploaded: EventEmitter<any> = new EventEmitter();
  @Output() onValidFiles: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('frontFileUpload', {static: false}) frontUpload: UploadFileComponent;
  @ViewChild('backFileUpload', {static: false}) backUpload: UploadFileComponent;

  constructor(
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private identificationUploadService: IdentificationUploadService,
    private fileProcessingService: FileProcessingService) { }

  get identificationName() {
    return this.applicant.IdentificationInfo.IdentificationTypeId === IDENTIFICATION_TYPE_ID.Driver ?
      'Driver’s License' : 'State ID';
  }

  get frontFileBase64() { return this._frontFileBase64; }
  set frontFileBase64(value: string) {
    if(this._frontFileBase64 !== value) {
      this._frontFileBase64 = value;
      if(this.frontUpload && value === null) { this.frontUpload.removeFiles(); }
      if(this._frontFileBase64) { this.fileErrors = ''; }
      this.checkFilesValid();
    }
  }

  get backFileBase64() { return this._backFileBase64; }
  set backFileBase64(value: string) {
    if(this._backFileBase64 !== value) {
      this._backFileBase64 = value;
      if(this.backUpload && value === null) { this.backUpload.removeFiles(); }
      if(this._backFileBase64) { this.fileErrors = ''; }
      this.checkFilesValid();
    }
  }

  private checkFilesValid() {
    this.onValidFiles.emit(!!this.frontFileBase64 && !!this.backFileBase64);
  }

  uploadIdentification() {
    if(this.frontFileBase64 && this.backFileBase64) {
      const idDocumentsModel: mitekModel = {
        ApplicantId: this.applicant.ApplicantId,
        ApplicationId: this.applicant.ApplicationId,
        ApplicantTypeId: this.applicant.ApplicantType.ApplicantTypeId,
        FrontImage: this.frontFileBase64,
        BackImage: this.backFileBase64,
        DocumentTypeId: this.applicant.IdentificationInfo.IdentificationTypeId === IDENTIFICATION_TYPE_ID.Driver ?
          DOCUMENT_TYPE.DriverLicense :
          DOCUMENT_TYPE.StateId
      };
      this.identificationUploadService.uploadDocuments(idDocumentsModel, this.extractData)
        .subscribe((data) => {
          this.onIdentificationUploaded.emit(data);
          this.cdr.markForCheck();
        });
    }
  }

  onFileError(error) { this.fileErrors = error; }

  onFrontChanged(files: FileGroupItem[]) {
    this.onFileChange(files, true);
  }

  onBackChanged(files: FileGroupItem[]) {
    this.onFileChange(files, false);
  }

  private onFileChange(files: FileGroupItem[], isFront: boolean) {
    const file = files.length > 0 ? files[0] : null;
    if(file) {
      this.loadingService.startTask();
        this.fileProcessingService.getBase64(file.file)
          .then((base64: string) => {
            isFront ? this.frontFileBase64 = base64 : this.backFileBase64 = base64;
            this.loadingService.endTask();
          });
    } else {
      isFront ? this.frontFileBase64 = null : this.backFileBase64 = null;
    }
  }

  // #region MiTek
  private cvCallback(): void {
    this.loadingService.startTask();
  }

  private captureImage(name: string, isFront: boolean, captureParams: any) {
    MitekMobileWeb.captureAndProcessImage(
      isFront ? this.captureFrontCallback.bind(this) : this.captureBackCallback.bind(this) ,
      name, captureParams, this.cvCallback.bind(this));
  }

  captureFrontImage() {
    // use these params for the Front image capture to override the default settings
    var captureParams = {
        maxImageSize: 1600,
        isFront: true,
        skip4Corners: true,
        jpegEncodingQuality: 60,
        isSharpnessOn: true,
        isBrightnessOn: true,
        validateSharpness: true,
        validateBrightness: true,
        validateConfidence: false,
        validateMinFill: true
    };
    this.frontUpload.setPreview('');
    this.captureImage('DL_Front', true, captureParams);
  }

  captureBackImage() {
    this.backUpload.setPreview('');
    this.captureImage('DL_Back', false, {});
  }

  private captureImageCallBack(result, isFront: boolean) {
    if (result.errors.length > 0) {
      const captureErrors = MitekUtil.decodeCaptureErrorCodes(result.errors);
      this.onFileError(`Unable to read the ${ isFront ? 'Front' : 'Back'} of your license: Errors: ${captureErrors}`);
    } else if(result.status !== "success") {
      this.onFileError(`We were unable to read the ${ isFront ? 'Front' : 'Back'} of your license.  Please try again.`);
    }
    this.loadingService.endTask();
    this.cdr.markForCheck();
  }

  private captureFrontCallback(result) {
    if (result.status === "success") {
      this.frontFileBase64 = result.finalImage;
      this.frontUpload.setPreview(`data:image/jpeg;base64,${this.frontFileBase64}`);
    }
    this.captureImageCallBack(result, true);
  }

  private captureBackCallback(result) {
    if (result.status === "success") {
      this.backFileBase64 = result.finalImage;
      this.backUpload.setPreview(`data:image/jpeg;base64,${this.backFileBase64}`);
    }
    this.captureImageCallBack(result, false);
  }
  // #endregion
}
