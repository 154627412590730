export const AUTH_PARENT_ROUTE = 'auth';

export const AUTH_ROUTES = {
  INPROGRESS: 'inprogress',
  OTP: 'otp',
  FORGOTPASSWORD: 'forgotPassword/:back',
  LOGIN:'login',
  EXISTINGLOGIN:'login/existing',
  SECURITYQUESTIONS:'securityQuestions',
  UNLOCKACCOUNT:'unlock/:unlockToken',
  ADMINUNLOCKACCOUNT:'ResetPassword/:unlockToken',
  SESSION_TIME_OUT: 'sessionTimeOut',
  CREATE_USER_NAME: 'createUserName/:guid',
  REGISTER:'register',
  EXPIREDAPPLICATION: 'expiredApplication',
  LINK_EXPIRED: 'linkExpired',
}
