declare var environment: any;

// Ng
import { Injectable } from '@angular/core';
// RxJs
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getApplication, getCurrentApplicant, getDmdEnabled, getApplicationVestingTypeName } from 'store/selectors';
// Services
import { Platform } from '@angular/cdk/platform';
// Models, Enums and Constants
import {
  IApplication,
  Applicant
} from 'models';
import { ModuleFlow } from 'models/navigation';
import {
  APPLICANT_TYPE_NAME,
  BRAND_NAME,
  SOURCE_TYPES,
  APPLICATION_STATE,
  VESTING_TYPE_NAME
} from 'models/enums';
import { APPLICANT_INFORMATION_FLOWS } from '../constants/flows.constants';
import { ID_VERIFICATION_METHOD } from 'models/enums/id-verification-method.enum';
import { BaseDestroyComponent } from 'app/core/components/base-destroy.component';

@Injectable()
export class ApplicantInformationModuleFlowService extends BaseDestroyComponent implements ModuleFlow {
  private application: IApplication;
  private currentApplicant: Applicant;
  private vestingTypeName: string;
  private dmdEnabled: boolean;

  isAxosUniversal: boolean = !!environment.isAxosUniversal;

  constructor(store$: Store<EnrollmentState>, private platformService: Platform) {
    super();
    store$.select(getApplication)
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(store$.select(getDmdEnabled))
      )
      .subscribe(data => {
        this.application = data[0];
        this.dmdEnabled = data[1];
      });

    store$.select(getCurrentApplicant)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(applicant => !!applicant)
      ).subscribe(value => this.currentApplicant = value);

    store$.select(getApplicationVestingTypeName).pipe(
      takeUntil(this.unsubscribe$)).subscribe(vestingTypeName => {
        this.vestingTypeName = vestingTypeName
    })
  }

  getFlow(): APPLICATION_STATE[] {
    const applicantTypeName = this.currentApplicant.type;
    const vestingTypeName = this.vestingTypeName; //TODO get vestingtype
    // const manualCapture = (this.currentApplicant.manualCapture !== undefined && this.currentApplicant.manualCapture !== null) ?
    //     this.currentApplicant.manualCapture : true;

    //TODO include axos trading flow
    if (this.isAxosUniversal) {
      const flow: any = APPLICANT_INFORMATION_FLOWS[applicantTypeName];
      if (this.currentApplicant.type === APPLICANT_TYPE_NAME.Trust) {
        return flow;
      }
      if(applicantTypeName === APPLICANT_TYPE_NAME.Primary 
        && (vestingTypeName === VESTING_TYPE_NAME.Joint || vestingTypeName === VESTING_TYPE_NAME.Trust)){
        return flow.ADDITIONAL_OWNER
      }
      return flow.TRADING;
    }

    const manualCapture = this.currentApplicant.IdVerificationMethodId != ID_VERIFICATION_METHOD.Mitek;

    if (this.currentApplicant.type === APPLICANT_TYPE_NAME.Primary) {
      const primaryFlow: any = APPLICANT_INFORMATION_FLOWS[applicantTypeName];
      //TODO include axos trading flow
      if (this.application.SourceType.SourceTypeId === SOURCE_TYPES.existingEnroller) {
        return primaryFlow.EXISTING;
      } else if (this.application.Brand.Name === BRAND_NAME.AxosAdvisor) {
        return primaryFlow.ADVISOR_OR_DMD_MANUAL;
      } else if (this.dmdEnabled) {
        return manualCapture ? primaryFlow.ADVISOR_OR_DMD_MANUAL : primaryFlow.DMD;
        // Workaround for IE
      } /*else if(!this.dmdEnabled && !this.platformService.WEBKIT && !this.platformService.SAFARI {
            return primaryFlow.IE;
          }*/
      return manualCapture ? primaryFlow.MANUAL : primaryFlow.MITEK;
    }
    else if (this.currentApplicant.type === APPLICANT_TYPE_NAME.Joint) {
      const jointFlow: any = APPLICANT_INFORMATION_FLOWS[applicantTypeName];
      if (this.application.Brand.Name === BRAND_NAME.AxosAdvisor) {
        return jointFlow.ADVISOR_OR_DMD_MANUAL;
      } else if (this.dmdEnabled) {
        return manualCapture ? jointFlow.ADVISOR_OR_DMD_MANUAL : jointFlow.DMD;
        // Workaround for IE
      } /* else if(!this.dmdEnabled && !this.platformService.WEBKIT && !this.platformService.SAFARI) {
              return jointFlow.IE;
          } */
      return manualCapture ? jointFlow.MANUAL : jointFlow.MITEK;
    }
    return APPLICANT_INFORMATION_FLOWS[applicantTypeName];
  }
};
