import { BRAND_CONTEXT } from './brand.enum';
import { ADDRESS_TYPE_NAME } from './address-type.enum';

export enum IDENTIFICATION_TYPE_ID {
    Driver = 1,
    State = 2,
    MilitaryID = 3,
    ResidentAlien = 4,
    USPassport = 5,
    USPassportCard = 6,
    ForeignPassport = 7
}

export enum IDENTIFICATION_TYPE_NAME {
    Driver = "Driver's License",
    State = 'State Identification',
    Passport = 'Passport'
}

export enum IDENTIFICATION_ADDRESS_TYPE_NAME {
    Home = 'Home Address',
    Mailing = 'Mailing Address',
    Other = 'Other Address'
}
export const IDENTIFICATION_TYPE = {
    DRIVER: {
        key: IDENTIFICATION_TYPE_ID.Driver,
        name: IDENTIFICATION_TYPE_NAME.Driver,
        abbr: '',
        context: BRAND_CONTEXT.All
    },
    STATE: {
        key: IDENTIFICATION_TYPE_ID.State,
        name: IDENTIFICATION_TYPE_NAME.State,
        abbr: 'State ID',
        context: BRAND_CONTEXT.All
    },
    PASSPORT: {
        key: IDENTIFICATION_TYPE_ID.ForeignPassport,
        name: IDENTIFICATION_TYPE_NAME.Passport,
        abbr: 'Passport',
        context: BRAND_CONTEXT.Business
    }
}

export const IDENTIFICATION_ADDRESS_TYPE = {
    HOME: {
        key: ADDRESS_TYPE_NAME.Current,
        name: IDENTIFICATION_ADDRESS_TYPE_NAME.Home,
        abbr: ''
    },
    MAILING: {
        key: ADDRESS_TYPE_NAME.Mailing,
        name: IDENTIFICATION_ADDRESS_TYPE_NAME.Mailing,
        abbr: ''
    },
    OTHER: {
        key: ADDRESS_TYPE_NAME.Other,
        name: IDENTIFICATION_ADDRESS_TYPE_NAME.Other,
        abbr: ''
    },
}