export enum DOCUMENT_TYPE {
	DriverLicense = 1,
	SocialSecurityCard = 2,
	UtilityBill = 3,
	TrustCertificate = 4,
	SignatureCard = 5,
	eSignatureCard = 6,
	SignatureSample = 7,
	StateId = 8,
	Selfie = 9,
	Passport = 10
}

export enum DOCUMENT_TYPE_NAME {
	idFront = 'ID Front',
	idBack = 'ID Back',
	socialSecurityCard = 'Social Security Card',
	utilityBill = 'Utility Bill',
	trustCertification = 'Trust Certification',
	threeTwoOneZeroLetter = '3210 Letter',
	driverslicense = "driverslicense",
	stateissuedid = "stateissuedid"
}
