import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
// RxJs
import { map } from 'rxjs/operators';

import { BusinessBaseGuard } from './base.guard';

@Injectable()
export class BusinessDiffNameGuard extends BusinessBaseGuard implements CanActivate {
    canActivate() {
        return this.getData().pipe(
            map(value => {
                const _activate = value.business.hasDifferentName;
                this.shouldNavigate(_activate);
                return _activate;
            })
        )
    }
}