import { BrandState } from '../states';
import * as fromActions from '../actions/brand.action';
import { BrandEffects } from 'store/effects';

export const initialState: BrandState = {
  brand: null,
  chatSettings: null,
  chatBotSettings: null,
  loaded: false,
  loading: false
}

export function reducer(
  state = initialState,
  action: fromActions.BrandAction
): BrandState {
  switch(action.type) {
    case fromActions.BRAND_ACTION_TYPES.GET_BRAND: {
      return { ...state, loading: true };
    }
    case fromActions.BRAND_ACTION_TYPES.GET_BRAND_SUCCESS: {
      return { ...state,
          loaded: true,
          loading: false,
          brand: action.data
      };
    }
    case fromActions.BRAND_ACTION_TYPES.GET_CHAT_SETTINGS_SUCCESS: {
      return { ...state, chatSettings: action.data };
    }
    case fromActions.BRAND_ACTION_TYPES.GET_BRAND_LOGO_SUCCESS: {
      return { ...state, brand: { 
          ...state.brand,
          logo: action.data 
        } 
      };
    }
    case fromActions.BRAND_ACTION_TYPES.GET_CHAT_SETTINGS_FAIL: {
      return { ...state, chatSettings: {
        BrandName: '',
        IsActive: false,
        ButtonId: '',
        DeploymentId: '',
        DeploymentUrl: '',
        LoginButtonId: '',
        OrgId: '',
        Url: ''
      } };
    }
    case fromActions.BRAND_ACTION_TYPES.GET_CHAT_BOT_SETTINGS_SUCCESS: {
      var settings = action.data;

      return ({ ...state, 
        chatBotSettings: {
          BrandName: settings["brandName"],
          BaseUrl: settings["baseUrl"],
          ChannelEndpoint: settings["channelEndpoint"],
          Component: settings["component"],
          VerifyToken: settings["verifyToken"],
          WidgetChatBotUrl: settings["widgetChatBotUrl"]
        } 
      });
    }
    case fromActions.BRAND_ACTION_TYPES.GET_CHAT_BOT_SETTINGS_FAIL: {
      return {
        ...state,
        chatBotSettings: {
          BrandName: '',
          BaseUrl: '',
          ChannelEndpoint: '',
          Component: '',
          VerifyToken: '',
          WidgetChatBotUrl: ''
        }
      };
    }
  }
  return state;
}
