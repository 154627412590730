import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AuthServicesModule } from 'app/auth/auth-services.module';
// Vendors
import { CookieService } from 'ngx-cookie-service';
// Services
import {
  AddressService,
  ApplicantInformationService,
  ApplicationService,
  AuthService,
  BrandService,
  WebsiteService,
  GenericCatalogsService,
  ProductSelectionService,
  ProductsService,
  RegisterExistingCostumerService,
  RulesService,
  QuestionnaireService,
  DisclosuresService,
  UserProspectService,
  UserProspectMockService,
  TradingService,
  ApplicationInformationService,
  UserService,
  OrganizationService,
  FeatureFlagService,
  PdfService
} from './';
import { CustomerService } from './proxy';
import { WhiteLabelService } from './wl/white-label.service';

@NgModule({
  imports: [
    HttpClientModule,
    AuthServicesModule
  ],
  declarations: [ ],
  exports: [ AuthServicesModule ],
  providers: [
    AuthService,
    ApplicationService,
    ApplicantInformationService,
    AddressService,
    RulesService,
    QuestionnaireService,
    GenericCatalogsService,
    BrandService,
    WebsiteService,
    ProductsService,
    AddressService,
    UserService,
    UserProspectService,
    RegisterExistingCostumerService,
    ProductSelectionService,
    DisclosuresService,
    UserProspectMockService,
    // Revisit
    CookieService,
    TradingService,
    ApplicationInformationService,
    OrganizationService,
    CustomerService,
    WhiteLabelService,
    FeatureFlagService,
    PdfService
  ]
})
export class DataServiceModule { }
