import { Resolve } from '@angular/router';

import { Store } from '@ngrx/store';
import { EnrollmentState, GetECMDisclosureDocumentByDisclosureAction, GetElectronicDisclosureAction, GetFeatureStatusAction, GetInitialDisclosuresAction } from 'store';
import { getECMDisclosureDocuments, getElectronicDisclosure, getInitialDisclosures } from 'store/selectors/disclosure.selectors';

import { DetailDisclosure, FeatureFlag } from 'models';

import { Observable } from 'rxjs';
import { filter, first, map, tap, withLatestFrom } from 'rxjs/operators';
import { getFeatureFlags } from 'store/selectors/feature-flags.selectors';
import { FEATURE_FLAG } from 'models/enums';

export class FeatureFlagsResolver implements Resolve<Observable<FeatureFlag[]>> {
    constructor(private store: Store<EnrollmentState>) {  }

    resolve(){
        return this.store.select(getFeatureFlags).pipe(
            map(featureFlags => {
                if(!featureFlags) {
                    this.store.dispatch(new GetFeatureStatusAction(FEATURE_FLAG.IdDetails));
                }
                return featureFlags
            }),
            filter(featureFlags => !! featureFlags),
            first()
        );
    }
}
