declare var environment: any;
// Ng
import { Injectable } from '@angular/core';
// RxJs
import { BehaviorSubject, Observable } from 'rxjs';
// Models
import { IAuthToken, ILeadRequest } from 'models';
// RxJs
import { map } from 'rxjs/operators';


import { UserNameRequest } from 'models/auth';
import { BaseService } from 'services/abstracts';
import { APPLICATION_TYPE_FLOW } from 'models/enums/application-type.enum';
@Injectable()
export class UserService extends BaseService {

    private _siteCoreData = new BehaviorSubject<any>(null);
    siteCoreData$: Observable<any> = this._siteCoreData.asObservable();
    organizationName = environment.organizationName;

    create(user: ILeadRequest, guid?: string, applicationTypeName?: APPLICATION_TYPE_FLOW) {
        let route = `application/register`;
        if (guid) {
            route = `${route}?secondaryInvite=${guid}`;            
        }
        return this._post(route, {...user, applicationTypeName: applicationTypeName}).pipe(
            map(({ data, metadata }) => {
                return { data, metadata }
            })
        );
    }

    updateSiteCoreData(data) {
        this._siteCoreData.next(data);
    }

    verifyUserName(username: string) {
        username = encodeURIComponent(username);
        return this._get<{ isUserNameAvailable: boolean }>(`user/userNameAvailability?username=${username}`, null, false);
    }
    forgotPassword(username: string) {
        username = encodeURIComponent(username);
        return this._get(`user/ForgotPassword?username=${username}&organizationName=${this.organizationName}`);
    }

    forgotUserName(email) {
         email = encodeURIComponent(email);
        return this._get(`user/ForgotUserName?email=${email}&organizationName=${this.organizationName}`);
    }

    createUserNameForIdv1User(userInfo: UserNameRequest) {
        return this._put('user/UpdateUserNamePassword', userInfo);
    }
}
