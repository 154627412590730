export const GET_URL_ENV = () => {
  return GET_ENV_BASE_URL(window.location.hostname);
};

export const GET_URL_ALL_PARAMETERS = (lowerCaseParamName: boolean = true) => {
  const qryStr = window.location.search;
  return !!qryStr ? QUERY_STRING_TO_ARRAY(qryStr, lowerCaseParamName) : {};
};

export const GET_QUERY_PARAM = (paramName: string) => {
  const qryStr = window.location.search;
  return !!qryStr ? FIND_QUERY_PARAM(qryStr, paramName) : null;
};

export const QUERY_STRING_TO_ARRAY = (queryString: string, lowerCaseParamName: boolean = true): { [key: string]: any } => {
  queryString = decodeURIComponent(queryString.substr(1).replace(/\+/g, ' '));
  const params = {}, prmarr = queryString.split('&');

  prmarr.forEach(paramStr => {
    const tmpArr = paramStr.split('='),
      value = tmpArr[1],
      name = lowerCaseParamName ? tmpArr[0].toLowerCase() : tmpArr[0];
    let param = params[name];
    if (param) {
      if (!Array.isArray(param)) { param = [param] };
      params[name] = [...param, value];
    } else { params[name] = value; }
  });
  return params;
}

export const FIND_QUERY_PARAM = (queryString: string, paramName: string) => {
  paramName = paramName.toLowerCase();
  const params = QUERY_STRING_TO_ARRAY(queryString),
    param = params[paramName];
  return param ? param : '';
}

export const GET_ENV_BASE_URL = (host: string) => {
  const envs = ['dev', 'qa', 'uat', 'stg'];
  if (host === 'localhost') { return 'local'; }
  const parts = host.split('.');
  const env = parts.find(part => envs.includes(part));
  return env ? env.toLowerCase() : '';
}

/*
export const GET_URL_PARAMETER = (name: String) => {
  name = name.toLocaleLowerCase();
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const url = window.location.search.toLocaleLowerCase();
  const results = regex.exec(url);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
*/
