import { TradingState } from "store/states/trading.state";
import * as fromActions from '../actions/trading.action';
import { createReducer, on, Action } from "@ngrx/store";

export const initialState: TradingState = {
    accountFeature: null,
    financialInformation: null,
    features: [],
    personalInformation: [],
    investmentProfile: null,
    investmentObjetives: null,
    skipVerification: false
}

const tradingReducer = createReducer(
    initialState,
    on(fromActions.setAccountFeatureSuccess, (state, { feature }) => {
        const newFeature = feature;
        newFeature.TradingFeatures = newFeature.TradingFeatures || [];
        return {...state, accountFeature: feature}
    }),
    on(fromActions.setPersonalInformationSuccess, (state, { personalInformation }) => ({
        ...state,
        personalInformation: [
            ...state.personalInformation.filter(information => information.ApplicantId != personalInformation.ApplicantId),
            personalInformation
        ]
    })),
    on(fromActions.saveInvestmentProfileSuccess, (state, { investmentProfile }) => ({
        ...state,
        investmentProfile: investmentProfile
    })),
    on(fromActions.getInvestmentObjetivesActionSuccess, (state, { objetives }) => ({
        ...state,
        investmentObjetives: objetives
    })),
    on(fromActions.getInvestmentObjetivesActionFail, state => ({
        ...state,
        investmentObjetives: null
    })),
    on(fromActions.getAccountFeatureSuccess, (state, { accountFeature }) => ({
        ...state,
        accountFeature: accountFeature
    })),
    on(fromActions.getFeaturesSuccess, (state, { features }) => ({
        ...state,
        features: features
    })),
    // on(fromActions.getAllPersonalInformationSuccess, (state, { person: information }) => ({
    //     ...state,
    //     personalInformation: information
    // })),
    on(fromActions.saveFinancialInformationSuccess, (state, { information }) => ({
        ...state,
        financialInformation: { ...information }
    })),
    on(fromActions.getFinancialInformationSuccess, (state, { information }) => ({
        ...state,
        financialInformation: information
    })),
    on(fromActions.getInvestmentProfileSuccess, (state, { profile }) => ({
        ...state,
        investmentProfile: profile
    })),
    on(fromActions.setSkipVerificationSuccess, (state, { desicion }) => ({
        ...state,
        skipVerification: desicion
    })),
    on(fromActions.clearTradingState, () => ({
        ...initialState
    }))
);

export function reducer(state: TradingState | undefined, action: Action) {
    return tradingReducer(state, action);
}
