// Ng
import { Injectable } from '@angular/core';
// RxJs
import { tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { RouterNavigatedAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { EnrollmentState } from 'store';
// NgRx Effects
import { Effect, Actions, ofType } from '@ngrx/effects';
// Actions
import * as bannerActions from '../actions/banner.action';
import { APPLICATION_TYPE_NAME } from 'models/enums/application-type.enum';

declare var environment: any;

@Injectable()
export class RouteEffects {

  constructor(private actions$: Actions,
    private store$: Store<EnrollmentState>) { }

  @Effect({ dispatch: false })
  routeNavigated$ = this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    tap((action: RouterNavigatedAction) => {
      const data: any = action.payload.routerState['data'],
        banner = data.banner,
        subBanner = data.subBanner,
        pageTitle = data.pageTitle,
        progress = data.progress,
        isWLOrProxy = environment.applicationTypeName === APPLICATION_TYPE_NAME.whitelabelTrading || environment.applicationTypeName === APPLICATION_TYPE_NAME.proxyTrading;

      if(banner) { this.store$.dispatch(new bannerActions.UpdateBannerAction(banner)); }
      if(pageTitle) { this.store$.dispatch(new bannerActions.UpdatePageTitleAction(pageTitle)); }
      if(progress) { this.store$.dispatch(new bannerActions.UpdateProgressAction(progress.value, !isWLOrProxy)); }
      if(subBanner) { this.store$.dispatch(new bannerActions.UpdateSubBannerAction(subBanner.show)); }

      window.scrollTo(0, 0);
    })
  );
}
