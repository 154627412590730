import { Component, Input } from '@angular/core';

@Component({
    selector: 'axos-footer-mobile',
    templateUrl: './footer-mobile.component.html',
    styleUrls: ['./footer-mobile.component.scss'],
    host: {
        class: 'axos-container'
    }
})
export class FooterMobileComponent {
}