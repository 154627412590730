import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
// RxJs
import { map } from 'rxjs/operators';
// Enrollment Common
import { ADDRESS_TYPE_NAME } from 'models/enums';

import { BusinessBaseGuard } from './base.guard';

@Injectable()
export class BusinessAddresstGuard extends BusinessBaseGuard implements CanActivate {
    canActivate() {
        return this.getData().pipe(
            map(value => {
                const addressType = value.business.IdentificationInfo.IdentificationAddressType;
                const _activate = addressType !== ADDRESS_TYPE_NAME.Current
                this.shouldNavigate(_activate);
                return _activate;
            })
        )
    }
}
