import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
// RxJs
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Services
import { ZipService } from 'services';

export function ZipCodeAsyncValidator(zipService: ZipService, cityControl: AbstractControl, stateControl: AbstractControl): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        let value;
        if(control.value && control.value.includes('-')){
            value = control.value.split('-')[0];
        }
        return zipService.get(value?value:control.value).pipe(
            map((zipInfo: any) => {
                cityControl.setValue(zipInfo.city);
                stateControl.setValue(zipInfo.stateCode);
                return zipInfo ? null : { zipNotFound: true };
            }),
            catchError(() => {
                cityControl.setValue('');
                stateControl.setValue('');
                return of({ zipNotFound: true });
            })
        );
    };
}
