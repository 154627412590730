import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Services
import { BaseService } from './abstracts';
import { LoadingService } from 'app/core/services';

import { API_URL } from 'app/core/constants';
import { IdaResult } from 'models/rules';

@Injectable()
export class QuestionnaireService extends BaseService {
  constructor(
    http: HttpClient,
    @Inject(API_URL) apiURL: string,
    loadingService: LoadingService) {
    super(http, loadingService, apiURL);
  }

  getIdaQuestions$(applicationId, isRetry) {
    return this._post<IdaResult>(`v2/applications/initiateIda/${applicationId}/${isRetry}`);
  }
}
