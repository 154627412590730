import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FEATURE_NAMES } from "store/constants";
import { BrokerState } from "store/states/broker.state";

export const getBokerState = createFeatureSelector(FEATURE_NAMES.Broker);

export const getBroker = createSelector(
  getBokerState,
    (state: BrokerState) => state.broker
);

export const getBrokerName = createSelector(
  getBokerState,
    (state: BrokerState) => state.broker ? state.broker.name : ''
);

export const getBrokerPhone = createSelector(
  getBokerState,
    (state: BrokerState) => state.broker ? state.broker.phone : ''
);

export const getBrokerEmail = createSelector(
  getBokerState,
    (state: BrokerState) => state.broker ? state.broker.email : ''
);

export const getBrokerOrganization = createSelector(
  getBokerState,
    (state: BrokerState) => state.broker ? state.broker.organizationName : ''
);
