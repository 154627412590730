import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IExistingUser, IUser, IUserLead } from 'models';
import { FEATURE_NAMES } from '../constants';
import { AuthState } from '../states';



export const getAuthState = createFeatureSelector(FEATURE_NAMES.Auth);

export const getAuthLoading = createSelector(
    getAuthState, (state: AuthState) => state.loading
);

export const getUser = createSelector(
    getAuthState, (state: AuthState) => state.user
);

// export const getUserProspect = createSelector(
//     getAuthState, (state: AuthState) => state.userProspect
// );

export const getUserLead = createSelector(
    getAuthState, (state: AuthState) => state.userLead
);

export const getUserLeadResponse = createSelector(
  getAuthState, (state: AuthState) => state.userLeadResponse
);

export const getUserLeadId = createSelector(
    getUserLead, (userLead: IUserLead) => userLead ? userLead.leadId : null
);

export const getExistingUser = createSelector(
    getAuthState, (state: AuthState) => state.existingUser
);

export const getUserName = createSelector(
    getUser, (user: IUser) => user ? user.UserName : null
);

export const getUserEmail = createSelector(
  getUser, (user: IUser) => user ? user.Email : null
);

export const getUserPhone = createSelector(
    getUser, (user: IUser) => user ? user.PhoneNumber ? user.PhoneNumber : '' : null
);

export const getUserApplicationId = createSelector(
    getUser, (user: IUser) => user ? user.ApplicationId : null
);

// export const getUserApplicationId = createSelector(
//     getUserLead, (user: IUserLead) => user ? user.leadId : null
// );

export const getExisintgEnrollmentUrl = createSelector(
    getExistingUser, (user: IExistingUser) => user ? user.enrollmentUrl : null
);

export const getExistingCustomerBrandId = createSelector(
    getExistingUser, (user: IExistingUser) => user ? user.brandId : null
);

export const getToken = createSelector(
    getAuthState, (state: AuthState) => state.token
);

export const getUserLoggedIn = createSelector(
    getAuthState, (state: AuthState) => state.loggedIn
);

export const getLoginError = createSelector(
    getAuthState, (state:AuthState) => state.error
);

export const getVerificationError = createSelector(
    getAuthState, (state:AuthState) => state.error
);

export const getChallengeInfo  = createSelector(
    getAuthState, (state:AuthState) => state.challengeInfo
);

export const getHasSecurityQuestions  = createSelector(
    getAuthState, (state:AuthState) => state.token.hasSecurityQuestions
);

export const getOtpChallengeInfo  = createSelector(
    getAuthState, (state:AuthState) => state.otpChallengeInfo
);

export const getUserNoOtp  = createSelector(
    getAuthState, (state:AuthState) => state.NoOtp
)
export const getOtpCode = createSelector(
    getAuthState, (state: AuthState) => state.otpChallengeInfo.otp
)

export const getNoOtpCode = createSelector(
     getAuthState, (state: AuthState) => state.NoOtp.otp
 )

 export const getNoPhoneNumber = createSelector(
    getAuthState, (state: AuthState) => state.phoneNumber
 )

export const getSecurityQuestions = createSelector(
    getAuthState, (state:AuthState) => state.securityQuestions
)

export const getIsExisting=createSelector(
    getAuthState,(state:AuthState)=>state.isExisting
)

export const getUserIdToken=createSelector(
    getAuthState,(state:AuthState)=>state.userIdToken
)

export const getUserNameToken=createSelector(
    getAuthState,(state:AuthState)=>state.userNameToken
)

export const getShowRecoverySent=createSelector(
    getAuthState,(state:AuthState)=>state.showRecoverySent
)

export const getDisplayLoginPage=createSelector(
    getAuthState,(state:AuthState)=>state.displayLoginPage
)

export const getDisplayQuestion=createSelector(
    getAuthState,(state:AuthState)=>state.displayQuestion
)

export const getRandomQuestion=createSelector(
    getAuthState,(state:AuthState)=>state.question
)

export const getStepTitle=createSelector(
    getAuthState,(state:AuthState)=>state.stepTitle
)

export const getGuid = createSelector(
    getAuthState,
    (state: AuthState) => state.guid
)




