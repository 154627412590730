import { Injectable } from "@angular/core"
import { Actions, Effect, ofType,  } from "@ngrx/effects"
import { Store } from "@ngrx/store"
import { FeatureFlag } from "models"
import { of } from "rxjs"
import { catchError, map, switchMap } from "rxjs/operators"
import { FeatureFlagService } from "services"
import { EnrollmentState } from "store"
// Actions
import * as featuresActions from '../actions/features.action';

@Injectable()
export class FeatureFlagsEffects {

    constructor(
        private actions$: Actions,
        private featureFlagService: FeatureFlagService,
        private store$: Store<EnrollmentState>,
    ){}

    @Effect()
    get$ = this.actions$.pipe(
      ofType(featuresActions.FEATURES_ACTION_TYPES.GET_FEATURE_STATUS),
      switchMap((action: featuresActions.GetFeatureStatusAction) => {
        return this.featureFlagService.getStatus(action.featureName)
          .pipe(
            map((response: boolean) => new featuresActions.GetFeatureStatusSuccessAction(action.featureName, response)),
            catchError(err => of(new featuresActions.GetFeatureStatusFailAction(err)))
          );
      })
    );
}