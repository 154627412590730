import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UKValidators } from '@uikit/components/uk-validators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getStates } from 'store/selectors';

import { ZipService } from 'services';
// Models
import { Address } from 'models';
// Forms
import {
  ADDRESS_DEFAULT_VALIDATORS,
  setZipCodeValidator,
  ADDRESS_FORM_CREATOR
} from 'app/shared-forms'
// Components
import { BaseFormComponent } from 'app/core/components';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [ZipService],
})
export class AddressComponent extends BaseFormComponent<Address> {

  @Input() allowPOBox: boolean = false;
  @Input() addressName: string;
  @Output() addressSubmitted: EventEmitter<Address> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();

  states$ = this.store$.select(getStates);

  constructor(private fb: FormBuilder,
    private zipCodeService: ZipService,
    private store$: Store<EnrollmentState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.form = this.fb.group(ADDRESS_FORM_CREATOR());
    setZipCodeValidator(this.form, this.zipCodeService);
    this.form.get('Street').setValidators(
      this.allowPOBox ?
        ADDRESS_DEFAULT_VALIDATORS.Street :
        [...ADDRESS_DEFAULT_VALIDATORS.Street, UKValidators.POBoxNotAllow]
    );
    this.form.get('Street2').setValidators(
      ADDRESS_DEFAULT_VALIDATORS.Street2
    );
  }

  goBack() { this.back.emit(); }

  onSave() {
    this.addressSubmitted.emit({
      ...this.value,
      ...this.form.value
    });
  }
}
