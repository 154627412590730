import { Action } from '@ngrx/store';
// Models
import { Organization } from 'models';

export enum ORGANIZATION_ACTION_TYPES {
    GET_ORGANIZATION = '[Organization] Get organization',
    GET_ORGANIZATION_SUCCESS = '[Organization] Get organization success',
    GET_ORGANIZATION_FAIL = '[Organization] Get organization fail'
}

export class GetOrganizationAction implements Action {
    readonly type = ORGANIZATION_ACTION_TYPES.GET_ORGANIZATION;
    constructor(public organizationName: string, public settings: boolean = false) { }
}

export class GetOrganizationSuccessAction implements Action {
    readonly type = ORGANIZATION_ACTION_TYPES.GET_ORGANIZATION_SUCCESS;
    constructor(public data: Organization) { }
}

export class GetOrganizationFailAction implements Action {
    readonly type = ORGANIZATION_ACTION_TYPES.GET_ORGANIZATION_FAIL;
    constructor(public error: string) {}
}

export type OrganizationAction = GetOrganizationAction | GetOrganizationSuccessAction | GetOrganizationFailAction;