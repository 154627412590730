
declare var environment: any;
declare var client: any;
// Use for Ng Material Animations
import 'hammerjs';
// BOOTSTRAP
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app.module';
import { GET_QUERY_PARAM, GET_URL_ENV } from 'app/core/utils';
import { BRAND_ID } from 'models/enums/brand.enum';
import { BASE_HREF } from 'models/navigation';
import { PLATFORM_CONFIG_TOKEN } from 'app/core/constants/injection-tokens';

const urlEnv = GET_URL_ENV();
const confFile = `config.${ urlEnv ? urlEnv : 'prod' }.json`;


fetch(`/ip`)
  .then(response => response.json())
  .then(response => {
    client.setProperty('externalip', response['ip']);
    client.processDNA();
  });


Promise.all([
  fetch(`settings`),
  fetch(`config/${confFile}`)
])
  .then(([settings, response]) => Promise.all([settings.json(), response.json()]))
  .then(([settings, env]) => {
    const advisorId = GET_QUERY_PARAM('AdvisorID');
    if(advisorId && env.brandId === BRAND_ID.Axos) {
      env.brandId = BRAND_ID.AxosAdvisor;
    }
    const isPremierBrand = GET_QUERY_PARAM('Premier') !== '' && GET_QUERY_PARAM('Premier') === 'true';
    if (isPremierBrand) {
      if (env.brandId === BRAND_ID.NationWide) {
        env.brandId = BRAND_ID.NationwidePremier;
      }
      if (env.brandId === BRAND_ID.Axos) {
        env.brandId = BRAND_ID.AxosPremier;
      }
    }
    env.apiURLs.apigee = settings.apigeeApiUrl;

    environment = env;
    const apiUrl = environment.apiURLs[environment.api].replace('{apiDomain}', environment.apiDomain || 'axosbank');
    if (environment.production) { enableProdMode(); }

    bootstrapModule(apiUrl, window['base-href'] || 'axos');
  });

function bootstrapModule(apiUrl:string, orgName:string) {
  fetch(`${apiUrl}api/organization/${orgName}?settings=true&configState=true`)
    .then(response => response.json())
    .then(org => {
      let baseHref: string;
      let marketingURL;
      if(org.isWhiteLabel && org.wlProduct && !(org.wlProduct.name.toLowerCase() == 'enr')) {
        bootstrapModule(apiUrl, 'axos');
      }
      else {
        environment.organizationDisplayName = org.displayName;
        environment.organizationId = org.organizationId;
        environment.organizationName = org.name;
        environment.isWhiteLabel = org.isWhiteLabel;
        environment.orgSettings = org.settings;
        environment.bizUnitId = org.parentOrganizationId;
        marketingURL = org.settings.find(m => m.name.toLowerCase() == 'marketingurl');
        environment.marketingURL =(marketingURL != undefined) && marketingURL.value;
        environment.isConfigurationUnderway = environment.isWhiteLabel && org.configState && org.configState.name.toLowerCase() !== 'live';
        environment.organizationPhone = org.phone;
        environment.organizationEmail = org.email;

        if (environment.isWhiteLabel) {
          baseHref = window['base-href'];
          if (!environment.isProxy) {
            environment.applicationTypeName = 'whitelabelTrading';
          }
        } else {
          baseHref = '/';
        }
        platformBrowserDynamic(
          [
            { provide: BASE_HREF, useValue: baseHref },
            { provide: PLATFORM_CONFIG_TOKEN, useValue: org }
          ]
        ).bootstrapModule(AppModule);
      }
    });
}