import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[setRange]'
})
export class setRangeDirective {
  // regexStr = '^[0-9-]*$';

  constructor() { }
  
 @HostListener('focus', ['$event.target']) public onFocus(target:any) {
  const input = target;
  let length = input.value.length;
  setTimeout(() => {
    input.selectionStart = input.selectionEnd = length;  
  }, 250);
  }
}
