import { APPLICATION_STATE } from './states.enum';

export const DMD_STATES = [
  APPLICATION_STATE.IdVerificationMethod,
  APPLICATION_STATE.IdVerificationOtpValidation,
  APPLICATION_STATE.IdVerificationIdTypeSelection,
  APPLICATION_STATE.IdVerificationFrontId,
  APPLICATION_STATE.IdVerificationBackId,
  APPLICATION_STATE.IdVerificationSelfie,
  APPLICATION_STATE.IdVerificationReviewAndSubmit
]
