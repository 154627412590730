declare var environment: any;
// Ng
import { Injectable } from '@angular/core';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { SetProductsSelectionAction } from 'store/actions';
// Core
import { GET_URL_ALL_PARAMETERS } from '../utils';

import { LazyLoadingService, SCRIPT_LOAD_TYPE } from './lazy-loading.service';
import { MarketingService } from './marketing.service';
import { BrowserTypeService } from './browser-type.service';
import { BRAND_ID } from 'models/enums';

@Injectable()
export class EnrollmentInitilizerService {

  constructor(
    private store$: Store<EnrollmentState>,
    private lazyLoadingService: LazyLoadingService,
    private marketingService: MarketingService,
    private browserTypeService: BrowserTypeService) { }

  load() {
    // this.productsResolver();
    this.marketingService.loadMarketingScript();
    // Lazy Load Fonts
    // For IE
    if(!this.browserTypeService.isChrome() && !this.browserTypeService.isSafari()) {
      this.lazyLoadingService.loadStyles('brand-fonts-ie.css');
    } else {
      this.lazyLoadingService.loadStyles('brand-fonts.css');
    }
  }

  setupWebBacon() {
    
    let webBacon: string = "";

    if(environment.brandId === BRAND_ID.Axos || environment.brandId === BRAND_ID.AxosAdvisor )
    {
        webBacon = environment.production ? 
            "//cdn.evgnet.com/beacon/axosbank/axos/scripts/evergage.min.js" 
          : "//cdn.evgnet.com/beacon/axosbank/uat/scripts/evergage.min.js" ;

          this.lazyLoadingService.loadScript(webBacon);
    }
    else
    {
        webBacon = environment.production ? 
            "https://cdn.evgnet.com/beacon/axosbank/engage/scripts/evergage.min.js" 
          : "https://cdn.evgnet.com/beacon/axosbank/uat/scripts/evergage.min.js" ;

        this.lazyLoadingService.loadScript(
          webBacon,
          SCRIPT_LOAD_TYPE.Defer,
          [
              { name: 'id', value: 'baconbrowsescript' },
              { name: 'charset', value: 'utf-8' },
              { name: 'data-presence', value: 'on' }
          ]
        );
    }
  }

  setupGlance() {
    const env = environment.production ? 'production' : 'staging';
    this.lazyLoadingService.loadScript(
      `https://www.glancecdn.net/cobrowse/CobrowseJS.ashx?group=20280&site=${env}`,
      SCRIPT_LOAD_TYPE.Defer,
      [
          { name: 'id', value: 'cobrowsescript' },
          { name: 'charset', value: 'utf-8' },
          { name: 'data-site', value: env },
          { name: 'data-groupid', value: '20280' },
          { name: 'data-presence', value: 'on' }
      ]
    );
  }

  // TODO: Remove this after everything is validated
  productsResolver() {
    const stateParams = GET_URL_ALL_PARAMETERS();
    const productCodes = stateParams['products[]'];
    let vestingType = stateParams['vestingtype'] || 1;
    let advisorId = stateParams['advisorid'];
    const scId = stateParams['sc_id'];

    /*
    if (scId) {
        sitecoreVisitorProfileService.getProfile(scId, appConstants.env).then(function (result) {
          ngUserService.businessExistingCustomer(result).subscribe();
        });
    }
    */

    if (vestingType) {
      const vestingTypes = {
        individual: 1,
        joint: 2,
        minor: 3,
        trust: 4,
        teen: 5
      };
      vestingType = vestingTypes[vestingType] || 1;
    }
    this.store$.dispatch(new SetProductsSelectionAction(productCodes, vestingType, advisorId));
  }
}
