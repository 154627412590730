import { environment } from 'environments/environment';
// Ng
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
// NgRx
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, NavigationActionTiming } from '@ngrx/router-store';
// Services
import { ApplicationFlowService } from 'services/http';
import { DataServiceModule } from 'services/data-services.module';
import { StateRouteService, UtmCodeService } from 'app/core/services';

import { effects } from './effects';
import { reducers } from './reducers';
import { EnrollmentSerializer } from './route-serializer';

let importModules = [
  DataServiceModule,
  StoreModule.forRoot(reducers),
  EffectsModule.forRoot(effects),
  StoreRouterConnectingModule.forRoot({
    serializer: EnrollmentSerializer,
    navigationActionTiming: NavigationActionTiming.PostActivation
  })
];

if(!environment.production) {
  importModules = [
    ...importModules,
    StoreDevtoolsModule.instrument({ maxAge: 500 })
  ]
}

@NgModule({
  imports: importModules,
  providers: [
    ApplicationFlowService,
    StateRouteService,
    // Revisit
    UtmCodeService,
  ],
  declarations: []
})
export class EnrollmentStoreModule { }
