import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, withLatestFrom, map, switchMap, first, combineLatest } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { LoadApplicantAction } from 'store/actions';
import { getApplicantByType, getCurrentApplicantType, getCurrentApplicant } from 'store/selectors';
// Models
import { Applicant } from 'models';
import { APPLICANT_TYPE_NAME_PARAM } from '../constants';
import { APPLICANT_TYPE_TO_ID } from 'models/enums';
// Resolver
import { ApplicationResolver } from './application.resolver';
import { CountryResolver } from './country.resolver';

@Injectable()
export class ApplicantResolver implements Resolve<Observable<Applicant>> {

  constructor(private store$: Store<EnrollmentState>, private applicationResolver: ApplicationResolver, private countryResolver: CountryResolver) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const applicantTypeName = route.params[APPLICANT_TYPE_NAME_PARAM];
    let applicantTypeId: string = applicantTypeName;

    return this.applicationResolver.resolve().pipe(
      combineLatest(this.countryResolver.resolve()),
      map(data => {
        return data[0].applicants;
      }),
      withLatestFrom(this.store$.select(getCurrentApplicantType)),
      switchMap(data => {
        const applicants = data[0], applicantType = data[1];
        applicantTypeId = applicantType;
        // if(!applicantTypeId) {
        //   applicantTypeId = applicantType;
        // }
        return this.store$.select(getCurrentApplicant).pipe(
          map(applicant => {
            return { applicants, applicant };
          }))
        }),
        map(data => {
          if(data.applicants.length > 0 && !data.applicant) {
            const mApplicant = data.applicants.find(applicant => applicant.type === applicantTypeId);
            //TODO: Have a way to identify if is already being loaded
            this.store$.dispatch(new LoadApplicantAction(mApplicant.applicantId));
          }
          return data.applicant;
        }),
        filter(applicant => !!applicant),
        first()
    );
  }
}
