// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { GetVestingsAction } from 'store/actions';
import { getVestingTypes } from 'store/selectors';
// Model
import { VestingType } from 'models';

@Injectable()
export class VestingTypesResolver implements Resolve<Observable<VestingType[]>> {

    constructor(private store$: Store<EnrollmentState>) {  }

     resolve() {
        return this.store$.select(getVestingTypes).pipe(
            tap(vestingTypes => {
                if(vestingTypes.length === 0) {
                    this.store$.dispatch(new GetVestingsAction());
                }
            }),
            filter(vestingTypes => !!vestingTypes),
            first()
        );
    }
}
