import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Store } from "@ngrx/store";
import { ContactInformation } from "models/proxy";
import { Observable } from "rxjs";
import { filter, first, map, withLatestFrom } from "rxjs/operators";
import { EnrollmentState, getTrustedContact, LoadAllApplicantsAction } from "store";
import { getCurrentApplicant, getCurrentApplicantId, selectTrustedContactWl } from "store/selectors";

@Injectable()
export class TrustedContactResolver implements Resolve<Observable<ContactInformation>>{
    constructor(
        private store$: Store<EnrollmentState>
    ){}

    resolve() {
        return this.store$.select(selectTrustedContactWl).pipe(
            map(trustedContact => {
                this.store$.dispatch(new LoadAllApplicantsAction());

                if(!trustedContact){
                    this.store$.select(getCurrentApplicantId).pipe(
                        filter(currentApplicantId => !!currentApplicantId),
                        withLatestFrom(this.store$.select(getCurrentApplicant)),
                        map(data =>{
                            const currentApplicantId = data[0];
                            const applicant = data[1];
                            const applicantId = currentApplicantId ? currentApplicantId : applicant.applicantId;
                            this.store$.dispatch(getTrustedContact({applicantId: applicantId}));
                        })
                    ).subscribe();
                }
                return trustedContact;
            }),
            first()
        );
    }
}