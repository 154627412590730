import { Action } from '@ngrx/store';
import { IAuthToken, IChallengeInfo, IExistingUser, IOtpChallengeInfo, ISignInRequest, IUser, IUserRequest, UserNameRequest, IChallengeOtpRequest } from 'models/auth';
import { IValidateOtpRequest } from 'models/auth/validate-otp-request.model';
import { IUserLead, ILeadRequest, IUserLeadResponse } from 'models';
import { SOURCE_TYPES } from 'models/enums';


export enum USER_ACTION_TYPES {
    LOGIN = '[Auth] Login',
    LOGIN_FAIL = '[Auth] Login fail',
    LOGIN_SUCCESS = '[Auth] Login success',
    COOKIE_LOGIN = '[Auth] Cookie login',
    COOKIE_LOGIN_FAIL = '[Auth] Cookie login fail',
    COOKIE_LOGIN_SUCCESS = '[Auth] Cookie login success',

    TOKEN_LOGIN = '[Auth] Token login',
    TOKEN_LOGIN_FAIL = '[Auth] Token login fail',
    TOKEN_LOGIN_SUCCESS = '[Auth] Token login success',

    GET_USER = '[Auth] Get user',
    GET_USER_FAIL = '[Auth] Get user fail',
    GET_USER_SUCCESS = '[Auth] Get user success',

    CREATE_USER = '[Auth] Create user',
    CREATE_USER_FAIL = '[Auth] Create user fail',
    CREATE_USER_SUCCESS = '[Auth] Create user success',

    CREATE_PROXY_USER = '[Auth] Create proxy user',
    CREATE_PROXY_USER_FAIL = '[Auth] Create proxy user fail',
    CREATE_PROXY_USER_SUCCESS = '[Auth] Create proxy user success',

    CREATE_LEAD = '[Auth] Create lead',
    CREATE_LEAD_FAIL = '[Auth] Create lead fail',
    CREATE_LEAD_SUCCESS = '[Auth] Create lead success',

    UPDATE_LEAD = '[Auth] Update lead',
    UPDATE_LEAD_FAIL = '[Auth] Update lead fail',
    UPDATE_LEAD_SUCCESS = '[Auth] Update lead success',

    REGISTER_EXISTING_USER = '[Auth] Create existing user',
    REGISTER_EXISTING_USER_SUCCESS = '[Auth] Create existing user success',
    REGISTER_EXISTING_USER_FAIL = '[Auth] Create existing user fail',

    REFRESH_TOKEN = '[Auth] Refresh token',
    REFRESH_TOKEN_FAIL = '[Auth] Refresh token fail',
    REFRESH_TOKEN_SUCCESS = '[Auth] Refresh token success',

    REQUEST_MULTIFACTOR_OTP = '[Auth] Request multifactor otp',
    REQUEST_MULTIFACTOR_OTP_SUCCESS = '[Auth] Request multifactor otp success',
    REQUEST_MULTIFACTOR_OTP_FAIL = '[Auth] Request multifactor otp fail',

    REQUEST_MULTIFACTOR_NoUser_OTP = '[Auth] Request multifactor otp No user',
    REQUEST_MULTIFACTOR_NoUser_OTP_SUCCESS = '[Auth] Request multifactor otp success No user',
    REQUEST_MULTIFACTOR_NoUser_OTP_FAIL = '[Auth] Request multifactor otp fail No user',

    VALIDATE_OTP_CODE = '[Auth] Validate OTP code',
    VALIDATE_OTP_CODE_SUCCESS = '[Auth] Validate OTP code success',
    VALIDATE_OTP_CODE_FAIL = '[Auth] Validate OTP code fail',

    VALIDATE_EXISTING_CUSTOMER = '[Auth] Validate existing customer',
    VALIDATE_EXISTING_CUSTOMER_SUCCESS = '[Auth] Validate existing customer success',
    VALIDATE_EXISTING_CUSTOMER_FAIL = '[Auth] Validate existing customer fail',

    GET_ENROLLMENT_URL = '[Auth] Get enrollment url',
    GET_ENROLLMENT_URL_SUCCESS = '[Auth] Get enrollment url success',
    GET_ENROLLMENT_URL_FAIL = '[Auth] Get enrollment url fail',

    GET_SECURITY_QUESTIONS = '[Auth] Get security questions',
    GET_SECURITY_QUESTIONS_SUCCESS = '[Auth] Get security questions success',
    GET_SECURITY_QUESTIONS_FAIL = '[Auth] Get security questions fail',

    SAVE_SECURITY_QUESTIONS = '[Auth] Save security questions',
    SAVE_SECURITY_QUESTIONS_SUCCESS = '[Auth] Save security question success',
    SAVE_SECURITY_QUESTIONS_FAIL = '[Auth] Save security questions fail',

    RESET_TASKS_STATES = '[Auth] Reset existing customer tasks states',
    RESET_TASKS_STATES_SUCCESS = '[Auth] Reset tasks states success',
    RESET_TASKS_STATES_FAIL = '[Auth] Reset tasks states fail',

    CREATE = '[Auth] Create',
    CREATE_SUCCESS = '[Auth] Createsuccess',
    CREATE_FAIL = '[Auth] Create fail',

    REDIRECT_OTP = '[Auth] Redirect OTP',
    REDIRECT_SECURITY_QUESTIONS = '[Auth] Redirect Security Questions',
    CLEAR_ERROR = '[Auth] Clear error',
    FORGOT_PASSWORD = '[Auth] Forgot password',
    FORGOT_PASSWORD_SUCCESS = '[Auth] Forgot password success',
    FORGOT_PASSWORD_FAIL = '[Auth] Forgot password fail',
    FORGOT_USERNAME = '[Auth] Forgot username',
    FORGOT_USERNAME_SUCCESS = '[Auth] Forgot username success',
    FORGOT_USERNAME_FAIL = '[Auth] Forgot username fail',
    SHOW_LOGIN = '[Auth] Show Login',
    GET_RANDOM_SECURITY_QUESTION = '[Auth] Get random securyty question',
    GET_RANDOM_SECURITY_QUESTION_FAIL = '[Auth] Get random securyty question fail',
    GET_RANDOM_SECURITY_QUESTION_SUCCESS = '[Auth] Get random securyty question success',
    VERIFY_SECURITY_QUESTION_ANSWER = '[Auth] Verify security question answer',
    VERIFY_SECURITY_QUESTION_ANSWER_SUCCESS = '[Auth] Verify security question answer success',
    VERIFY_SECURITY_QUESTION_ANSWER_FAIL = '[Auth] Verify security question answer fail',
    VALIDATE_RESET_PASSWORD = '[Auth] Validate reset password',
    VALIDATE_RESET_PASSWORD_FAIL = '[Auth] Validate reset password fail',
    VALIDATE_RESET_PASSWORD_SUCCESS = '[Auth] Validate reset password success',
    CHANGE_PASSWORD = '[Auth] Change password',
    CHANGE_PASSWORD_FAIL = '[Auth] Change password fail',
    CHANGE_PASSWORD_SUCCESS = '[Auth] Change password success',
    LOGOUT = '[Auth] Logout',
    CREATE_USER_NAME = '[Auth] Create user name',
    CREATE_USER_NAME_SUCCESS = '[Auth] Create user name success',
    CREATE_USER_NAME_FAIL = '[Auth] Create user name fail',

    CHECK_GUID_VALID = '[Auth] Check guid valid',
    CHECK_GUID_VALID_SUCCESS = '[Auth] Check guid valid success',
    CHECK_GUID_VALID_FAIL = '[Auth] Check guid valid fail',

    CHECK_APP_VALID = '[Auth] Check App validity',
    CHECK_APP_VALID_SUCCESS = '[Auth] Check App validity success',
    CHECK_APP_VALID_FAIL = '[Auth] Check App validity fail',

    VERIFY_EXISTING_CUSTOMER = '[Auth] Verify existing customer',
    VERIFY_EXISTING_CUSTOMER_FAIL = '[Auth] Verify existing customer fail',

    VALIDATE_USER_EXIST = '[User] Validate existing customer',
    VALIDATE_USER_EXIST_SUCCESS = '[User] Validate existing customer success',
    VALIDATE_USER_EXIST_FAIL = '[User] Validate existing customer fail',

    SET_GUID = '[Auth] Set guid',
    SET_GUID_SUCCESS = '[Auth] Set guid success',
    SET_GUID_FAIL = '[Auth] Set guid fail'
}

interface IApplicationOptions {
    sourceTypeId: SOURCE_TYPES,
    textMessageAllowed: boolean,
    shareInfo?: boolean
  }

export class CreateUserAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_USER;
    constructor(public user: ILeadRequest, public electronicDisclosure = false) { }
}

export class CreateUserSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_USER_SUCCESS;
    constructor(public applicationId: string) { }
}

export class CreateUserFailAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_USER_FAIL;
    constructor(public error: string) { }
}

export class CreateProxyUserAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_PROXY_USER;
    constructor(public user: ILeadRequest, public electronicDisclosure = false) { }
}

export class CreateProxyUserSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_PROXY_USER_SUCCESS;
    constructor(public applicationId: string) { }
}

export class CreateLeadAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_LEAD;
    constructor(public userLead: IUserLead) { }
}

export class CreateLeadSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_LEAD_SUCCESS;
    constructor(public userLeadResponse: IUserLeadResponse) { }
}

export class CreateLeadFailAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_LEAD_FAIL;
    constructor(public error: string) { }
}

export class UpdateLeadAction implements Action {
  readonly type = USER_ACTION_TYPES.UPDATE_LEAD;
  constructor(public userLead: IUserLead) { }
}

export class UpdateLeadSuccessAction implements Action {
  readonly type = USER_ACTION_TYPES.UPDATE_LEAD_SUCCESS;
  constructor(public userLeadResponse: IUserLeadResponse) { }
}

export class UpdateLeadFailAction implements Action {
  readonly type = USER_ACTION_TYPES.UPDATE_LEAD_FAIL;
  constructor(public error: string) { }
}

export class CreateUserNameAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_USER_NAME;
    constructor(public user: UserNameRequest) { }
}

export class CreateUserNameSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_USER_NAME_SUCCESS;
    constructor() { }
}

export class CreateUserNameFailAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_USER_NAME_FAIL;
    constructor(public error: string) { }
}

export class RegisterExistingUserAction implements Action{
    readonly type = USER_ACTION_TYPES.REGISTER_EXISTING_USER;
    constructor(public user: IUserRequest){ }
}

export class RegisterExistingUserSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.REGISTER_EXISTING_USER_SUCCESS;
    constructor(public user: IUser) { }
}

export class RegisterExistingUserFailAction implements Action{
    readonly type = USER_ACTION_TYPES.REGISTER_EXISTING_USER_FAIL;
    constructor(public error: string){}
}

export class GetUserFailAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_USER_FAIL;
    constructor(public error: string) { }
}

export class LoginAction implements Action {
    readonly type = USER_ACTION_TYPES.LOGIN;
    constructor(public request: ISignInRequest) { }
}

export class LoginSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.LOGIN_SUCCESS;
    // TODO:
    constructor(public token: IAuthToken, public username: string = '', public isExisting: boolean = false) { }
}

export class LoginFailAction implements Action {
    readonly type = USER_ACTION_TYPES.LOGIN_FAIL;
    // TODO:
    constructor(public error: string) { }
}

export class CookieLoginAction implements Action {
    readonly type = USER_ACTION_TYPES.COOKIE_LOGIN;
    constructor() { }
}

export class CookieLoginSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.COOKIE_LOGIN_SUCCESS;
    constructor(public token: IAuthToken) { }
}

export class CookieLoginFailAction implements Action {
    readonly type = USER_ACTION_TYPES.COOKIE_LOGIN_FAIL;
    constructor() { }
}

export class TokenLoginAction implements Action {
    readonly type = USER_ACTION_TYPES.TOKEN_LOGIN;
    constructor(public token: string) { }
}

export class TokenLoginFailAction implements Action {
    readonly type = USER_ACTION_TYPES.TOKEN_LOGIN_FAIL;
    constructor() { }
}

export class TokenLoginSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.TOKEN_LOGIN_SUCCESS;
    constructor(public token: IAuthToken) { }
}

export class GetUserAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_USER;
    constructor(public loadApplication: boolean = true) { }
}

export class GetUserSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_USER_SUCCESS;
    constructor(public user: IUser) { }
}

export class RefreshTokenAction implements Action {
    readonly type = USER_ACTION_TYPES.REFRESH_TOKEN;
    constructor(public refreshToken: string) { }
}

export class RefreshTokenSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.REFRESH_TOKEN_SUCCESS;
    constructor(public token: IAuthToken) { }
}
export class RefreshTokenFailAction implements Action {
    readonly type = USER_ACTION_TYPES.REFRESH_TOKEN_FAIL;
}

export class RequestMultifactorOTPAction implements Action {
    readonly type = USER_ACTION_TYPES.REQUEST_MULTIFACTOR_OTP;
    constructor(public request: IChallengeOtpRequest) { }
}

export class RequestMultifactorNoUserOTPAction implements Action {
    readonly type = USER_ACTION_TYPES.REQUEST_MULTIFACTOR_NoUser_OTP;
    constructor(public request: string) { }
}
export class RequestMultifactorOTPSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.REQUEST_MULTIFACTOR_OTP_SUCCESS;
    constructor(public response: IOtpChallengeInfo) { }
}

export class RequestMultifactorNoUserOTPSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.REQUEST_MULTIFACTOR_NoUser_OTP_SUCCESS;
    constructor(public response: any) { }
}

export class RequestMultifactorOTPFailAction implements Action {
    readonly type = USER_ACTION_TYPES.REQUEST_MULTIFACTOR_OTP_FAIL;
    constructor(public error: string) { }
}

export class ValidateOTPCodeAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_OTP_CODE;
    constructor(public request: IValidateOtpRequest) { }
}

export class ValidateOTPCodeSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_OTP_CODE_SUCCESS;
    constructor(public token: IAuthToken) { }
}

export class ValidateOTPCodeFailAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_OTP_CODE_FAIL;
    constructor(public error: string) { }
}

export class ValidateExistingCustomerAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_EXISTING_CUSTOMER;
}

export class ValidateExistingCustomerSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_EXISTING_CUSTOMER_SUCCESS;
    constructor(public user: IExistingUser) { }
}

export class ValidateExistingCustomerFailAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_EXISTING_CUSTOMER_FAIL;
    constructor(public user: IExistingUser) { }
}

export class GetEnrollmentUrlAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_ENROLLMENT_URL;
    constructor(public brandId: number) { }
}

export class GetEnrollmentUrlSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_ENROLLMENT_URL_SUCCESS;
    constructor(public user: IExistingUser) { }
}

export class GetEnrollmentUrlFailAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_ENROLLMENT_URL_FAIL;
}

export class GetSecurityQuestionsAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_SECURITY_QUESTIONS
    constructor(public resetPasswordToken: boolean) { }
}
export class GetSecurityQuestionsSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_SECURITY_QUESTIONS_SUCCESS
    constructor(public questions: any) { }
}

export class GetSecurityQuestionsFailAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_SECURITY_QUESTIONS_FAIL
    constructor(public error: string) { }
}

export class SaveSecurityQuestionsAction implements Action {
    readonly type = USER_ACTION_TYPES.SAVE_SECURITY_QUESTIONS
    constructor(public questions: any, public resetPasswordToken: boolean) { }
}

export class SaveSecurityQuestionsSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.SAVE_SECURITY_QUESTIONS_SUCCESS
    constructor(public response) { }
}

export class SaveSecurityQuestionsFailAction implements Action {
    readonly type = USER_ACTION_TYPES.SAVE_SECURITY_QUESTIONS_FAIL
    constructor(public error: string) { }
}

export class ResetTasksStatesAction implements Action {
    readonly type = USER_ACTION_TYPES.RESET_TASKS_STATES
    constructor(public user: IUser) { }
}

export class ResetTasksStatesSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.RESET_TASKS_STATES_SUCCESS
    constructor() { }
}

export class ResetTasksStatesFailAction implements Action {
    readonly type = USER_ACTION_TYPES.RESET_TASKS_STATES_FAIL
    constructor(public error: string) { }
}

export class CreateAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE
    constructor(public user: IUser) { }
}

export class CreateSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_SUCCESS
    constructor(public applicantId: number,) { }
}

export class CreateFailAction implements Action {
    readonly type = USER_ACTION_TYPES.CREATE_FAIL
    constructor(error: string) { }
}

export class RedirectOTPAction implements Action {
    readonly type = USER_ACTION_TYPES.REDIRECT_OTP
    constructor(public challengeInfo: IChallengeInfo) { }
}
export class RedirectSecurityQuestionsAction implements Action {
    readonly type = USER_ACTION_TYPES.REDIRECT_SECURITY_QUESTIONS
    constructor() { }
}


export class ClearErrorAction implements Action {
    readonly type = USER_ACTION_TYPES.CLEAR_ERROR
    constructor() { }
}

export class ForgotPasswordAction implements Action {
    readonly type = USER_ACTION_TYPES.FORGOT_PASSWORD
    constructor(public username: string, public resend: boolean = false) {
    }
}
export class ForgotPasswordSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.FORGOT_PASSWORD_SUCCESS
    constructor(public resend: boolean = false) {
    }
}
export class ForgotPasswordFailAction implements Action {
    readonly type = USER_ACTION_TYPES.FORGOT_PASSWORD_FAIL
    constructor(public error: string) {
    }
}

export class ForgotUsernameAction implements Action {
    readonly type = USER_ACTION_TYPES.FORGOT_USERNAME
    constructor(public email: string, public resend: boolean = false) {
    }
}
export class ForgotUsernameSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.FORGOT_USERNAME_SUCCESS
    constructor(public resend: boolean = false) {
    }
}
export class ForgotUsernameFailAction implements Action {
    readonly type = USER_ACTION_TYPES.FORGOT_USERNAME_FAIL
    constructor(public error: string) {
    }
}

export class ShowLoginAction implements Action {
    readonly type = USER_ACTION_TYPES.SHOW_LOGIN
    constructor(public show: boolean) { }
}

export class GetRandomSecurityQuestionAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_RANDOM_SECURITY_QUESTION
    constructor(public token: any) { }
}

export class GetRandomSecurityQuestionSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_RANDOM_SECURITY_QUESTION_SUCCESS
    constructor(public response:any) { }
}

export class GetRandomSecurityQuestionFailAction implements Action {
    readonly type = USER_ACTION_TYPES.GET_RANDOM_SECURITY_QUESTION_FAIL
    constructor(public error: string) { }
}

export class VerifySecurityQuestionAnswerAction implements Action {
    readonly type = USER_ACTION_TYPES.VERIFY_SECURITY_QUESTION_ANSWER
    constructor(public questions:any) { }
}

export class VerifySecurityQuestionAnswerSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.VERIFY_SECURITY_QUESTION_ANSWER_SUCCESS
    constructor(public response:any) { }
}
export class VerifySecurityQuestionAnswerFailAction implements Action {
    readonly type = USER_ACTION_TYPES.VERIFY_SECURITY_QUESTION_ANSWER_FAIL
    constructor(public error:string) { }
}
export class ValidateResetPasswordAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_RESET_PASSWORD
    constructor(public idToken:any, public usernameToken?:any) { }
}
export class ValidateResetPasswordSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_RESET_PASSWORD_SUCCESS
    constructor(public response:any) { }
}
export class ValidateResetPasswordFailAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_RESET_PASSWORD_FAIL
    constructor(public error:string) { }
}
export class ChangePasswordAction implements Action {
    readonly type = USER_ACTION_TYPES.CHANGE_PASSWORD
    constructor(public password:string, public unlockTocken:any,public usernameToken?:any) { }
}
export class ChangePasswordSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.CHANGE_PASSWORD_SUCCESS
    constructor() { }
}
export class ChangePasswordFailAction implements Action {
    readonly type = USER_ACTION_TYPES.CHANGE_PASSWORD_FAIL
    constructor(public error:string) { }
}

export class Logout implements Action{
    readonly type = USER_ACTION_TYPES.LOGOUT;
    constructor(){}
}

export class CheckGuidValidAction implements Action{
    readonly type = USER_ACTION_TYPES.CHECK_GUID_VALID;
    constructor(public guid: any){}
}

export class CheckGuidValidSuccessAction implements Action{
    readonly type = USER_ACTION_TYPES.CHECK_GUID_VALID_SUCCESS;
    constructor(public response: any){}
}

export class CheckGuidValidFailAction implements Action{
    readonly type = USER_ACTION_TYPES.CHECK_GUID_VALID_FAIL;
    constructor(public error: string){}
}

export class CheckAppValidityAction implements Action{
    readonly type = USER_ACTION_TYPES.CHECK_APP_VALID;
    constructor(public guid: any){}
}

export class CheckAppValiditySuccessAction implements Action{
    readonly type = USER_ACTION_TYPES.CHECK_APP_VALID_SUCCESS;
    constructor(public response: any){}
}

export class CheckAppValidityFailAction implements Action{
    readonly type = USER_ACTION_TYPES.CHECK_APP_VALID_FAIL;
    constructor(public error: string){}
}

export class VerifyExistingCustomerAction implements Action {
    readonly type = USER_ACTION_TYPES.VERIFY_EXISTING_CUSTOMER;
    constructor(public lead: IUserLead) { }
}

export class VerifyExistingCustomerFailAction implements Action {
    readonly type = USER_ACTION_TYPES.VERIFY_EXISTING_CUSTOMER_FAIL;
    constructor(public error: string) { }
}

export class ValidateUserExistAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_USER_EXIST;
    constructor(public user: ILeadRequest, public applicationOptions: IApplicationOptions) { }
}

export class ValidateUserExistSuccessAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_USER_EXIST_SUCCESS;
    constructor(public user: ILeadRequest, public applicationOptions: IApplicationOptions, public redirectToLogin: boolean, public userState: number) { }
}

export class ValidateUserExistFailAction implements Action {
    readonly type = USER_ACTION_TYPES.VALIDATE_USER_EXIST_FAIL;
    constructor(public user: ILeadRequest, public applicationOptions: IApplicationOptions, public shouldCreate: boolean, public errorMessage?: string) { }
}

export class SetGuidAction implements Action {
    readonly type = USER_ACTION_TYPES.SET_GUID;
    constructor(public guid: string){}
}

export type AuthAction =
    LoginAction | LoginSuccessAction | LoginFailAction | CookieLoginAction | CookieLoginSuccessAction |
    CookieLoginFailAction | TokenLoginAction | TokenLoginSuccessAction | TokenLoginFailAction |
    GetUserAction | GetUserSuccessAction | GetUserFailAction |
    RefreshTokenAction | RefreshTokenSuccessAction | RefreshTokenFailAction |
    CreateUserAction | CreateUserFailAction |CreateUserSuccessAction | RedirectOTPAction |
    RequestMultifactorOTPAction | RequestMultifactorOTPSuccessAction | RequestMultifactorOTPFailAction |
    ValidateOTPCodeAction | ValidateOTPCodeSuccessAction | ValidateOTPCodeFailAction |
    GetSecurityQuestionsSuccessAction | SaveSecurityQuestionsAction | SaveSecurityQuestionsSuccessAction | SaveSecurityQuestionsFailAction |
    GetEnrollmentUrlAction | GetEnrollmentUrlSuccessAction | GetEnrollmentUrlFailAction |
    ValidateExistingCustomerSuccessAction | ValidateExistingCustomerFailAction | CreateSuccessAction |
    ClearErrorAction | ForgotPasswordSuccessAction | ForgotPasswordFailAction | ForgotUsernameSuccessAction |
    ForgotUsernameFailAction | ShowLoginAction | VerifySecurityQuestionAnswerSuccessAction |
    GetRandomSecurityQuestionSuccessAction | GetRandomSecurityQuestionFailAction | ValidateResetPasswordFailAction |
    RegisterExistingUserAction | RegisterExistingUserSuccessAction | RegisterExistingUserFailAction |
    ClearErrorAction | Logout | ValidateResetPasswordSuccessAction| VerifySecurityQuestionAnswerFailAction |
    ChangePasswordFailAction | CreateUserNameAction | CreateUserNameSuccessAction | CreateUserNameFailAction |
    CheckGuidValidAction | CheckGuidValidSuccessAction | CheckGuidValidFailAction | 
    CheckAppValidityAction | CheckAppValiditySuccessAction | CheckAppValidityFailAction | 
    CreateLeadAction | CreateLeadSuccessAction | CreateLeadFailAction |
    UpdateLeadAction | UpdateLeadSuccessAction | UpdateLeadFailAction |
    VerifyExistingCustomerAction | VerifyExistingCustomerFailAction |
    ValidateUserExistAction | ValidateUserExistSuccessAction | ValidateUserExistFailAction |
    SetGuidAction | CreateProxyUserAction | CreateProxyUserSuccessAction | RequestMultifactorNoUserOTPAction |
    RequestMultifactorNoUserOTPSuccessAction;
