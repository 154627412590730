import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'axos-label'
})
export class LabelDirective {
  @Input() align: 'left' | 'right';
  @HostBinding('attr.id') @Input() id: string;
  @HostBinding('class.right') isAlignedRight: boolean;
  uniqueId = 0;

  constructor() {
    this.id = `axos-label-${this.uniqueId++}`;
    this.isAlignedRight = this.align === 'right'
   }

}
