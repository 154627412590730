import { Injectable } from '@angular/core';
import { BaseService } from 'services/abstracts/base.service';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { map } from "rxjs/operators";

import { INVESTMENT_OBJETIVES } from 'services/mocks/mock-data/investment-objetives.mock';
import { AxosResult } from 'models/enums/trading/axos-result.model';

@Injectable({
  providedIn: 'root'
})
export class InvestmentProfileService extends BaseService {
  private level = new BehaviorSubject<string>('');
  currentLevel = this.level.asObservable();

  changeLevel(level: string) {
    this.level.next(level);
  }

  getInvestmentObjetives(): Observable<any> {
    //return of(INVESTMENT_OBJETIVES);
    return this._get<any>(`generic/trading/catalog/investmentprofile`);
  }
}
