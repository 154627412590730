// Ng
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
// RxJs
import { map } from 'rxjs/operators';
// NgRx
import { EnrollmentState } from 'store';
import { Store } from '@ngrx/store';
import { CookieLoginAction } from 'store/actions';

import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'services';

@Injectable()
export class TokenCookieGuard implements CanActivate {
  constructor(private router: Router,
    private store$: Store<EnrollmentState>,
    private cookieService: CookieService,
    private authService: AuthService) { }

  canActivate() {
    const cookie = this.cookieService.get('XSRF-TOKEN');
    if (cookie) {
      this.store$.dispatch(new CookieLoginAction());
    }
    return true;
  }

  private formatUrl(url: string): string {
    return url.split(new RegExp('(?<!:)\/{2,}')).join('/');
  }

  // private validateExistingCustomer(brandId: string, localBrandId: string): Observable<boolean> {
  //   if (brandId) {
  //     if (brandId == localBrandId) {
  //       // Navigate to same brand error page
  //       const errorUrl = `${LANDING_PARENT_PATH}/${LANDING_ROUTES.SSO_ERROR.path}`;
  //       this.router.navigateByUrl(this.formatUrl(errorUrl));
  //     } else {
  //       this.store$.dispatch(new GetEnrollmentUrlAction(+brandId));
  //       return this.store$.select(getExisintgEnrollmentUrl).pipe(skip(1), switchMap(url => {
  //         // Navigate to different brand error page
  //         const obUrl = `${url}/${LANDING_PARENT_PATH}/${LANDING_ROUTES.SSO_ERROR.path}`;
  //         document.location.href = this.formatUrl(obUrl);
  //         return of(false);
  //       }));
  //     }
  //   } else if (brandId === '') {
  //     // Login and check for in-flight application
  //     this.store$.dispatch(new CookieLoginAction());
  //     return of(false);
  //   }
  //   return of(true);
  // }
}
