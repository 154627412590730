declare var environment: any;
// Ng
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// RxJs
import { of, iif } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, first, catchError, tap, mergeMap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState, GetBeneficiariesInformationSuccessAction } from 'store';
import { fetchWorkflow } from 'store/workflows';
import {
  getUserApplicationId,
  getMarketingSelectedProducts,
  getBrand,
  getApplication,
  getAdvisorId,
  getSelectedVestingTypeId,
  getCurrentApplicantType,
  getDmdEnabled,
  getToken,
  getApplicationId,
  getApplicationStateId,
  getIRAFlag,
  getIsManagedPortfolio,
  getPrimaryApplicant,
  getCurrentApplicantId
} from 'store/selectors';
// NgRx Effects
import { Effect, Actions, ofType } from '@ngrx/effects';
// Services
import { ApplicationService } from 'services';
import { StateRouteService, UtmCodeService } from 'app/core/services';
// Models
import { SOURCE_TYPES, BRAND_ID, APPLICATION_STATE, BENEFICIARY_TYPE } from 'models/enums';
// Actions
import * as applicationActions from '../actions/application.action';
import * as applicantsAction from '../actions/applicants.action';
import * as rulesActions from '../actions/rules.action';
import * as authActions from '../actions/auth.action';
import * as productsActions from '../actions/products.action';
// Route constants
import { AUTH_PARENT_ROUTE, AUTH_ROUTES } from 'app/auth/constants/route-names';
import { SET_CACHE } from 'app/core/utils';
import { IApplication, TrustCertificate } from 'models';
import { APPLICATION_TYPE, APPLICATION_TYPE_FLOW } from 'models/enums/application-type.enum';
import { OUTCOME_PARENT_ROUTE, OUTCOME_ROUTES } from 'app/outcome/constants';
import { LANDING_ROUTES } from 'app/landing/routing';
import { DatePipe } from '@angular/common';

@Injectable()
export class ApplicationEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<EnrollmentState>,
    private applicationService: ApplicationService,
    private stateRouteService: StateRouteService,
    private utmCodeService: UtmCodeService,
    private router: Router) { }

  @Effect()
  loadApplication$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION),
    // switchMap(() => this.applicationId$.pipe(first())),
    switchMap((action: applicationActions.LoadApplicationAction) => {
      return this.applicationService.getApplication(action.applicationId).pipe(
        map((response: IApplication) => {
          response.Applicants = response.applicants.reduce((acc, item) => {
            acc.push({ ApplicantId: item.applicantId })
            return acc;
          }, []);
          const IRAProduct = response.productCodes.find(p => p == 'RIRA' || p == 'TIRA');
          this.store$.dispatch(new productsActions.SetIRAFlag(!!IRAProduct));
          // this.store$.dispatch(new applicantsAction.LoadAllApplicantsSuccessAction(response.applicants));
          return new applicationActions.LoadApplicationSuccessAction(response);
        })
      );
    })
  );

  @Effect()
  loadApplicationByToken$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_BY_TOKEN),
    withLatestFrom(
      this.store$.select(getMarketingSelectedProducts),
      this.store$.select(getIsManagedPortfolio),
      this.store$.select(getIRAFlag)
    ),
    switchMap(([application, products, isManagedPortfolio, isIRA]) => {
      const action: applicationActions.LoadApplicationByTokenAction = application;
      const productCodes = products.map(x => x.productCode);

      let applicationTypeName = APPLICATION_TYPE_FLOW.Trading;
      let isIRAProduct = productCodes.find(x => x.toLowerCase() === 'tira' || x.toLowerCase() === 'rira');

      if (isIRA || isIRAProduct) {
        if (environment.applicationTypeName == APPLICATION_TYPE_FLOW.Trading) { applicationTypeName = APPLICATION_TYPE_FLOW.TradingIRA }
        if (environment.applicationTypeName == APPLICATION_TYPE_FLOW.WhitelabelTrading) { applicationTypeName = APPLICATION_TYPE_FLOW.WhitelabelTradingIRA }
        if (environment.applicationTypeName == APPLICATION_TYPE_FLOW.ProxyTrading) { applicationTypeName = APPLICATION_TYPE_FLOW.ProxyIRA }
      } else if (isManagedPortfolio) {
        applicationTypeName = APPLICATION_TYPE_FLOW.ManagedPortfolio;
      } else {
        applicationTypeName = environment.applicationTypeName;
      }

      const utmCodes = this.utmCodeService.getUTMCampaignCodes();

      return this.applicationService.getApplicationByToken(action.createNew, productCodes, action.sso, applicationTypeName, utmCodes).pipe(
        map((res: IApplication) => {
          if (res.createNewApplication) {
            return new applicationActions.CreateApplicationAction(SOURCE_TYPES.existingEnroller);
          } else {
            res.Applicants = res.applicants.reduce((acc, item) => {
              acc.push({ ApplicantId: item.applicantId })
              return acc;
            }, []);
            //for proxy
            const IRAProduct = res.productCodes.find(p => p == 'RIRA' || p == 'TIRA');
            
            this.store$.dispatch(new productsActions.SetIRAFlag(!!IRAProduct));
            this.store$.dispatch(new applicationActions.SetApplicationApplicants(res.applicants));
            this.store$.dispatch(new productsActions.SetProductsSelectionAction(res.productCodes));
            return new applicationActions.LoadApplicationByTokenSuccessAction(res);
          }
        })
      );
    })
  )

  @Effect({ dispatch: false })
  loadApplicationByTokenSuccess$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_BY_TOKEN_SUCCESS),
    tap(() => {
      return [
        this.store$.dispatch(new applicantsAction.LoadAllApplicantsAction()),
        this.store$.dispatch(new applicationActions.GetConfirmationNumberAction())
      ];
    })
  )

  /*
  @Effect({ dispatch: false })
  loadApplicationSucceed$ =this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_SUCCESS),
    withLatestFrom(
      this.store$.select(getToken),
      this.store$.select(getUser)
    ),
    tap(data => {
      const action: applicationActions.LoadApplicationSuccessAction = data[0],
        { Version, Brand, ApplicationId } = action.application,
        env = environment.env,
        token = data[1],
        user = data[2];
      if(Version === 'A' && Brand.Id === BRAND_ID.UFBDirect) {
        let domain = 'enrollment.ufbdirect.com';
        SET_CACHE(user, 'user')
        if(env !== 'prod') {
          domain = `enrollment.${env}.ufbdirect.com`;
        }
        const redirectTo = `https://${domain}/welcome`;
        console.log('Redirect to old UFB:', redirectTo);
        // window.location.href = redirectTo;
      }
    })
  );
  */

  @Effect()
  loadAccounts$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.LOAD_ACCOUNTS),
    withLatestFrom(this.store$.select(getApplicationId)),
    switchMap(([_, applicationId]) => {
      return this.applicationService.getAccounts(applicationId).pipe(
        map(response => new applicationActions.LoadAccountsSuccessAction(response))
      );
    }),
    catchError((error) => {
      return of(new applicationActions.LoadAccountsFailAction());
    }),
  );

  @Effect()
  createApplication$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.CREATE_APPLICATION),
    withLatestFrom(
      this.store$.select(getMarketingSelectedProducts),
      this.store$.select(getIRAFlag),
      this.store$.select(getIsManagedPortfolio)
      //   this.store$.select(getSelectedVestingTypeId),
      //   this.store$.select(getAdvisorId)
    ),
    switchMap(data => {
      const action: applicationActions.CreateApplicationAction = data[0];
      const ProductCodes = data[1].map(products => products.productCode);
      const isIRA: boolean = data[2];
      const isManagedPortfolio: boolean = data[3];
      let applicationTypeName: APPLICATION_TYPE_FLOW = APPLICATION_TYPE_FLOW.Trading;
      let isIRAProduct = ProductCodes.find(x => x.toLowerCase() === 'tira' || x.toLowerCase() === 'rira');

      if (isIRA || isIRAProduct) {
        if (environment.applicationTypeName == APPLICATION_TYPE_FLOW.Trading) { applicationTypeName = APPLICATION_TYPE_FLOW.TradingIRA }
        if (environment.applicationTypeName == APPLICATION_TYPE_FLOW.WhitelabelTrading) { applicationTypeName = APPLICATION_TYPE_FLOW.WhitelabelTradingIRA }
        if (environment.applicationTypeName == APPLICATION_TYPE_FLOW.ProxyTrading) { applicationTypeName = APPLICATION_TYPE_FLOW.ProxyIRA }
      } else if (isManagedPortfolio) {
        applicationTypeName = APPLICATION_TYPE_FLOW.ManagedPortfolio;
      } else {
        applicationTypeName = environment.applicationTypeName;
      }
      // VestingTypeId = data[2],
      // AdvisorId = data[3];
      var utmCodes = this.utmCodeService.getUTMCampaignCodes();
      return this.applicationService.createApplication({
        // SourceTypeId: action.sourceTypeId,
        // TextMessageAllowed: action.textMessageAllowed,
        // ShareInfo: action.shareInfo,
        // AdvisorId,
        UtmCodes: utmCodes,
        ProductCodes,
        organizationName: environment.organizationName,
        organizationId: environment.organizationId,
        BranchId: 1,
        ApplicationTypeId: APPLICATION_TYPE.Existing,
        applicationTypeName: applicationTypeName
        // VestingTypeId
      });
    }),
    switchMap(response => [
      new applicationActions.CreateApplicationSuccessAction(),
      new authActions.GetUserSuccessAction(response)
    ])
  );

  @Effect()
  updatePromoCode$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.UPDATE_PROMO_CODE),
    switchMap((action: applicationActions.UpdatePromocodeAction) => {
      return this.applicationService.updatePromoCode(action.promoCode).pipe(
        map(response => new applicationActions.UpdatePromoCodeSuccessAction(response.PromoCode))
      )
    })
  );

  @Effect()
  setState$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.SET_STATE),
    switchMap((action: applicationActions.SetApplicationStateAction) => {
      return this.applicationService.setState(action.state).pipe(
        map(() => new applicationActions.SetApplicationStateSuccessAction(action.state))
      );
    })
  );

  @Effect()
  fetchApplicationFlow$ = this.actions$.pipe(
    ofType(
      applicationActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_SUCCESS,
      applicationActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_BY_TOKEN_SUCCESS,
      applicationActions.APPLICATION_ACTION_TYPES.SET_VESTING_TYPE_SUCCESS,
    ),
    withLatestFrom(this.store$.select(getApplication)),
    map(([action, { applicationId }]) => fetchWorkflow({ applicationId })),
  );

  /*
  @Effect({ dispatch: false })
  correctApplicationRoute$ = this.actions$.pipe(
    ofType(
      applicationActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_SUCCESS,
      applicationActions.APPLICATION_ACTION_TYPES.LOAD_APPLICATION_BY_TOKEN_SUCCESS,
      applicationActions.APPLICATION_ACTION_TYPES.SET_STATE_SUCCESS,
      applicationActions.APPLICATION_ACTION_TYPES.CORRECT_ROUTE,
      rulesActions.RULES_ACTION_TYPES.VALIDATE_APPLICATION_SUCCESS,
      rulesActions.RULES_ACTION_TYPES.RESUME_RULES_SUCCESS,
      rulesActions.RULES_ACTION_TYPES.GET_IDA_QUESTIONS_SUCCESS,
      rulesActions.RULES_ACTION_TYPES.BOARD_IN_MITEK_SUCCESS
    ),
    withLatestFrom(
      this.store$.select(getApplication),
      this.store$.select(getCurrentApplicantType),
      this.store$.select(getMarketingSelectedProducts),
      this.store$.select(getDmdEnabled)
    ), tap(data => {
      const application = data[1], applicantTypeId = data[2], mProds = data[3], dmdEnabled = data[4];
      this.stateRouteService.correctRoute(application, applicantTypeId, mProds, dmdEnabled);
    })
  );
  */

  @Effect()
  cancelApplication$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.CANCEL_APPLICATION),
    withLatestFrom(this.store$.select(getBrand)),
    switchMap(data => {
      const action = <applicationActions.CancelApplicationAction>data[0];
      const brand = data[1];
      return this.applicationService.cancelInflight(brand.id).pipe(
        map(() => new applicationActions.CancelApplicationSuccessAction())
      );
    })
  );

  @Effect()
  checkInFlight$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.CHECK_INFLIGHT_APPLICATION),
    switchMap(() => {
      return this.applicationService.checkInFlight(environment.brandId).pipe(
        map(() => {
          return new applicationActions.CheckInFlightApplicationRedirectAction(`${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.INPROGRESS}`)
        }),
        catchError((error) => {
          return iif(() => error.status == 404,
            of(new applicationActions.CreateApplicationAction(SOURCE_TYPES.existingEnroller))
              .pipe(
                tap(() => this.stateRouteService.checkForInProgress = false)
              ),
            of(new applicationActions.CheckInFlightApplicationRedirectAction(`${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.EXISTINGLOGIN}`))
          );
        })
      );
    })
  );

  @Effect()
  createApplicationSuccess$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.CREATE_APPLICATION_SUCCESS),
    switchMap(() => of(new applicationActions.LoadApplicationByTokenAction()))
  );

  @Effect({ dispatch: false })
  getInFlightRedirect$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.CHECK_INFLIGHT_APPLICATION_REDIRECT),
    switchMap((action: applicationActions.CheckInFlightApplicationRedirectAction) => this.router.navigate([action.path]))
  );

  @Effect()
  getConfirmationNumber$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.GET_CONFIRMATION_NUMBER),
    withLatestFrom(this.store$.select(getApplicationId)),
    switchMap(data => {
      const action = data[0],
        applicationId = data[1];
      return this.applicationService.getConfirmationNumber(applicationId).pipe(
        map(res => {
          return new applicationActions.GetConfirmationNumberSuccessAction(res)
        })
      )
    })
  );

  @Effect({ dispatch: false })
  saveBeneficiaries$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.SAVE_BENEFICIARIES),
    mergeMap((action: applicationActions.SaveBeneficiariesAction) => {
      return this.applicationService.saveBeneficiaries(action.beneficiariesInformation, action.beneficiariesType, action.stepsToSkip, action.skipHistory).pipe(
        tap((response: any) => {
          const datePipe = new DatePipe('en-US');
          const _data = response.map((ben) => ({
            ...ben,
            birthDate: datePipe.transform(ben.birthDate, 'MM/dd/yyyy')//date format
          }));
          if (action.beneficiariesType === BENEFICIARY_TYPE.Contingent) {
            this.store$.dispatch(new applicationActions.SaveBeneficiariesContingentSuccessAction(_data));
          } else {
            this.store$.dispatch(new applicationActions.SaveBeneficiariesSuccessAction(_data));
          }
        })
      )
    })
  );

  @Effect()
  skipBeneficiaries$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.SKIP_BENEFICIARIES),
    switchMap((action: applicationActions.SkipBeneficiariesAction) => {
      return this.applicationService.saveBeneficiaries(action.beneficiariesInformation, action.beneficiariesType, action.stepsToSkip).pipe(
        map(() => {
          if (action.beneficiariesType === BENEFICIARY_TYPE.Contingent) {
            return new applicationActions.SkipBeneficiariesContingentSuccessAction(action.beneficiariesInformation);
          } else {
            return new applicationActions.SkipBeneficiariesSuccessAction(action.beneficiariesInformation);
          }
        })
      )
    })
  );

  @Effect({ dispatch: false })
  declinedBeneficiaries$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.DECLINED_BENEFICIARIES),
    withLatestFrom(
      this.store$.select(getApplicationId),
      this.store$.select(getCurrentApplicantId)
    ),
    mergeMap(([ac, applicationId, applicantId]) => {
      let action: applicationActions.DeclinedBeneficiaries = ac;
      return this.applicationService.declinedBeneficiariesSelection(action.beneficiariesType, applicationId, applicantId, action.declinedBeneficiaries, action.isProxy).pipe(
        tap(() => {
          this.store$.dispatch(new applicationActions.SkipBeneficiariesAction([], action.beneficiariesType, action.stepsToSkip));
        })
      )
    })
  )

  @Effect({ dispatch: false })
  getBeneficiaries$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.GET_BENEFICIARIES_INFORMATION),
    withLatestFrom(
      this.store$.select(getApplicationStateId)
    ),
    mergeMap((data) => {
      const action = <applicationActions.GetBeneficiariesInformationAction>data[0],
        applicationStateId = data[1];
      return this.applicationService.getBeneficiaries(action.typeName).pipe(
        tap((beneficiaries) => {
          if (action.typeName === BENEFICIARY_TYPE.Primary)
            this.store$.dispatch(new applicationActions.GetBeneficiariesInformationSuccessAction(beneficiaries));
          if (action.typeName === BENEFICIARY_TYPE.Contingent)
            this.store$.dispatch(new applicationActions.GetBeneficiariesContingentInformationSuccessAction(beneficiaries))
        })
      )
    })
  );

  @Effect()
  saveTrustCertificate$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.SAVE_TRUST_CERTIFICATE),
    switchMap((action: applicationActions.SaveTrustCertificateAction) => {
      return this.applicationService.saveTrustCertificate(action.applicationId, action.certificate, action.skipHistory).pipe(
        map((response: any) => {
          if(environment.isProxy) {
            return new applicationActions.SaveTrustCertificateSuccessAction(response.data.data, action.shouldNavigate, action.skipHistory)
          }
          return new applicationActions.SaveTrustCertificateSuccessAction(response.data, action.shouldNavigate, action.skipHistory)
        })
      )
    })
  );

  @Effect()
  getTrustCertificate$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.GET_TRUST_CERTIFICATE_INFORMATION),
    switchMap((action: applicationActions.GetTrustCertificateInformationAction) => {
      return this.applicationService.getTrustCertificate(action.applicationId).pipe(
        map((response: TrustCertificate) => new applicationActions.GetTrustCertificateInformationSuccessAction(response))
      );
    })
  );

  @Effect({dispatch: false})
  redirectoToGeneralErrorPage$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.REDIRECTO_TO_GENERAL_ERROR_PAGE),
    tap(() => this.router.navigateByUrl(LANDING_ROUTES.ERROR.path))
  );

  @Effect()
  getTrustTypes$ = this.actions$.pipe(
    ofType(applicationActions.APPLICATION_ACTION_TYPES.GET_TRUST_TYPES),
    switchMap((action: applicationActions.GetTrustTypesAction) => {
      return this.applicationService.getTrustTypes().pipe(
        map(response => new applicationActions.GetTrustTypesSuccessAction(response.data)),
        catchError(() => of(new applicationActions.GetTrustTypesFailAction()))
      );
    })
  );

  // @Effect({dispatch: false})
  // getBeneficiariesContingent$ = this.actions$.pipe(
  //   ofType( applicationActions.APPLICATION_ACTION_TYPES.GET_BENEFICIARIES_CONTINGENT_INFORMATION),
  //   withLatestFrom(
  //     this.store$.select(getApplicationId)
  //   ),
  //   switchMap(() => {
  //     return this.applicationService.getBeneficiaries().pipe(
  //       tap((beneficiaries) => {
  //         this.store$.dispatch(new applicationActions.GetBeneficiariesContingentInformationSuccessAction(beneficiaries))
  //       })
  //     )
  //   })
  // )
}
