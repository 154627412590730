export const OUTCOME_PARENT_ROUTE = 'result';

export const OUTCOME_ROUTES = {
  SUBMITTED: 'submitted',
  REGISTER_ONLINE_BANKING: 'rob',
  DECLINED: 'declined', // DECLINED: 'declined/:applicationId',
  APPROVED: 'approved', // APPROVED: 'approved/:applicationId/:secondChance',
  INREVIEW: 'inreview',
  RETRY: 'retry',
  APPROVED_FAILURE: 'approved/pending',
  CANCELED: 'canceled',
  BOARDING_IN_PROGRESS: 'boarding',
  PENDING_ADDITIONAL_OWNER: 'pendingAdditionalOwner'
};
