import { createAction, props } from "@ngrx/store";
import { AccountFeature, Feature } from "models/trading/account-feature.model";
import { PersonalInformationTrading } from "models/trading/personal-information.model";
import { IInvestmentProfile } from "models/trading/investment-profile.model";
import { IInvestmentObjetive, ApplicationSummary, FinancialInformation } from "models";
import { Person } from 'models/trading/person.model';


export const setAccountFeature = createAction(
    '[Trading] Set account feature',
    props<{ feature: AccountFeature }>()
);

export const setAccountFeatureSuccess = createAction(
    '[Trading] Set account feature success',
    props<{feature: AccountFeature}>()
);

export const setAccountFeatureFail = createAction(
    '[Trading] Set account feature fail',
    props<{ error: string }>()
);

export const setPersonalInformation = createAction(
    '[Trading] Set personal information',
    props<{ personalInformation: PersonalInformationTrading }>()
);

export const setPersonalInformationSuccess = createAction(
    '[Trading] Set personal information success',
    (personalInformation: PersonalInformationTrading, shouldNavigate: boolean = true) => ({ personalInformation, shouldNavigate })
);

export const setPersonalInformationFail = createAction('[Trading] Set personal information fail');

export const saveInvestmentProfile = createAction(
    '[Trading] Save investment profile',
    props<{investmentProfile: IInvestmentProfile, skipHistory: boolean}>()
);

export const saveInvestmentProfileSuccess = createAction(
    '[Trading] Save investment profile success',
    (investmentProfile: IInvestmentProfile, shouldNavigate: boolean = true) => ({ investmentProfile, shouldNavigate })
);

export const saveInvestmentProfileFail = createAction(
    '[Trading] Save investment profile fail',
    props<{ error: string }>()
);

export const getInvestmentObjetives = createAction('[Trading] Get investment objetives');

export const getInvestmentObjetivesActionSuccess = createAction(
    '[Trading] Get investment objetives success',
    props<{objetives: IInvestmentObjetive[]}>()
);

export const getInvestmentObjetivesActionFail = createAction('[Trading] Get investment objetives fail');

export const getAccountFeature = createAction('[Trading] Get account feature');

export const getAccountFeatureSuccess = createAction(
    '[Trading] Get account feature success',
    props<{accountFeature: AccountFeature}>()
);

export const getFeatures = createAction('[Trading] Get features catalog');

export const getFeaturesSuccess = createAction(
    '[Trading] Get feature catalog success',
    props<{features: Feature[]}>()
);

export const getPersonalInformation = createAction(
    '[Trading] Get all personal information',
    props<{applicantId: string}>()
);

export const getAllPersonalInformationSuccess = createAction(
    '[Trading] Get all personal information success',
    props<{person: Person}>()
);

export const getInvestmentProfile = createAction('[Trading] Get investment profile');

export const getInvestmentProfileSuccess = createAction(
    '[Trading] Get investment profile success',
    props<{profile: IInvestmentProfile}>()
);

export const setSkipVerification = createAction(
    '[Trading] Set skip verification',
    (applicantId: number, desicion: boolean, navigate: boolean = false) => ({ applicantId, desicion, navigate })
);

export const setSkipVerificationSuccess = createAction(
    '[Trading] Set skip verification success',
    props<{desicion: boolean}>()
);

export const saveTradingDisclosures = createAction(
    '[Trading] Save disclosures',
    props<{appId: string, applicantId: string, summary: ApplicationSummary}>()
);

export const saveTradingDisclosuresSuccess = createAction('[Trading] Save disclosures success');

export const saveTradingDisclosuresFail = createAction(
    '[Trading] Save disclosures fail',
    props<{error: string}>()
);

export const saveFinancialInformation = createAction(
    '[Trading] Save financial information',
    props<{information: FinancialInformation, applicationId: string, skipHistory: boolean}>()
);

export const saveFinancialInformationSuccess = createAction(
    '[Trading] Save financial information success',
    (information: FinancialInformation, shouldNavigate: boolean = true) => ({information, shouldNavigate})
);

export const saveFinancialInformationFail = createAction(
    '[Trading] Save financial information fail',
    props<{error: string}>()
);

export const getFinancialInformation = createAction('[Trading] Get financial information');

export const getFinancialInformationSuccess = createAction(
    '[Trading] Get financial information success',
    props<{information: FinancialInformation}>()
);

export const logAction = createAction(
    '[Trading] Log action',
    (message: string, applicantType: string = 'primary') => ({message, applicantType})
);

export const clearTradingState = createAction('[Trading] Clear State');
