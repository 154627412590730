export const FEATURE_NAMES = {
    Applicants: 'applicants',
    Application: 'application',
    Brand: 'brand',
    Auth: 'auth',
    Banner: 'banner',
    Catalogs: 'catalogs',
    Disclosures: 'disclosures',
    Products: 'products',
    Layout: 'layout',
    Router: 'router',
    Trading: 'trading',
    Organization: 'organization',
    Landing: 'landing',
    Proxy: 'proxy',
    Broker: 'broker',
    WhiteLabel: 'whiteLabel',
    Identification: 'identification',
    FeatureFlags: 'featureFlags'
};
