import { APPLICANT_IDENTIFICATION_STEP } from "../enums";

export class DmdPollingStep {
  step: APPLICANT_IDENTIFICATION_STEP;
  imageUrl: string;
  displayLabel: string;

  constructor(step: APPLICANT_IDENTIFICATION_STEP, url: string, label: string) {
    this.step = step;
    this.imageUrl = url;
    this.displayLabel = label;
  }
}
