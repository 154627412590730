import { Injectable } from '@angular/core';
import { AxosResult } from 'models/enums/trading';
import { map } from 'rxjs/operators';
import { BaseService } from './abstracts';

@Injectable()
export class FeatureFlagService extends BaseService {
  private _features = {
    proxy: true,
    landing: true,
    enableTrustVestingType: false
  }

  get features(){
    return this._features;
  }

  getStatus(featureName: string) {
    return this._get<AxosResult<boolean>>(`generic/featureflag?feature=${featureName}`)
    .pipe(
      map(result => result.data)
    );
  }
  
}
