export enum DMD_EXCEPTION_CODE {
  ApplicationNotFound = 'EX00',
  ApplicantNotFound = 'EX01',
  SmsServiceFailed = 'EX02',
  DmdFlowNotValid = 'EX03',
  RetryCountExceeded = 'EX04',
  InvalidOtpCode = 'EX05',
  TokenGenerationExceeded = 'EX06',
  DmdLinkExpired = 'EX07'
}
