import { DisclosureState } from 'store/states/disclosure.state';
import * as fromActions from '../actions/disclosures.action';

export const initialState: DisclosureState = {
    electronicDisclosure: null,
    initialDisclosures: null,        
    detailDisclosures: null,
    disclosuresCatalog: null,
    axosClearingDisclosures: null,
    ecmDisclosures: null,
    ecmDisclosureDocuments: null,
    cashSweepDisclosures: null
}

export function reducer(
    state = initialState,
    action: fromActions.DisclosuresAction
): DisclosureState {
    switch (action.type) {
        case fromActions.DISCLOSURES_ACTION_TYPES.GET_DETAIL_DISCLOSURES_SUCCESS: {
            return { ...state, detailDisclosures: action.disclosures };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.GET_DISCLOSURES_CATALOG_SUCCESS: {
            return { ...state, disclosuresCatalog: action.disclosures };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.GET_AXOS_CLEARING_DISCLOSURES_SUCCESS: {
            return { ...state, axosClearingDisclosures: action.disclosures };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.GET_ELECTRONIC_DISCLOSURE_SUCCESS: {
            return { ...state, electronicDisclosure: action.disclosure };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.GET_INITIAL_DISCLOSURES_SUCCESS: {
            return { ...state, initialDisclosures: action.disclosures };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.UPDATE_ELECTRONIC_DISCLOSURE: {
            const electronicDisclosure = state.electronicDisclosure;
            electronicDisclosure.accepted = action.accepted;
            return { ...state, electronicDisclosure };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURES_SUCCESS: {
            return { ...state, ecmDisclosures: action.disclosures };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.GET_AXOS_INVEST_DISCLOSURE_SUCCESS: {
            return { ...state, electronicDisclosure: action.disclosure };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.GET_TAXPAYER_DISCLOSURE_SUCCESS: {
            const initialDisclosures = state.initialDisclosures;
            const currentDisclosure = initialDisclosures.find(x => x.name == action.disclosure.name);
            currentDisclosure.accepted = action.disclosure.accepted;
            return { ...state, initialDisclosures };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURE_DOCUMENT_SUCCESS:
        case fromActions.DISCLOSURES_ACTION_TYPES.GET_ECM_DISCLOSURE_DOCUMENT_BY_DISCLOSURE_SUCCESS: {
            const documents = state.ecmDisclosureDocuments ? state.ecmDisclosureDocuments : [];
            const found = documents.find(x => x.disclosureName == action.disclosure.disclosureName);

            if (!found) {
                documents.push(action.disclosure);
            }

            return { ...state, ecmDisclosureDocuments: documents };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.UPDATE_INITIAL_DISCLOSURE: {
            if (state.initialDisclosures) {
                const initialDisclosures = state.initialDisclosures;

                const currentDisclosure = initialDisclosures.find(x => x.name == action.name);
                currentDisclosure.accepted = action.accepted;

                return { ...state, initialDisclosures };
            }

            return state;
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.SET_CASH_SWEEP_DISCLOSURES: {
            return { ...state, cashSweepDisclosures: action.disclosure };
        }
        case fromActions.DISCLOSURES_ACTION_TYPES.CLEAR_STATE: {
            return { ...initialState };
        }
    }

    return state;
};
