declare var environment: any;

//Ng
import { Injectable } from "@angular/core";
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from "rxjs";
import { filter, first, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState, GetChatBotSettingsAction } from "store";
import { getChatBotSettings } from "store/selectors";
// Model
import { ChatBotSettings } from "models/brand/chatbot-settings.model";

@Injectable()
export class ChatBotResolver implements Resolve<Observable<ChatBotSettings>> {
    constructor(private store$: Store<EnrollmentState>) { }

    resolve() {
        return this.store$
            .select(getChatBotSettings)
            .pipe(
                tap(settings => {
                    if (!settings) {
                        this.store$.dispatch(new GetChatBotSettingsAction(environment.brandId));
                    }
                }),
                filter(settings => !!settings),
                first()
            );
    }
}