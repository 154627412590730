import { Routes } from '@angular/router';
// Module Routes
import {
  LANDING_PARENT_PATH,
  LANDING_PARENT_PATH_OLD
} from 'app/landing/routing';

import { PRODUCTS_PARENT_PATH } from 'app/products/routing';
import { APPLICANT_INFORMATION_PARENT_PATH } from 'app/applicant-information/routing';
import { OUTCOME_PARENT_ROUTE } from 'app/outcome/constants';
import { DOCUMENTS_PARENT_ROUTE } from 'app/documents/constants';
import { DISCLOSURES_PARENT_PATH, CONFIRM_DISCLOSURES } from 'app/disclosures/routing';
import { AUTH_PARENT_ROUTE, AUTH_ROUTES } from 'app/auth/constants';
import { REVIEWSUBMIT_PARENT_ROUTE } from 'app/review-submit/route.constants';
import { RULES_PARENT_ROUTE } from 'app/rules/constants';
import { FEATURES_PARENT_PATH } from 'app/trading-features/routing/constants';
import { PROXY_PARENT_PATH } from 'app/proxy/routing/constants/routes';
import { MANAGE_PORTFOLIO_PARENT_PATH } from 'app/manage-portfolio/routing';

export const PUBLIC_APP_ROUTES: Routes = [
  //#region Redirects for Old Routes
  {
    path: 'welcome/login',
    redirectTo: `${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.LOGIN}`
  },
  {
    path: 'welcome/createUserName/:guid',
    redirectTo: `${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.CREATE_USER_NAME}`
  },
  {
    path: 'confirmDisclosures/:guid',
    redirectTo: `${DISCLOSURES_PARENT_PATH}/${CONFIRM_DISCLOSURES}`
  },
  {
    path: 'UnlockAccount/:unlockToken',
    redirectTo: `${AUTH_PARENT_ROUTE}/${AUTH_ROUTES.UNLOCKACCOUNT}`
  },
  //#endregion
  //#region Lazy Loading
  {
    path: LANDING_PARENT_PATH,
    loadChildren: () => import('app/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: AUTH_PARENT_ROUTE,
    loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: PROXY_PARENT_PATH,
    loadChildren: () => import('app/proxy/proxy.module').then(m => m.ProxyModule)
  },
  //#endregion
];

export const SECURE_APP_ROUTES: Routes = [
  //#region Lazy Loading
  {
    path: PRODUCTS_PARENT_PATH,
    loadChildren: () => import('app/products/products.module').then(m => m.ProductsModule)
  },
  {
    path: FEATURES_PARENT_PATH,
    loadChildren: () => import('app/trading-features/trading-features.module').then(m => m.TradingFeaturesModule)
  },
  {
    path: APPLICANT_INFORMATION_PARENT_PATH,
    loadChildren: () => import('app/applicant-information/applicant-information.module').then(m => m.ApplicantInformationModule)
  },
  {
    path: MANAGE_PORTFOLIO_PARENT_PATH,
    loadChildren: () => import('app/manage-portfolio/manage-portfolio.module').then(m=>m.ManagePortfolioModule)
  },
  {
    path: DISCLOSURES_PARENT_PATH,
    loadChildren: () => import('app/disclosures/disclosures.module').then(m => m.DisclosuresModule)
  },
  {
    path: OUTCOME_PARENT_ROUTE,
    loadChildren: () => import('app/outcome/outcome.module').then(m => m.OutcomeModule)
  },
  {
    path: DOCUMENTS_PARENT_ROUTE,
    loadChildren: () => import('app/documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path: RULES_PARENT_ROUTE,
    loadChildren: () => import('app/rules/rules.module').then(m => m.RulesModule)
  },
  {
    path: REVIEWSUBMIT_PARENT_ROUTE,
    loadChildren: () => import('app/review-submit/review-submit.module').then(m => m.ReviewSubmitModule)
  },
  //#endregion
];
