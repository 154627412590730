// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import {  GetSecurityQuestionsAction, GetUserSuccessAction } from 'store/actions';
import {  getSecurityQuestions } from 'store/selectors';


@Injectable()
export class SequrityQuestionsResolver implements Resolve<Observable<String[]>> {

    constructor(private store$: Store<EnrollmentState>) {  }

     resolve() {
        return this.store$.select(getSecurityQuestions).pipe(
            tap((securityquestions) => {
                if(securityquestions===null) {
                    this.store$.dispatch(new GetSecurityQuestionsAction(false));
                } else {
                    this.store$.dispatch(new GetUserSuccessAction(null));
                }
            }),
            filter(securityquestions => securityquestions!=null),
            first()
        );
    }
}
