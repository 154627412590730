import { Component, Input } from '@angular/core';
import { EnrollmentNavigationComponent } from '../abstracts';
import { Address } from 'models';
import { ZipCode } from 'app/enrollment-common/pipes/zipcode';

@Component({
    selector:'app-address-selection',
    templateUrl:'./address-selection.component.html',
    styleUrls:['./address-selection.component.scss'],
    providers: [ZipCode]
})

export class AddressSelectionComponent extends EnrollmentNavigationComponent {
    @Input() address:Address    
    constructor(){ super(); }
    onBack(){ this.onNavigationChanged.emit({goBack:true}); }    
    onContinue(value: string) { this.onNavigationChanged.emit({ goBack: false, value }); }
}

