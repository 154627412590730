import { Validators, FormControl } from '@angular/forms';

export const ADDRESS_DEFAULT_VALIDATORS = {
    Street: [
        Validators.required,
        TrimValidator,
        Validators.minLength(4),
        Validators.maxLength(30),
        ValidateOnlyNumbers,
        ValidateSpecialCharacters
    ],
    Street2: [
        ValidateSpecialCharacters,
        Validators.maxLength(30),
    ],
    Zip: [
        Validators.required,
        validateZipRange,
        Validators.pattern(/^[0-9-]*$/)
    ],
    City: [Validators.required],
    State: [Validators.required]
}

export const INVEST_ADDRESS_DEFAULT_VALIDATORS = {
    Street: [
        Validators.required,
        TrimValidator,
        ValidateOnlyNumbers,
        Validators.minLength(4),
        Validators.maxLength(30),
        InvestValidateSpecialCharacters
    ],
    Street2: [
        Validators.maxLength(30),
        InvestValidateSpecialCharacters
    ],
    Zip: [
        Validators.required,
        validateZipRange,
        Validators.pattern(/^[0-9-]*$/)
    ],
    City: [
        Validators.required,
        InvestValidateCityCharacters
    ],
    State: [Validators.required]
}

export function ValidateSpecialCharacters(c: FormControl) {
    const regex = /^[a-zA-Z0-9#\/.\-,\s]*$/;
    return (regex.test(c.value) || c.value == '') ? null : {
        validateSpecialCharacters: {
            valid: false
        }
    };
}

export function InvestValidateSpecialCharacters(c: FormControl) {
    const regex = /^[a-zA-Z0-9#\/\-\s]*$/;
    return (regex.test(c.value)) ? null : {
        validateSpecialCharacters: {
            valid: false
        }
    };
}

export function InvestValidateCityCharacters(c: FormControl) {
    const regex = /^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/;
    return (regex.test(c.value)) ? null : {
        validateSpecialCharacters: {
            valid: false
        }
    };
}

export function ValidateOnlyNumbers(c: FormControl) {
    let regex = /[\d]/;
    return (regex.test(c.value)) ? null : {
        validateOnlyNumbers: {
            valid: false
        }
    };
}


export function validateZipRange(c:FormControl){
    if(c && c.value){
        const checkLength = c.value.length;
        return (checkLength >= 6 && checkLength < 10) ?  {validateZipRange:{valid:false}}: null;
    }
}

export function TrimValidator(c: FormControl) {
    const value = c.value;
    if(value && typeof value == 'string') {
        return value.trim() === value ? null : {
            validateTrim : {
                valid: false
            }
        }
    }
}