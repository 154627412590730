export enum MITEK_DOCUMENT_TYPE {
  IdFront = 'DL_FRONT',
  IdBack = 'DL_BACK',
  Document = 'Document',
  Check = 'Check',
  Passport = 'Passport',
  Other = 'Other'
}
export enum MITEK_RESULT_STATUS {
  Success = 'success'
}
