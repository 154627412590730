import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';

@Injectable()
export class ImageProcessingService {
  private minimumImageSize: number = 450;
  private maximumDefaultImageSize: number = 1920;
  private defaultEncodingQuality: number = 70;
  private defaultMimeType: string = 'image/jpeg';

  constructor() { }

  public compressImageFile(sourceFile: File, maxSize: number = this.maximumDefaultImageSize, minSize: number = this.minimumImageSize, encodingQuality: number = this.defaultEncodingQuality): Observable<File> {
      let compressedFile$ = from(this.getCompressedFile(sourceFile, maxSize, minSize, encodingQuality));
      return compressedFile$;
  }

  private getCompressedFile(sourceFile, maxSize: number, minSize: number, encodingQuality: number): Promise<File> {
    return new Promise((resolve, reject) => {
      let __this = this;
      let windowURL = window.URL || (window as any).webkitURL;
      let img = new Image();
      let canvas = document.createElement("canvas");
      let canvasContext = canvas.getContext("2d");
      if (windowURL) {
        let dataUrl = windowURL.createObjectURL(sourceFile);

        img.addEventListener('load', () => {
          windowURL.revokeObjectURL(dataUrl);

          let resizeResult = __this.resizeImageCanvas(canvas, img, maxSize, minSize);
          if (resizeResult == false) {
            reject(new Error(`Image too small; should be minimum ${minSize} pixels in height or width.`));
          }
          else {
            canvasContext.drawImage(img, 0, 0, canvas.width, canvas.height);
            let dataUrl = canvas.toDataURL(__this.defaultMimeType, (encodingQuality / 100));
            this.srcToFile(dataUrl, sourceFile.name, __this.defaultMimeType)
              .then((compressedFile: File) => {
                resolve(compressedFile);
              });

            //#region Research Notes
            /*
            1. toBlob not supported on all browsers
            canvas.toBlob((blob) => {
              compressedFile = blob;
            }, "image/jpeg", (encodingQuality / 100));

            2. we can get imageData as well:
            let newImageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
            */
            //#endregion
          }
        });

        img.addEventListener('error', () => {
          //reject(new Error(`Failed to load image's URL`));
          resolve(sourceFile);
        });

        img.src = dataUrl;
      }
      else {
        resolve(sourceFile);
      }
    });
  }

  private resizeImageCanvas(canvas: HTMLCanvasElement, img: HTMLImageElement, maxDimension: number, minDimension: number) {
    let status = true;
    let imgMaxDimension = Math.max(img.width, img.height);

    if (typeof minDimension != "undefined" && minDimension > imgMaxDimension)
      return false;

    if (imgMaxDimension > maxDimension) {
      if (img.width > img.height) {
        let aspectRatio = img.width / img.height;
        canvas.width = maxDimension;
        canvas.height = canvas.width / aspectRatio;
      }
      else {
        let aspectRatio = img.height / img.width;
        canvas.height = maxDimension;
        canvas.width = canvas.height / aspectRatio;
      }
      console.log("Image exceeds " + maxDimension + "px | Resizing image to: " + canvas.width + " x " + canvas.height);
    }
    else { canvas.width = img.width; canvas.height = img.height; }

    return status;
  }

  private srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
    );
  }
}
