import { Injectable } from '@angular/core';
// Models
import { Address, RecommendedAddress } from 'models';
import { BaseService } from './abstracts';
import { of, Observable } from 'rxjs';
import { AxosResult } from 'models/enums/trading/axos-result.model';
import { map } from 'rxjs/operators';

@Injectable()
export class AddressService extends BaseService {
  updateApplicantAddress(applicantId: string, address: Address, skipHistory: boolean = false) {
    return this._postWithParams<AxosResult<Address>>(`applicantdetails/${applicantId}/address/`, {skipHistory}, address).pipe(map(res => res.data));
    //return of(address);
  }

  calculateMonthsAtAddress(homeAddressInfo: Address) {
    let monthsAtAddress = 0;

    const numberOfYearsAtAddress = homeAddressInfo.NumberOfYearsAtAddress;
    const numberOfMonthsAtAddress = homeAddressInfo.NumberOfMonthsAtAddress;

    if (numberOfYearsAtAddress != null && !isNaN(numberOfYearsAtAddress)) {
        monthsAtAddress = monthsAtAddress + (numberOfYearsAtAddress * 12);
    }

    if (numberOfMonthsAtAddress != null && !isNaN(numberOfMonthsAtAddress)) {
        monthsAtAddress = monthsAtAddress + numberOfMonthsAtAddress;
    }
    return monthsAtAddress;
  }

  getAddressInfoes(applicantId: string): Observable<any>{
    return this._get<AxosResult<Address[]>>(`applicant/${applicantId}`).pipe(
      map((res) => res.data)
    );
  }

  getRecommendedAddress(address: RecommendedAddress) {
    return this._post<RecommendedAddress>(`generic/address/validate`, address)
  }

  private yearsAtAddress(months) { return Math.floor(months / 12); }
  private monthsAtAddress(months) { return months % 12; }
}
