import { Directive, ElementRef, OnInit, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({ selector: '[FileGroup]' })
export class FileGroupDirective implements OnInit {

    input: HTMLInputElement;

    @Output()onFileChange: EventEmitter<FileList> = new EventEmitter();

    @HostListener('change', ['$event']) onChange(event) {
        this.onFileChange.emit(this.input.files);
    }

    constructor(private element: ElementRef) { }

    ngOnInit() { this.input = this.element.nativeElement; }
}
