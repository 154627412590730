import { createSelector } from '@ngrx/store';

import { Applicant } from 'models';
import { ADDRESS_TYPE_NAME } from 'models/enums';
// Selectors
import { getApplicants, getCurrentApplicant, getPrimaryApplicant } from './applicants.selectors';
// Core
import { GET_ADDRESS_BY_TYPE, GET_RECOMMEND_ADDRESS_BY_TYPE } from 'app/core/utils';

export const getCurrentApplicantAddress = (addressType: ADDRESS_TYPE_NAME) => {
  return createSelector(
    getCurrentApplicant,
    (applicant: Applicant) => GET_ADDRESS_BY_TYPE(applicant, addressType)
  );
}

export const getRecommendAddress = (addressType: ADDRESS_TYPE_NAME) => {
  return createSelector(
    getCurrentApplicant,
    (applicant: Applicant) => GET_ADDRESS_BY_TYPE(applicant, addressType)
  );
}

export const selectAddressInfoes = createSelector(
  getCurrentApplicant,
  (applicant: Applicant) => applicant.addresses
)
export const selectRecommendAddressInfoes = createSelector(
  getCurrentApplicant,
  (applicant: Applicant) => applicant.recommend
)

export const getCurrentApplicantHomeAddress = createSelector(
  getCurrentApplicant,
  (applicant: Applicant) => applicant.HomeAddressInfo
);

export const getCurrentApplicantMailingAddress = createSelector(
  getCurrentApplicant,
  (applicant: Applicant) => applicant.MailingAddressInfo
);

export const getCurrentApplicantIDAddress = createSelector(
  getCurrentApplicant,
  (applicant: Applicant) => applicant.IdentificationAddressInfo
);

export const getPrimaryApplicantHomeAddress = createSelector(
  getPrimaryApplicant,
  (applicant: Applicant) => applicant.HomeAddressInfo
);

export const getPrimaryApplicantMailingAddress = createSelector(
  getPrimaryApplicant,
  (applicant: Applicant) => applicant.MailingAddressInfo
);

export const getPrimaryApplicantIDAddress = createSelector(
  getPrimaryApplicant,
  (applicant: Applicant) => applicant.IdentificationAddressInfo
);
