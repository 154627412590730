import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable()
export class PdfService {

  public createPDF(data: HTMLElement):void { 
    html2canvas(data).then(canvas => {   
        let fileWidth = 208;
        var pageHeight = 295;
        let fileHeight = canvas.height * fileWidth / canvas.width;     
        const FILEURI = canvas.toDataURL('image/png')
        let PDF = new jsPDF('p', 'mm', 'a4', true);
        let position = 0;
        let heightLeft = fileHeight - pageHeight;

        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight, 'FAST');        

        while(heightLeft >= 0){
          position = heightLeft - fileHeight;
          PDF.addPage();
          PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight, 'FAST');
          heightLeft -= pageHeight;
        }
        var date = new Date();
        PDF.save(`application_${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}.pdf`);
    });     
  }
}
