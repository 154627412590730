export const MANAGE_PORTFOLIO_PARENT_PATH = 'manage-portfolio';

export const MANAGE_PORTFOLIO_ROUTES: { [key: string] : string } = {
    INVESTMENT_GOALS: 'investment-goals',
    LARGE_PURCHASE: 'large-purchase',
    RETIREMENT_ACCOUNT_TYPE: 'retirement-account-type',
    TIME_HORIZON: 'time-horizon',
    RECOMMENDED: 'recommended',
    INVESTING_HISTORY: 'investing-history',
    RISK_QUESTIONS: 'risk-questions'
}
