import { DECLINED_TRUST, IS_VALIDATED, PHONE_NUMBER, PROXY_APPLICATION_ID, TOKEN_STORAGE_NAME, UTM_STORAGE_NAME } from "app/auth/constants";

export function GET_CACHE(storage_name: string) {
    let store = localStorage.getItem(storage_name);
    if(!store) { store = sessionStorage.getItem(storage_name); }
    return store ? JSON.parse(store): null;
}

export function SET_CACHE(value: any, storage_name: string, temp = false) {
    if(value) {
        const valueString = JSON.stringify(value);
        temp ? sessionStorage.setItem(storage_name, valueString) : localStorage.setItem(storage_name, valueString);
    } else {
        temp ? sessionStorage.removeItem(storage_name) : localStorage.removeItem(storage_name);
    }
}

export function CLEAR_CACHE(){
    localStorage.removeItem(UTM_STORAGE_NAME);
    localStorage.removeItem(TOKEN_STORAGE_NAME);
    sessionStorage.removeItem(UTM_STORAGE_NAME);
    sessionStorage.removeItem(TOKEN_STORAGE_NAME);
    localStorage.removeItem(DECLINED_TRUST);
    localStorage.removeItem(PROXY_APPLICATION_ID);
    localStorage.removeItem(PHONE_NUMBER);
    localStorage.removeItem(IS_VALIDATED);
}