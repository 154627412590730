import { Component } from '@angular/core';
// NgRx Store
import { Store } from '@ngrx/store';
import { EnrollmentState } from 'store';
import { getBrandIsUFB } from 'store/selectors';

@Component({
  selector: 'axos-sms-popup',
  templateUrl: './sms-popup.component.html',
  styleUrls: ['./sms-popup.component.scss']
})
export class SmsPopupComponent {

  private pdfUrl: string = 'assets/disclosures/pdf/smsandmms.pdf';

  constructor(private store$: Store<EnrollmentState>) { }

  isUFB$ = this.store$.select(getBrandIsUFB);

  printTerms(): any {
    window.open(this.pdfUrl);
  }
}

