import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
// RxJs
import { map } from 'rxjs/operators';
// Enrollment Common
import { BusinessBaseGuard } from './base.guard';


@Injectable()
export class BusinessMailingAddresstGuard extends BusinessBaseGuard implements CanActivate {
    canActivate() {
        return this.getData().pipe(
            map(value => {
                const _activate = !value.business.isMailingAddressSameAsHomeAddress;
                this.shouldNavigate(_activate);
                return _activate;
            })
        )
    }
}
