declare var environment: any;
declare var GLANCE: any;

import { AfterViewChecked, Component, ElementRef, Input, OnInit } from '@angular/core';
import { BRAND_ID } from 'models/enums';
// UK Modal
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UkModalService } from '@uikit/components/uk-modal';
import { FooterModalsPopupComponent } from 'app/modals/components';
import { of } from 'rxjs';
import { AppSettingsService } from 'services/app-settings.service';
import { EnrollmentState } from 'store';
import { getSubBanner } from 'store/selectors';

@Component({
    selector: 'axos-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    host: {
        class: 'axos-container'
    }
})
export class FooterComponent implements OnInit, AfterViewChecked {
    isAxosUniversal: boolean = !!environment.isAxosUniversal;
    today: number = Date.now();
    wlFooter: any;
    showWLFooter = false;
    isProxy = environment.isProxy;
    subBanner$ = !this.isProxy ? this.store$.select(getSubBanner) : of(null);
    isAccountFeaturePage = false;

    @Input() showPatriot: boolean;

    constructor(
        private modalService: UkModalService,
        private settingsService: AppSettingsService,
        private elementRef: ElementRef,
        private store$: Store<EnrollmentState>,
        private router: Router
    ) {
        this.showPatriot = true;
    }

    ngAfterViewChecked(): void {
        const elements : any[] = this.elementRef.nativeElement.querySelectorAll('#wlFooter a');
        elements.forEach(element => {
            element.addEventListener('click', (event) => {
                event.preventDefault();
                this.footerModalsPopup(element.href);
            });
        });
    }

    ngOnInit(): void {
        this.showWLFooter = (environment.applicationTypeName.toUpperCase().includes('WHITELABEL') || environment.applicationTypeName.toUpperCase().includes('PROXY'));
        if(this.showWLFooter){
            this.settingsService.getFooter().subscribe(footer => this.wlFooter = footer);
        }

        this.router.events.subscribe((route) => {
            if (route instanceof NavigationEnd) {
                this.isAccountFeaturePage = route.urlAfterRedirects === '/features';
            }
        })
    }

    startGlanceSession() {
        GLANCE.Cobrowse.Visitor.showTerms({
            sessionKey: "GLANCE_KEYTYPE_RANDOM"
        });
    }

    get isNationwide() {
        return environment.brandId === BRAND_ID.NationWide ||
            environment.brandId === BRAND_ID.NationWideBusinessBanking;
    }

    isUFB() {
        return /UFB/.test(environment.brandName.toUpperCase());
    }

    footerModalsPopup(url: string) {
        let modal: FooterModalsPopupComponent = this.modalService.createModal(null, true, FooterModalsPopupComponent);
        modal.redirectUrl = url;
    }
}
