import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { EnrollmentState, GetAddressInfoesAction } from "store";
import { Store } from "@ngrx/store";
import { first, map, filter } from "rxjs/operators";
import { getCurrentApplicant } from 'store/selectors';
import { Address } from "models";
import { element } from "angular";

@Injectable()
export class AddressResolver implements Resolve<Observable<Address[]>>{
    constructor(private store$: Store<EnrollmentState>){ }

    resolve(){
        return this.store$.select(getCurrentApplicant).pipe(
            map(applicant => {
                if (applicant) {
                    applicant.addresses.forEach((element)=>{
                        if(element.zipPlus4 && element.zipPlus4 !=''){
                            if(!element.zip.includes('-')){
                                element.zip = `${element.zip}-${element.zipPlus4}`;
                            }
                        }
                    });
                    const addressInfoes = applicant.addresses;
                    const applicantId = applicant.applicantId;
                    if(!addressInfoes){
                        this.store$.dispatch(new GetAddressInfoesAction(applicantId));
                    }
                    return addressInfoes;
                } else {
                    return null;
                }
            }),
            filter(addressInfoes => !!addressInfoes),
            first()
        );
    }
}