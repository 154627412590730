import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
// Material
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
// UI Kit
import { UIKitCommonModule } from '@uikit/components/common';
import { UIKitIconsModule } from '@uikit/components/uk-icons';
import { UkFormControlsModule } from '@uikit/components/uk-form-controls';
import { UkDropdownModule } from '@uikit/components/uk-dropdown';
import { UkRadioModule } from '@uikit/components/uk-radio';

// Enrollment Modules
import { SharedFormsModule } from 'app/shared-forms/shared-forms.module';
import {
  IdentificationUploadService
} from './services'

import {
  NoteComponent,
  AddressComponent,
  IdSelectionComponent,
  AddressSelectionComponent,
  ApplicantTitleComponent,
  IdentificationUploadComponent,
  UploadFileComponent,
  ErrorComponent,
  AddressUniversalComponent,
  AddressSelectionUniversalComponent,
  SelectorListComponent,
  TooltipComponent,
} from './components';
import { FileGroupDirective } from './directives';
import { SafeHtml } from './pipes';
import { MillionPipe } from 'app/enrollment-common/pipes/million.pipe';
import { ZipCode } from './pipes/zipcode';
import { SSN } from './pipes/ssn.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { ItinDirective } from './directives/itin.directive';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { UIKitTooltipModule } from '@uikit/components';
import { ErrorDirective } from './directives/error.directive';
import { HintDirective } from './directives/hint.directive';
import { LabelDirective } from './directives/label.directive';
import { PrefixDirective } from './directives/prefix.directive';
import { SuffixDirective } from './directives/suffix.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // UI Kit
    UIKitCommonModule,
    UkFormControlsModule,
    UkDropdownModule,
    UkRadioModule,
    UIKitIconsModule,
    UIKitTooltipModule,
    // Material
    MatTooltipModule,
    MatDialogModule,
    // Enrollment Modules
    SharedFormsModule,
    PdfViewerModule
  ],
  declarations: [
    NoteComponent,
    AddressComponent,
    IdSelectionComponent,
    AddressSelectionComponent,
    ApplicantTitleComponent,
    UploadFileComponent,
    IdentificationUploadComponent,
    FileGroupDirective,
    SafeHtml,
    MillionPipe,
    ErrorComponent,
    ZipCode,
    SSN,
    PhonePipe,
    AddressUniversalComponent,
    AddressSelectionUniversalComponent,
    SelectorListComponent,
    TooltipComponent,
    FormFieldComponent,
    ItinDirective,
    ErrorDirective,
    HintDirective,
    LabelDirective,
    PrefixDirective,
    SuffixDirective,
  ],
  exports: [
    NoteComponent,
    AddressComponent,
    IdSelectionComponent,
    AddressSelectionComponent,
    ApplicantTitleComponent,
    UploadFileComponent,
    IdentificationUploadComponent,
    SafeHtml,
    ErrorComponent,
    MillionPipe,
    ZipCode,
    SSN,
    PhonePipe,
    AddressUniversalComponent,
    AddressSelectionUniversalComponent,
    SelectorListComponent,
    TooltipComponent,
    FormFieldComponent,
    ItinDirective,
    ErrorDirective,
    HintDirective,
    LabelDirective,
    PrefixDirective,
    SuffixDirective
  ],
  providers: [
    IdentificationUploadService
  ]
})
export class EnrollmentCommonModule { }
