import { Injectable } from '@angular/core';

import { BaseService } from 'services';
import { DocumentRequest } from '../models';
import { AxosResult } from 'models/enums/trading';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentRequestService extends BaseService {
    getRequestedDocuments(applicationId: any): Observable<DocumentRequest[]> {
        return this._get<AxosResult<DocumentRequest[]>>(`applicantdocument/requested/${applicationId}`).pipe(map(res => {
            return res.data;
        }));
    }
}
