// Ng
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Guard
import { AuthGuard } from 'app/auth/guards';
import { MarketingProductsGuard } from 'app/core/guards';
// Resolvers
import { BrandResolver, UserResolver, ChatResolver, OrganizationResolver, BrokerResolver, ChatBotResolver, FeatureFlagsResolver } from 'app/core/resolvers';
import { APP_CONSUMER_SECURE_ROUTES, PUBLIC_APP_ROUTES } from './constants';
import { LANDING_PARENT_PATH_OLD, LANDING_PARENT_PATH } from 'app/landing/routing';

const routes: Routes = [
  {
    path: '',
    resolve: {
      brand: BrandResolver,
      broker: BrokerResolver,
      //chatSettings: ChatResolver
      chatBotSetting: ChatBotResolver,
      featureFlags: FeatureFlagsResolver
    },
    //canActivate: [ MarketingProductsGuard ],
    children: [
      ...PUBLIC_APP_ROUTES,
      ...APP_CONSUMER_SECURE_ROUTES,
      // PRESERVING OLD PATH AND REDIRECT TO AVOID BREAKING CHANGES TO SITECORE
      {
        path: LANDING_PARENT_PATH_OLD,
        pathMatch: 'full',
        redirectTo: LANDING_PARENT_PATH
      }
    ]
  },
  {
    path: ':orgName',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    BrandResolver,
    ChatResolver,
    ChatBotResolver,
    UserResolver,
    AuthGuard,
    MarketingProductsGuard,
    OrganizationResolver,
    BrokerResolver,
    FeatureFlagsResolver
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
