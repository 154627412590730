import { Injectable } from '@angular/core';
// Services
import { BaseService } from './abstracts';
// Models
import { ISimpleProduct } from 'models';
import { AxosResult } from 'models/enums/trading';
import { of, Observable } from 'rxjs';

import { map } from "rxjs/operators";

@Injectable()
export class ProductSelectionService extends BaseService {

    getProductsByCode(productCodes: string[]) {
      return this._get<AxosResult<ISimpleProduct[]>>('products', { productCodes }).pipe(map(res => res.data));
    }

    getProductsFromApplication() {
      return this._get<AxosResult<ISimpleProduct[]>>(`products/selected`).pipe(map(res => res.data));
    }
    getProductsByUserIdentityGuId(userIdentityId: string) {
      return this._get<AxosResult<string[]>>('products/UserProduct/'+userIdentityId).pipe(map(res => res.data));
    }
}
