import { Action } from '@ngrx/store';
// Models
import { Account, ApplicationStatus, IApplication, Applicant, IApplicantMetadata, BeneficiariesInformation, Beneficiary, TrustCertificate, TrustTypes } from 'models';
import { APPLICATION_STATE, SOURCE_TYPES, BENEFICIARY_TYPE } from 'models/enums';

export enum APPLICATION_ACTION_TYPES {
    CREATE_APPLICATION = '[Application] Create',
    CREATE_APPLICATION_FAIL = '[Application] Create fail',
    CREATE_APPLICATION_SUCCESS = '[Application] Create success',

    LOAD_APPLICATION = '[Application] Load',
    LOAD_APPLICATION_FAIL = '[Application] Load fail',
    LOAD_APPLICATION_SUCCESS = '[Application] Load success',

    SET_APPLICATION_APPLICANTS = '[Application] Set application applicants',

    LOAD_APPLICATION_BY_TOKEN = '[Application] Load by token',
    LOAD_APPLICATION_BY_TOKEN_FAIL = '[Application] Load by token fail',
    LOAD_APPLICATION_BY_TOKEN_SUCCESS = '[Application] Load by token success',

    LOAD_ACCOUNTS = '[Application] Load Accounts',
    LOAD_ACCOUNTS_FAIL = '[Application] Load Accounts fail',
    LOAD_ACCOUNTS_SUCCESS = '[Application] Load Accounts success',

    SET_STATE = '[Application] Set State',
    SET_STATE_FAIL = '[Application] Set State fail',
    SET_STATE_SUCCESS = '[Application] Set State success',

    CANCEL_APPLICATION = '[Application] Cancel',
    CANCEL_APPLICATION_FAIL = '[Application] Cancel fail',
    CANCEL_APPLICATION_SUCCESS = '[Application] Cancel success',

    UPDATE_PROMO_CODE = '[Application] Update Promo Code',
    UPDATE_PROMO_CODE_FAIL = '[Application] Update Promo Code fail',
    UPDATE_PROMO_CODE_SUCCESS = '[Application] Update Promo Code success',

    CORRECT_ROUTE = '[Application] Correct Route',

    CHECK_INFLIGHT_APPLICATION = '[Application] Check in-flight application',
    CHECK_INFLIGHT_APPLICATION_REDIRECT = '[Application] Check in-flight application redirect',

    UPDATE_LOCAL_APPLICATION_STATE = '[Application] Update local application state',

    GET_CONFIRMATION_NUMBER = '[Application] Get confirmation number',
    GET_CONFIRMATION_NUMBER_SUCCESS = '[Application] Get confirmation number success',
    GET_CONFIRMATION_NUMBER_FAIL = '[Application] Get confirmation number fail',

    SET_APPLICANTS_META = '[Application] Set Applicants meta',
    SET_VESTING_TYPE_SUCCESS = '[Application] Set vesting type success',

    SAVE_BENEFICIARIES = '[Applicant] Save beneficiaries',
    SAVE_BENEFICIARIES_SUCCESS = '[Applicant] Save beneficiaries success',
    SAVE_BENEFICIARIES_FAIL = '[Applicant] Save beneficiaries fail',

    DECLINED_BENEFICIARIES = '[Applicant] Declined beneficiaries',

    SAVE_BENEFICIARIES_CONTINGENT_SUCCESS = '[Applicant] Save beneficiaries contingent success',
    SAVE_BENEFICIARIES_CONTINGENT_FAIL = '[Applicant] Save beneficiaries contingent fail',

    SKIP_BENEFICIARIES = '[Applicant] Skip beneficiaries',
    SKIP_BENEFICIARIES_SUCCESS = '[Applicant] Skip beneficiaries success',
    SKIP_BENEFICIARIES_FAIL = '[Applicant] Skip beneficiaries fail',

    SKIP_BENEFICIARIES_CONTINGENT_SUCCESS = '[Applicant] Skip beneficiaries contingent success',

    GET_BENEFICIARIES_INFORMATION = '[Application] Get beneficiaries information',
    GET_BENEFICIARIES_INFORMATION_SUCCESS = '[Application] Get beneficiaries information success',
    GET_BENEFICIARIES_INFORMATION_FAIL = '[Application] Get beneficiaries information fail',

    GET_BENEFICIARIES_CONTINGENT_INFORMATION = '[Application] Get beneficiaries contingent information',
    GET_BENEFICIARIES_CONTINGENT_INFORMATION_SUCCESS = '[Application] Get beneficiaries contingent information success',
    GET_BENEFICIARIES_CONTINGENT_INFORMATION_FAIL = '[Application] Get beneficiaries contingent information fail',

    SAVE_TRUST_CERTIFICATE = '[Application] Save trust certificate',
    SAVE_TRUST_CERTIFICATE_SUCCESS = '[Application] Save trust certificate success',

    GET_TRUST_CERTIFICATE_INFORMATION = '[Application] Get trust certificate information',
    GET_TRUST_CERTIFICATE_INFORMATION_SUCCESS = '[Application] Get trust certificate information success',
    GET_TRUST_CERTIFICATE_INFORMATION_FAIL = '[Application] Get trust certirficate information fail',

    REDIRECTO_TO_GENERAL_ERROR_PAGE = '[Application] Redirecto to general error page',

    GET_TRUST_TYPES = '[Application] Get trust types',
    GET_TRUST_TYPES_SUCCESS = '[Application] Get trust types success',
    GET_TRUST_TYPES_FAIL = '[Application] Get trust types fail',

    GET_SELECTE_TRUST_TYPE = '[Application] Get selected trust type',
    GET_SELECTE_TRUST_TYPE_SUCCESS = '[Application] Get selected trust type success',
    GET_SELECTE_TRUST_TYPE_FAIL = '[Application] Get selected trust type fail',

    CLEAR_STATE = '[Application] Clear State'
}

export class SetApplicationStateAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SET_STATE;
    constructor(public state: APPLICATION_STATE) { }
}

export class SetApplicationStateSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SET_STATE_SUCCESS;
    constructor(public state: APPLICATION_STATE) { }
}

export class LoadAccountsAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.LOAD_ACCOUNTS;
    constructor() { }
}

export class LoadAccountsSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.LOAD_ACCOUNTS_SUCCESS;
    constructor(public accounts: Account[]) { }
}

export class LoadAccountsFailAction implements Action {
  readonly type = APPLICATION_ACTION_TYPES.LOAD_ACCOUNTS_FAIL;
}

export class LoadApplicationAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.LOAD_APPLICATION;
    constructor(public applicationId?: string) { }
}

export class LoadApplicationSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.LOAD_APPLICATION_SUCCESS;
    constructor(public application: IApplication) { }
}

export class SetApplicationApplicants implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SET_APPLICATION_APPLICANTS;
    constructor(public applicants: Applicant[]) { }
}
export class LoadApplicationByTokenAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.LOAD_APPLICATION_BY_TOKEN;
    constructor(public createNew: boolean = false, public sso?: boolean) { }
}

export class LoadApplicationByTokenSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.LOAD_APPLICATION_BY_TOKEN_SUCCESS;
    constructor(public application: IApplication) { }
}

export class CreateApplicationAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.CREATE_APPLICATION;
    constructor(public sourceTypeId: SOURCE_TYPES, public textMessageAllowed: boolean = false, public shareInfo?: boolean) { }
}

export class CreateApplicationSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.CREATE_APPLICATION_SUCCESS;
    constructor() { }
}

export class CreateApplicationFailAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.CREATE_APPLICATION_FAIL;
    constructor(error: string) { }
}

export class UpdatePromocodeAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.UPDATE_PROMO_CODE;
    constructor(public promoCode: string) { }
}

export class UpdatePromoCodeSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.UPDATE_PROMO_CODE_SUCCESS;
    constructor(public promoCode: string) { }
}

export class CancelApplicationAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.CANCEL_APPLICATION;

}

export class CancelApplicationSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.CANCEL_APPLICATION_SUCCESS;

}

export class CancelApplicationFailAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.CANCEL_APPLICATION_FAIL;

}

export class CheckInFlightApplicationAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.CHECK_INFLIGHT_APPLICATION;
}

export class CheckInFlightApplicationRedirectAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.CHECK_INFLIGHT_APPLICATION_REDIRECT;
    constructor(public path: string) { }
}

export class CorrectApplicationRouteAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.CORRECT_ROUTE;
}

export class UpdateLocalApplicationState implements Action {
    readonly type = APPLICATION_ACTION_TYPES.UPDATE_LOCAL_APPLICATION_STATE;
    constructor(public state: ApplicationStatus) { }
}

export class GetConfirmationNumberAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_CONFIRMATION_NUMBER;
}

export class GetConfirmationNumberSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_CONFIRMATION_NUMBER_SUCCESS;
    constructor(public confirmationNumber: string) { }
}

export class SetVestingTypeSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SET_VESTING_TYPE_SUCCESS;
    constructor(public vestingTypeName: string, public applicants: IApplicantMetadata[]) { }
}

export class SetApplicantsMeta implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SET_APPLICANTS_META;
    constructor(public applicants: IApplicantMetadata[]) { }
}

export class SaveBeneficiariesAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SAVE_BENEFICIARIES;
    constructor(public beneficiariesInformation: Beneficiary[],
        public beneficiariesType: BENEFICIARY_TYPE,
        public stepsToSkip: any = null,
        public skipHistory: boolean = false) {}
}

export class SaveBeneficiariesSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SAVE_BENEFICIARIES_SUCCESS;
    constructor(public beneficiariesInformation: Beneficiary[]) {}
}

export class SaveBeneficiariesFailAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SAVE_BENEFICIARIES_FAIL;
    constructor() {}
}

export class SaveBeneficiariesContingentSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SAVE_BENEFICIARIES_CONTINGENT_SUCCESS;
    constructor(public beneficiariesInformation: Beneficiary[]) {}
}

export class SaveBeneficiariesContingentFailAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SAVE_BENEFICIARIES_CONTINGENT_FAIL;
    constructor() {}
}

export class SkipBeneficiariesAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SKIP_BENEFICIARIES;
    constructor(
        public beneficiariesInformation: Beneficiary[],
        public beneficiariesType: BENEFICIARY_TYPE,
        public stepsToSkip: any = null,
    ) {}
}

export class SkipBeneficiariesSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SKIP_BENEFICIARIES_SUCCESS;
    constructor(public beneficiariesInformation: Beneficiary[]) {}
}

export class SkipBeneficiariesFailAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SKIP_BENEFICIARIES_FAIL;
    constructor() {}
}

export class SkipBeneficiariesContingentSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SKIP_BENEFICIARIES_CONTINGENT_SUCCESS;
    constructor(public beneficiariesInformation: Beneficiary[]) {}
}

export class DeclinedBeneficiaries implements Action {
    readonly type = APPLICATION_ACTION_TYPES.DECLINED_BENEFICIARIES;
    constructor(
        public declinedBeneficiaries: boolean,
        public isProxy: boolean,
        public beneficiariesInformation: Beneficiary[],
        public beneficiariesType: BENEFICIARY_TYPE,
        public stepsToSkip: any = null,
    ) {}
}

export class GetBeneficiariesInformationAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_BENEFICIARIES_INFORMATION;
    constructor(public typeName: string) {}
}

export class GetBeneficiariesInformationSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_BENEFICIARIES_INFORMATION_SUCCESS;
    constructor(public beneficiaries: Beneficiary[]) { }
}

export class GetBeneficiariesInformationFailAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_BENEFICIARIES_INFORMATION_FAIL;
    constructor() {}
}

export class GetBeneficiariesContingentInformationAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_BENEFICIARIES_CONTINGENT_INFORMATION;
    constructor() {}
}

export class GetBeneficiariesContingentInformationSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_BENEFICIARIES_CONTINGENT_INFORMATION_SUCCESS;
    constructor(public beneficiaries: Beneficiary[]) { }
}

export class GetBeneficiariesContingentInformationFailAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_BENEFICIARIES_CONTINGENT_INFORMATION_FAIL;
    constructor() {}
}

export class SaveTrustCertificateAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SAVE_TRUST_CERTIFICATE;
    constructor(public applicationId: string, public certificate: TrustCertificate, public shouldNavigate = true, public skipHistory: boolean) { }
}

export class SaveTrustCertificateSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.SAVE_TRUST_CERTIFICATE_SUCCESS;
    constructor(public certificate: TrustCertificate, public shouldNavigate = true, public skipHistory: boolean) { }
}

export class GetTrustCertificateInformationAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_TRUST_CERTIFICATE_INFORMATION;
    constructor(public applicationId: string) { }
}

export class GetTrustCertificateInformationSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_TRUST_CERTIFICATE_INFORMATION_SUCCESS;
    constructor(public certificate: TrustCertificate) { }
}

export class GetTrustCertificateInformationFailAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_TRUST_CERTIFICATE_INFORMATION_FAIL;
    constructor() { }
}

export class RedirectToGeneralErrorPage implements Action {
    readonly type = APPLICATION_ACTION_TYPES.REDIRECTO_TO_GENERAL_ERROR_PAGE;
    constructor() { }
}

export class GetTrustTypesAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_TRUST_TYPES;
    constructor() { }
}

export class GetTrustTypesSuccessAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_TRUST_TYPES_SUCCESS;
    constructor(public trustTypes: TrustTypes[]) { }
}

export class GetTrustTypesFailAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.GET_TRUST_TYPES_FAIL;
    constructor() { }
}

export class ClearApplicationStateAction implements Action {
    readonly type = APPLICATION_ACTION_TYPES.CLEAR_STATE;
    constructor() { }
}

// export class GetSelectedTrustTypeAction implements Action {
//     readonly type = APPLICATION_ACTION_TYPES.GET_TRUST_TYPES;
//     constructor(public ) { }
// }

// export class GetSelectedTrustTypeSuccessAction implements Action {
//     readonly type = APPLICATION_ACTION_TYPES.GET_TRUST_TYPES_SUCCESS;
//     constructor(public trustTypes: string[]) { }
// }

// export class GetSelectedTrustTypeFailAction implements Action {
//     readonly type = APPLICATION_ACTION_TYPES.GET_TRUST_TYPES_FAIL;
//     constructor() { }
// }

export type ApplicationAction =
    LoadApplicationAction | LoadApplicationSuccessAction |
    LoadApplicationByTokenAction | LoadApplicationByTokenSuccessAction |
    CreateApplicationAction | CreateApplicationSuccessAction | CreateApplicationFailAction |
    SetApplicationApplicants | LoadAccountsAction | LoadAccountsSuccessAction | LoadAccountsFailAction |
    SetApplicationStateAction | SetApplicationStateSuccessAction |
    UpdatePromocodeAction | UpdatePromoCodeSuccessAction |
    CancelApplicationAction | CancelApplicationSuccessAction | CancelApplicationFailAction |
    CheckInFlightApplicationAction | CheckInFlightApplicationRedirectAction |
    UpdateLocalApplicationState | CorrectApplicationRouteAction |
    GetConfirmationNumberAction | GetConfirmationNumberSuccessAction | SetVestingTypeSuccessAction | SetApplicantsMeta |
    SaveBeneficiariesAction | SaveBeneficiariesSuccessAction | SaveBeneficiariesFailAction | DeclinedBeneficiaries |
    SaveBeneficiariesContingentSuccessAction | SaveBeneficiariesContingentFailAction |
    SkipBeneficiariesAction | SkipBeneficiariesSuccessAction | SkipBeneficiariesFailAction | SkipBeneficiariesContingentSuccessAction |
    GetBeneficiariesInformationAction | GetBeneficiariesInformationSuccessAction | GetBeneficiariesInformationFailAction |
    GetBeneficiariesContingentInformationAction | GetBeneficiariesContingentInformationSuccessAction | GetBeneficiariesContingentInformationFailAction |
    SaveTrustCertificateAction | SaveTrustCertificateSuccessAction |
    GetTrustCertificateInformationAction | GetTrustCertificateInformationSuccessAction | GetTrustCertificateInformationFailAction | RedirectToGeneralErrorPage |
    GetTrustTypesAction | GetTrustTypesSuccessAction | GetTrustTypesFailAction |
    ClearApplicationStateAction;
