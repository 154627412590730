import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FEATURE_NAMES } from "store/constants";
import { LayoutState } from "store/states";

export const getLayoutState = createFeatureSelector(FEATURE_NAMES.Layout);

export const getMobileLayout = createSelector(
     getLayoutState, (state: LayoutState) => {
         return state.isMobile
     }
);