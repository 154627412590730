// Ng
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
// RxJs
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApplicationResolver } from './application.resolver';
import { ManagedPortfolioService } from 'services/universal/managed-portfolio.service';

@Injectable()
export class InvestmentGoalsResolver implements Resolve<Observable<any>> {

    constructor(private applicationResolver: ApplicationResolver, private portfolioService: ManagedPortfolioService) { }

    resolve() {
        return this.applicationResolver.resolve().pipe(
            switchMap(app => this.portfolioService.getInvestmentGoals(app.applicationId)),
            switchMap((investmentGoals) => of({investmentGoals}))
        );
    }
}
