import { Injectable } from '@angular/core';
import { DeviceProfile } from 'models';

@Injectable()
export class BrowserTypeService {
  private _isTouchDevice: boolean;
  private _doesUserAgentHaveMobileOrTabletToken: boolean;
  private _userAgent: string;

  constructor() {
    this._userAgent = navigator.userAgent;
    this._isTouchDevice = ((navigator.maxTouchPoints && navigator.maxTouchPoints > 1) || 'ontouchstart' in document.documentElement);
    this._doesUserAgentHaveMobileOrTabletToken = (/mobile|mobi|iemobile|tablet|android/i.test(this._userAgent.toLowerCase()));
  }

  public isBrowserOnMobileDevice(): boolean {
    return (this._isTouchDevice === true && this._doesUserAgentHaveMobileOrTabletToken === true );
  }

  public getBrowserAndDeviceInfo(): any {
    let deviceProfile: DeviceProfile = {
      appCodeName: navigator.appCodeName,
      appName: navigator.appName,
      appVersion: navigator.appVersion,
      maxTouchPoints: navigator.maxTouchPoints,
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      vendor: navigator.vendor
    }
    return deviceProfile;
  }

  public isSafari(): boolean {
    return this._userAgent.toLowerCase().indexOf('safari') > -1;
  }

  public isChrome(): boolean {
    return this._userAgent.toLowerCase().indexOf('chrome') > -1;
  }
}
