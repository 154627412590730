import {
    APPLICANT_IDENTIFICATION_STEP,
} from '../enums'

export class ApplicantIdentificationStep {
    Step: APPLICANT_IDENTIFICATION_STEP;
    IsComplete: boolean;
    IsInProgress: boolean;

    constructor(step: APPLICANT_IDENTIFICATION_STEP) {
        this.Step = step;
        this.IsComplete = false;
        this.IsInProgress = true;
    }
}
