import { ModuleFlow } from 'models/navigation';

import { BUSINESS_FLOW } from './flows.constants';
import {
    BUSINESS_MODULE_PREFIX,
    BUSINESS_PARENT_PATH,
    BUSINESS_ROUTES
} from './route.constants';

export const BUSINESS_INFORMATION_MODULE_FLOW: ModuleFlow = {
  getFlow() { return BUSINESS_FLOW; }
};
