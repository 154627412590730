declare var environment: any;
import { Component } from '@angular/core';
import { BRAND_ID } from 'models/enums';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {

  get isUFB() { return environment.brandId === BRAND_ID.UFBDirect; }
  get logoURL() { return `assetsheader/logo${this.isUFB ? '-dark' : ''}.svg`; }

  constructor() { }
}
