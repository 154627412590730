import { Injectable } from '@angular/core';
// Models
import { AnnualSales, Country, State, IGenericItem, FinancialInformationCatalog, BeneficiariesCatalog, Beneficiary } from 'models';
import { TAX_BRACKETS } from './mocks/mock-data/financial-information.mock';

import { BaseService } from './abstracts';

import { of, Observable } from 'rxjs';
import { CatalogItem } from 'models/trading/catalog-item.model';
import { AxosResult } from 'models/enums/trading';

import { map } from 'rxjs/operators';

@Injectable()
export class GenericCatalogsService extends BaseService {
    getAnnualSalesRange() { return super._get<AnnualSales[]>(`v2/AnnualSales`); }

    getCountries() { return super._get<Country[]>('generic/common/country'); }

    getStates() { return super._get<State[]>('generic/common/states/us'); }

    getOccupations() { return super._get<CatalogItem[]>(`generic/common/occupation`); }

    getCompanyTitles() { return super._get<CatalogItem[]>(`generic/catalog/companyTitle`); }

    getAffiliations() { return super._get<CatalogItem[]>(`generic/trading/catalog/tradingSecuritiesPersonalAffiliation`); }

    getFinancialCatalogInfo(): Observable<FinancialInformationCatalog> {
        return this._get<FinancialInformationCatalog>('generic/trading/catalog/financial');
    }

    getBeneficiariesCatalogInfo(): Observable<CatalogItem[]> { return this._get<AxosResult<CatalogItem[]>>('generic/beneficiaries/relationships').pipe(map((res) => res.data));}
}
