import { OrganizationState } from '../states';
import * as fromActions from '../actions/features.action';
import { FeatureFlag } from 'models';
import { FeatureFlagsState } from 'store/states/feature-flags.state';
import { FeatureFlagsEffects } from 'store/effects/feature-flags.effects';

export const initialState: FeatureFlagsState = {
    featureFlags : null
}

export function reducer(
    state = initialState,
    action: fromActions.FeaturesAction
  ): FeatureFlagsState {
    switch(action.type) {
      case fromActions.FEATURES_ACTION_TYPES.GET_FEATURE_STATUS: {
        return { ...state }
      }
      case fromActions.FEATURES_ACTION_TYPES.GET_FEATURE_STATUS_SUCCESS: {
        const featureFlags = state && state.featureFlags ? state.featureFlags.filter(x => x.name != action.featureName) : [];
        return {          
          ...state,
           featureFlags: [ ...featureFlags, { name: action.featureName, isEnabled: action.isEnabled} ]
        }
      }
      case fromActions.FEATURES_ACTION_TYPES.GET_FEATURE_STATUS_FAIL: {
        return { 
          ...state, 
          featureFlags: [ ...state.featureFlags ]
        }      
      }    
    }
    return state;
}