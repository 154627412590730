import { GET_CACHE, SET_CACHE } from 'app/core/utils';

export abstract class CacheService {

    constructor() { }

    protected getCached(storage_name: string) {
        return GET_CACHE(storage_name);
    }

    protected setCache(value: any, storage_name: string, temp = false) {
        SET_CACHE(value, storage_name, temp);
    }
}