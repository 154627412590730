declare var environment: any;

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding
} from '@angular/core';
// RxJs
import { combineLatest, map } from 'rxjs/operators';
// NgRx Store
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { EnrollmentState } from 'store';
import {
  getBanner,
  getBannerIsExisting,
  getProgress,
  getSubBanner
} from 'store/selectors';

@Component({
    selector: 'axos-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { 'class': 'center middle uk-text-center' }
})
export class BannerComponent {
  isProxy = !!environment.isProxy;
  banner$ = this.store$.select(getBanner);
  subBanner$ =  !this.isProxy? this.store$.select(getSubBanner): of(null);
  progress$ = this.store$.select(getProgress);
  isExisting$ = this.store$.select(getBannerIsExisting);

  data$ = this.banner$.pipe(
    combineLatest(
      this.subBanner$,
      this.progress$
    ),
    map(([banner, subBanner, progress]) => {
      return { banner: banner, subBanner: subBanner, progress: progress };
    })
  )

  @HostBinding('class.existing-customer') isExisting: boolean;
  @HostBinding('class.brand-banner') showBrandBanner: boolean = environment.orgSettings ? environment.orgSettings.some(x => x.name === 'brandBanner') : false;
  @HostBinding('class.brand-mobile-banner') showBrandMobileBanner: boolean = environment.orgSettings ? environment.orgSettings.some(x => x.name === 'brandMobileBanner') : false;

  constructor(private store$: Store<EnrollmentState>) {
    this.isExisting$.subscribe(isExisting => this.isExisting = isExisting);
  }
}
